<template>
  <div :class="[{ 'has-icons-right': !!styleType,
                'is-clearfix': !hasMessage || icon,
                'is-expanded': expanded,
                'has-icons-left': icon },
                'control',
                wrapperClass]">
    <slot>
      <input  v-bind="$attrs"
              :value="value"
              :disabled="disabled"
              :autocomplete="autoComplete"
              :class="['input', inputTagClass, styleType]"
              :maxlength="maxLength"
              :placeholder="placeholder"
              type="text"
              @input="updateValue"
              @keydown.enter="handleEnter"
              @blur="emit('blur')">
    </slot>
    <slot name="icon">
      <bulma-icon v-if="icon"
                  :icon-class="icon"
                  class="is-left"/>
    </slot>
    <span v-show="!!styleType"
          :class="['icon is-right', getTextClass()]">
      <i  :class="['fa', getIconClass()]"/>
    </span>
  </div>
</template>

<script setup>
/* Intended replacement for Buefy Field.vue AKA b-input
 * In contrast to b-input, it only supports text type.
 * If a different type is necessary, utilize the slot
 * to override the default input tag.
 * See DetailEditorForm.vue for typical implementation.
 * See SettingInput.vue for implementation using slot.
 */
import BulmaIcon from '@/components/common/BulmaIcon.vue'

const props = defineProps({
  maxLength: {
    type: Number,
    default: 255
  },
  wrapperClass: {
    type: String,
    default: ''
  },
  // styleType must follow Bulma color naming convention
  // E.g., is-danger, is-primary, etc.
  styleType: {
    type: String,
    default: ''
  },
  autoComplete: {
    type: String,
    default: 'on'
  },
  value: {
    type: String,
    default: ''
  },
  hasMessage: Boolean,
  disabled: Boolean,
  placeholder: {
    type: String,
    default: ''
  },
  expanded: Boolean,
  icon: {
    type: String,
    default: ''
  },
  inputTagClass: {
    type: String,
    default: ''
  }
})

const styleTypeToTextMap = new Map([
  ['is-danger', 'has-text-danger'],
  ['is-success', 'has-text-success'],
  ['is-info', 'has-text-info'],
  ['is-warning', 'has-text-warning'],
  ['is-primary', 'has-text-primary'],
  ['is-secondary', 'has-text-secondary']
])
const getTextClass = () => styleTypeToTextMap.has(props.styleType)
  ? styleTypeToTextMap.get(props.styleType)
  : ''

const typeToIconsMap = new Map([
  ['is-danger', 'fa-exclamation-circle'],
  ['is-success', 'fa-check'],
  ['is-info', 'fa-info-circle'],
  ['is-warning', 'fa-exclamation-triangle']
])
const getIconClass = () => typeToIconsMap.has(props.styleType)
  ? typeToIconsMap.get(props.styleType)
  : ''

const emit = defineEmits(['input', 'enter', 'blur'])

function updateValue(e) {
  emit('input', e.target.value.trim())
}
function handleEnter() {
  emit('enter')
}
</script>
