<template>
  <a  role="listitem"
      class="has-text-dark my-3"
      :class="{ 'is-disabled': disabled }"
      @click="handleClick">
    <slot>
      <span :class="[icon, 'pr-4 fas']"/>
      {{ text }}
    </slot>
  </a>
</template>

<script setup>
const props = defineProps({
  text: {
    type: String,
    default: ''
  },
  icon: {
    type: String,
    default: ''
  },
  disabled: Boolean
})
const emits = defineEmits(['click'])

function handleClick() {
  if (!props.disabled) {
    emits('click')
  }
}
</script>
<style  scoped>
.is-disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
</style>