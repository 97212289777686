<template>
  <div class="anchor-navigation">
    <nav>
      <h5>{{ $t('goTo') }}:</h5>
      <a v-if="canViewComments"
         @click="navigateTo('Comments')">
        <pills-tag  :text="$tc('comment', 2)"
                    text-color="is-info"
                    :display-empty-pill="true"
                    :total="content.comments.total"/>
      </a>
      <a v-if="canViewRelated"
         @click="navigateTo('Related')">
        <pills-tag  :text="$t('related')"
                    text-color="is-info"
                    :display-empty-pill="true"
                    :total="content.related.total"/>
      </a>
      <a v-if="canViewWhereUsed"
         @click="navigateTo('WhereUsed')">
        <pills-tag  :text="$t('whereUsed')"
                    text-color="is-info"
                    :display-empty-pill="true"
                    :total="content.whereused.total"/>
      </a>
      <a v-if="canViewSuggestions"
         @click="navigateTo('Suggestions')">
        <pills-tag  :text="$tc('suggestion', 2)"
                    text-color="is-info"
                    :display-empty-pill="true"
                    :total="content.suggestions.total"/>
      </a>
    </nav>
  </div>
  <div v-if="!hideBanner" class="where-used-banner">
    <span class="where-used-banner-text">
       {{ $t('partVerificationInstructions') }}
    </span>
    <button class="button where-used-banner-button"
      @click="navigateTo('WhereUsed')"
    >
      <span class="where-used-banner-button-text">{{ $t('viewWhereUsed') }}</span>
    </button>
  </div>
</template>

<script setup>
import { useStore } from 'vuex'
import { computed } from "vue"
import { EntityType } from "@/interfaces/global"

import PillsTag from "@/components/common/PillsTag.vue"
import theme from "@/store/modules/theme/theme";

const store = useStore()

const content = computed(() => store.state['content'])
const isPart = computed(() => content.value.type === EntityType.part)
const isPage = computed(() => content.value.type === EntityType.page)
const isPagePart = computed(() => isPart.value && content.value.pagePartId !== '')
const canViewComments =  computed(() => store.getters['user/canViewComments'])
const canViewRelated = computed(() => store.getters['user/canViewRelated'])
const canViewWhereUsed = computed(() => isPart.value || isPage.value)
const canViewSuggestions = computed(() => {
  return store.getters['user/hasPartOrderSuggestions'] && isPart.value
})
const showPartInformationBanner = computed(() => store.state.user.showPartInformationBanner)
const hideBanner = computed(() => (!isPart.value || isPagePart.value) || !showPartInformationBanner.value || (store.state.user.hidePagesInSearch && store.state.user.hideBooksInSearch))
const navigateTo = (anchor) => {
  document.getElementById(anchor)?.scrollIntoView()
}
</script>

<style scoped lang="scss">
.where-used-banner {
  border: 2px solid;
  border-radius: 0.5rem;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  background-color: var(--banners);
  color: var(--banners--color-invert);
  border-color: var(--banners--dark-color);
  padding: 5px;
  @media (max-width: 500px) {
    justify-content: left;
  }
}
.where-used-banner-text {
  font-weight: bold;
  margin-right: 1rem;
  font-size: 1.10rem;
  @media (max-width: 500px) {
    width: 75%;
  }
}
.where-used-banner-button {
  background-color: var(--banners--color-invert) !important;
  border-color: var(--banners--80--rgba) !important;
  color: var(--banners--color-invert-text) !important;
  height: 1.75rem;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6) !important;
  @media (max-width: 500px) {
    width: 75%;
  }
  @media (max-width: 300px) {
    width: 50%;
  }
  &:focus {
    outline: none !important;
  }
}
.where-used-banner-button-text {
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
