import http from '@/http'
import i18n from '@/locales'
import { notify } from "@kyvg/vue3-notification"
import router from '@/router'
import bom from './bom/bom'
import comments from './comments'
import reportanissue from './reportanissue'
import exports from './exports'
import pricing from './pricing'
import related from './related'
import suggestions from './suggestions'
import tags from './tags'
import toc from './toc'
import whereused from './whereused'
import qs from 'qs'
import { entityTypePluralize, routeToEntityTypeAndId } from './contentHelpers'
import {
  CONTENT_RECEIVED, CONTENT_LOADING, BOM_ILLUSTRATION_RECEIVED, CLEAR_CONTENT, clearContentMutations
} from './contentTypes'
import { saveUserAction, setClickedThrough } from '@/controllers/library'
import { getUserActionQuery } from './helper'
import { getRoute } from "@/router/getRoute"
import { SearchEntityTypes } from '@/helpers/SearchEntityType'
import { getExactMatchParameter } from "@/controllers/global";

const HOTPOINTS_RECEIVED = 'HOTPOINTS_RECEIVED'

const state = getDefaultState()

const getters = {
  hotpointsFlatList(state) {
    if (!!state.hotpoints) {
      return Object.values(state.hotpoints).flat()
    } else {
      return []
    }
  },
  pspdfDocumentData(state) {
    if (state.contentType === 'document') {
      return {
        documentId: state.pspdfDocumentId,
        jwt: state.pspdfJwtToken
      }
    } else {
      return null
    }
  }
}

const actions = {
  // A copy of below, to respect the different callers and their respective privileges
  async getAdminContent ({ commit, state, dispatch, rootState }, { id, type }) {
    try {
      const entities = routeToEntityTypeAndId(id, type)
      const { 0: first, [entities.length - 1]: last } = entities
      const wasPagePart = state.pagePartId ? last.id !== state.pagePartId : false

      if (!state.isLoaded || last.id !== state.id || wasPagePart) {
        commit(CONTENT_LOADING)
        const entityTypePlural = entityTypePluralize(last.type)

        // Fetch Bom/TOC
        dispatch('getTOC', { id: first.id, type: first.type })
        let content
        if (last.type === 'pagepart') {
          const { [entities.length - 2]: page } = entities
          dispatch('getBom', { id: page.id, type: page.type })

          const [result1, result2] = await Promise.all([
            http.get(`admin/${entityTypePlural}/?pagePartId=${last.id}`),
            http.get(`admin/${entityTypePluralize(page.type)}/${page.id}`)
          ])
          content = result1.data
          content.pagePartId = last.id
          content.bookId = entities.length > 1 ? first.id : ''
          commit(CONTENT_RECEIVED, { content })
          commit(BOM_ILLUSTRATION_RECEIVED, { illustration: result2.data })
        } else {
          const url = `admin/${entityTypePlural}/${last.id}`
          content = await dispatch('tryPageContent', url)
          content.bookId = entities.length > 1 ? first.id : ''
          commit(CONTENT_RECEIVED, { content })

          // ** Content received must be committed prior to dispatching getAdminBom
          await dispatch('getAdminBom', { id: last.id, type: last.type })
          commit(BOM_ILLUSTRATION_RECEIVED, {
            illustration: content.contentType === 'illustration' ? content : null
          })
        }
      }

      const currentRoute = getRoute()
      if (state.type === 'part' && currentRoute.name === 'Asset') {
        router.replace({ path: `${currentRoute.path}/details` }).catch(() => {})
      }
    } catch (err) {
      console.log('err :>> ', err)
      const response = err.response
      router.replace({ path: '/error', query: { status: response.status } }).catch(() => {})
    }
  },

  async tryPageContent (Context, url) {
    let content = null

    //  cannot reach Vue.prototype.$wait so creating our own wait method
    async function timeout (ms) {
      return new Promise(async resolve => setTimeout(resolve, ms));
    }

    //  test if any sheet has a zoom url
    async function hasZoomUrl(sheets) {
      let hasZoomUrl = await sheets
        .filter(sheet => sheet.openZoomUrl)
      return hasZoomUrl && hasZoomUrl.length === sheets.length
    }

    //  retry for 10 seconds to get page sheet with zoom url
    async function retry (url) {
      const retries = 120
      try {
        for (let i = 0; i < retries; i++) {
          await timeout(250)
          const { data } = await http.get(url)
          content = data
          if (content) {
            if(await hasZoomUrl(content.sheets)) break
          }
        }
      } catch (err) {
        throw new Error()
      }
    }

    //  First attempt and see if a ZoomUrl is returned else retry
    try {
      const { data } = await http.get(url)
      if (data) {
        if (!await hasZoomUrl(data.sheets)  && !data.imageless) {
          await retry(url)
        } else {
          content = data
        }
      }
    } catch(err) {
      throw new Error()
    }
    return content
  },
  async getContent ({ commit, rootState, state, dispatch }, { id, type, queryVal }) {
    try {
      // Build stringified query of a UserActionLog with respect to viewed library content
      // and then save it to DB if media type is valid.
      // Per discussion with Product: invalid query will be logged in the console
      if (!id) return
      // new path needs to be removed for logging old routes properly
      let strippedId = id.replace('/details', '')

      const query = getUserActionQuery(strippedId, type)
      if (query) {
        saveUserAction(query)
      }

      const entities = routeToEntityTypeAndId(strippedId, type)
      const { 0: first, [entities.length - 1]: last } = entities

      commit(CONTENT_LOADING)
      const entityTypePlural = entityTypePluralize(last.type)

      /*
        * Awaiting this dispatch ICW the conditional GET request
        * in the else block below ensures that multiple GET requests
        * are not performed to "/media/:id" for the same content
        * by preventing a race condition of updating content in state
        */
      await dispatch('getTOC', { id: first.id, type: first.type })

      let content
      if (last.type === 'pagepart') {
        const { [entities.length - 2]: page } = entities
        await dispatch('getBom', { id: page.id, type: page.type })

        const [result1, result2] = await Promise.all([
          http.get(`${entityTypePlural}/?pagePartId=${last.id}`),
          http.get(`${entityTypePluralize(page.type)}/${page.id}`)
        ])
        content = result1.data
        content.pagePartId = last.id
        content.bookId = entities.length > 1 ? first.id : ''
        commit(CONTENT_RECEIVED, { content })
        commit(BOM_ILLUSTRATION_RECEIVED, { illustration: result2.data })
      } else {
        await dispatch('getBom', { id: last.id, type: last.type })

        /*
         * This if-check ICW the previous getTOC dispatch call
         * ensures that multiple GET requests are not performed
         * to "/media/:id" for the same content
         * Equivalent code to this block conditionally runs in the
         * getTOC action found in toc.js
        */
        if (last.id !== rootState.content.id) {
          try {
            const { data } = await http.get(`${entityTypePlural}/${last.id}`)
            content = data
            content.bookId = entities.length > 1 ? first.id : ''
            commit(CONTENT_RECEIVED, { content })
            commit(BOM_ILLUSTRATION_RECEIVED, {
              illustration: rootState.content.contentType === 'illustration' ? content : null
            })
          } catch(err) {
            router.replace({ path: '/error', query: { status: err.response.status } })
              .catch(() => {})
          }
        }
      }

      await dispatch('getCallouts')
      commit(CONTENT_LOADING, false)

      const currentRoute = getRoute()
      if (state.type === 'part' && currentRoute.name === 'Asset') {
        router.replace({ path: `${currentRoute.path}/details`, query: queryVal ?? currentRoute.query }).catch(() => {})
      }
    } catch (err) {
      console.log('err :>> ', err)
      const response = err.response
      router.replace({ path: '/error', query: { status: response.status } }).catch(() => {})
    }
  },
  async getContentWithReturn (context, searchResult) {
    let content
    try {
      const entityTypePlural = entityTypePluralize(searchResult.entityType)
      const { data } = await http.get(`${entityTypePlural}/${searchResult.entityId}`)

      // Transform Data a bit for UI before Returning
      data.pricing = {
        retailPrice: data.retailPrice || '',
        discountPrice: data.discountPrice || '',
        wholesalePrice: data.wholesalePrice || '',
        availability: '',
        eta: ''
      }

      content = data
    } catch (err) {
      console.log(err)
    }
    return content
  },
  async getCallouts ({ state, commit }) {
    try {
      if (state.contentType === 'illustration') {
        const entityTypePlural = entityTypePluralize(state.type)
        let params = {}
        if (state.bookId) {
          params = { mediaId: state.bookId }
        }
        const { data } = await http.get(
          `${entityTypePlural}/${state.id}/hotpoints`,
          { params }
        )
        commit(HOTPOINTS_RECEIVED, data)
      }
    } catch (err) {
      console.log(err)
    }
  },
  navigateToContent (context, { content, query, replace = false }) {
    const parts = []
    if (content.mediaId) {
      parts.push('book')
      parts.push(content.mediaId + '')
    }

    const entityId = content.entityId || content.id
    const entityType = content.mediaType || content.entityType ||
      content.contentType || content.type
    parts.push(entityType)
    parts.push(entityId)

    const path = '/' + parts.reduce((acc, cur, idx, src) => {
      if (idx % 2 === 0) {
        const next = [cur, src[idx + 1]]

        if (idx === 0) {
          return [...acc, ...next]
        } else {
          return [...acc, next.join(':')]
        }
      }
      return acc
    }, []).join('/')

    if (replace) {
      router.replace({ path: path, query: query }).catch(() => {})
    } else {
      router.push({ path: path, query: query }).catch(() => {})
    }
  },
  navigateToContentPath (context, { path, replace }) {
    // In some cases a hash needs to be set based on what the end user selected.
    // This value has been appended to the path already at this point
    // check what type of icon the user clicked in order to set a hash value on the path
    // Ultimately they will be navigated and then scrolled to the proper section of page
    // by usage of the native browser url #hash anchor api
    let hash = ''
    if(context.rootState.user.accessPrivileges.indexOf('ENABLE_LIBRARY_BETA_FEATURES')) {
      hash = path.includes('#Related')
        ? '#Related'
        : path.includes('#Comments')
          ? '#Comments'
          : path.includes('#InfoWrapper')
            ? '#InfoWrapper'
            : ''
    }
    if (replace) {
      router.replace({ path, hash: hash}).catch(() => {})
    } else {
      router.push({ path, hash: hash }).catch(() => {})
    }
  },
  async exportSearchContent (context, { route, payload }) {
    const params = {}
    const exactMatch = getExactMatchParameter()
    const { q = '' } = route.query

    if (q !== null && q !== undefined && q !== '') {
      params.q = exactMatch ? `"${q}"` : q
    }

    params.entity_type = '-Chapter'

    const prefixes = []
    const suffixes = []
    Object.keys(this.state.search.filters.selected).forEach((key) => {
      const prefixIndex = key.indexOf('_prefix')
      const suffixIndex = key.indexOf('_suffix')
      if (prefixIndex > -1) {
        prefixes.push(this.state.search.filters.selected[key])
      } else if (suffixIndex > -1) {
        suffixes.push(this.state.search.filters.selected[key])
      } else {
        params[key] = this.state.search.filters.selected[key]
      }
    })

    if (prefixes.length > 0) {
      params.tag_range_prefix = [prefixes.toString()]
    }
    if (suffixes.length > 0) {
      params.tag_range_suffix = [suffixes.toString()]
    }

    const formData = new FormData()
    formData.append('solrQueryString', qs.stringify(params, { indices: false }))
    formData.append('locale', payload.locale)
    formData.append('pageSize', payload.pageSize)
    formData.append('orientation', payload.orientation)
    try {
      await http.post('/bulkPrinter/zip', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
    } catch (error) {
      console.log('error :>> ', error)
    }
    notify({
      title: i18n.global.t('export'),
      text: i18n.global.t('exportMessage'),
      type: 'success'
    })
  },
  clearContent (context) {
    // Clear state in content & all child modules
    clearContentMutations.forEach(it => context.commit(it))
    context.commit(CONTENT_LOADING, true)
    context.dispatch('asyncFetcher/cancelAllTimers', {}, { root: true })
  },
  setContentLoading({ commit }, isLoading = true) {
    commit(CONTENT_LOADING, isLoading)
  },
  callSearchTracker (context, { searchTrackingId, contentType, contentId }) {
    if (!searchTrackingId || !contentType || !contentId) return

    try {
      setClickedThrough({
        searchLogId: searchTrackingId,
        contentType: SearchEntityTypes[contentType],
        contentId: contentId
      })
    } catch (e) { }
  }
}

const mutations = {
  [HOTPOINTS_RECEIVED] (state, payload) {
    state.hotpoints = payload
  },
  [CONTENT_RECEIVED] (state, { content }) {
    state.id = content.id || ''
    state.type = content.type || ''
    state.contentType = content.contentType || content.type || ''
    state.identifier = content.identifier || ''
    state.name = content.name || ''
    state.originalName = content.originalName || ''
    state.description = content.description || ''
    state.created = content.created || ''
    state.updated = content.updated || ''
    state.thumbnailUrl = content.thumbnailUrl || ''
    state.thumbnailUrlLarge = content.thumbnailUrlLarge || ''

    state.contentUrl = content.contentUrl || ''

    state.externalUrl = content.externalUrl || ''
    state.openInNewBrowserWindow = content.openInNewBrowserWindow || false
    state.micrositeEntryFile = content.micrositeEntryFile || '' // Microsite

    state.pagePartId = content.pagePartId || ''
    state.partNumber = content.partNumber || ''
    state.partCode = content.partCode || false
    state.quantity = content.quantity || 0
    state.orderable = (content.orderable && !content.partCode) || false

    state.sheets = content.sheets || null
    state.bookId = content.bookId || ''
    state.imageless = content.imageless || null
    state.disableExport = content.disableExport || false
    state.pageHashkey = content.hashKey ?? null
    state.tagCount = content.tagCount?? null
    state.suggestedCount = content.suggestedCount?? null
    state.enableQuickAddForContent = content.enableQuickAddForContent?? null
    state.pspdfDocumentId = content.pspdfDocumentId ?? null
    state.pspdfJwtToken = content.pspdfJwtToken ?? null
    state.pspdfDocumentUnsupported = content.pspdfDocumentUnsupported ?? false
  },
  [CONTENT_LOADING] (state, value = true) {
    state.isLoaded = !value
  },
  [CLEAR_CONTENT] (state) {
    Object.assign(state, getDefaultState())
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: {
    bom,
    comments,
    reportanissue,
    exports,
    pricing,
    related,
    suggestions,
    tags,
    toc,
    whereused
  }
}

export function getDefaultState() {
  return {
    id: '',
    type: '',
    contentType: '',
    identifier: '',
    name: '',
    originalName: '',
    description: '',
    created: '',
    updated: '',
    thumbnailUrl: '',
    thumbnailUrlLarge: '',
    hotpoints: null,
    contentUrl: '',
    externalUrl: '',
    openInNewBrowserWindow: '',
    pagePartId: '',
    partNumber: '',
    quantity: 0,
    orderable: false,
    partCode: false,
    sheets: null,
    isLoaded: false,
    bookId: '',
    imageless: null,
    disableExport: false,
    tagCount: null,
    suggestedCount: null,
    enableQuickAddForContent: null,
    pspdfDocumentId: null,
    pspdfJwtToken: null,
    /**
     * Applicable to large files or unsupported file types
     */
    pspdfDocumentUnsupported: false
  }
}
