/* eslint-disable max-len */
module.exports =
{
  aOfB: '{a} z {b}',
  about: 'Informacje',
  aboutThisItem: 'O tym elemencie',
  accessApiKeyManager: 'Dostęp do menedżera klucza API tego środowiska.',
  accessControls: 'Kontrola Dostępu',
  accessControlsCopiedText: 'Ustawienia kontroli dostępu są aktualizowane natychmiastowo. Uprawnienia zabezpieczeń multimediów zostaną zaktualizowane po upływie kilku minut.',
  accessControlsCopiedTitle: 'Z powodzeniem skopiowano ustawienia kontroli dostępu',
  accessControlsCopyConfirmation: 'Spowoduje to usunięcie wszystkich istniejących ustawień kontroli dostępu z {to} i zastąpienie ich ustawieniami kontroli dostępu z {from}. Tej operacji nie można cofnąć.',
  accessControlsDescription: 'Kontrola dostępu określa, kto może przeglądać media w zależności od wybranych kategorii Organizacje oraz Kategorie Mediów.',
  accessControlsIndeterminance: 'Uwaga: Organizacje nadrzędne zawsze mają prawa dostępu do treści każdej z organizacji podrzędnych.',
  account: 'Konto',
  accountErrorMessage: 'Komunikat o błędzie konta',
  accountErrorMessageWarning: 'Pole accountErrorMessage w bazie danych dla tego środowiska zostanie ustawione na „Brak uprawnień”.',
  action: 'Czynność | Czynności',
  actionDelete: 'usuń',
  actionLoad: 'załaduj',
  actionPaste: 'Wklej',
  actionSave: 'Zapisz',
  actionRun: 'wykonaj',
  actionUndoWarning: 'Tej operacji nie można cofnąć.',
  actionUpload: 'prześlij',
  actualSize: 'Faktyczny Rozmiar',
  add: 'Dodaj',
  addAPIKey: 'Dodaj Klucz API',
  addItem: 'Dodaj {item}',
  addABookText: 'Dodaj katalog',
  addASharedCart: 'Dodaj koszyk współdzielony',
  addATemplate: 'Dodaj szablon',
  addATag: 'Dodaj etykietę',
  addABookToStart: 'Aby rozpocząć, {addABookText}.',
  addAForm: 'Dodaj Formularz, aby rozpocząć',
  addAFormOnly: 'Dodaj formularz',
  addAMedia: 'Dodaj multimedia',
  addAnOrderQuoteEmailFormat: 'Dodaj format wiadomości e-mail z zamówieniem/zapytaniem',
  addAPrintSettingText: 'Dodaj Ustawienia Drukowania',
  addAPrintSettingToStart: 'Aby rozpocząć, {addAPrintSettingText}.',
  addBooks: 'Dodaj Katalogi',
  addChildBrowseFlow: 'Dodaj Podrzędny Kierunek Przeglądania',
  addChapter: 'Dodaj Rozdział',
  addComment: 'Dodaj komentarz',
  addExistingPart: 'Dodaj Istniejącą Część',
  addGroupsToUsers: 'Dodaj grupy {groups} do użytkowników: {users}',
  additionalInformation: 'Dodatkowa Informacja',
  additionalNotification: 'Dodatkowe Powiadomienie',
  additionalPartNumberDescription: 'Użyj jako dodatkowego pola numeru części',
  addBrowseFlow: 'Dodaj Kierunek Przeglądania',
  addMedia: 'Dodaj Multimedia',
  addPages: 'Dodaj Strony',
  addPart: 'Dodaj Część',
  addReports: 'Dodaj Raporty',
  addRootBrowseFlow: 'Dodaj Główny Kierunek Przeglądania',
  addRow: 'Dodaj wiersz',
  addTemplates: 'Dodaj szablony',
  addToCartMessage: 'Pomyślnie dodano pozycję {partNumber}',
  addToUserGroup: 'Dodaj do Grup',
  address: 'Adres | Adresy',
  addressInUse: 'Adres jest obecnie ustawiony jako adres domyślny i nie może zostać usunięty',
  addressInUseSubtitle: 'Aby usunąć ten adres, musisz najpierw anulować ustawienie tego adresu jako domyślnego.',
  addressLine: 'Linia adresu {number}',
  addWidgetKey: 'Dodaj klucz widżetu',
  admin: 'Administrator',
  adminCenter: 'Centrum Administracyjne',
adminCenterBanner: {
    bodyLabel: 'Treść baneru',
    enableLabel: 'Włącz baner administratora',
    headerGreenOption: 'Zielony',
    headerLabel: 'Nagłówek baneru',
    headerRedOption: 'Czerwony',
    headerYellowOption: 'Żółty',
    title: 'Baner centrum administracyjnego',
    typeLabel: 'Typ baneru'
  },
  adminCenterMessage: 'Centrum administracyjne dla zawartości, użytkowników, ustawień biblioteki, raportowania i eksportu',
  adminMessage: 'Funkcje są dodawane w miarę ich udostępniania',
  administration: 'Administracja',
  affectedBookMedia: 'Elementy multimedialne ksiąg, które zostaną zaktualizowane: {count}.',
  affectedTemplateMedia: 'Szablony ksiąg, które zostaną zaktualizowane: {count}.',
  after: 'Po',
  aggregatedFromChildren: 'Zgromadzone z Podmiotów Podrzędnych',
  aiAlgorithm: 'Skaner SI',
  all: 'Wszystko',
  alphanumeric: 'Alfanumeryczne',
  algorithm: 'Algorytm',
  alignHorizontal: 'Wyrównaj w Poziomie',
  alignVertical: 'Wyrównaj w Pionie',
  allUsersDisabled: 'Wszyscy użytkownicy należący do środowiska zostaną zablokowani.',
  analyticsJSON: 'Analityka JSON',
  andCountMore: 'i więcej: {count}…',
  any: 'Dowolne',
  apiKey: 'Klucz API | Klucze API',
  apiKeyAdmin: 'Administrator Klucza API',
  apiAuthHeader: 'Nagłówek Autoryzacji API',
  apiKeyCopied: 'Skopiowano wartość Nagłówka Autoryzacji API do Schowka',
  apiKeyDeleted: 'Klucz API usunięty',
  apiKeyDeletedError: 'Błąd usuwania Klucza API',
  apiKeyDeleteMsg: 'Usunięcie tego Klucza API wyłączy jego użycie przez integracje',
  apiKeyDeleteTitle: 'Czy na pewno chcesz usunąć ten Klucz API?',
  apiKeyInstructions: 'Klucz zostanie wygenerowany po Zapisaniu',
  apiKeyManager: 'Menedżer Klucza API',
  apiKeyNameEmpty: 'Nazwa nie może być pusta',
  apiKeyRegenError: 'Błąd podczas ponownego generowania Klucza API',
  apiKeyRegenMsg: 'Ponowne wygenerowanie tego Klucza API może wyłączyć istniejące integracje',
  apiKeyRegenTitle: 'Czy na pewno chcesz ponownie wygenerować ten Klucz API?',
  apiKeySaved: 'Klucz API Zapisany',
  apiKeyType: 'Typ klucza API',
  appliesTo: 'Dotyczy',
  appliesToEmpty: 'Wymagany jest przynajmniej jeden typ jednostki',
  appliesToPopulated: 'Zdecyduj, do jakich jednostek można stosować znacznik',
  applyItem: 'Zastosuj obiekt {item}',
  applyToChildItems: 'Zastosuj do elementu podrzędnego {item}',
  applyToPartOnly: 'Zastosuj tylko do tej części',
  april: 'Kwiecień',
  area: 'Obszar',
  areYouSure: 'Czy na pewno chcesz usunąć {item}?',
  areYouSureCopy: 'Czy na pewno chcesz skopiować {item}?',
  areYouSureYouWantToDisableTheTenant: 'Czy na pewno chcesz zablokować środowisko?',
  assembly: 'Zespół | Zespoły',
  assemblyCreatorSuccessTitle: 'Przesłano zadanie Utwórz montaż',
  assemblyCreatorSuccessSubtitle: 'Linki Hotpoint i zmiany w spisie treści katalogów zostaną wprowadzone natychmiast. Wyniki wyszukiwania zostaną odzwierciedlone po zakończeniu indeksowania.',
  assemblyConfirmationTitle: 'Czy na pewno chcesz zakończyć tworzenie zespołu?',
  assemblyCreatorHelpText1: 'Ta funkcja pozwala na wstawienie wybranej strony do katalogów wszędzie tam, gdzie znajduje się ta część {partName}, co automatycznie łączy część i wybraną stronę łączem hotpoint. Opcjonalnie można ograniczyć wstawianie łączy tylko do wybranych katalogów.',
  assemblyCreatorHelpText2: 'Wybrana strona jest wstawiana raz na katalog po pierwszym wystąpieniu części {partName}.',
  assemblyStepOne: 'Krok 1: Wybierz stronę do wstawienia',
  assemblyStepTwo: 'Krok 2: Wybierz katalogi, do których chcesz wstawić stronę',
  asset: 'Zasób | Zasoby',
  assetsNotFound: 'Nie Znaleziono: {type}',
  assignBrowseFlow: 'Przypisz Kierunek Przeglądania',
  assignedOrganizations: 'Przypisane Organizacje',
  atLeastFiveError: 'Prosimy wybrać wartość, która nie jest niższa niż 5.',
  attachment: 'Załącznik | Załączniki',
  attachmentEditorDescription: 'Załącz obrazy, klipy wideo oraz dokumenty bezpośrednio do tej jednostki. Pamiętaj, że nie będą się one znajdować w zakładce „Powiązane”.',
  attachmentView: 'Zobacz Załączniki',
  attachRelatedPDFs: 'Załącz powiązane strony części w formacie PDF',
  attributions: 'Atrybucje',
  august: 'Sierpień',
  automaticZoom: 'Powiększenie Automatyczne',
  autoHotpoint: 'Automatyczne Punkty Interaktywne',
  autoPopulate: 'Automatycznie Wypełnij',
  availability: 'Dostępność',
  back: 'Wstecz',
  backCover: 'Okładka Tylna',
  backStitch: 'Szycie Grzbietu',
  backToLibrary: 'Powrót do Biblioteki',
  backToSearch: 'Powrót do Wyszukiwania',
  badCredentials: 'Błędne Dane Uwierzytelniające',
  bahasaDescription: 'Opis Indonezyjski',
  bahasaName: 'Nazwa Indonezyjska',
  banner: 'Baner',
  before: 'Przed',
  beginTypingToFindAnything: 'Zacznij wpisywanie, aby rozpocząć szukanie',
  betaAccessKeys: 'Klucze dostępu do wersji beta',
  betaResources: 'Zasoby Beta',
  betaReleaseVideo: 'Wideo w Wersji Beta',
  blobFinder: 'Wyszukiwarka BLOB',
  body: 'Treść',
  bomDefaultSupplier: 'Domyślny dostawca listy materiałowej',
  bomItem: 'Pozycja na Liście Materiałowej',
  bom: 'Lista Materiałowa',
  bomExportSuccess: 'Pomyślnie wyeksportowano listę materiałową',
  bomLayout: 'Układ Zestawienia Materiałowego',
  bomListOptions: 'Lista Opcji Zestawienia Materiałów',
  bomListSettings: 'Ustawienia Listy Zestawienia Materiałów',
  both: 'Oba',
  book: 'Katalog | Katalogi',
  bookInserts: 'Wkładki do Katalogów',
  bookLabel: 'Katalog | Katalogi',
  bookPrintingDisable: 'Wyłącz Wydruk Katalogów',
  booksAndPagesDisplay: 'Wyświetlanie Katalogów i Stron',
  boost: 'Przyspieszenie Wyszukiwania',
  boostDescription: 'Dodaj wartość powyżej 1,5, aby zwiększyć istotność tego znacznika w wynikach wyszukiwania ponad numery części, identyfikatory mediów oraz nazwy',
  boostRangeInvalid: 'Wzmocnienie musi wynosić między 0,5 a 100,0',
  border: 'Ramka',
  bottom: 'Dół',
  branding: 'Znakowanie',
  browse: 'Przeglądaj',
  browseComputer: 'Przeglądaj Komputer',
  browseFlow: 'Kierunek Przeglądania | Kierunki Przeglądania',
  browseFlowAssigned: 'Przypisany Kierunek Przeglądania',
  browseFlowCopyMessage: 'Wprowadź unikalną nazwę nowego Kierunku Przeglądania.',
  browseFlowDeleted: 'Usunięto przepływ przeglądania',
  browseFlowDeleteMessage: 'Usunięcie kierunku przeglądania powoduje usunięcie nie tylko kierunku przeglądania, ale wszystkich jego podrzędnych przepływów przeglądania.',
  browseFlowEmptyState: 'Nie zdefiniowano żadnych Kierunków Przeglądania. Aby rozpocząć, {new}.',
  browseFlowHeirarchy: 'Hierarchia Kierunku Przeglądania',
  browseFlowNoExistingCriteria: 'Nie definiowano kryteriów dla tego kierunku przeglądania.  Korzystaj z elementów sterowania powyżej, aby wybrać kryteria wyszukiwania.',
  browseFlowMoveToRoot: 'Upuść węzeł tutaj, aby utworzyć element podrzędny pierwszego rzędu',
  browseFlowNoAssignedOrgs: 'Brak przypisanych organizacji',
  browseFlowSaveFailed: 'Nie można zaktualizować Kierunków Przeglądania',
  browseFlowSetDefaultSuccess: 'Ustawiono domyślny Kierunek Przeglądania',
  browseFlowThumbnailDeleteFailTitle: 'Usunięcie Miniatury Nie Powiodło Się',
  browseFlowThumbnailDeleteSuccessTitle: 'Pomyślne Usunięcie Miniatury',
  browseFlowThumbnailUploadFailTitle: 'Przekazanie Miniatury Nie Powiodło Się',
  browseFlowThumbnailUploadSuccessTitle: 'Pomyślne Przekazanie Miniatury',
  browseFlowTooManyOrgs: 'Zbyt wiele organizacji korzysta z bieżącego edytora kierunku przeglądania. Skontaktuj się z przedstawicielem Pomocy Technicznej Documoto pod adresem {email} w celu uzyskania pomocy w zakresie kierunków przeglądania Documoto.',
  browserPartiallySupport: 'W przeglądarce mogą być widoczne różnice estetyczne i inne zmiany.',
  browseFlowSaved: 'Zapisano Kierunek Przeglądania',
  browserDeprecation: {
    title: 'Komunikat Przestarzałej Przeglądarki',
    text: 'W związku z wycofywaniem przez firmę Microsoft wsparcia dla przeglądarki Internet Explorer (wszystkie wersje) i związanymi z tym obawami dotyczącymi bezpieczeństwa, nie będziemy już wspierać dostępu użytkowników do tej witryny za pośrednictwem przeglądarki Internet Explorer (wszystkie wersje) po 30 września 2021 r.'
  },
  buildHotpointLinks: 'Buduj Łącza Hotpoint',
  buildHotpointLinksInfo: 'Ta akcja najpierw spowoduje usunięcie wszystkich istniejących łączy hotpoint ze stron katalogu, a następnie utworzy nowe łącza hotpoint pomiędzy częściami i wszystkimi dopasowanymi stronami montażu.',
  buildHotpointLinksTitle: 'Czy chcesz wprowadzić łącza hotpoint w tym katalogu?',
  bulkIndexingLane: 'Pas indeksowania zbiorczego',
  bulkInsert: 'Wprowadzanie Zbiorcze',
  bulkExportConfirm: 'Czy chcesz wyeksportować {n} {unit}?',
  bulkExportLimit: 'Maksymalna liczba katalogów na czynność eksportu: {limit}',
  bulkPageInsertJob: 'Zbiorcze Wstawianie Stron',
  business: 'Business',
  cancel: 'Anuluj',
  canceled: 'Anulowano',
  cancelAssemblyCreatorTitle: 'Usunąć zadanie Utwórz montaż?',
  cancelAssemblyCreatorSubtitle: 'Zadanie Utwórz montaż nie będzie przetwarzane.',
  cannotExceed100: 'Nie może przekraczać 100%',
  cart: 'Koszyk | Koszyki',
  cartBehaviorEnabled: 'Włącz Zachętę do Dodawania do Koszyka',
  cartBehaviorDisabled: 'Wyłącz Zachętę do Dodawania do Koszyka',
  cartBehaviorLabel: 'Zachowanie Ilościowe Dodawania do Koszyka',
  cartColumn: 'Kolumna Koszyka | Kolumny Koszyków',
  cartColumn1: '1. Kolumna Cen w Koszyku',
  cartColumn2: '2. Kolumna Cen w Koszyku',
  cartColumn3: '3. Kolumna Cen w Koszyku',
  cartColumnPartLocationContent1: 'Włączenie tej funkcji będzie miało następujące skutki:',
  cartColumnPartLocationContent2: 'Unikalność wiersza części w koszyku będzie również oparta na jego lokalizacji w obrębie fragmentu treści, jeśli ma to zastosowanie.',
  cartColumnPartLocationContent3: 'To ustawienie nie może być wyłączone na poziomie organizacji i będzie miało zastosowanie do wszystkich użytkowników.',
  cartColumnPartLocationContent4: 'To ustawienie nie powinno być włączane bez wcześniejszego kontaktu z Menedżerem ds. Sukcesu Klienta w Documoto, jeśli posiadasz integrację Submit Cart.',
  cartColumnPartLocationOption: 'Włącz Dane Lokalizowania Części w Koszyku',
  cartColumnPartLocationTitle: 'Czy na pewno chcesz włączyć Dane Lokalizowania Części w Koszyku?',
  cartDisable: 'Wyłącz Koszyk',
  cartEmailFailed: 'Nie udało się wysłać e-maila dotyczącego koszyka',
  cartEmailSent: 'E-mail dotyczący koszyka wysłany',
  cartEnable: 'Włącz Koszyk',
  cartSaveEnable: 'Włącz Zapisywanie Koszyka',
  cartErpMessage: 'Pomyślnie wysłano do systemu Erp',
  cartImportError: 'Błąd podczas importowania koszyka',
  cartLoadedWithItemsRemoved: 'Usunięto pozycje w liczbie {count}, ponieważ nie masz aktualnie uprawnień do ich zamawiania',
  cartSharedAddEdit: 'Dodaj / Edytuj Koszyki Współdzielone',
  cartUpdated: 'Zaktualizowano Koszyk',
  cartUpdatedAndRemovedCount: 'Koszyk Zaktualizowany; Usunięto części niemożliwe do zamówienia w liczbie {count}',
  category: 'Kategoria | Kategorie',
  categoryCreated: 'Utworzono Kategorię',
  categoryUpdated: 'Zaktualizowano Kategorię',
  centimeter: 'Centymetr | Centymetry',
  changeNotification: 'Zawiadomienie o Zmianie',
  changeScope: 'Wystąpi wpływ na: {n} {type}',
  changeUserOrganization: 'Zmień Organizację',
  chapter: 'Rozdział | Rozdziały',
  chapterHeader: 'Nagłówek Rozdziału',
  chaptersOnly: 'Tylko Rozdziały',
  chineseDescription: 'Opis Chiński',
  chineseName: 'Nazwa Chińska',
  circle: 'Okrąg',
  city: 'Miasto',
  classicAdminMessage: 'Spowoduje to powrót do Flex w celu skorzystania z modułów administratora.',
  classicAdmins: 'Administratorzy Klasyczni',
  classicExporter: 'Eksporter Klasyczny',
  classicExporterMessage: 'Starszy moduł eksportowania na bazie Flash',
  classicOrganizationAdmin: 'Klasyczny Administrator Organizacji',
  classicOrganizationAdminMessage: 'Starszy moduł administracji na bazie Flash do zarządzania organizacjami i użytkownikami',
  classicPublisher: 'Klasyczny Moduł Publikacji',
  classicPublisherMessage: 'Starszy moduł publikacji na bazie Flash do zmiany ustawień tworzenia treści oraz drukowania',
  classicReporting: 'Klasyczne Raportowanie',
  classicReportingMessage: 'Starszy moduł raportowania na bazie Flash',
  classicTenantAdmin: 'Klasyczny Moduł Administracji Dzierżawy',
  classicTenantAdminMessage: 'Starszy moduł administracji na bazie Flash do zmiany ustawień dzierżawy',
  className: 'Nazwa klasy',
  clear: 'Wyczyść',
  clearItemNumber: 'Usuń numer pozycji',
  clearItemNumberTitle: 'Czy chcesz usunąć numer pozycji?',
  clearItemNumberMessage: 'Usunięcie pozycji spowoduje usunięcie następujących danych:',
  clearFilters: 'Wyczyść Filtry',
  clearHotpoints: 'Wyczyść punkty Hotpoints',
  clearSearch: 'Wyczyść Pole Wyszukiwania',
  clickHereToUpload: 'Upuść plik lub kliknij, aby przesłać obiekt zastępczy.',
  clickMagnifyingGlass: 'Kliknij lupkę, aby przejść bezpośrednio do wyszukiwania zaawansowanego.',
  clickOkToContinue: 'Kliknij "OK", aby kontynuować.',
  clickToDownload: 'Kliknij, aby Pobrać',
  clickToEnlarge: 'Kliknij, aby powiększyć',
  clickToOpen: 'Kliknij, aby Otworzyć',
  clipboard: 'Schowek',
  clipboardAccessError: 'Dostosuj ustawienia swojej przeglądarki, aby zezwolić na dostęp do Schowka',
  close: 'Zamknij',
  closed: 'Zamknięto',
  collapseAll: 'Zwiń Wszystko',
  color: 'Kolor | Kolory',
  column: 'Kolumna | Kolumny',
  columnName: 'Nazwa Kolumny',
  columnItemType: {
    customText: 'Tekst Własny',
    documentTitle: 'Tytuł Dokumentu',
    horizontalLine: 'Linia Pozioma',
    pageName: 'Nazwa Strony',
    pageNumber: 'Numer Strony',
    printDate: 'Data Wydruku',
    tenantName: 'Nazwa Dzierżawcy',
    userName: 'Nazwa Użytkownika'
  },
  combinedPage: 'Strona Połączona',
  combinedPageMessage: 'Diagram na górze, lista części na dole',
  comingSoon: 'Wkrótce',
  commaDelimited: 'Oddzielone przecinkiem',
  comment: 'Komentarz | Komentarze',
  commentAdded: 'Dodano komentarz',
  commentDeleted: 'Usunięto komentarz',
  commentEdit: 'Dodaj / Edytuj Komentarze',
  commentEditAll: 'Edytuj Wszystkie Komentarze',
  commentUpdated: 'Komentarz zaktualizowany',
  commentView: 'Zobacz Komentarze',
  commerceSystem: 'System Handlowy',
  completed: 'Ukończono',
  confirm: 'Zatwierdź',
  confirmBrowseFlowApplyToChildren: 'Czy chcesz zastosować to kierunek przeglądania do organizacji podrzędnych?',
  confirmBrowseFlowOverrideToChildren: 'Spowoduje to usunięcie nadpisanych kierunków przeglądania w organizacjach podrzędnych.',
  confirmPrintSettingsApplyToChildren: 'Czy chcesz zastosować to ustawienie drukowania do organizacji podrzędnych?',
  confirmPrintSettingsOverrideToChildren: 'Spowoduje to usunięcie zmodyfikowanych ustawień wydruku w organizacjach podrzędnych.',
  contains: 'Zawiera',
  containsHeaderRow: 'Zawiera Wiersz Nagłówka',
  content: 'Treść | Treści',
  contentAccessControls: '{content} – Kontrola Dostępu',
  contentEntity: 'Jednostka treści | Jednostki Treści',
  contentNotFound: 'Nie znaleziono treści',
  contentUpdatedQueueJob: 'Zaktualizowana Kolejka Treści',
  contourShape: 'Kształt konturu',
  convertEntity: 'Konwertuj {entity}',
  convertToEntity: 'Konwertuj do {entity}',
  copyAccessControls: 'Kopiuj Ustawienia Kontroli Dostępu',
  copyAccessControlsWarning: 'Spowoduje to skopiowanie wszystkich przypisanych ustawień kontroli dostępu z wybranej Organizacji do bieżącej Organizacji.',
  copy: 'Kopiuj',
  copyAndRetain: 'Kopiuj i Zachowaj do Użytku Integracji',
  copyAndRetainWidget: 'Kopiuj i zachowaj; to jedyny raz, gdy wartość autoryzacji widżetu będzie widoczna w aplikacji',
  copyAtoB: 'Kopiuj {a} do {b}',
  copyContent: 'Kopiuj {type}? {name}',
  copyingItem: 'Kopiowanie {item}',
  copyItem: 'Kopiuj {item}',
  copyItemQuestion: 'Skopiować pozycję {item}?',
  copyTo: 'Kopiuj do {target}',
  copyMeMessage: 'Prześlij kopię tej wiadomości do mnie',
  copyrightMessage: '© 2010–{year} Documoto Inc. Wszelkie prawa zastrzeżone.',
  count: 'Liczba',
  country: 'Kraj | Kraje',
  coverPages: 'Strony Okładki',
  coverAmpStitchPages: 'Połącz Strony i Dołącz Okładkę',
  createAssembly: 'Utwórz Zespół',
  created: 'Utworzono',
  createdBy: 'Utworzone przez: {name}',
  createdDate: 'Utworzono datę',
  createdOn: 'Utworzono dn.',
  currentPassword: 'Bieżące Hasło',
  custom: 'Niestandardowe',
  czechDescription: 'Opis Czeski',
  czechName: 'Nazwa Czeska',
  dashboard: 'Panel sterowania | Panele sterowania',
  dashboardLibrary: 'Biblioteka Pulpitu',
  dataInsights: 'Wgląd w Dane',
  dataInsightsInfoMessage: 'Raportowanie i Centrum Pulpitu do wglądu w treść, użytkowników i biblioteki.',
  dataMapping: 'Mapowanie Danych / Przykładowe Dane (maksymalnie 10 wierszy)',
  dataOptions: 'Opcje danych',
  date: 'Data',
  dateModified: 'Data Modyfikacji',
  debugPageBuilderPLZ: 'Debuguj Kreatora Stron PLZ',
  december: 'Grudzień',
  decreaseHotpoint: 'Zmniejsz Wartość Punktu Interaktywnego',
  default: 'Domyślne',
  defaultAddress: 'Domyślny Adres',
  defaultBrowseFlow: 'Domyślny Kierunek Przeglądania',
  defaultCurrencyCode: 'Domyślny Kod Waluty',
  defaultCurrencySymbolOn: 'Domyślny symbol waluty włączony',
  defaultEmailRecipient: 'Domyślny Odbiorca Wiadomości E-mail | Domyślni Odbiorcy Wiadomości E-mail',
  defaultImage: 'Domyślny Obraz | Domyślne Obrazy',
  defaultImagesManager: 'Menedżer Obrazów Domyślnych',
  defaultItemImage: 'Obraz Domyślny {item}',
  defaultPartsListLayout: 'Domyślny Układ Listy Części',
  defaultPartsListLayoutHelp: 'Domyślny układ listy części po lewej lub poniżej ilustracji',
  defaultSearchMode: 'Domyślny Tryb Wyszukiwania',
  defaultSearchModeHelp: 'Domyślna globalna metoda wyszukiwania \'zawiera\' lub \'dokładnie\'',
  defaultSortMode: 'Domyślny Tryb Sortowania',
  defaultSupplier: 'Dostawca Domyślny',
  defaultTocStateOpen: 'Domyślny Stan Spisu Treści Otwarty',
  defaultTocStateOpenHelp: 'Określa, czy Spis Treści powinien otwierać się automatycznie podczas przeglądania katalogu',
  defaultUom: 'Domyślna Jednostka Miary',
  defaultValue: 'Domyślna Wartość',
  defaults: 'Domyślne',
  defaultsAndCapabilities: 'Wartości Domyślne i Możliwości',
  delete: 'Usuń',
  deleteAll: 'Odziedzicz po Obiekcie Nadrzędnym',
  deleteAnyPartnerApiKeys: 'Usuń wszystkie klucze API partnera udostępnione SmartEquip.',
  deleteContent: 'Czy na pewno chcesz usunąć {type}? {name}',
deletePart: {
    infoOne: 'Usunięcie części w bibliotece i sekcji administratora treści nastąpi natychmiastowo. Usunięta część będzie widoczna w wynikach wyszukiwania do momentu ponownej indeksacji, która nastąpi w ciągi kilku minut.',
    deletingPartNumber: 'Element {partNumber} zostanie usunięty.',
    affectedCarts: 'Część zostanie usunięta ze wszystkich koszyków, w których się znajdowała.',
    infoTwo: 'Tego działania nie można cofnąć bez pomocy działu wsparcia Documoto. Po 90 dniach usunięty rekord i wszystkie jego powiązania zostaną trwale wyczyszczone.'
  },
deletePartInUse: {
    title: 'Ta część przynależy do stron i nie można jej usunąć.',
    body: 'Aktualnie Documoto umożliwia wyłącznie usuwanie części, które nie przynależą do stron. Usuń tę część ze wszystkich niepublikowanych lub roboczych stron, aby móc ją wykasować.',
    submitLabel: 'Przejdź do powiązanej strony'
  },
  deleteRelated: 'Czy na pewno chcesz usunąć {count} {type}?',
  deletedItem: '{item} Usunięto',
  deleteFilesMessage: 'Czy na pewno chcesz usunąć plik(i) {count}?',
  deleteHotpointLinksTitle: 'Czy na pewno chcesz usunąć link(i) Hotpoint?',
  deleteMessage: 'Czy na pewno chcesz usunąć?',
  deleteSelectedCriteria: 'Usuń Wybrane Kryteria',
  deleteTagsTitle: 'Czy na pewno chcesz usunąć znacznik(i)?',
  deleteTaxonMessage: 'To spowoduje trwałe usunięcie tego taksonu razem z jego obiektami podrzędnymi. Czy na pewno chcesz kontynuować?',
  deleteTaxonomyMessage: 'To spowoduje trwałe usunięcie wszystkich taksonów tej Organizacji lub tego Dzierżawcy. Czy na pewno chcesz kontynuować?',
  deleteTenantSupplier: 'Czy na pewno chcesz usunąć tego dostawcę dzierżawcy?',
  demo: 'Demo',
  description: 'Opis',
  deselectAll: 'Usuń Wybór Wszystkiego',
  deselectCriteria: 'Odznacz Kryteria',
  detail: 'Szczegół | Szczegóły',
  diagonal: 'Przekątna',
  diameter: 'Średnica',
  disable: 'Wyłącz',
  disableAll: 'Wyłącz wszystko',
  disableDocumotoTenant: 'Zablokuj środowisko Documoto',
  disableFwdReindex: 'Wyłącz przekazywanie reindeksowanych multimediów docelowych dla DSW',
  disableLibraryBannerImage: 'Wyłącz obraz banera biblioteki',
  disableSmartEquipModalTitle: 'Czy na pewno chcesz zablokować tę licencję?',
  disableTEK: 'Dezaktywuje Login do Bezpośredniego URL',
  disabled: 'Wyłączono',
  discard: 'Anuluj',
  discardChanges: 'Anuluj zmiany',
  discardQ: 'Anulować zmiany?',
  discounted: 'Z rabatem',
  discountedPrice: 'Cena z Rabatem',
  discountedTotal: 'Łącznie po Rabacie',
  discountPrice: 'Promocyjna Cena',
  displayInOrdersList: 'Wyświetl na Liście Zamówień',
  displayOrder: 'Kolejność Wyświetlania',
  displayOrderDescription: 'Ustaw kolejność wyświetlania listy rozwijalnej Filtrów na potrzeby znaczników typu treści',
  document: 'Dokument | Dokumenty',
  documentLabel: 'Dokument | Dokumenty',
  documentSettings: 'Ustawienia dokumentów',
  documotoAcademy: 'Documoto Academy',
  documotoLicensePackage: 'Pakiet licencji Documoto',
  domain: 'Domena',
  domainEmptyMessage: 'Dozwolona domena nie może być pusta',
  domainInvalidMessage: 'Wymagana poprawna nazwa domeny',
  download: 'Pobierz',
  downloadBrowser: 'Pobierz jedną z tych znakomitych przeglądarek.',
  downloadFailed: 'Błąd pobierania {name}',
  downloadStarted: 'Trwa pobieranie {name}',
  draft: 'Wersja Robocza | Wersje Robocze',
  draftBomDeleteTitle: 'Czy na pewno chcesz usunąć tę część ze strony?',
  draftBomMultipleDeleteTitle: 'Czy na pewno chcesz usunąć te części ze strony?',
  draftBomDropError: 'Nie można zagnieżdżać części na ponad 5 poziomów wgłąb',
  draftBomEmptyField: 'Należy uzupełnić wszystkie pola na Liście Części',
  draftBomQuantityError: 'Ilość musi być liczbą',
  draftBomError: 'Błąd podczas przetwarzania projektów List Materiałowych',
  draftPage: 'Strona Projektowa',
  draftPageDelete: 'Usuń Projekt',
  draftPageDeleteMessage: 'Projekt został usunięty.',
  draftPageDeleteWarning: 'Usunięcie projektu Strony spowoduje utratę aktualizacji postępów.',
  draftPageLastUpdated: 'Projekt – ostatnia aktualizacja dnia {date} przez użytkownika {username}',
  draftPageLoadError: 'Nie można wczytać strony z projektem.',
  draftPageProcessingPlz: 'Trwa ładowanie strony z pliku PLZ',
  draftPagePublishInProgress: 'Zadanie Publikacji Strony w toku',
  draftPageSaveSuccess: 'Strona projektowa została zapisana z powodzeniem.',
  draftPageSaveUnsuccessful: 'Strona projektowa nie została zapisana.',
  draftSheetImageHotpointsFailure: 'Nie można wygenerować {algorithm} punktów Hotpoints dla obrazu szkicu arkusza.',
  draftValidationMessage: {
    item: 'UWAGA: Brak określonego numeru pozycji dla numeru części {partNumber} na liście BOM',
    empty: 'Brak Błędów/Ostrzeżeń Walidacji',
    hotpointOrHotpointLink: 'Nie zdefiniowano żadnych punktów interaktywnych / łączy punktów interaktywnych dla pozycji {item} Zestawienia Materiałów',
    quantity: 'Nie zdefiniowano ilości dla pozycji {item} Zestawienia Materiałów',
    tag: 'Brak wartości znacznika {tagName} pozycji {item} Zestawienia Materiałów',
    translationDescription: 'Brak opisu tłumaczenia na język {lang} dla pozycji {item} Zestawienia Materiałów',
    uom: 'Nie zdefiniowano jednostki miary dla pozycji {item} Zestawienia Materiałów',
    supersede: 'Część {partNumber} zastąpiono {supersededPartNumber} z powodu usunięcia.'
  },
  drawAutohotpointRegion: 'Rozmiar rysowanego kształtu: {shape}',
  drawMinimumArea: 'Przeciągnij minimalny obszar',
  dropFileMessage: 'Upuść plik tutaj lub kliknij, aby przesłać.',
  duplicate: 'Powiel',
  duplicateSaveError: 'Powielony wpis. Nie można zapisać.',
  dutchDescription: 'Opis Niderlandzki',
  dutchName: 'Nazwa Niderlandzka',
  dynamic: 'Dynamiczne',
  dynamicNaming: 'Nazewnictwo Dynamiczne',
  ecommerce: 'Handel Elektroniczny',
  ecommerceEmailFormatCreated: 'Utworzono format wiadomości e-mail e-commerce',
  ecommerceEmailFormatSaved: 'Zapisano format wiadomości e-mail e-commerce',
  ecommerceLogo: 'Logo e-commerce',
  ecommerceUseExtOrder: 'Użyj zewnętrznego numeru zamówienia e-commerce',
  edit: 'Edytuj',
  editItem: 'Edytuj: {item}',
  email: 'E-mail',
  emailFormat: 'Format wiadomości e-mail',
  emailFormatTemplate: 'Szablon formatu wiadomości e-mail | Szablony formatu wiadomości e-mail',
  emailList: 'Lista E-mailowa',
  emailMessage: 'Wymagany jest adres e-mail',
  emailRecipient: 'Odbiorca Wiadomości E-mail | Odbiorcy Wiadomości E-mail',
  emailRecipientMessage: 'Te adresy e-mail będą otrzymywać wiadomość e-mail, gdy status zamówienia zmieni się na',
  emptyMessage: {
    orderSuggestions: 'Wygląda na to, że nie ma jeszcze Sugestii Zamówień dla tej części.'
  },
  emptyBomState: 'Dla strony nie określono żadnych Pozycji z Listy Materiałowej. Kliknij +{add}, aby rozpocząć',
  enable: 'Włącz',
  enable3d: 'Włącz widok 3D',
  enableAll: 'Włącz wszystko',
  enableAPIKeyAdmin: 'Włącz administratora klucza API',
  enabled: 'Włączono',
  enableCommerce: 'Włącz Sprzedaż',
  enableEcommerceOrderManagement: 'Włącz zarządzanie zamówieniami',
  enableExporter: 'Włącz eksportera',
  enableExportToLinkOne: 'Włącz eksportowanie do LinkOne',
  enableExportToSmartEquip: 'Włącz eksportowanie do SmartEquip',
  enablePDFDownloadWarning: 'Włącz ostrzeżenie o pobieraniu dokumentu PDF o dużych rozmiarach',
  largePDFDownloadWarning: 'W związku z dużym rozmiarem pliku PDF załadowanie i wyświetlenie dokumentu w aplikacji może potrwać dłużej niż zwykle – prosimy o cierpliwość.',
  enableIframeSupport: 'Włącz pomoc iFrame',
  enableLibraryBetaFeatures: 'Włącz funkcje beta biblioteki',
  enablePerformanceLogging: 'Włącz rejestrowanie wyników',
  enablePLZToDraft: 'Włącz przetwarzanie plików PLZ do wersji roboczej',
  enableQuickAddToCart: 'Włącz funkcję Szybkiego dodawania do koszyka',
  enableQuickAddToCartHelpContext: 'Ustawienie \„Włącz\” nad przeglądarką spowoduje włączenie funkcji szybkiego dodawania do koszyka dla tej zawartości.',
  enableRocketUi: 'Włącz Rocket User Experience ',
  enableSmartEquipApiIntegration: 'Włącz integrację API SmartEquip',
  enableWidgetLogo: 'Włącz logo widżetu Documoto',
  enableWidgets: 'Włącz widżety',
  encryptedKey: 'Klucz Szyfrowany',
  endDate: 'Data Zakończenia',
  endDateBad: 'Data rozpoczęcia musi być taka sama lub wcześniejsza od daty zakończenia.',
  enforceNumericSize: 'Wymuś Rozmiar Numeryczny',
  englishDescription: 'Opis Angielski',
  englishName: 'Nazwa Angielska',
  enterprise: 'Enterprise',
  enterReportParameters: 'Wprowadź parametry raportu',
  enterUniqueNameForNewItem: 'Wprowadź unikalną nazwę nowego obiektu {item}',
  enterValidNumber: 'Wprowadź prawidłową liczbę',
  enterValidEmail: 'Wprowadź prawidłowy adres e-mail',
  entityType: 'Typ Jednostki | Typy Jednostek',
  erp: 'ERP',
  erpEndpointConfig: 'Konfiguracja punktu końcowego ERP',
  erpPartInfo: 'Czas trwania pamięci podręcznej części ERP w minutach',
  erpSystem: 'System ERP',
  erpSystems: 'Systemy ERP',
  erpSystemSaved: 'System ERP zapisany',
  error: 'Błąd',
  errorDeletePageMessage: 'Podczas usuwania tej strony wystąpił błąd.',
  errorInputParsing: 'Problem z analizą pliku wejściowego.',
  errorPageMessage: 'Wystąpił błąd.',
  errorPageTitle: 'Coś poszło nie tak…',
  errorType: {
    error: 'Błąd',
    warning: 'Ostrzeżenie'
  },
  essential: 'Essential | Essentials',
  eta: 'Przewidywany czas dostawy',
  etlJob: 'ETL',
  exact: 'Dokładnie',
  excelFile: 'Plik Excel',
  expandAll: 'Rozwiń Wszystko',
  export: 'Eksportuj',
  exportSearch: 'Eksportuj Wyszukiwanie',
  exportAsCsv: 'Eksportuj jako Plik CSV (Wymagany Format Eksportu)',
  exportAsCSVDropdown: 'Eksportuj jako CSV',
  exportAsHtml: 'Wyeksportuj jako HTML',
  exportAsPdf: 'Eksportuj jako PDF',
  exportAsPlz: 'Eksportuj jako PLZ',
  exportAsXlsx: 'Eksportuj jako XLSX',
  exportBasicPartInfo: 'Eksportuj Podstawowe Informacje o Części',
  exportCartToXlsxDisabled: 'Wyłącz Eksport Koszyka do XLSX',
  exportCartToXlsxEnabled: 'Włącz Eksport Koszyka do XLSX',
  exportDisplayedCartColumns: 'Eksportuj Wyświetlane Kolumny Koszyka',
  exportJob: 'Zadanie Eksportu',
  exportLane: 'Eksportuj pas',
  exportMessage: 'Pomyślnie przesłano zadanie eksportu.',
  exporter: 'Eksporter',
  exporterMessage: 'Umożliwia eksportowanie treści',
  exportParts: 'Eksportuj Części',
  external: 'Zewnętrzny | Zewnętrzne',
  externalUrl: 'Zewnętrzny adres URL',
  faceted: 'Fasetowane',
  facetedTag: 'Wartości filtra muszą być fasetowe',
  facetedTagTooMany: 'Znacznik przekracza ograniczenie 1000 unikatowych wartości znaczników',
  failedAction: '{content} – niepowodzenie czynności {action}.',
  failedProcessContentSql: 'Przetwarzanie treści nie powiodło się ze względu na niedozwolony tekst. Ponownie prześlij obsługiwaną zawartość.',
  failedToCopy: 'Nie można skopiować elementu {content}.',
  failedToDelete: 'Nie udało się usunąć elementu {content}',
  failedToLoad: '{content} – niepowodzenie ładowania.',
  failedToSave: '{content} – niepowodzenie zapisywania.',
  failedToSubmitItem: 'Nie udało się przesłać obiektu {item}',
  failedToUpdate: 'Nie można zaktualizować elementu {content}.',
  false: 'Fałsz',
  faq: 'FAQ',
  favorites: 'Ulubione',
  favoritesLists: 'Lista Ulubionych',
  february: 'Luty',
  feedbackForm: 'Prześlij Informację Zwrotną',
  feedbackFormMessage: 'Wskaż funkcje, które chcesz zobaczyć',
  fieldDefinition: 'Definicja Pola',
  field: 'Pole | Pola',
  fieldName: 'Nazwa pola',
  file: 'Plik | Pliki',
  filename: 'Nazwa Pliku',
  filesSubmitted: 'Przekazano plik(i) do przetwarzania',
  fileUpload: 'Przesyłanie pliku',
  fill: 'Wypełnij',
  filter: 'Filtr | Filtry',
  filterOrgNameOnly: 'Filtr: Tylko Nazwa Org',
  filterOrgParentNames: 'Filtr: Nazwy Org i Nadrzędne',
  filterable: 'Filtrowane',
  filterableTag: 'Filtrowalny Znacznik',
  filterBy: 'Filtruj Wg',
  filterBoundsError: 'Filtr musi zawierać 3 lub więcej znaków',
  finnishDescription: 'Opis Fiński',
  finnishName: 'Nazwa Fińska',
  firstName: 'Imię',
  firstNameEmpty: 'Pole imienia nie może być puste',
  fixedHotpointSize: 'Stały Rozmiar Łącza Hotpoint',
  font: 'Czcionka | Czcionki',
  footer: 'Stopka',
  forgotPassword: 'Nie Pamiętam Hasła',
  forgotPasswordMessage: 'E-mail z instrukcją resetowania hasła zostanie wysłany do podanego użytkownika.',
  forgotPasswordSuccess: 'Jeśli podana nazwa użytkownika jest poprawna, na podany adres zostanie wysłany e-mail z instrukcją resetowania hasła.',
  form: 'Formularz | Formularze',
  format: 'Format',
  formattingOptions: 'Opcje formatowania',
  formCount: 'Liczba Formularzy',
  formName: 'Nazwa formularza',
  formType: 'Rodzaj formularza',
  freezeImage: 'Zablokuj obraz',
  frenchDescription: 'Opis Francuski',
  frenchName: 'Nazwa Francuska',
  fri: 'Pt',
  from: 'Od',
  fromDate: 'Data Od',
  frontCover: 'Okładka Przednia',
  frontStitch: 'Obszycie Przednie',
  fullScreen: 'Pełny ekran',
  gdprFaqs: 'RODO – Częste Pytania',
  gdprMessage: 'Logując się do tej sekcji naszej strony internetowej, wyrażasz zgodę na korzystanie przez nas z podstawowych plików cookie. Są one niezbędne do umożliwienia nawigacji po naszych usługach. Bez tych plików nie jesteśmy w stanie świadczyć żądanych usług. W celu uzyskania dodatkowych informacji patrz {policy}.',
  general: 'Ogólne',
  generalSearch: 'Wyszukiwanie Ogólne',
  generic: 'Ogólne',
  germanDescription: 'Opis Niemiecki',
  germanName: 'Nazwa Niemiecka',
  gotIt: 'Rozumiem',
  goTo: 'Przejdź do',
  goToHome: 'Przejdź do Strony Głównej',
  goToJobManager: 'Przejdź do Menedżera Zadań',
  goToSearch: 'Przejdź do Wyszukiwania',
  hashKey: 'Klucz Skrótu',
  headerAmpFooter: 'Nagłówek i Stopka',
  header: 'Nagłówek',
  help: 'Pomoc',
  helpCenter: 'Centrum Pomocy',
  highlightDescription: 'Oznacz tym znacznikiem części na liście części',
  history: 'Historia',
  home: 'Strona główna',
  horizontal: 'Poziomo',
  host: 'Host',
  hotpoint: 'Punkt Aktywny | Punkty Aktywne',
  hotpointCalloutBehavior: 'Objaśnienie hotpointów',
  hotpointLinksWillBeCreated: 'Łącza Hotpoint zostaną utworzone w: {count} {content}',
  hotpointLink: 'Link Hotpoint | Linki Hotpoint',
  hotpointsGenerated: 'Ilość najważniejszych punktów znalezionych na obrazie: {count}.',
  hotpointShift: 'Punkt Interaktywny (Shift H)',
  hourlyDWSRequestLimit: 'Godzinowy limit żądań DWS',
  hourlyRESTRequestLimit: 'Godzinowy limit żądań REST',
  htmlUiUserSwitchEnabled: 'Przełączanie użytkownika interfejsu HTML włączone',
  hungarianDescription: 'Opis Węgierski',
  hungarianName: 'Nazwa Węgierska',
  icon: 'Ikona',
  id: 'ID',
  identifier: 'Identyfikator',
  ignore: 'IGNORUJ',
  ignoreAccessControls: 'Ignoruj Ustawienia Kontroli Dostępu',
  ignoreMatchingTranslations: 'Ignoruj tłumaczenia z dopasowanymi wersjami językowymi po imporcie',
  illustration: 'Ilustracja | Ilustracje',
  image: 'Obraz | Obrazy',
  imageDeleteMessage: 'Czy na pewno chcesz usunąć ten Obraz Strony?',
  imageUploadFailure: 'Nie Można Przesłać Obrazu',
  imageSaveFail: 'Nie Można Zapisać Obrazu',
  imageOptimizer: 'Optymalizator Obrazów',
  imageSizeExceededTitle: 'Przekroczono Maksymalny Rozmiar Obrazu',
  imageSizeExceededMessage: 'Maksymalny rozmiar pliku obrazu dopuszczalny w Documoto wynosi {size} MB. Rozmiar obrazu, który próbujesz przesłać, przekracza maksymalny limit i nie można go załadować. Aby dodać obraz do tej strony, zmień jego rozmiar.',
  imageDimensionsExceededMessage: 'Maksymalny rozmiar obrazu w Documoto wynosi 65,5 megapikseli (8191 X 8191 pikseli).  Obraz, który chcesz załadować, przekracza tę wartość i nie może zostać załadowany.  Kliknij "Zmień rozmiar", aby umożliwić Documoto zmianę rozmiaru obrazu i jego wprowadzenie, lub "Anuluj", aby edytować obraz i wprowadzić go ręcznie',
  import: 'Importuj',
  importBOM: 'Importuj Zestawienie Materiałów',
  importBOMError: 'Nie można przetworzyć próbnych danych, sprawdź ponownie źródło danych',
  importParts: 'Importuj Części',
  inCart: 'W Koszyku',
  inCartDescription: 'Wyświetl znaczniki w koszyku',
  includePagePartDetails: 'Dołącz szczegóły poziomu części ze strony',
  includePartDetails: 'Dołącz szczegóły poziomu części',
  indexQueueJob: 'Kolejka indeksowania',
  inPartsList: 'Na Liście Części',
  inPartsListDescription: 'Wyświetl znaczniki na liście części',
  inputSource: 'Źródło Danych',
  inch: 'Cal | Cale',
  includeComments: 'Dołącz Komentarze',
  includeCommentsAuthorAndDate: 'Dołącz Autora i Datę Komentarza',
  includeHeaderRow: 'Dołącz wiersz nagłówka',
  includeImages: 'Uwzględnij Obrazy',
  includePagesIndex: 'Uwzględnij Indeks Stron',
  includePartsIndex: 'Uwzględnij Indeks Części',
  includePartsList: 'Uwzględnij Listę Części',
  includeSubChapters: 'Uwzględnij Podrozdziały',
  indexLane: 'Pas indeksowania',
  useSequentialPageNumbering: 'Stosuj Ciągłe Numerowanie Stron',
  hideBookInSearchAndRecent: 'Ukrywaj Katalogi w Wynikach Wyszukiwania i Ostatnio Dodanych',
  hideDuplicatePages: 'Ukryj Duplikaty Stron',
  hidePageInSearchAndRecent: 'Ukrywaj Strony w Wynikach Wyszukiwania i Ostatnio Dodanych',
  hidePartInSearchAndRecent: 'Ukrywaj Części w Wynikach Wyszukiwania i Ostatnio Dodanych',
  homePageSettings: 'Ustawienia strony domowej',
  hotpointZoomBehavior: 'Przybliżanie hotpointów',
  includeNewParts: 'Dołącz tylko „nowe” części (nie w Documoto)',
  includeWatermark: 'Dodać znak wodny?',
  increaseHotpoint: 'Zwiększ Wartość Punktu Interaktywnego',
  indexed: 'Indeksowane',
  indexingConfiguration: 'Konfiguracja indeksowania',
  indexIncludeTimestamp: 'Dołącz znacznik czasu',
  info: 'Informacje',
  information: 'Informacje',
  inherit: 'Dziedzicz',
  inheritedParent: 'Odziedziczone po Obiekcie Nadrzędnym.',
  inputSpecification: 'Specyfikacja Wejściowa',
  inputType: 'Typ Danych Wejściowych',
  inputTypeListboxWarning: 'Konfiguracje sortowania zostaną wyświetlone po zapisaniu',
  integration: 'Integracja | Integracje',
  integrationsAddPartToErpCart: '{action} Dodaj Część do Koszyka ERP',
  integrationsAddToShoppingCartPricingFromErp: '{action} Dodaj Ceny do Koszyka z ERP',
  integrationsDisableErp: 'Wyłącz ERP',
  integrationsDoErpForQuotes: 'Wykorzystaj ERP do Wycen',
  integrationsEnableErp: 'Włącz ERP',
  integrationsEnableFetchPartInfoFromErp: 'Włącz Pobieranie Informacji o Części z ERP',
  integrationsEnableScopeErp: 'Włącz {scope} ERP',
  integrationsEnableSendShoppingCartToErp: 'Włącz Przesyłanie Koszyka do ERP',
  integrationsEnableSyncPoAddressWithErp: 'Włącz Synchronizację Adresu PO z ERP',
  integrationsErpAvailabilityMappedFrom: 'Mapowanie Dostępności z ERP',
  integrationsErpCurrencyCodeMappedFrom: 'Kod Waluty Mapowany z ERP',
  integrationsErpDiscountedPriceMappedFrom: 'Przewidywany czas Dostawy w Cenie z Rabatem Mapowany z ERP',
  integrationsErpEtaMappedFrom: 'Mapowanie Przewidywanego Czasu Dostawy z ERP',
  integrationsErpMappings: 'Mapowania ERP',
  integrationsErpRetailPriceMappedFrom: 'Przewidywany Czas Dostawy w Cenie Detalicznej Mapowany z ERP',
  integrationsErpWholesalePriceMappedFrom: 'Przewidywany Czas Dostawy w Cenie Hurtowej Mapowany z ERP',
  integrationsInvokeGetPartInfoFromClient: 'Pobierz Dane GetPartInfo od Klienta',
  integrationsParseAddressForType: 'Analizuj Adres Typu',
  integrationsShoppingCartAndPartsTitle: 'Koszyk i Części',
  integrationsShoppingCartAvailability: '{action} Dostępność Koszyka',
  integrationsShoppingCartEtaFromErp: '{action} Przewidywany Czas Dostawy z ERP',
  internalApiKey: 'Wewnętrzny klucz API',
  internalDescription: 'Opis Wewnętrzny',
  internalName: 'Nazwa Wewnętrzna',
  internalNameOptional: 'Twój identyfikator znacznika',
  internalNameRequired: 'Wymagana jest nazwa',
  invalidDecimals: 'Można wprowadzić tylko dwie cyfry po przecinku',
  invalidField: 'Wartość {field} jest nieprawidłowa',
  invalidFileType: 'Nieobsługiwany typ pliku. Pełną listę obsługiwanych rozszerzeń plików znajdziesz w Centrum Pomocy Documoto.',
  isSearchable: 'Jest Wyszukiwalne',
  insertDataField: 'Wstaw pole danych',
  isSystem: 'Jest Systemowe',
  italianDescription: 'Opis Włoski',
  italianName: 'Nazwa Włoska',
  item: '#',
  itemAlt: 'Pozycja',
  itemCopied: 'Skopiowano: {item}',
  itemDeleted: 'Usunięto: {item}',
  itemInProgress: 'W toku: {item}',
  itemInformation: 'Informacje: {item}',
  itemMustBeLteItem: '{itemA} musi być równe lub niższe od {itemB}.',
  itemMustBeUnique: '{item} musi być unikalne',
  itemMustBeUniqueAndNotEmpty: '{item} musi być unikalne i jest wymagane',
  itemName: 'Nazwa: {item}',
  itemNotShown: '',
  itemSaved: 'Zapisano: {item}',
  itemSpecification: 'Specyfikacja Pozycji',
  showChapterTOCForHiddenPages: 'Pokaż Spis Treści Rozdziału dla Ukrytych Stron',
  january: 'Styczeń',
  japaneseDescription: 'Opis Japoński',
  japaneseName: 'Nazwa Japońska',
  job: 'Zadanie | Zadania',
  jobManager: 'Menedżer Zadań',
  jobManagerMessage: 'Zadania eksportu, publikacji i druku/pobierania',
  july: 'Lipiec',
  june: 'Czerwiec',
  koreanDescription: 'Opis Koreański',
  koreanName: 'Nazwa Koreańska',
  label: 'Etykieta | Etykiety',
  labelKey: 'Klucz Etykiety',
  landscape: 'Format Poziomy',
  laneConfiguration: 'Konfiguracja pasa',
  language: 'Język',
  lastModifiedDate: 'Data ostatniej modyfikacji',
  lastGenerated: 'Ostatnio Wygenerowane',
  lastName: 'Nazwisko',
  lastNameEmpty: 'Pole nazwiska nie może być puste',
  lastUpdatedOn: 'Ostatnia aktualizacja',
  layout: 'Układ',
  layoutOptions: 'Opcje Układu',
  ledger: 'Rejestr',
  left: 'W lewo',
  legal: 'Legal',
  letter: 'Letter',
  library: 'Biblioteka',
  libraryAdmin: 'Administrator Biblioteki',
  libraryBannerLogo: 'Logo baneru biblioteki',
  librarySettings: 'Ustawienia Biblioteki',
  librarySettingsDescription: 'Te ustawienia dotyczą różnych elementów biblioteki. Zachowaj szczególną ostrożność przy wprowadzaniu zmian. Skontaktuj się z Documoto Services w celu uzyskania dodatkowych informacji.',
  licenseConfiguration: 'Konfiguracja licencji',
  line: 'Linia',
  lineTool: 'Narzędzie Linii',
  link: 'Link | Linki',
  listBox: 'Pole listy',
  listBoxValue: 'Wartość w Polu Listy | Wartości w Polu Listy',
  listValue: 'Wartość Listy | Wartości Listy',
  load: 'Załaduj',
  loadMore: 'Załaduj więcej',
  loading: 'Ładowanie',
  locale: 'Ustawienia regionalne',
  login: 'Zaloguj',
  loginBackground: 'Tło Logowania',
  loginGDPRMessageBeginning: 'Ta strona internetowa wykorzystuje pliki cookie. Korzystając z tej strony, wyrażasz zgodę na używanie plików cookie. W celu uzyskania dodatkowych informacji patrz ',
  loginGDPRMessageEnd: 'oraz',
  loginSSO: 'SSO logowania',
  logiReportName: 'Nazwa Raportu Logi',
  logiReportToggle: 'Raport Logi?',
  logo: 'Logo | Logo',
  logout: 'Wyloguj',
  lookupLoadParts: 'Wyszukaj/Załaduj Części z Documoto przy Imporcie',
  lowerBound: 'Niższe Powiązanie',
  makePagesDivisibleBy: 'Wykonaj Strony Podzielne Przez:',
  mainInfo: 'Informacje Główne',
  mainLogo: 'Logo Główne',
  manageItem: 'Zarządzaj {item}',
  management: 'Zarządzanie',
  march: 'Marzec',
  margin: 'Marża | Marże',
  markdown: 'Markdown',
  may: 'Maj',
  media: 'Multimedia | Multimedia',
  mediaCategory: 'Kategoria Multimediów | Kategorie Multimediów',
  mediaCategoryEditor: 'Edytor Kategorii Multimediów',
  mediaCategoryName: 'Nazwa Kategorii Multimediów',
  mediaCategoryNameMissing: 'Wszystkie kategorie multimediów wymagają nazwy',
  mediaBookDeleteMessage: 'Usunięcie nośnika spowoduje usunięcie dostępu do tej treści zarówno w Bibliotece, jak i w Administratorze Treści i treści nie będą już dłużej dostępne dla użytkowników ani Administratorów Treści. Wszelkie części lub strony znajdujące się w tym katalogu, które nie występują w innych katalogach, także nie będą dłużej dostępne w Bibliotece.',
  mediaDeleteMessage: 'Skasowanie tego szablonu spowoduje usunięcie roli Administratora Treści i nie będzie możliwości późniejszego skopiowania do nowego Medium.',
  mediaDeleteSuccessText: 'Zostanie on usunięty z list po zakończeniu indeksowania.',
  mediaDescription: 'Opis multimediów',
  mediaIdentifier: 'ID multimediów',
  mediaName: 'Nazwa multimediów',
  mediaPrintSettingsSaveSuccess: 'Zapisano Ustawienie Drukowania Mediów',
  mediaWhereUsed: 'Wykorzystanie Multimediów',
  menu: 'Menu',
  merge: 'Scal',
  mergeOrOverrideMessage: 'Czy chcesz nadpisać czy połączyć te pozycje?',
  mergeWarning: 'Zaimportowanie danych spowoduje zaktualizowanie istniejących części i dodanie nowych pozycji',
  message: 'Wiadomość',
  messageLevel: 'Poziom Komunikatu',
  microsite: 'Mikrostrona | Mikrostrony',
  micrositeAdmin: {
    cardSubtitle: 'Plik ze skompresowaną zawartością oraz informacje dostępowe HTML',
    cardTitle: 'Zawartość strony',
    errorRequiredFile: 'Wymagany jest plik ZIP z zawartością strony',
    fieldFile: 'Plik ZIP z zawartością strony',
    fieldPoint: 'Punkt dostępu HTML',
  },
  minArea: 'Minimalne Pole',
  minAreaMessage: 'Wartość minimalna jest wymagana. Wartość musi się mieścić w zakresie od 1 do 1500.  Musi być liczbą całkowitą.',
  moreInfo: 'Więcej informacji',
  multipleAlternatingPages: 'Wiele Stron Alternatywnych',
  multipleAlternatingPagesMessage: 'Diagram na stronach 1 i 3, listy części na stronach 2 i 4.',
  mToM: 'M2M',
  multiplePages: 'Wiele Stron',
  multiplePagesMessage: 'Diagram na tej samej stronie z listą części poniżej',
  requiredField: 'Pole Wymagane',
  messageMessage: 'Wymagana jest wiadomość',
  metadata: 'Metadane',
  mine: 'Moje',
  miscellaneous: 'Różne',
  mon: 'Pn',
  myCart: 'Mój koszyk | Moje Koszyki',
  navigation: 'Nawigacja',
  nItemsCreated: 'Utworzono {n} {items}',
  nItemsDeleted: 'Usunięto {n} {items}',
  nOfMItems: '{n} z {m} {items}',
  name: 'Nazwa',
  nameDescriptionHelpText: 'Nazwa i opcjonalny opis',
  new: 'Nowe',
  newAPIKey: 'Nowy Klucz API',
  newERPSystem: 'Nowy system ERP',
  newItem: 'Nowa pozycja {item}',
  newKeyGenerated: 'Nowy Klucz Integracji Wygenerowany, Skopiowany i Zachowany do wykorzystania w przyszłości',
  newMediaCategory: 'Nowa Kategoria Multimediów',
  newPassword: 'Nowe Hasło',
  newsItem: 'Aktualność | Aktualności',
  newTenant: 'Nowy dzierżawca',
  newWidgetKey: 'Nowy klucz widżetu',
  newWidgetKeyGenerated: 'Wygenerowano nowy klucz widżetu; skopiuj i zachowaj go do wykorzystania w przyszłości',
  noTenantSuppliers: 'Brak dostawców przypisanych do dzierżawcy.',
  noAPIKeys: 'Brak zdefiniowanych Kluczy API.',
  node: 'Węzeł',
  noData: 'Brak Danych',
  noFilesSelected: 'Nie Wybrano Plików',
  noIllustrationFound: 'Nie Znaleziono Ilustracji',
  noImageFound: 'Nie Znaleziono Obrazu',
  noItemAvailable: 'Niedostępne: {item}',
  noItemFound: 'Nie znaleziono pozycji {item}.',
  noItemSelected: 'Niewybrane: {item}',
  noItemAddedToTarget: 'Żaden {item} nie został dodany do {target}. ',
  noItemsFound: 'Nie Znaleziono Pozycji',
  noMoreResults: 'Nie ma więcej wyników',
  noFormsMessage: 'Brak zdefiniowanych Formularzy.',
  noMediaDefined: 'Brak zdefiniowanych multimediów',
  nonSearchableFieldsSuccessfullyUpdated: 'Pomyślnie zaktualizowano pola bez możliwości wyszukiwania.',
  noPartsSelected: 'Nie wybrano części.',
  noPrice: 'Uzyskaj Wycenę',
  noResults: 'Brak wyników',
  norwegianDescription: 'Opis Norweski',
  norwegianName: 'Nazwa Norweska',
  noSearchableTags: 'Brak wyszukiwalnych tagów',
  noSuggestions: 'Brak sugestii dla wyszukiwanej frazy',
  notOrderable: 'Niemożliwe do Zamówienia',
  notSelected: 'Niezaznaczone',
  noTagsFound: 'Nie znaleziono tagów',
  noUnsavedChanges: 'Brak Niezapisanych Zmian',
  none: 'Brak',
  notImplemented: 'Niewdrożone',
  notification: 'Powiadomienie | Powiadomienia',
  notificationsMessage: 'Ważne ogłoszenia i aktualności',
  notMapped: 'Nie Zmapowano',
  november: 'Listopad',
  noWidgetKeys: 'Nie zdefiniowano żadnych kluczy widżetów.',
  numeric: 'Liczbowe',
  numberOf: '# z {item}',
  october: 'Październik',
  of: 'z',
  off: 'Wyłączone',
  offlineContent: 'Treści Offline',
  ok: 'OK',
  oldPassword: 'Stare hasło',
  on: 'Włączone',
  opacity: 'Przezroczystość',
  open: 'Otwórz',
  openItem: 'Otwórz {item}',
  optimizeIndexJob: 'Optymalizuj Indeks',
  options: 'Opcje',
  openTab: 'Otwórz link w nowej karcie',
  order: 'Zamówienie | Zamówienia',
  orderable: 'Możliwe do zamówienia',
  orderableDescription: 'Nie można dodać do koszyka i zakupić tej części',
  orderableLocal: 'Dostępne do zamówienia na tej stronie',
  orderableLocalBlocked: 'Część nie jest dostępna do Zamówienia Globalnie',
  orderDisable: 'Wyłącz Historię Zamówień',
  orderEmailRecipient: 'Odbiorca Wiadomości E-mail z Zamówieniem | Odbiorcy Wiadomości E-mail z Zamówieniem',
  orderEntity: 'Jednostka Kolejności | Jednostki Kolejności',
  orderFieldUneditableRequired: 'Ustawienie Pola Zamówienia/Wyceny jako wymaganego i niedostępnego do Edycji przez Użytkownika może uniemożliwić wypełnienie Formularza Zamówienia/Wyceny. Sprawdź, czy Formularze Zamówień/Wyceny mogą zostać wypełnione.',
  orderFieldsEmptyMessage: 'Nie zdefiniowano Pól Zamówienia/Wyceny.',
  orderFieldsEmptyPrompt: 'Dodaj Pole Zamówienia/Wyceny',
  orderField: {
    nameMessage: 'Jeśli zmienisz nazwę Pola Zamówienia/Wyceny, pamiętaj, aby zaktualizować wszystkie Formularze Zamówienia/Wyceny lub Formaty Wiadomości e-mail, które korzystają z tego pola.',
  },
  orderFieldCopyMessage: 'Podaj niepowtarzalną nazwę dla nowego Pola Zamówienia/Wyceny',
  orderForm: {
    uniqueNameInputHint: 'Wprowadź niepowtarzalną nazwę nowego Formularza.'
  },
  orderFormField: 'Pole Formularza Zamówienia/Wyceny | Pola Formularza Zamówienia/Wyceny',
  orderFormLayout: 'Układ Formularza Zamówienia/Wyceny | Układy Formularza Zamówienia/Wyceny',
  orderFormSetDefault: 'Ustawiony domyślny Formularz Zamówienia/Wyceny',
  orderFormType: 'Formularz zamówienia',
  orderLayout: 'Układ Zamówienia | Układy Zamówień',
  orderManagementApprover: 'Zatwierdzanie Zarządzania Zamówieniem',
  orderManagementDisable: 'Anuluj Administratora Zarządzania Zamówieniem',
  orderManagementViewer: 'Przeglądanie Zarządzania Zamówieniem',
  orderNumber: 'Numer Zamówienia',
  orderQuoteEmail: 'Wiadomość e-mail z zamówieniem/zapytaniem | Wiadomości e-mail z zamówieniem/zapytaniem',
  orderQuoteEmailFormat: 'Format wiadomości e-mail z zamówieniem/zapytaniem | Formaty wiadomości e-mail z zamówieniem/zapytaniem',
  orderQuoteEmailFormatCopyMessage: 'Wybierz język dla nowego formatu wiadomości e-mail z zamówieniem/zapytaniem.',
  orderQuoteFetchError: 'Wystąpił błąd podczas pobierania danych zamówienia/zapytania',
  orderQuoteForm: 'Formularz zamówienia/wyceny | Formularze zamówienia/wyceny',
  orderQuoteSaveConfirmation: 'Zapisać zmiany w formularzach zamówienia/zapytania organizacji?',
  orderQuoteSaveError: 'Wystąpił błąd podczas zapisywania danych zamówienia/zapytania',
  orderQuoteFormSaved: 'Zapisano Formularz Zamówienia/Wyceny',
  orderStatus: 'Status Zamówienia/Wyceny | Statusy Zamówienia/Wyceny',
  orderStatusDeleteMessage: 'Ten status został wykorzystany w zamówieniach i nie można go usunąć.',
  orderSubmittedMessage: 'Pomyślnie Wysłano',
  orderSuggestion: 'Sugestia Zamówienia | Sugestie Zamówienia',
  orderSuggestionEnable: 'Włącz Sugestie Zamówienia',
  orderSuggestionRemovalWarning: 'Ta część nie będzie już wyświetlana jako sugestia zamówienia. | Te części nie będą już wyświetlane jako sugestie zamówienia.',
  orderSuggestionThreshold: 'Próg Sugestii Zamówienia',
  ordersLogo: 'Logo Zamówień',
  ordersMessage: 'Historia przesłanych zamówień',
  orientation: 'Orientacja',
  org: 'Org',
  orgEmailRecipients: 'Odbiorcy Wiadomości E-mail w Organizacji',
  organization: 'Organizacja | Organizacje',
  organizationAdmin: 'Administrator Organizacji',
  organizationAdminBrowseFlow: 'Organizacja może używać domyślnego przepływu przeglądania lub można go zastąpić i używać dowolnego innego przepływu przeglądania.',
  organizationAdminMessage: 'Kontroluje ustawienia zarządzania użytkownikami i organizacji',
  organizationEmpty: 'Należy wybrać organizację',
  organizationEmailRecipients: 'Odbiorcy Wiadomości E-mail w Organizacji',
  organizationEnforceAccessControlsForQuickAdd: 'Egzekwuj Kontrolę Dostępu dla Szybkiego Dodania',
  organizationKey: 'Klucz Organizacji',
  organizationLibrarySettingsDescription: 'Te ustawienia dotyczą różnych elementów Biblioteki i Opcji na Poziomie Organizacji. Zachowaj szczególną ostrożność przy wprowadzaniu zmian. Skontaktuj się z Documoto Services w celu uzyskania dodatkowych informacji.',
  organizationMoveToRoot: 'Upuść tutaj, aby przejść do katalogu głównego',
  organizationName: 'Nazwa organizacji',
  organizationRearrange: 'Zmień Ustawienia Organizacji',
  organizationRearrangeDescription: 'Pionowa struktura organizacyjna określa wiele dziedziczonych atrybutów Organizacji, takich jak Ustawienia Drukowania, Style i inne. Przeciągnij i upuść Organizacje, aby odzwierciedlić pożądaną hierarchię.',
  organizationReportSettingsSuccessMessage: 'Zapisano ustawienia raportu',
  organizationSelect: 'Wybierz Organizację',
  organizationTags: 'Znaczniki Organizacji',
  organizationsEmpty: 'Wygląda na to, że nie utworzono jeszcze organizacji',
  orgNameOnly: 'Tylko Nazwa Org',
  other: 'Inne',
  otherSettings: 'Inne Ustawienia',
  outboundCustomerIdParameter: 'Nazwa Parametru Zewnętrznego ID Klienta',
  outboundSessionIdParameter: 'Nazwa Parametru Zewnętrznego ID Sesji',
  outlineMode: 'Tryb Obrysu (Shift O)',
  output: 'Plik Wyjściowy',
  outputCatalog: 'Katalog Pliku Wyjściowego',
  outputDefinition: 'Definicja Pliku Wyjściowego | Definicje Plików Wyjściowych',
  outputDefinitionDeleted: 'Usunięto Definicję Pliku Wyjściowego',
  outputDefinitionSaved: 'Zapisano Definicję Pliku Wyjściowego',
  outputFileName: 'Nazwa Pliku Wyjściowego',
  outputName: 'Nazwa Pliku Wyjściowego',
  outputDestination: 'Lokalizacja docelowa',
  outputSpecification: 'Specyfikacja docelowa',
  outputSubType: 'Podtyp Pliku Wyjściowego',
  outputType: 'Typ Pliku Wyjściowego',
  overridden: 'Zastąpiono',
  overriddenByOrg: 'Zastąpiono przez Org.',
  override: 'Nadpisz',
  overrideBrowseFlow: 'Nadpisz Kierunek Przeglądania',
  overrideFacetLimitValue: 'Zastąp wartość graniczną aspektu',
  overrideHotpointScale: 'Nadpisz Skalę Identyfikatora Hotpoint',
  overridePdfNamingStandard: 'Nadpisz standard nazewnictwa plików PDF',
  overridePrintSettings: 'Nadpisz Ustawienia Drukowania',
  overrideFontSettings: 'Nadpisz Ustawienia Czcionki',
  overrideOrderForm: 'Zastąp formularz zamówienia',
  overrideQuoteForm: 'Zastąp formularz zapytania',
  overrideSvgOpacity: 'Nadpisz Przezroczystość SVG',
  overrideWarning: 'Zaimportowanie danych spowoduje zastąpienie bieżącej listy Zestawienia Materiałów',
  owner: 'Właściciel | Właściciele',
  page: 'Strona | Strony',
  pageBulkInsertConfirmTitle: 'Czy na pewno chcesz zakończyć wprowadzanie zbiorcze?',
  pageBulkInsertConfirmInsertAfter: 'To spowoduje wstawienie {replacement} po {original} w {whereUsedBookCount} {bookUnits} oraz {whereUsedTemplateCount} {templateUnits}.',
  pageBulkInsertConfirmInsertBefore: 'To spowoduje wstawienie {replacement} przed {original} w {whereUsedBookCount} {bookUnits} oraz {whereUsedTemplateCount} {templateUnits}.',
  pageBulkInsertConfirmInsertReplace: 'To spowoduje wstawienie {replacement} i usunięcie {original} w {whereUsedBookCount} {bookUnits} oraz {whereUsedTemplateCount} {templateUnits}.',
  pageBulkInsertBooksConfirmInsertAfter: 'To spowoduje wstawienie {replacement} po {original} w {whereUsedBookCount} {bookUnits}.',
  pageBulkInsertBooksConfirmInsertBefore: 'To spowoduje wstawienie {replacement} przed {original} w {whereUsedBookCount} {bookUnits}.',
  pageBulkInsertBooksConfirmInsertReplace: 'To spowoduje wstawienie {replacement} i usunięcie {original} w {whereUsedBookCount} {bookUnits}.',
  pageBulkInsertTemplatesConfirmInsertAfter: 'To spowoduje wstawienie {replacement} po {original} w {whereUsedTemplateCount} {templateUnits}.',
  pageBulkInsertTemplatesConfirmInsertBefore: 'To spowoduje wstawienie {replacement} przed {original} w {whereUsedTemplateCount} {templateUnits}.',
  pageBulkInsertTemplatesConfirmInsertReplace: 'To spowoduje wstawienie {replacement} i usunięcie {original} w {whereUsedTemplateCount} {templateUnits}.',
  pageBulkInsertDiscard: 'Wprowadzanie zbiorcze nie będzie przetwarzane.',
  pageBulkInsertHelpText: 'Ta funkcja pozwala wstawić stronę źródłową {thisDisplayName} do wybranych katalogów części, w których istnieje już inna strona {originalDisplayName}. Opcjonalnie można także usunąć {originalDisplayName} z tych katalogów.',
  pageBulkInsertInsertAfter: 'Wstaw {replacement} ZA {original}',
  pageBulkInsertInsertBefore: 'Wstaw {replacement} PRZED {original}',
  pageBulkInsertInsertReplace: 'Wstaw {replacement} i USUŃ {original}',
  pageBulkInsertScopeAll: 'Wszystkie {type}, których strona docelowa to {originalDisplayName} ({whereUsedCount} {units})',
  pageBulkInsertScopeNone: 'Żadne z {type}, których strona docelowa to {originalDisplayName}',
  pageBulkInsertScopeSome: 'Niektóre z {type}, których strona docelowa to {originalDisplayName}',
  pageBulkInsertStep1: 'Krok 1: Znajdź wybraną stronę docelową',
  pageBulkInsertStep2: 'Krok 2: Zdecyduj, jak wprowadzić lub zastąpić strony',
  pageBulkInsertStep3: 'Krok 3: Wybierz katalogi, do których chcesz wstawić zbiorczo strony.',
  pageBulkInsertStep4: 'Krok 4: Wybierz szablony, do których chcesz wstawić zbiorczo strony.',
  pageBulkInsertSuccessTitle: 'Przesłano zadanie wprowadzania zbiorczego',
  pageBulkInsertSuccessBody: 'Zmiany w spisie treści katalogów zostaną wprowadzone natychmiast. Wyniki wyszukiwania zostaną odzwierciedlone po zakończeniu indeksowania.',
  pageBulkErrorTooManyBooks: 'Ogranicz liczbę wybranych książek do maksymalnie 1000',
  pageBuilder: 'Kreator Stron',
  pageComposer: 'Page Composer',
  pageCreated: 'Utworzono Stronę',
  pageDeleteMessage: 'Usunięcie stron w bibliotece i sekcji administratora treści nastąpi natychmiastowo. Usunięta strona będzie widoczna w wynikach wyszukiwania do momentu ponownej indeksacji, która nastąpi w ciągi kilku minut.',
  pageDeleteMessage2: 'Tego działania nie można cofnąć bez pomocy działu wsparcia Documoto. Po 90 dniach usunięty rekord i wszystkie jego powiązania zostaną trwale wyczyszczone.',
  pageDescription: 'Opis strony',
  pageFile: 'Plik Strony',
  pageFilename: 'Nazwa pliku strony',
  pageFilenameDeleted: 'Element {filename} zostanie usunięty.',
  pageFileRequired: 'Plik strony jest wymagany.',
  pageBomPartsDeleted: '{count} części na stronie BOM nie będzie już dostępnych za pośrednictwem {filename}.',
  pageDraftsDeleted: 'Strony robocze powiązane z tą stroną zostaną trwale usunięte.',
  pageFit: 'Dopasowanie Strony',
  pageHeader: 'Nagłówek Strony',
  pageLabel: 'Strona | Strony',
  pageLayout: 'Układ Strony',
  pageName: 'Nazwa strony',
  pagePart: 'Część Strony | Części Strony',
  pagePartTag: 'Znacznik Części na Stronie | Znaczniki Części na Stronie',
  pagePartData: 'Dane Części na Stronie',
  pageUsageDataCouldNotBeLoaded: 'Nie można wczytać danych wykorzystania strony',
  pagePartLevelDetails: 'Części ze strony dostępne/widoczne, tagi części ze strony',
  pagePartNumberVisible: 'Numer Katalogowy na Stronie Widoczny',
  pagePartOrderable: 'Możliwość Zamówienia Części na Stronie',
  pagePartOrderableDescription: 'Możliwość Zamówienia Części na tej stronie',
  pagePartVisibleDescription: 'Numer Katalogowy widoczny na tej stronie',
  pageUpdated: 'Zaktualizowano Stronę',
  pageViewer: 'Wyświetlarka Stron',
  pageWidth: 'Szerokość Strony',
  pagesWhereUsed: 'Strony w Użyciu',
  parentName: 'Nazwa Elementu Nadrzędnego',
  part: 'Część | Części',
  partCode: 'Kod Części | Kody Części',
  partCodeAddError: 'Nie można dodać Kodów Części',
  partCodeErrorEmpty: 'Nie można zapisać: Pole kodu części nie może być puste',
  partCodeErrorInUse: 'Ten kod części jest używany przez części w Documoto i nie może zostać usunięty. W celu uzyskania pomocy skontaktuj się z Pomocą Techniczną Documoto pod adresem support{\'@\'}documoto.com.',
  partCodeErrorNotFound: 'Ten kod części nie został usunięty, ponieważ nie można go było znaleźć, upewnij się, że wszystkie zmiany zostały zapisane.',
  partCodesManager: 'Menedżer Kodów Części',
  partCodesMessage: 'Kody części mogą być stosowane zamiast numerów części i często służą do wskazania, kiedy pozycje BOM są przestarzałe lub niedostępne albo służą tylko do celów orientacyjnych (np. \'PNNA\', \'-\', \'REF\').',
  partCreated: 'Utworzono Część',
  partData: 'Dane Części',
  partUpdated: 'Zaktualizowano część',
  partDebouncerEmailQueueJob: 'Kolejka E-mailowa',
  partDescription: 'Opis strony',
  partLabel: 'Część | Części',
  partLevelDetails: 'Ceny części, dostępne/widoczne, dodatkowe tłumaczenia, tagi części',
  partName: 'Nazwa Części',
  partnerApiKey: 'Klucz API partnera',
  partnerRESTRequestLimit: 'Limit żądań REST dla partnera',
  partNumber: 'Numer Części',
  partNumberDescription: 'Wewnętrzny numer części do wyszukiwania przez użytkowników końcowych',
  partNumberVisible: 'Widoczny Numer Części',
  partNumberVisibleDescription: 'Numer części jest widoczny dla użytkowników końcowych',
  partNumberVisibleLocal: 'Widoczne na tej stronie',
  partNumberVisibleLocalBlocked: 'Numer Części Niewidoczny Globalnie',
  partOrderable: 'Możliwość Zamówienia Części',
  partRequired: 'Wymagana jest przynajmniej jedna część',
  partTagProperty: 'Właściwość Znacznika Części | Właściwości Znacznika Części',
  partTag: 'Znacznik Części | Znaczniki Części',
  partThumbnailDescription: '',
  partialSupport: 'Wsparcie Częściowe',
  partsListColumn: 'Kolumna Listy Części | Kolumny Listy Części',
  partsBook: 'Katalog części | Katalogi części',
  partsColumns: 'Kolumny Części',
  partsNotSelected: 'Domyślny obraz do wykorzystywania w miniaturkach. Jeśli nie zostanie zastosowana niestandardowa miniatura, Documoto wyświetli miniaturę domyślną.',
  partsPage: 'Strona z Częściami | Strony z Częściami',
  partsPages: 'Strony Części',
  partsPageGroup: {
    excludePartCodesFromPartsLists: 'Wyklucz Kody Części z Listy Części',
    highlightAlternateRows: 'Podświetl Naprzemienne Wiersze',
    rotateImagesAutomatically: 'Obracaj Obrazy Automatycznie',
    printBorderAroundImages: 'Drukuj Ramkę Wokół Obrazów',
    keepImagesOnLeft: 'Wyrównaj Obrazy do Lewej Strony',
    keepBOMOnRight: 'Wyrównaj BOM do Prawej Strony',
    columnsUpdateErrorMsg: 'Wystąpił błąd podczas próby aktualizacji Kolumn Części',
    columnsUpdateSuccessMsg: 'Zaktualizowano Kolumny Części',
    columnsLoadErrorMsg: 'Nie udało się załadować kolumn części',
    printHotpointsOnImages: 'Drukuj Punkty Interaktywne na Obrazach',
  },
  partsList: 'Lista Części',
  partsListData: 'Dane Listy Części',
  partsListGridHeader: 'Nagłówek Siatki Listy Części',
  password: 'Hasło',
  passwordExpiredHeader: 'Hasło wygasło',
  passwordExpiredInfo: 'Twoje hasło wygasło. Twoje nowe hasło musi spełniać następujące kryteria:',
  passwordCriteriaInfo: 'Twoje nowe hasło musi spełniać następujące kryteria:',
  passwordBlankMessage: 'Pole hasła nie może być puste',
  passwordCannotBeSame: 'Nie może być takie samo jak obecne hasło',
  passwordCasingMassage: 'Musi zawierać co najmniej jedną małą i jedną wielką literę',
  passwordLowerCaseMessage: 'Hasło musi zawierać co najmniej jedną małą literę',
  passwordMinLengthMessage: 'Hasła muszą zawierać co najmniej 8 znaków',
  passwordNotMatchingMessage: 'Hasła nie są identyczne',
  passwordSpecialCharactersMessage: 'Hasło musi zawierać co najmniej jeden znak specjalny',
  passwordUpperCaseMessage: 'Hasło musi zawierać co najmniej jedną wielką literę',
  passwordWillExpire1Day: 'Hasło do logowania wygaśnie za niecały 1 dzień',
  passwordWillExpire5Days: 'Hasło do logowania wygaśnie za niecałe 5 dni',
  passwordsMatch: 'Hasła są zgodne',
  permission: 'Zezwolenie | Zezwolenia',
  phoneNumber: 'Numer Telefonu',
  phoneNumberExt: 'Wewn.',
  phonePrimary: 'Główny Numer Telefonu',
  phoneSecondary: 'Dodatkowy Numer Telefonu',
  pinFilter: 'Przypnij filtr do strony z wynikami wyszukiwania',
  pinned: 'Przypięte',
  plusMoreOrgs: '+ dodatkowe Organizacje: {totalOrgCount}',
  polishDescription: 'Opis Polski',
  polishName: 'Nazwa Polska',
  populatePdfNameWith: 'Uzupełnij nazwę pliku PDF, używając',
  populatePdfNameSelection: 'Wybór sposobu uzupełnienia nazwy pliku PDF',
  port: 'Port',
  portrait: 'Pionowo',
  portugueseDescription: 'Opis Portugalski',
  portugueseName: 'Nazwa Portugalska',
  postComment: 'Zamieść Komentarz',
  postalCode: 'Kod Pocztowy',
  poweredBy: 'Technologia',
  preferences: 'Preferencje',
  preferencesDisable: 'Wyłącz Ustawienia Użytkownika',
  prefix: 'Prefiks | Prefiksy',
  preview: 'Podgląd',
  previewOfName: 'Podgląd {name}',
  pricing: 'Cennik',
  pricingDiscountEnabled: 'Wyświetl Cenę z Rabatem',
  pricingDisplayInCart: 'Wyświetl Ceny w Koszyku',
  pricingDisplayInPartsList: 'Wyświetl Ceny na Liście Części',
  pricingGlobal: 'Globalny Cennik',
  pricingGlobalDescription: 'Normalna cena bez Zmian Organizacyjnych',
  pricingHidePricingButton: 'Włącz Przycisk Ukrywania Ceny',
  pricingHidePricingButtonDisable: 'Wyłącz Przycisk Ukrywania Ceny',
  pricingOrganization: 'Cennik Organizacji',
  pricingOrganizationDeleteConfirm: 'Czy na pewno chcesz usunąć ten Cennik organizacji?',
  pricingOrganizationDescription: 'Cennik organizacji zastępuje cennik globalny dla wszystkich użytkowników w ramach organizacji i jej Organizacji podrzędnych.',
  pricingOrganizationDuplicate: 'Ta organizacja jest już zastąpiona',
  pricingOrganizationEmptyState: 'Nie określono zasad zastępowania organizacji. Kliknij {add}, aby rozpocząć.',
  pricingRetailEnabled: 'Wyświetl Cenę Detaliczną',
  pricingWholesaleEnabled: 'Wyświetl Cenę Hurtową',
  primary: 'Podstawowe',
  print: 'Drukuj',
  printerLane: 'Pas drukarki',
  printJob: 'Zadanie Druku',
  printLogo: 'Drukuj logo',
  printSetting: 'Ustawienia Drukowania',
  printSettingDeleteFail: 'Nie Udało się Usunąć Ustawień Drukowania',
  printSettingSaveFail: 'Nie Udało Się Zapisać Ustawień Drukowania',
  printSettingEmptyMessage: 'Nie zdefiniowano Ustawień Drukowania. Dodaj Ustawienia Drukowania, aby rozpocząć.',
  printSettingSetDefaultSuccess: 'Domyślny zestaw Ustawień Drukowania',
  printSettingUpdateFail: 'Nie Udało Się Zaktualizować Ustawień Drukowania',
  printSettingCopyMessage: 'Wprowadź unikalną nazwę nowych Ustawień Drukowania.',
  printSettingCoverLoadErrorMsg: '',
  printSettingCreated: 'Utworzono Ustawienia Drukowania',
  printSettings: 'Ustawienia Drukowania',
  printSettingsLogo: 'Logo Ustawień Drukowania',
  printing: 'Drukowanie',
  printingDisabled: 'Wyłącz Drukowanie',
  privacyPolicy: 'Polityka Prywatności',
  proceed: 'Kontynuuj',
  process: 'Przetwórz',
  processContent: 'Przetwarzaj Zawartość',
  processing: 'Przetwarzanie',
  processToDraft: 'Przetwarzaj do wersji roboczej',
  processedPlzToDraft: 'Pliki PLZ przetworzone do wersji roboczej',
  production: 'Produkcja',
  professional: 'Professional',
  profile: 'Profil',
  prohibitExportDownloadOfContent: 'Zabroń eksportowania/pobierania zawartości',
  prohibitExportDownloadOfContentHelpText: 'Zaznaczenie „Włącz” uniemożliwi każdemu użytkownikowi (z uprawnieniami) eksportowanie i pobieranie tej zawartości.',
  property: 'Właściwość | Właściwości',
  province: 'Prowincja',
  public: 'Publiczne',
  publish: 'Publikuj',
  publisherLane: 'Pas publikacji',
  publisherManualLane: 'Ręczny pas publikacji',
  publishThumbnailJob: 'Opublikuj Miniaturę',
  publishDocuments: 'Opublikuj Dokumenty',
  publishDraft: 'Opublikuj Wersję Roboczą',
  publisher: 'Publikator | Publikatory',
  publishingErrors: 'Błędy w Publikacji',
  publishingErrorsWithName: 'Błędy Publikacji {name}',
  publishingInProgress: 'Trwa publikowanie, nie można przesłać',
  publisherJob: 'Zadanie Publikatora',
  publisherMessage: 'Ustawienia tworzenia treści, zarządzania i drukowania',
  purchaseOrder: 'Zamówienie Zakupu',
  purchaseOrderTemplateEnabled: 'Włącz Szablony Zamówienia',
  purchaseOrderTemplateDisabled: 'Wyłącz Szablony Zamówienia',
  qty: 'Il.',
  quantity: 'Ilość',
  query: 'Zapytanie',
  quickAdd: 'Szybkie Dodawanie',
  quickAddHelpText: 'Zacznij wpisywać, aby znaleźć części i dodać je do koszyka.',
  quickAddInCartEnabled: 'Włącz Szybkie Dodawanie do Koszyka',
  quickLinks: 'Szybkie Łącza',
  quote: 'Wycena | Wyceny',
  quoteFormType: 'Formularz zapytania',
  quoteTemplateDisabled: 'Wyłącz Szablony Wycen',
  quoteTemplateEnabled: 'Włącz Szablony Wycen',
  range: 'Zakres',
  rearrange: 'Ustaw Ponownie',
  rebuildIndexJob: 'Utwórz Indeks Ponownie',
  recentSearches: 'Ostatnie wyszukiwania',
  recentlyAdded: 'Ostatnio Dodane',
  recentlyEdited: 'Ostatnio Edytowane',
  recentlyViewed: 'Ostatnio Wyświetlane',
  rectangle: 'Prostokąt',
  redirect: 'Przekieruj',
  refresh: 'Odśwież',
  related: 'Powiązane',
  relatedDescription: 'Powiąż multimedia występujące w bibliotece z tym {type}. Uwaga, powiązane multimedia muszą mieć zastosowaną kontrolę dostępu, aby użytkownicy mogli je zobaczyć w zakładce \'Powiązane\'.',
  relatedInfo: 'Informacje Powiązane',
  relatedView: 'Zobacz Powiązane Multimedia',
  relationship: 'Powiązanie | Powiązania',
  releaseNotes: 'Informacje o Wydaniu',
  rememberMe: 'Zapamiętaj mnie',
  remainingWidth: 'Pozostała Szerokość',
  remove: 'Usuń',
  removeAll: 'Usuń Wszystko',
  removeItemConfirmation: 'Czy na pewno chcesz usunąć {item}?',
  removeItemCount: 'Czy na pewno chcesz usunąć {count} {type}?',
  replace: 'Zastąp',
  replaceExisting: 'Zamień Istniejące',
  report: 'Raport | Raporty',
  reportAnIssue: 'Zgłoś Problem',
  reportAnIssueMessage: 'Pomyślnie wysłano e-mail.',
  reportCatalogName: 'Nazwa Katalogu z Raportem',
  reportCatalogNotSet: 'Nie określono katalogu dla raportu.',
  reportDefinition: 'Definicja Raportu | Definicje Raportu',
  reportDefinitionDeleteError: 'Nie usunięto definicji raportu',
  reportsEmptyState: 'Brak Raportów',
  reportExportTimeWarning: 'W zależności od liczby wpisów i wybranego formatu eksport może zająć do kilku minut.',
  reporting: 'Raportowanie',
  reportingMessage: 'Dostęp do różnych raportów i miar',
  reportSettings: 'Ustawienia Raportów',
  reportsLibrary: 'Biblioteka Raportów',
  requestForQuote: 'Zapytanie Ofertowe',
  requestFormType: 'Formularz zapytania',
  required: 'Wymagane',
  requiredItem: 'Wymagany jest obiekt {item}.',
  requiredMessage: 'Wymagany jest obiekt {item}.',
  requiredMessageAndUnique: 'Element {item} jest wymagany i musi być unikatowy.',
  requireAuthentication: 'Wymagane Uwierzytelnienie',
  requiredReading: 'Wymagana Lektura',
  requiredReadingMessage: 'Przejrzyj poniższe informacje:',
  requiredUrlMessage: 'Wymagany jest poprawnie sformułowany adres URL.',
  reset: 'Resetuj',
  resetPassword: 'Resetuj Hasło',
  resetPasswordError: 'Wystąpił problem podczas resetowania hasła, spróbuj ponownie.',
  resetPasswordTokenError: 'Token resetowania hasła jest nieprawidłowy.',
  resetZoom: 'Resetuj Powiększenie',
  resource: 'Zasób | Zasoby',
  resize: 'Zmień rozmiar',
  resizeThumbnails: 'Automatycznie zmieniaj rozmiar miniatur',
  result: 'Wynik | Wyniki',
  resultFor: 'Wynik dla pozycji {item} | Wyniki dla pozycji {item}',
  retail: 'Sprzedaż Detaliczna',
  retailPrice: 'Cena Detaliczna',
  retailTotal: 'Łączna Cena Detaliczna',
  return: 'Powrót',
  returnToItem: 'Powróć do elementu {item}',
  returnToPartList: 'Powróć do Listy Części',
  returnToMedia: 'Powróć do Multimediów',
  returnToTemplate: 'Wróć do Szablonu',
  returnToViewer: 'Powrót do podglądu',
  revertChanges: 'Cofnij Zmiany',
  right: 'W prawo',
  romanianDescription: 'Opis Rumuński',
  romanianName: 'Nazwa Rumuńska',
  rootLevelBrowseFlow: 'Główny Poziom',
  row: 'Wiersz',
  russianDescription: 'Opis Rosyjski',
  russianName: 'Nazwa Rosyjska',
  sampleData: 'Przykładowe Dane',
  samplePreview: 'Przykładowy podgląd',
  sandbox: 'Piaskownica',
  sat: 'So',
  save: 'Zapisz',
  saveChanges: 'Czy zapisać zmiany w {item}? {name}',
  saveChangesToItem: 'Czy zapisać zmiany w {item}?',
  saved: 'Zapisz',
  saveNewAPIKey: 'Nowy Klucz Integracji nie został Zapisany, Wróć do pełnej konfiguracji',
  saveNewWidgetKey: 'Nie zapisano nowego klucza widżetu. Wróć, aby dokończyć konfigurację.',
  search: 'Wyszukaj',
  searchableFieldsSuccessfullyUpdated: 'Pomyślnie zaktualizowano pola z możliwością wyszukiwania.',
  searchAllContent: 'Przeszukaj całą zawartość',
  searchConfiguration: 'Konfiguracja wyszukiwania',
  searchCriteria: 'Kryteria Wyszukiwania',
  searchDocumoto: 'Wyszukaj w Documoto',
  searchFeatures: 'Funkcje wyszukiwania',
  searchFieldConfigDescription: 'Wskazane ustawienia wyszukiwania w polach systemowych mają zastosowanie wyłączenie do ogólnego wyszukiwania w bibliotece, strony z wynikami wyszukiwania oraz opcji szybkiego dodawania do koszyka. Nie wpłyną one na inne opcje wyszukiwania (jak wyszukiwanie w centrum administracyjnym oraz powiązanej bibliotece).',
  searchInContent: 'Wyszukaj w {contentName}...',
  searchingInLanguage: 'Szukanie {lang} tylko w',
  searchingInTwoLanguages: 'Szukanie tylko w {lang1} i {lang2}',
  searchPlaceholder: 'Przeszukaj całą zawartość…',
  searchPrompt: 'Przeszukaj całą zawartość…',
  searchProperty: 'Właściwość Wyszukiwania | Właściwości Wyszukiwania',
  searchResultsConfiguration: 'Konfiguracja wyników wyszukiwania',
  searchSettings: 'Ustawienia wyszukiwania',
  searchSettingsDescription: 'Te ustawienia odnoszą się do różnych zachowań, funkcji i wyników wyszukiwania w bibliotece.',
  searchTerm: 'Termin Wyszukiwania',
  searchTips: 'Wskazówki dotyczące wyszukiwania:',
  searchable: 'Wyszukiwalne',
  searchableTag: 'Wyszukiwalny Znacznik',
  section: 'Sekcja',
  select: 'Wybierz',
  selectAField: 'Wybierz: {field}',
  selectAll: 'Wybierz Wszystko',
  selectAndClose: 'Wybierz i Zamknij',
  selected: 'Wybrano',
  selectedTargetPage: 'Wybierz Stronę Docelową',
  selectChapters: 'Wybierz Rozdziały',
  selectChildren: 'Wybierz Element Podrzędny',
  selectFields: 'Wybierz {fields}',
  selectItem: 'Wybierz {item}',
  selectMedia: 'Wybierz Multimedia',
  selectPage: 'Wybierz Stronę',
  selectPages: 'Wybierz Strony',
  selectTargetPage: 'Wybierz Stronę Docelową',
  send: 'Wyślij',
  september: 'Wrzesień',
  serbianDescription: 'Opis Serbski',
  serbianName: 'Nazwa Serbska',
  serviceUri: 'URI Usługi',
  sessionLog: 'Dziennik sesji',
  set: 'Ustaw',
  setAsDefault: 'Ustaw jako Domyślne',
  settings: 'Ustawienia',
  settingsMessage: 'Aktualizuj preferencje użytkownika i hasło',
  sequence: 'Kolejność',
  shape: 'Kształt',
  shapeTool: 'Narzędzie Kształtu',
  shared: 'Udostępnione',
  sharedCart: 'Koszyk Współdzielony | Koszyki Współdzielone',
  sharedCartDeleteContent: 'Spowoduje to usunięcie tego Współdzielonego Koszyka z wszystkich Organizacji, w których został on udostępniony.',
  sharedCartOrganizationContent: 'Wybierz Organizacje, które mogą korzystać z tego Współdzielonego Koszyka.',
  sharedShoppingCartsEnabled: 'Włącz Koszyki Współdzielone',
  shoppingCartExportJob: 'Eksport Koszyka',
  shoppingCartLoadingLongTime: 'Trwa ładowanie koszyka. Ikony koszyka będą dostępne po zakończeniu procesu. Jeśli problem nie ustąpi, skontaktuj się z administratorem Documoto, aby uzyskać wsparcie.',
  showExportFromSearch: 'Zezwól na Eksport Wyników Wyszukiwania',
  showExportFromSearchHelp: 'Zezwól na eksport listy katalogów z wyników wyszukiwania',
  showHelp: 'Wyświetl pomoc',
  showIdentifierInSearch: 'Wyświetl identyfikator nośnika w wyszukiwaniu',
  showItem: 'Pokaż {item}',
  showLess: 'Pokaż Mniej',
  showMediaDescriptionFilter: 'Pokaż Filtr Opisu Nośnika',
  showMediaIdentifierFilter: 'Pokaż Filtr Identyfikatora Nośnika',
  showMediaNameFilter: 'Pokaż Filtr Nazwy Nośnika',
  showMore: 'Pokaż Więcej',
  showOnlyExactMatches: 'Pokaż tylko dokładne dopasowania',
  showOrganizationFilter: 'Pokaż Filtr Organizacji',
  showPartNameFilter: 'Pokaż Filtr Nazwy Części',
  showPartNumberFilter: 'Pokaż Filtr Numeru Części',
  showRecentlyAdded: 'Pokaż Ostatnio Dodane',
  showRecentlyViewed: 'Pokaż Ostatnio Wyświetlane',
  showSearchButton: 'Pokaż Przycisk Wyszukiwania na Stronie Głównej',
  showSearchButtonHelp: 'Pokaż przycisk \'Przejdź do Wyszukiwania\' w nagłówku Biblioteki',
  signature: 'Podpis',
  size: 'Rozmiar',
  snapshot: 'Migawka | Migawki',
  solrSlaveSvrOverride: 'Zastąp podstawowe adresy URL wtórnych serwerów SOLR',
  sortAsc: '{value} A–Z',
  sortDesc: '{value} Z–A',
  sortRelevant: 'Najtrafniejsze',
  sortType: 'Sortuj wg Typu',
  spanishDescription: 'Opis Hiszpański',
  spanishName: 'Nazwa Hiszpańska',
  square: 'Kwadrat',
  ssoConfiguration: 'Konfiguracja SSO',
  ssoIdpMetadataUrl: 'Adres URL metadanych dostawcy tożsamości SSO',
  ssoLoginMaxAuthenticationAgeSeconds: 'Maksymalny czas uwierzytelniania w sekundach dla logowania SSO',
  ssoSamlDnAttributeName: 'Nazwa atrybutu nazwy wyróżniającej dla SAML logowania SSO',
  ssoSamlEmailAttributeName: 'Nazwa atrybutu adresu e-mail dla SAML logowania SSO',
  ssoSamlMemberofAttributeName: 'Nazwa atrybutu członka dla SAML logowania SSO',
  ssoSniForSsoMetadataRetrieval: 'Użyj SNI do pobierania metadanych SSO',
  standard: 'Standardowe',
  standardUserGroup: 'Standardowa grupa użytkowników',
  startDate: 'Data Rozpoczęcia',
  startDateRequired: 'Wymagana data rozpoczęcia.',
  state: 'Stan',
  static: 'Statyczne',
  status: 'Status',
  statusCode: 'Kod Statusu | Kody Statusu',
  stitchPages: 'Połącz Strony',
  storeFrontAdmin: 'Administrator Storefront',
  storefront: 'Storefront',
  storefrontIntegration: 'Integracja ze Storefront | Integracje ze Storefront',
  storefrontIntegrationSubtitle: 'Te ustawienia dotyczą różnych elementów integracji ze Storefront. Zachowaj szczególną ostrożność przy wprowadzaniu zmian. Skontaktuj się z Pomocą Techniczną Documoto w celu uzyskania dodatkowych informacji.',
  storefrontSettings: 'Ustawienia Storefront',
  storefrontSettingsDescription: 'Te ustawienia dotyczą różnych elementów i opcji ze Storefront. Zachowaj szczególną ostrożność przy wprowadzaniu zmian. Skontaktuj się z Documoto Services w celu uzyskania dodatkowych informacji.',
  storefrontSettingsOrgDescription: 'Te ustawienia dotyczą różnych elementów i opcji na poziomie organizacji Storefront. Zachowaj szczególną ostrożność przy wprowadzaniu zmian. Skontaktuj się z Documoto Services w celu uzyskania dodatkowych informacji.',
  storeFilterValues: 'Zapisz wartości filtra',
  storeFilterValuesDescription: 'Włączenie tego ustawienia spowoduje zapisanie wartości określonych przez użytkowników w filtrach strony wyszukiwania dla tego tagu.',
  stroke: 'Pociągnięcie',
  style: 'Styl | Style',
  styles: {
    colorHotpoint: 'Domyślne ustawienie hotpoint',
    invalidHexcode: 'Błędny kod hex',
    inheritedFromOrg: 'Przejęto od organizacji {org}',
    inheritedFromSystem: 'Przejęto od domyślnych ustawień systemu',
    inheritedFromTenant: 'Przejęto od najemcy',
    organization: 'Styl organizacji | Style organizacji',
    organizationSaved: 'Zapisano style organizacji',
    sampleNavigation: 'Karty nawigacji',
    samplePrimary: 'Nawigacja/przyciski',
    sampleSelectedHotpoint: 'Wybrane hotpointy',
    tenant: 'Styl najemcy | Style najemcy',
    tenantSaved: 'Zapisano style najemcy'
  },
  subChapter: 'Podrozdział | Podrozdziały',
  subject: 'Temat',
  subjectMessage: 'Wymagany jest temat',
  submit: 'Prześlij',
  submitPurchaseOrder: 'Zamówienie Zakupu',
  submitRequestForQuote: 'Zapytanie Ofertowe',
  submitted: 'Przesłane',
  submitter: 'Nadawca',
  submittedBy: 'Przesłane przez',
  submitterEmail: 'Adres e-mail przesyłającego',
  submitterFirstName: 'Imię przesyłającego',
  submittedFilesMessage: 'Przesłano pliki ({fileCount}).',
  submitterLastName: 'Nazwisko przesyłającego',
  submitterOrgName: 'Nazwa organizacji przesyłającego',
  submitterUserName: 'Nazwa użytkownika przesyłającego',
  success: 'Sukces',
  imageSaveSuccess: 'Zaktualizowano Obrazy',
  successNotificationDuration: 'Dodaj do Koszyka – Czas trwania Powiadomienia Toast o Sukcesie',
  suffix: 'Sufiks | Sufiksy',
  suggestedKeywords: 'Sugerowane słowa kluczowe',
  suggestedResults: 'Sugerowane wyniki',
  suggestion: 'Sugestia | Sugestie',
  sun: 'Nd',
  superadmin: 'Superadministrator',
  superAdminSettings: "Ustawienia superadministratora",
  supersede: 'Zastąp',
  supersedeComplete: 'Czy na pewno chcesz zakończyć sesję nadrzędną?',
  supersedeDiscardChanges: 'Czy zakończyć sesję nadrzędną?',
  supersedeMainText: 'Sesja nadrzędna zastępuje istniejącą część co najmniej jedną nową częścią na każdej stronie, na której znajduje się stara część. Stary numer części jest usuwany, ale można go używać do wyszukiwania przez znaczniki, a zatem możliwe jest wyszukanie zawartości przy użyciu starego(-ych) lub nowego(-ych) numeru(-ów).',
  supersedeMessage: 'Aktualizacje dotyczące części wchodzą w życie natychmiast. Wyszukiwanie będzie odzwierciedlać aktualizacje w ciągu kilku minut.',
  supersedeNotProcessed: 'Sesja nadrzędna nie będzie przetwarzana.',
  supersedeReplacePart: 'Znajdź jedną lub więcej części, które zastąpią wybraną część',
  supersedeSuccessful: 'Zastępowanie Zakończone Powodzeniem',
  supersedeWith: 'Zastąp Część…',
  supplier: 'Dostawca | Dostawcy',
  supplierKey: 'Klucz dostawcy',
  supplierDescription: 'Firma dostarczająca część',
  supportedImageFormats: 'Obsługiwane formaty obrazów: GIF, JPG, JPEG, PNG',
  supportedDraftArchives: 'Nieobsługiwany format pliku; obsługiwane formaty wersji roboczej: MDZ, PLZ',
  surroundWithQuotes: 'Ujmij wyszukiwane frazy w cudzysłowach, aby uzyskać dokładny wynik.',
  svgFinder: 'Wyszukiwarka SVG',
  swedishDescription: 'Opis Szwedzki',
  swedishName: 'Nazwa Szwedzka',
  system: 'System',
  systemAdmin: 'Administrator Systemu',
  systemFields: 'Pola Systemowe',
  systemFieldSearchAndFiltering: 'Wyszukiwanie i filtrowanie w polach systemowych',
  systemStatus: 'Stan Systemu',
  systemName: 'Nazwa systemu',
  systemVersion: 'Wersja systemu',
  tabDelimited: 'Tekst rozdzielany tabulatorami',
  tableOfContents: 'Spis Treści',
  tabloid: 'Tabloid',
  tag: 'Znacznik | Znaczniki',
  tagName: 'Nazwa tagu',
  tagEditor: {
    rangeBoundLengthInvalid: 'Granica nie może mieć więcej niż {limit} cyfr.',
    tagEditorModal: 'Modalny Edytor Znaczników',
    filterPrompt: 'Wyświetlono pierwszych 100 wartości. Użyj filtra, aby wyświetlić więcej.'
  },
  tagSearchAndFiltering: 'Wyszukiwanie i filtrowanie wg tagów',
  tagSearchAndFilteringHelpText: 'Wskazówka: niektóre ustawienia wyszukiwania i filtrowania można również skonfigurować w edytorach Menedżera tagów dostępnych tutaj: ',
  tagSubmitAffected: 'Poza wprowadzeniem zmian, znacznik {tag} zostanie odłączony od wszystkich treści typu {type}.',
  tagDeleteAffectedCount: 'Spowoduje to usunięcie znacznika oraz informacji o znaczniku z {count} elementów.',
  tagDeleteAffectedNone: 'Żaden element nie używa obecnie tego znacznika.',
  tagManagerDeleteWarning: 'Wszelkie istniejące multimedia z {deleteCategoryName} zostaną wyłączone z tej kategorii po jej usunięciu.',
  tagsDisplayMore: 'Wyświetlono pierwszych 100 wartości. Użyj filtra, aby wyświetlić więcej.',
  tagsManager: 'Menedżer Zadań',
  tagValue: 'Wartość tagu | Wartości Tagu',
  tagValueMissing: 'Musisz wprowadzić wartość tagu',
  tagValueMultiple: 'Wiele wpisów rozdzielonych średnikami',
  tagValueTooLong: 'Wartość znacznika przekracza limit 25 znaków | Wartości znacznika przekraczają limit 25 znaków',
  taxon: 'Takson',
  taxonomy: 'Taksonomia',
  template: 'Szablon | Szablony',
  templateEmailRecipients: 'Szablon Odbiorców Wiadomości E-mail',
  templateLayout: {
    addRow: 'Dodaj wiersz',
    addItem: 'Dodaj element',
    deleteRow: 'Usuń wiersz',
    deleteColumn: 'Usuń kolumnę',
    fullWidth: 'Pełna szerokość',
    oneColumn: 'Jedna kolumna',
    threeColumns: 'Trzy kolumny',
    twoColumns: 'Dwie kolumny'
  },
  tenant: 'Dzierżawca | Dzierżawcy',
  tenantAdmin: 'Administracja Dzierżawcami',
  tenantAdminMessage: 'Kontroluje ustawienia dzierżawców',
  tenantCleanerJob: 'Utrzymanie Dzierżawy',
  tenantConfiguration: 'Konfiguracja dzierżawcy',
  tenantEmailRecipients: 'Odbiorcy Wiadomości E-mail do Dzierżawców',
  tenantEmailRecipientsOverrideMessage: 'Zostaną nadpisane, jeśli Odbiorcy Wiadomości E-mail dla Organizacji są wskazani',
  tenantIndexQueueJob: 'Kolejka Indeksu Dzierżawy',
  tenantKey: 'Klucz dzierżawcy',
  tenantKeyError: 'Klucz dzierżawcy nie jest unikatowy',
  tenantKeyMessage: 'Klucz dzierżawcy musi być unikatowy',
  tenantLabelDeleteMessage: 'Usunięcie etykiety spowoduje przywrócenie domyślnej nazwy Documoto.',
  tenantReindexModulus: 'Moduł reindeksowania dzierżawcy',
  tenantSettings: {
    collapseTOCLabel: 'Zwiń Spis Treści na Pasującej Stronie',
    defaultLocaleLabel: 'Domyślna Lokalizacja',
    defaultLocaleMessage: 'Domyślna lokalizacja dzierżawcy.',
    deleteMissingTranslationsOnPublishLabel: 'Usunąć brakujące tłumaczenia podczas publikowania?',
    deleteMissingTranslationsOnPublishMessage: 'Włącz usuwanie brakujących tłumaczeń podczas publikowania',
    defaultSupplierLabel: 'Dostawca Domyślny',
    defaultSupplierMessage: 'Domyślny dostawca dzierżawcy.',
    enableChapterIndexingMessage: 'Zaindeksuj rozdziały',
    enableChapterIndexingLabel: 'Włącza opcję indeksowania dla rozdziałów mediów',
    enableIndexingLabel: 'Indeks w zakresie Publikowania',
    enableIndexingMessage: 'Włącza indeksowanie dla publikowania.',
    helpUrlLabel: 'Domyślny Adres URL Pomocy',
    helpUrlMessage: 'Adres URL alternatywnego systemu pomocy.',
    hotPointLinkPageInBookOnlyLabel: 'Tylko Linki Hotpoint do Strony w Sprawozdaniu',
    hotPointLinkPageInBookOnlyMessage: 'Tylko Linki Hotpoint ze Stron w Sprawozdaniu',
    mediaTimestampLabel: 'Aktualizuj Znacznik Czasu Multimediów przy Zmianie Ustawień Kontroli Dostępu',
    mediaTimestampMessage: 'Włącz, jeśli zmiana w kontroli dostępu powinna aktualizować znacznik czasu multimediów.',
    publisherDefaultUom: 'Domyślna JM Publikującego',
    publishingContactLabel: 'Adres E-mail Kontaktu Publikującego',
    publishingContactMessage: 'Domyślny adres e-mail, na który przesyłane będą wiadomości, gdy zostanie zgłoszony problem z treścią.',
    replaceHotpointLabel: 'Zawsze Zastępuj Łącza Hotpoint przy Publikacji',
    replaceHotpointMessage: 'Tylko w przypadku publikacji w Docustudio.',
    ssoLoginSystemLabel: 'System logowania SSO',
    ssoLoginSystemMessage: 'Dostawca tożsamości dla SSO.',
    userPasswordExpirationLabel: 'Dni do wygaśnięcia hasła użytkownika',
    userPasswordExpirationMessage: 'Liczba dni, po których należy zresetować hasła; pozostaw puste, aby nigdy nie wygasło',
    lockPartTrOnPublishLabel: 'Zablokuj brakujące tłumaczenia podczas publikowania',
    lockPartTrOnPublishMessage: 'Uniemożliwia modyfikowanie brakujących tłumaczeń podczas publikowania',
    exportPgAsHashLabel: 'Eksportuj nazwę pliku strony jako klucz skrótu',
    exportPgAsHashMessage: 'Użyj kluczy skrótu strony jako nazw plików strony w eksportowanej zawartości'
  },
  tenantSettingsTitle: 'Ustawienia Najemcy',
  tenantSettingsSaved: 'Zapisano Ustawienia Najemcy',
  tenantSettingsSubtitle: 'Te ustawienia sterują wszystkimi właściwościami i zachowaniami najemcy. Przy wprowadzaniu zmian należy zachować ostrożność. W celu uzyskania dalszych informacji należy Skontaktować się z Działem usług Documoto.',
  tenantSuppliers: 'Dostawcy dzierżawcy',
  tenantLabelsPageText: 'Etykiety są używane do dostosowywania nazw wielu obiektów znajdujących się w Bibliotece i na Ekranach Administracyjnych Documoto.',
  tenantPrintSettingsDescription: 'Wybierz domyślne ustawienia drukowania, dla całej dzierżawy. Te ustawienia będą używane dla wszystkich wydruków katalogów, chyba że zostaną zmienione w sekcjach Organizacja lub Multimedia. ',
  tenantType: 'Typ dzierżawcy',
  tenantWebserviceUser: 'Użytkownik usługi internetowej dzierżawcy',
  text: 'Tekst',
  textArea: 'Obszar Tekstowy',
  textField: 'Pole Tekstowe',
  textFile: 'Plik Tekstowy (CSV, TXT)',
  textTool: 'Narzędzie Tekstowe',
  threeD: {
    addFile: 'Dodaj plik z częściami 3D',
    beforeSaveModal: {
      title: 'W systemie Documoto już istnieje plik CAD o tej nazwie.',
      message: 'Czy chcesz zastąpić ten plik w każdej lokalizacji, czy tylko dołączyć go do tej części? Jeśli zdecydujesz się zastąpić plik:',
      messageBulletOne: '{count} Nastąpi aktualizacja części nowym plikiem CAD',
      messageBulletTwo: 'Poprzednia wersja pliku zostanie usunięta z systemu plików Documoto',
      tip: 'Wskazówka: Aby zapobiec nadpisaniu pliku podczas procesu dołączania, przed zaimportowaniem możesz zmienić jego nazwę.'
    },
    deleteTitle: 'Czy na pewno chcesz usunąć widok 3D?',
    drawMode: {
      label: 'Tryb rysowania',
      hiddenLines: 'Ukryte linie',
      shaded: 'Zacienienie',
      wireframe: 'Szkielet',
      wireframeShaded: 'Szkielet z zacienieniem',
    },
    explode: 'Rozbij na części',
    fileSizeModal: {
      message: 'Maksymalny rozmiar pliku dozwolony w Documoto wynosi 25 megabajtów. Plik, który chcesz załadować, przekracza tę wartość i nie można go załadować. Kliknij „OK”, aby kontynuować.',
      title: 'Przekroczono maksymalny rozmiar pliku',
    },
    orientationAndProjection: {
      back: 'Tył',
      bottom: 'Dół',
      front: 'Przód',
      isometric: 'Izometryczny',
      left: 'Lewy',
      orthographic: 'Ortograficzny',
      projection: 'Odwzorowanie',
      perspective: 'Perspektywa',
      right: 'Prawy',
      top: 'Góra'
    },
    resetCamera: 'Resetuj aparat',
    wycinek: {
      label: 'Wycinek',
      planeX: 'Płaszczyzna cięcia X',
      planeY: 'Płaszczyzna cięcia Y',
      planeZ: 'Płaszczyzna cięcia Z',
      reset: 'Resetuj płaszczyznę cięcia',
      section: 'Przełącz przekrój płaszczyzny cięcia',
      visibility: 'Przełącz widok płaszczyzny cięcia',
    },
    supportedFilesMessage: 'System Documoto obsługuje różne formaty plików CAD. Skontaktuj się z centrum pomocy, jeśli nie masz pewności, czy dany format pliku CAD jest obsługiwany.',
    toolsLabel: 'Narzędzia 3D',
    topAssemblyFilename: 'Nazwa pliku Top Assembly',
    topAssemblyFilenameRequired: 'Nazwa pliku Top Assembly jest wymagana',
    viewerTitle: 'Podgląd 3D',
  },
  thu: 'Cz',
  thumbnail: 'Miniatura',
  thumbnailDeleted: 'Pomyślnie Usunięto Miniatury',
  thumbnailDeletedError: 'Usunięcie Miniatury Nie Powiodło Się',
  thumbnailUpdated: 'Pomyślnie Zaktualizowano Miniatury',
  thumbnailUpdatedError: 'Aktualizacja Miniatury Nie Powiodła Się',
  thumbnailReapplyWarning: 'System może ponownie zastosować miniaturę na podstawie zawartości multimediów. To może zająć kilka minut.',
  thumbnailResizeTooltip: 'Po zaznaczeniu umożliwia zmianę rozmiaru miniatur do maksymalnego rozmiaru 500 × 500 pikseli.',
  title: 'Tytuł',
  to: 'Do',
  toastNotifications: 'Powiadomienia Toast',
  toDate: 'Do Dnia',
  toc: 'Spis Treści',
  tocClipBoardSuccessTitle: 'Skopiowano do Schowka',
  tocClipBoardWarningText: 'OSTRZEŻENIE: Niezapisane zmiany nie mogą zostać skopiowane do schowka',
  tocClipBoardWarningTitle: 'Skopiowano do Schowka',
  tocDisplayingFirstValues: 'Wyświetlono pierwszych 100 wartości. Użyj filtra, aby wyświetlić więcej.',
  tocDuplicateWarningText: 'W rozdziale wykryto powieloną stronę. Duplikat został usunięty.',
  tocEmptyMessage: 'Wygląda na to, że nie utworzono jeszcze Spisu Treści.',
  toGetStarted: 'aby rozpocząć',
  tocMissingChapterName: 'Brak Tytułu Rozdziału',
  tocModalTableCaption: 'Modalny Edytor Znaczników',
  tocNothingSelected: 'Niczego Nie Wybrano',
  tocOpenEditor: 'Otwórz Edytor',
  tocSaveRequired: 'Wymagane jest zapisanie przed przystąpieniem do edycji szczegółów rozdziału',
  tocState: 'Początkowy Stan Spisu Treści',
  tocThumbnailDeleteFailTitle: 'Usunięcie Miniatury Nie Powiodło Się',
  tocThumbnailDeleteSuccessTitle: 'Pomyślne Usunięcie Miniatury',
  tocThumbnailUploadFailTitle: 'Przekazanie Miniatury Nie Powiodło Się',
  tocThumbnailUploadSuccessTitle: 'Pomyślne Przekazanie Miniatury',
  tocTranslationMessage: 'Nazwa i opcjonalny opis tego rozdziału',
  tocUntitledPage: 'Strona Bez Tytułu',
  tooManySelections: 'Lista przekracza limit wyboru wynoszący {limit}',
  togglePage: 'Przełącz Stronę',
  top: 'Na Górze',
  total: 'Łącznie',
  totalItems: 'Pozycje Łącznie',
  translation: 'Tłumaczenie | Tłumaczenia',
  translationHelpTextItem: 'Nazwa i opcjonalny opis elementu {item}',
  true: 'Prawda',
  tryAgain: 'Spróbuj ponownie.',
  tue: 'Wt',
  turkishDescription: 'Opis Turecki',
  turkishName: 'Nazwa Turecka',
  twoSided: '2-stronnie',
  type: 'Typ | Typy',
  typeAtLeastTwoChars: 'Wpisz co najmniej 2 znaki, aby zobaczyć listę dopasowań.',
  typeToConfirm: 'Typ do potwierdzenia: {value}',
  typography: 'Typografia',
  ukraineDescription: 'Opis Ukraiński',
  ukraineName: 'Nazwa Ukraińska',
  unableToRetrieveResults: 'Nie można pobrać wyników zapytania/wyszukiwania',
  unauthorized: 'Niezatwierdzone',
  unknownError: 'Wystąpił nieznany błąd. W celu uzyskania pomocy skontaktuj się z pomocą techniczną Documoto pod adresem support{\'@\'}documoto.com',
  unknownPart: 'Nieznana Część',
  unknownType: 'Nieznany typ',
  unsavedAPIKeyWarning: 'Twój Klucz API zostanie utracony, jeśli nie Wrócisz',
  unsavedChanges: 'Masz niezapisane zmiany. Kontynuować?',
  unsavedChangesWarning: 'Zmiany zostaną utracone, jeżeli ich nie zapiszesz.',
  unsavedWidgetKeyWarning: 'Jeśli nie wrócisz, utracisz klucz widżetu.',
  unsupportedBrowserMessage: 'Stworzyliśmy tę aplikację przy użyciu najnowszych technologii. Sprawia to, że jest ona szybsza i łatwiejsza w obsłudze. Niestety Twoja przeglądarka nie obsługuje tych technologii.',
  uom: 'Jednostka Miary',
  update: 'Aktualizuj',
  updateFailed: 'Aktualizacja nie powiodła się',
  updateIndex: 'Aktualizuj Indeks',
  updatePassword: 'Aktualizuj Hasło',
  updatePasswordMessage: 'Pomyślnie zaktualizowano hasło',
  updatePasswordPending: 'Trwa zapisywanie zmiany hasła.',
  updated: 'Zaktualizowano',
  upgradeBrowser: 'Zaktualizuj przeglądarkę.',
  upload: 'Prześlij',
  uploadComplete: 'Ukończono Przekazywanie',
  uploadInProgress: 'Przekazywanie w Toku',
  uponSavingDisabledTenant: 'Po zapisaniu tej strony przy tym ustawieniu:',
  uponSavingThisLicense: 'NALEŻY WYKONAĆ NASTĘPUJĄCE CZYNNOŚCI:',
  upperBound: 'Górne Ograniczenie',
  untitledOrg: 'Organizacja Bez Tytułu',
  usCurrency: 'USD',
  useDefaultCartColumns: 'Użyj Domyślnych Kolumn Koszyka',
  useDefaultPartsListColumns: 'Użyj Domyślnego Układu Listy Części',
  user: 'Użytkownik',
  userAddress: 'Adresy Użytkownika | Adresy Użytkowników',
  userCount: 'Liczba Użytkowników',
  userEditable: 'Edytowalne przez Użytkownika',
  userExpirationDate: 'Data wygaśnięcia dla użytkownika',
  userExpirationDateInvalid: 'Należy podać datę wygaśnięcia dla użytkownika',
  userGroup: 'Grupa Użytkowników | Grupy Użytkowników',
  userGroupEmpty: 'Grupy Użytkowników nie mogą być puste',
  userGroupType: 'Typ grupy użytkowników',
  userGroupUniqueMessage: 'Ta nazwa musi być unikalna w stosunku do pozostałych nazw Grup Użytkowników dla tego dzierżawcy',
  userGroupUsers: 'Użytkownicy w Grupie',
  username: 'Nazwa Użytkownika',
  usernameEmpty: 'Pole Nazwy Użytkownika nie może być puste',
  users: 'Użytkownicy',
  usersWillReceiveAnUnauthorizedError: 'przy próbie zalogowania do tego konta użytkownicy zobaczą błąd „Brak uprawnień”.',
  useSniForSsoMetadataRetrieval: 'Użyj SNI do pobierania metadanych SSO',
  value: 'Wartość',
  validate: 'Potwierdź',
  validateTenantDirectories: {
    buttonLabel: 'Weryfikuj katalogi zawartości',
    error: 'Nie udało się zweryfikować katalogów',
    success: 'Zweryfikowane katalogi dzierżawców'
  },
  variantSKU: 'SKU Wariantu',
  validationMessage: 'Komunikat Walidacji | Komunikaty Walidacji',
  valueGteError: 'Wartość musi być niższa od lub równa {max}',
  valueLteError: 'Wartość musi być wyższa od lub równa {min}',
  vendorName: 'Nazwa dostawcy',
  verifyPassword: 'Potwierdź Hasło',
  vertical: 'Pionowo',
  version: 'Wersja',
  video: 'Film | Filmy',
  view: 'Widok',
  viewAll: 'Zobacz wszystko',
  viewAllPagesAndBooks: 'Widok: Wszystkie strony i księgi',
  viewAllResults: 'Zobacz wszystkie wyniki',
  viewBooks: 'Widok: Księgi',
  viewMore: 'Zobacz więcej',
  viewPages: 'Widok: Strony',
  viewModeList: 'Widok Listy',
  viewModeTable: 'Widok Tabeli',
  visibility: 'Widoczność',
  visibilityDescription: 'Kto może przeglądać treści: {entity}',
  visible: 'Widoczne',
  waitForUpload: 'Zaczekaj na zakończenie przekazywania.',
  watermark: 'Znak wodny',
  watermarkFont: 'Czcionka znaku wodnego',
  watermarkOpacityPercentage: 'Widoczność znaku wodnego (%)',
  watermarkOrientation: 'Kierunek znaku wodnego',
  watermarkSaved: 'Zapisano znak wodny',
  watermarkSaveFailed: 'Nie udało się zapisać znaku wodnego.',
  watermarkSelectionRequired: 'Wymagana nazwa użytkownika lub tekst.',
  watermarkText: 'Tekst znaku wodnego',
  wed: 'Śr',
  whereUsed: 'Miejsca Użycia',
  whoCanViewComment: 'Kto może zobaczyć ten komentarz?',
  wholesale: 'Hurtowa',
  wholesalePrice: 'Cena Hurtowa',
  wholesaleTotal: 'Łączna Cena Hurtowa',
  widget: 'Widżet',
  widgetAuthHeader: 'Wartość autoryzacji widżetu',
  widgetKey: 'Klucz widżetu | Klucze widżetów',
  widgetKeyAdmin: 'Administrator klucza widżetu',
  widgetKeyCopied: 'Wartość autoryzacji widżetu skopiowana do schowka',
  widgetKeyDeleted: 'Usunięto klucz widżetu',
  widgetKeyDeletedError: 'Podczas usuwania klucza widżetu wystąpił błąd',
  widgetKeyDeleteMsg: 'Usunięcie klucza widżetu uniemożliwi jego wykorzystanie',
  widgetKeyManager: 'Menedżer kluczy widżetów',
  widgetKeyNameEmpty: 'Nazwa musi być unikatowa i jest wymagana. Wartość ta zostanie również wykorzystana do celów raportowania i logowania jako nazwa użytkownika dla dowolnej aktywności widżetu',
  widgetKeyRegenError: 'Podczas ponownego generowania klucza widżetu wystąpił błąd',
  widgetKeyRegenMsg: 'Ponowne wygenerowanie tego klucza widżetu może dezaktywować istniejące integracje',
  widgetKeyRegenTitle: 'Czy na pewno chcesz ponownie wygenerować ten klucz widżetu?',
  widgetKeySaved: 'Zapisano klucz widżetu',
  widgetPermissions: 'Użytkowników widżetu można tylko odczytać, a dostępem do funkcji zarządza się na poziomie konfiguracji widżetu. Standardowe uprawnienia dla grupy użytkowników nie mają zastosowania do użytkowników widżetu. Zastosowania mają natomiast uprawnienia dostępu do kategorii multimediów. Przejdź do opcji przypisania kontroli dostępu do kategorii multimediów.',
  widgetRefreshErrorMsg: "Odśwież stronę, aby wyświetlić żądane treści",
  widgetUserGroup: 'Grupa użytkowników widżetu',
  widgetUserGroupDeleteMsg: 'Nie można usunąć grupy widżetu z użytkownikami',
  willBeAffected: 'będzie dotyczyć.',
  willBeDeleted: 'Treść {content} zostanie usunięta.',
  willBeInsertedIntoItem: 'Nazwa {name} zostanie wstawiona do pozycji {item}',
  willBeUpdated: 'zostanie zaktualizowane(-a/-y).',
  zipCode: 'Kod Pocztowy',
  zoomHotpointToCanvas: 'Zbliż i Dostosuj Łącze Hotpoint do rozmiaru Tła',
  zoomIn: 'Powiększ',
  zoomOut: 'Pomniejsz'
}
/* eslint-enable max-len */
