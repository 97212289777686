<template>
  <section  class="is-flex is-justify-content-center" 
            style="align-items: center; height: 100%;">
    <loading-icon v-show="themeLoaded"/>
  </section>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
import LoadingIcon from '@/components/common/LoadingIcon.vue'

const store = useStore()
const themeLoaded = computed(() => !!store.getters['theme/getTheme'] && !!store.state.auth.hasWidgetStyles)
</script>