<template>
  <bulma-field  has-addons>
    <bulma-input  :value="email"
                  disabled
                  expanded />
    <bulma-button-icon  class="ml-1 is-danger is-light is-outlined"
                        iconClass="fas fa-trash"
                        @click="deleteEmail"/>
  </bulma-field>
</template>

<script>
import BulmaInput from "@/components/common/BulmaInput.vue"
import BulmaField from "@/components/common/BulmaField.vue"
import BulmaButtonIcon from "@/components/common/BulmaButtonIcon.vue"

export default {
  name: "EmailEntry",
  components: { BulmaButtonIcon, BulmaInput, BulmaField },
  props: {
    email: { 
      type: String,
      default: ""
    }
  },
  methods: {
    deleteEmail() {
      this.$emit('deleteEmail', this.email)
    }
  }
}
</script>