import { RequiredWidgetConfig, WidgetConfig, WidgetTypesToIdentifiers, isWidgetLackingIdentifier } from '@/interfaces/global/widgets'

export function isValidWidgetConfig(config: WidgetConfig, 
  validLocalesSet: Set<string>) {
  return (typeof config === "object") 
    && hasRequiredFields(config) 
    && hasIdentifierForType(config)
    && isValidLocale(config.locale, validLocalesSet)
}

function hasRequiredFields(config: WidgetConfig) {
  return Object.keys(RequiredWidgetConfig)
    .every(field => !!config[field])
}

function hasIdentifierForType(config: WidgetConfig) {
  const { widgetType } = config
  return isWidgetLackingIdentifier(widgetType)
    || !!config[WidgetTypesToIdentifiers[widgetType]]
}

function isValidLocale(locale: string, validLocalesSet: Set<string>) {
  return validLocalesSet.has(locale.replace("_", "-"))
}