/* eslint-disable max-len */
module.exports =
{
  aOfB: '{a} de {b}',
  about: 'Acerca de',
  aboutThisItem: 'Acerca de Este item',
  accessApiKeyManager: 'Acceder al Administrador de la Clave de API para este propietario.',
  accessControls: 'Controles de Acceso',
  accessControlsCopiedText: 'Los controles de acceso se actualizan inmediatamente. Los privilegios de seguridad de los medios se actualizarán en los próximos minutos.',
  accessControlsCopiedTitle: 'Los controles de acceso se han copiado correctamente',
  accessControlsCopyConfirmation: 'Esto eliminará todos los controles de acceso existentes desde {to} y los reemplazará por los controles de acceso desde {from}. Esta acción no se puede deshacer.',
  accessControlsDescription: 'Los controles de acceso definen quién puede ver estos medios, basándose en las Categorías de Medios y de Organizaciones seleccionadas',
  accessControlsIndeterminance: 'Nota: Las organizaciones principales siempre tienen acceso al contenido asignado a todas sus organizaciones secundarias.',
  account: 'Cuenta',
  accountErrorMessage: 'Mensaje de error de la cuenta',
  accountErrorMessageWarning: 'El campo accountErrorMessage en la base de datos se configurará a "No autorizado" para el propietario.',
  action: 'Acción | Acciones',
  actionDelete: 'eliminar',
  actionLoad: 'cargar',
  actionPaste: 'Pegar',
  actionSave: 'Guardar',
  actionRun: 'ejecutar',
  actionUndoWarning: 'Esta acción no se puede deshacer.',
  actionUpload: 'subir',
  actualSize: 'Tamaño Real',
  add: 'Añadir',
  addAPIKey: 'Agregar una Clave de API',
  addItem: 'Añadir {item}',
  addABookText: 'Añadir un libro',
  addASharedCart: 'Añadir un carro compartido',
  addATemplate: 'Añadir una plantilla',
  addATag: 'Añadir una etiqueta',
  addABookToStart: '{addABookText} para comenzar.',
  addAForm: 'Añadir un Formulario para comenzar.',
  addAFormOnly: 'Añadir un Formulario',
  addAMedia: 'Añadir un medio',
  addAnOrderQuoteEmailFormat: 'Añadir un Pedido/Formato del Correo Electrónico de Presupuesto',
  addAPrintSettingText: 'Añadir una Configuración de Impresión',
  addAPrintSettingToStart: '{addAPrintSettingText} para comenzar.',
  addBooks: 'Añadir Libros',
  addChildBrowseFlow: 'Añadir Flujo del Navegador secundario',
  addChapter: 'Añadir Capítulo',
  addComment: 'Añadir comentario',
  addExistingPart: 'Añadir Sección Existente',
  addGroupsToUsers: 'Añadir {groups} grupos a {users} usuarios',
  additionalInformation: 'Información Adicional',
  additionalNotification: 'Notificación Adicional',
  additionalPartNumberDescription: 'Usar como campo adicional de cifras',
  addBrowseFlow: 'Añadir un Flujo del Navegador',
  addMedia: 'Añadir Medios',
  addPages: 'Añadir Páginas',
  addPart: 'Añadir una Sección',
  addReports: 'Añadir Informes',
  addRootBrowseFlow: 'Añadir Flujo del Navegador Raíz',
  addRow: 'Añadir Fila',
  addTemplates: 'Añadir plantillas',
  addToCartMessage: 'Se ha añadido correctamente {partNumber}',
  addToUserGroup: 'Añadir a Grupos',
  address: 'Dirección | Direcciones',
  addressInUse: 'Ya se ha configurado una dirección como dirección predeterminada y no se puede eliminar',
  addressInUseSubtitle: 'Para eliminar esta dirección, en primer lugar, debe borrarla como dirección predeterminada.',
  addressLine: 'Línea {number} de la Dirección',
  addWidgetKey: 'Agregar una Clave de Widget',
  admin: 'Administración',
  adminCenter: 'Centro de Administración',
adminCenterBanner: {
    bodyLabel: 'Cuerpo del Banner',
    enableLabel: 'Habilitar Banner de Admin',
    headerGreenOption: 'Verde',
    headerLabel: 'Encabezado del Banner',
    headerRedOption: 'Rojo',
    headerYellowOption: 'Amarillo',
    title: 'Banner del Centro de Administración',
    typeLabel: 'Tipo de Banner'
  },
  adminCenterMessage: 'Centro de administración para contenido, usuarios, configuración de biblioteca, creación de informes y exportación',
  adminMessage: 'Las características se añaden a medida que están disponibles',
  administration: 'Administración',
  affectedBookMedia: '{count} Los Medios de Libros se actualizarán.',
  affectedTemplateMedia: '{count} Las Plantillas de Libros se actualizarán.',
  after: 'Después',
  aggregatedFromChildren: 'Agregado de Elemento Secundario',
  aiAlgorithm: 'Escáner de IA',
  all: 'Todo',
  alphanumeric: 'Alfanumérico',
  algorithm: 'Algoritmo',
  alignHorizontal: 'Alineación Horizontal',
  alignVertical: 'Alineación Vertical',
  allUsersDisabled: 'Se deshabilitarán todos los usuarios que pertenecen al propietario.',
  analyticsJSON: 'Análisis JSON',
  andCountMore: 'y {count} más...',
  any: 'Todos',
  apiKey: 'Clave de API | Claves de API',
  apiKeyAdmin: 'Administrador de Claves de API',
  apiAuthHeader: 'Encabezado de Autorización de API',
  apiKeyCopied: 'Valor de Encabezado de Autorización de API copiado en el Portapapeles',
  apiKeyDeleted: 'La Clave de API se ha eliminado',
  apiKeyDeletedError: 'Error al eliminar la Clave de API',
  apiKeyDeleteMsg: 'Al eliminar esta Clave de API, se deshabilitará su uso por parte de las integraciones',
  apiKeyDeleteTitle: '¿Seguro que desea eliminar esta Clave de API?',
  apiKeyInstructions: 'La Clave se generará al Guardar',
  apiKeyManager: 'Administrador de la Clave de API',
  apiKeyNameEmpty: 'Nombre no puede estar vacío',
  apiKeyRegenError: 'Error al regenerar la Clave de API',
  apiKeyRegenMsg: 'Al Regenerar esta Clave de API, es posible que se deshabiliten las integraciones existentes',
  apiKeyRegenTitle: '¿Seguro que desea regenerar esta Clave de API?',
  apiKeySaved: 'La Clave de API se ha Guardado',
  apiKeyType: 'Tipo de Clave de API',
  appliesTo: 'Se aplica a',
  appliesToEmpty: 'Solicite que se seleccione al menos un tipo de entidad',
  appliesToPopulated: 'Determine a qué entidades puede aplicarse la etiqueta',
  applyItem: 'Aplicar {item}',
  applyToChildItems: 'Aplicar a Elemento Secundario {item}',
  applyToPartOnly: 'Aplicar solo a esta pieza',
  april: 'Abril',
  area: 'Área',
  areYouSure: '¿Seguro que desea eliminar {item}?',
  areYouSureCopy: '¿Seguro que desea copiar {item}?',
  areYouSureYouWantToDisableTheTenant: '¿Seguro que desea deshabilitar el propietario?',
  assembly: 'Ensamblaje | Ensamblajes',
  assemblyCreatorSuccessTitle: 'Se ha enviado la creación de tarea de ensamblado',
  assemblyCreatorSuccessSubtitle: 'Los enlaces de puntos calientes y los cambios en el índice del libro se realizarán inmediatamente. Los resultados de búsqueda se verán reflejados cuando termine la indexación.',
  assemblyConfirmationTitle: '¿Desea terminar el ensamblaje creado?',
  assemblyCreatorHelpText1: 'Esta función le permite insertar una página seleccionada en los libros en el lugar en el que se encuentre esta sección {partName}, conectando automáticamente la sección y la página seleccionada a través de un enlace de punto caliente. De manera opcional, puede restringir la inserción a los libros seleccionados exclusivamente.',
  assemblyCreatorHelpText2: 'La página seleccionada se inserta una vez por libro después de la primera aparición de {partName}.',
  assemblyStepOne: 'Paso 1: Seleccionar la página que se va a insertar',
  assemblyStepTwo: 'Paso 2: Elegir los libros donde quiere que se inserte la página',
  asset: 'Ventaja | Ventajas',
  assetsNotFound: 'No se ha Encontrado {type}',
  assignBrowseFlow: 'Asignar Flujo del Navegador',
  assignedOrganizations: 'Organizaciones Asignadas',
  atLeastFiveError: 'Seleccione un valor que no sea inferior a 5.',
  attachment: 'Documento Adjunto |  Documentos Adjuntos',
  attachmentEditorDescription: 'Adjuntar imágenes, vídeos y documentos directamente a esta entidad. Nota: se encontrarán en la pestaña "Relacionados".',
  attachmentView: 'Ver Adjuntos',
  attachRelatedPDFs: 'Adjuntar PDF de las páginas de secciones relacionadas',
  attributions: 'Atribuciones',
  august: 'Agosto',
  automaticZoom: 'Zoom Automático',
  autoHotpoint: 'Punto Caliente Automático',
  autoPopulate: 'Rellenar Automáticamente',
  availability: 'Disponibilidad',
  back: 'Atrás',
  backCover: 'Contraportada',
  backStitch: 'Puntada de Vuelta',
  backToLibrary: 'Volver a Biblioteca',
  backToSearch: 'Volver a Buscar',
  badCredentials: 'Credenciales Incorrectas',
  bahasaDescription: 'Descripción en Indonesio Bahasa',
  bahasaName: 'Nombre Indonesio Bahasa',
  banner: 'Banner',
  before: 'Antes',
  beginTypingToFindAnything: 'Empiece a escribir para buscar algo',
  betaAccessKeys: 'Claves de acceso a la versión Beta',
  betaResources: 'Recursos Beta',
  betaReleaseVideo: 'Vídeo de Lanzamiento Beta',
  blobFinder: 'Buscador BLOB',
  body: 'Cuerpo',
  bomDefaultSupplier: 'Proveedor Predeterminado de la Lista de materiales',
  bomItem: 'Elemento de la Lista de Materiales',
  bom: 'Lista de Materiales',
  bomExportSuccess: 'La Lista de materiales se ha Exportado Correctamente',
  bomLayout: 'Disposición de la Lista de Materiales',
  bomListOptions: 'Opciones de la Lista de Materiales',
  bomListSettings: 'Configuración de la Lista de Materiales',
  both: 'Ambos',
  book: 'Libro | Libros',
  bookInserts: 'Inserciones del Libro',
  bookLabel: 'Libro | Libros',
  bookPrintingDisable: 'Deshabilitar Impresión de Libros',
  booksAndPagesDisplay: 'Visualización de Libros y Páginas',
  boost: 'Mejora de la Búsqueda',
  boostDescription: 'Añadir un valor superior a 1,5 para mejorar la relevancia de la búsqueda de esta etiqueta sobre los números de secciones, los identificadores de medios y los nombres',
  boostRangeInvalid: 'La búsqueda debe estar entre 0,5 y 100,0',
  border: 'Borde',
  bottom: 'Parte Inferior',
  branding: 'Creación de marca',
  browse: 'Examinar',
  browseComputer: 'Examinar Ordenador',
  browseFlow: 'Flujo del Navegador | Flujos del Navegador',
  browseFlowAssigned: 'Flujo del Navegador Asignado',
  browseFlowCopyMessage: 'Introducir un nombre exclusivo para el nuevo Flujo del Navegador.',
  browseFlowDeleted: 'Flujo del Navegador Eliminado',
  browseFlowDeleteMessage: 'La eliminación de un flujo del navegador no solo elimina el flujo del navegador, sino todos sus flujos del navegador secundarios.',
  browseFlowEmptyState: 'No se han definido Flujos del Navegador. {new} para comenzar.',
  browseFlowHeirarchy: 'Jerarquía del Flujo del Navegador',
  browseFlowNoExistingCriteria: 'No se han establecido criterios de búsqueda para este flujo del navegador. Utilice los controles anteriores para seleccionar los criterios de búsqueda.',
  browseFlowMoveToRoot: 'Suelte el nodo aquí para crear un elemento secundario de primer nivel',
  browseFlowNoAssignedOrgs: 'No hay organizaciones asignadas',
  browseFlowSaveFailed: 'El Flujo del Navegador no se ha podido actualizar',
  browseFlowSetDefaultSuccess: 'Se ha establecido el Flujo del Navegador Predeterminado',
  browseFlowThumbnailDeleteFailTitle: 'Error al Eliminar la Miniatura',
  browseFlowThumbnailDeleteSuccessTitle: 'La Miniatura se ha Eliminado Correctamente',
  browseFlowThumbnailUploadFailTitle: 'Error al Cargar la Miniatura',
  browseFlowThumbnailUploadSuccessTitle: 'La miniatura se ha Cargado Correctamente',
  browseFlowTooManyOrgs: 'Tiene demasiadas organizaciones para usar el editor de flujo del navegador actual. Póngase en contacto con el Centro de Asistencia de Documoto a través de {email} para obtener ayuda con la edición de sus flujos del navegador de Documoto.',
  browserPartiallySupport: 'En este navegador se pueden visualizar diferencias estéticas y otros cambios.',
  browseFlowSaved: 'El Flujo del Navegador se ha guardado',
  browserDeprecation: {
    title: 'Aviso de Degradación del Navegador',
    text: 'En consonancia con la retirada progresiva del soporte de Microsoft para Internet Explorer (todas las versiones) y los problemas de seguridad relacionados, no ofreceremos soporte para el acceso de los usuarios a esta web a través de Internet Explorer (todas las versiones) a partir del 30 de septiembre de 2021.'
  },
  buildHotpointLinks: 'Generar Enlaces de Punto Caliente',
  buildHotpointLinksInfo: 'Esta acción es aditiva y no eliminará ningún enlace de punto caliente existente. Al ejecutar esta acción, se añadirán nuevos enlaces de punto caliente entre las secciones y cualquier conjunto de páginas que corresponda si no existe ya un enlace de punto caliente.',
  buildHotpointLinksTitle: '¿Desea generar enlaces de punto caliente en este libro?',
  bulkIndexingLane: 'Pista de indexación en masa',
  bulkInsert: 'Insertar En Masa',
  bulkExportConfirm: '¿Desea exportar {n} {unit}?',
  bulkExportLimit: 'El límite es {limit} libros por trabajo de exportación',
  bulkPageInsertJob: 'Inserción de Páginas a Gran Escala',
  business: 'Empresa',
  cancel: 'Cancelar',
  canceled: 'Cancelado',
  cancelAssemblyCreatorTitle: '¿Descartar la creación de ensamblado?',
  cancelAssemblyCreatorSubtitle: 'Su creación de ensamblado no se procesará.',
  cannotExceed100: 'No puede superar el 100 %',
  cart: 'Carro | Carros',
  cartBehaviorEnabled: 'Habilitar Indicador de Cantidad en Añadir al Carro',
  cartBehaviorDisabled: 'Deshabilitar Indicador de Cantidad en Añadir al Carro',
  cartBehaviorLabel: 'Funcionamiento de la Cantidad en Añadir al Carro',
  cartColumn: 'Columna del Carro | Columnas del Carro',
  cartColumn1: 'Columna del Precio del 1er Carro',
  cartColumn2: 'Columna del Precio del 2° Carro',
  cartColumn3: 'Columna del Precio del 3er Carro',
  cartColumnPartLocationContent1: 'La habilitación de esta función tendrá las siguientes implicaciones: ',
  cartColumnPartLocationContent2: 'La singularidad de la fila de secciones del carro se basará en su ubicación dentro de una sección del contenido, si procede.',
  cartColumnPartLocationContent3: 'Esta configuración no se puede deshabilitar a nivel de la organización y se aplicará a todos los usuarios.',
  cartColumnPartLocationContent4: 'No debe habilitar esta función si tiene una integración Enviar Carro sin ponerse en contacto primero con su Gestor de Satisfacción del Cliente de Documoto.',
  cartColumnPartLocationOption: 'Habilitar Datos de Ubicación de Piezas en el Carro',
  cartColumnPartLocationTitle: '¿Seguro que desea habilitar los Datos de Ubicación de Piezas en el Carro?',
  cartDisable: 'Deshabilitar Carro',
  cartEmailFailed: 'Error de Correo electrónico del Carrito',
  cartEmailSent: 'El Correo electrónico del Carrito se ha enviado',
  cartEnable: 'Habilitar Carro',
  cartSaveEnable: 'Habilitar Guardar Carro',
  cartErpMessage: 'Enviado correctamente a Erp',
  cartImportError: 'Error al importar el carrito',
  cartLoadedWithItemsRemoved: '{count} elemento(s) eliminado(s) porque no tiene permiso para pedirlo(s) en este momento',
  cartSharedAddEdit: 'Añadir/Editar Carros Compartidos',
  cartUpdated: 'El Carro se ha Actualizado',
  cartUpdatedAndRemovedCount: 'Carro Actualizado; se han eliminado {count} piezas que no se pueden pedir',
  category: 'Categoría | Categorías',
  categoryCreated: 'Categoría Creada',
  categoryUpdated: 'Categoría Actualizada',
  centimeter: 'Centímetro | Centímetros',
  changeNotification: 'Cambiar Notificación',
  changeScope: '{n} {type} se verá afectado',
  changeUserOrganization: 'Cambiar Organización',
  chapter: 'Capítulo | Capítulos',
  chapterHeader: 'Encabezamiento del Capítulo',
  chaptersOnly: 'Solo Capítulos',
  chineseDescription: 'Descripción en Chino',
  chineseName: 'Nombre en Chino',
  circle: 'Círculo',
  city: 'Ciudad',
  classicAdminMessage: 'Desde aquí se te enviará de nuevo a Flex para utilizar los módulos de administración.',
  classicAdmins: 'Administraciones Clásicas',
  classicExporter: 'Exportador Clásico',
  classicExporterMessage: 'Módulo tradicional de exportación de contenido basado en Flash',
  classicOrganizationAdmin: 'Administración de la Organización Clásica',
  classicOrganizationAdminMessage: 'Módulo tradicional de administración de usuarios basado en Flash para gestionar organizaciones y usuarios',
  classicPublisher: 'Editor Clásico',
  classicPublisherMessage: 'Módulo tradicional del editor basado en Flash para la creación de contenido y la configuración de impresión',
  classicReporting: 'Creación de Informes Clásica',
  classicReportingMessage: 'Módulo tradicional de creación de informes basado en Flash',
  classicTenantAdmin: 'Administración de Propietario Clásica',
  classicTenantAdminMessage: 'Módulo tradicional de administración basado en Flash para la configuración amplia de propietarios',
  className: 'Nombre de Clase',
  clear: 'Borrar',
  clearItemNumber: 'Borrar número de artículo',
  clearItemNumberTitle: '¿Desea borrar el número de artículo?',
  clearItemNumberMessage: 'Al borrar el número de artículo, se eliminarán los siguientes datos:',
  clearFilters: 'Borrar Filtros',
  clearHotpoints: 'Borrar Puntos Calientes',
  clearSearch: 'Borrar Búsqueda',
  clickHereToUpload: 'Arrastrar archivo o hacer clic para subir reemplazo.',
  clickMagnifyingGlass: 'Haga clic en la lupa para ir directamente a Búsqueda Avanzada.',
  clickOkToContinue: 'Haga clic en "Aceptar" para continuar.',
  clickToDownload: 'Hacer clic para Descargar',
  clickToEnlarge: 'Haga clic para aumentar',
  clickToOpen: 'Hacer clic para Abrir',
  clipboard: 'Portapapeles',
  clipboardAccessError: 'Ajuste la configuración de su navegador para permitir el acceso al Portapapeles',
  close: 'Cerrar',
  closed: 'Cerrado',
  collapseAll: 'Contraer Todo',
  color: 'Color | Colores',
  column: 'Columna | Columnas',
  columnName: 'Nombre de la Columna',
  columnItemType: {
    customText: 'Texto Personalizado',
    documentTitle: 'Título del Documento',
    horizontalLine: 'Línea Horizontal',
    pageName: 'Nombre de la Página',
    pageNumber: 'Número de la Página',
    printDate: 'Fecha de Impresión',
    tenantName: 'Nombre del Propietario',
    userName: 'Nombre de Usuario'
  },
  combinedPage: 'Página Combinada',
  combinedPageMessage: 'Diagrama en la parte superior; lista de piezas en la parte inferior',
  comingSoon: 'Próximamente',
  commaDelimited: 'Delimitado por comas',
  comment: 'Comentario | Comentarios',
  commentAdded: 'Comentario añadido',
  commentDeleted: 'Comentario eliminado',
  commentEdit: 'Añadir/Editar Comentarios',
  commentEditAll: 'Editar Todos los Comentarios',
  commentUpdated: 'Comentario actualizado',
  commentView: 'Ver Comentarios',
  commerceSystem: 'Sistema de Comercio',
  completed: 'Completado',
  confirm: 'Confirmar',
  confirmBrowseFlowApplyToChildren: '¿Desea aplicar este flujo del navegador a las organizaciones secundarias?',
  confirmBrowseFlowOverrideToChildren: 'Esto eliminará todas los reemplazos del flujo del navegador establecidas en las organizaciones secundarias.',
  confirmPrintSettingsApplyToChildren: '¿Desea aplicar esta configuración de impresión a las organizaciones secundarias?',
  confirmPrintSettingsOverrideToChildren: 'Esto eliminará todas los reemplazos de la configuración de impresión establecidos en las organizaciones secundarias.',
  contains: 'Incluye',
  containsHeaderRow: 'Contiene Fila de Encabezado',
  content: 'Contenido | Contenidos',
  contentAccessControls: 'Controles de Acceso a {content}',
  contentEntity: 'Entidad de Contenido | Entidades de Contenido',
  contentNotFound: 'No se ha encontrado el contenido',
  contentUpdatedQueueJob: 'Cola de Actualización de Contenido',
  contourShape: 'Forma del Contorno',
  convertEntity: 'Convertir {entity}',
  convertToEntity: 'Convertir a {entity}',
  copyAccessControls: 'Copiar Controles de Acceso',
  copyAccessControlsWarning: 'Esta acción copiará todos los controles de acceso asignados desde una Organización seleccionada a esta Organización actual',
  copy: 'Copiar',
  copyAndRetain: 'Copiar y Conservar para Uso de Integración',
  copyAndRetainWidget: 'Copiar y Conservar, esta es la única vez que el valor de Autorización de Widget estará visible en la aplicación',
  copyAtoB: 'Copiar {a} a {b}',
  copyContent: '¿Copiar {type}? {name}',
  copyingItem: 'Copiando {item}',
  copyItem: 'Copiar {item}',
  copyItemQuestion: '¿Copiar {item}?',
  copyTo: 'Copiar a {target}',
  copyMeMessage: 'Ponerme en copia en este mensaje',
  copyrightMessage: '© 2010-{year} Documoto Inc. Todos los derechos reservados.',
  count: 'Recuento',
  country: 'País | Países',
  coverPages: 'Portadas',
  coverAmpStitchPages: 'Páginas de Puntada y Portada',
  createAssembly: 'Crear Ensamblaje',
  created: 'Creado',
  createdBy: 'Creado por {name}',
  createdDate: 'Fecha de Creación',
  createdOn: 'Creado el',
  currentPassword: 'Contraseña Actual',
  custom: 'Personalizado',
  czechDescription: 'Descripción en Checo',
  czechName: 'Nombre en Checo',
  dashboard: 'Panel de control | Paneles de control',
  dashboardLibrary: 'Biblioteca del Panel de Control',
  dataInsights: 'Conocimientos Sobre Datos',
  dataInsightsInfoMessage: 'Centro de Panel de Control e Informes para saber el contenido de los datos, los usuarios y las bibliotecas.',
  dataMapping: 'Asignación de Datos/Datos de Muestra (hasta 10 filas)',
  dataOptions: 'Opciones de Datos',
  date: 'Fecha',
  dateModified: 'Fecha Modificada',
  debugPageBuilderPLZ: 'Depurar PLZ de generador de páginas ',
  december: 'Diciembre',
  decreaseHotpoint: 'Disminuir Punto Caliente',
  default: 'Predeterminado',
  defaultAddress: 'Dirección Predeterminada',
  defaultBrowseFlow: 'Flujo del Navegador Predeterminado',
  defaultCurrencyCode: 'Código de Divisa Predeterminado',
  defaultCurrencySymbolOn: 'Símbolo de moneda predeterminado activado',
  defaultEmailRecipient: 'Destinatario del Correo Electrónico Predeterminado | Destinatarios del correo electrónico Predeterminados',
  defaultImage: 'Imagen Predeterminada | Imágenes Predeterminadas',
  defaultImagesManager: 'Gestor de Imágenes Predeterminadas',
  defaultItemImage: '{item} Imagen Predeterminada',
  defaultPartsListLayout: 'Plantilla de Lista de Piezas Predeterminada',
  defaultPartsListLayoutHelp: 'Plantilla de piezas predeterminada a la izquierda o debajo de la ilustración',
  defaultSearchMode: 'Modo de Búsqueda Predeterminado',
  defaultSearchModeHelp: 'Método de búsqueda global predeterminado para \'incluye\' o \'exacto\'',
  defaultSortMode: 'Modo de Clasificación Predeterminado',
  defaultSupplier: 'Proveedor Predeterminado',
  defaultTocStateOpen: 'Estado del Índice Abierto Predeterminado',
  defaultTocStateOpenHelp: 'Determina si el índice debería abrirse automáticamente cuando visualice un libro',
  defaultUom: 'Unidad de Medida Predeterminada',
  defaultValue: 'Valor Predeterminado',
  defaults: 'Valores Predeterminados',
  defaultsAndCapabilities: 'Valores Predeterminados y Competencias',
  delete: 'Eliminar',
  deleteAll: 'Heredado Del Elemento Principal',
  deleteAnyPartnerApiKeys: 'Eliminar cualquier Clave de API de Socio que se haya proporcionado a SmartEquip.',
  deleteContent: '¿Seguro que desea eliminar {type}? {name}',
deletePart: {
    infoOne: 'El borrado de piezas tendrá efecto inmediato tanto en la Biblioteca como en el Administrador de Contenido. La pieza eliminada se podrá seguir consultando hasta que se produzca la reindexación en unos minutos.',
    deletingPartNumber: '{partNumber} se eliminará.',
    affectedCarts: 'A todos los carros que contengan esta pieza se les retirará la pieza de forma permanente.',
    infoTwo: 'Esta acción no se puede deshacer sin la ayuda del Centro de Asistencia de Documoto. Transcurridos 90 días, el registro eliminado y todas las asociaciones se borrarán de forma permanente.'
  },
deletePartInUse: {
    title: 'Esta pieza pertenece a las páginas y no se puede eliminar.',
    body: 'En este momento, Documoto solo puede eliminar piezas que no pertenecen a las páginas. Por favor, quite esta pieza de cualquier borrador o página publicada para proceder a su eliminación.',
    submitLabel: 'Vaya a Páginas Donde se Utilice'
  },
  deleteRelated: '¿Seguro que desea eliminarlo {count} {type}?',
  deletedItem: '{item} eliminado',
  deleteFilesMessage: '¿Seguro que desea borrar {count} archivo/s?',
  deleteHotpointLinksTitle: '¿Seguro que desea eliminar el/los enlace(s) a punto(s) caliente(s)?',
  deleteMessage: '¿Seguro que desea borrar?',
  deleteSelectedCriteria: 'Eliminar Criterios Seleccionados',
  deleteTagsTitle: '¿Seguro que desea eliminar la(s) etiqueta(s)?',
  deleteTaxonMessage: 'Borrará de forma permanente este taxón y todos sus elementos secundarios. ¿Seguro que desea continuar?',
  deleteTaxonomyMessage: 'Borrará de forma permanente todos los taxones de esta Organización o este Propietario. ¿Seguro que desea continuar?',
  deleteTenantSupplier: '¿Seguro de que desea eliminar este proveedor del propietario?',
  demo: 'Versión Demo',
  description: 'Descripción',
  deselectAll: 'Anular la Selección de Todos',
  deselectCriteria: 'Anular la Selección de Criterios',
  detail: 'Detalle | Detalles',
  diagonal: 'Diagonal',
  diameter: 'Diámetro',
  disable: 'Deshabilitar',
  disableAll: 'Deshabilitar todo',
  disableDocumotoTenant: 'Deshabilitar Propietario de Documoto',
  disableFwdReindex: 'Deshabilitar reenvío Reindexar los medios de destino de DSW',
  disableLibraryBannerImage: 'Deshabilitar Imagen del Banner de la Biblioteca',
  disableSmartEquipModalTitle: '¿Seguro que desea deshabilitar esta licencia?',
  disableTEK: 'Deshabilitar Inicio de Sesión de Dirección URL',
  disabled: 'Deshabilitado',
  discard: 'Descartar',
  discardChanges: 'Descartar cambios',
  discardQ: '¿Descartar cambios?',
  discounted: 'Descontado',
  discountedPrice: 'Precio de Descuento',
  discountedTotal: 'Descuento Total',
  discountPrice: 'Precio de Descuento',
  displayInOrdersList: 'Mostrar en Lista de Pedidos',
  displayOrder: 'Orden de Visualización',
  displayOrderDescription: 'Establece el orden de visualización de la lista desplegable de filtros para las etiquetas de tipo de contenido',
  document: 'Documento | Documentos',
  documentLabel: 'Documento | Documentos',
  documentSettings: 'Configuración del Documento',
  documotoAcademy: 'Academia Documoto',
  documotoLicensePackage: 'Paquete de licencia de Documoto',
  domain: 'Dominio',
  domainEmptyMessage: 'El Dominio Permitido no puede estar vacío',
  domainInvalidMessage: 'Se requiere un nombre de dominio válido',
  download: 'Descargar',
  downloadBrowser: 'Descargar uno de estos grandes navegadores.',
  downloadFailed: 'Error al descargar {name}',
  downloadStarted: 'Descarga en curso para {name}',
  draft: 'Borrador | Borradores',
  draftBomDeleteTitle: '¿Seguro que quiere eliminar esta parte de la página?',
  draftBomMultipleDeleteTitle: '¿Seguro que desea eliminar estas piezas de la página?',
  draftBomDropError: 'No se pueden anidar piezas con más de 5 niveles de profundidad',
  draftBomEmptyField: 'Se deben completar todos los campos de la Lista de Piezas',
  draftBomQuantityError: 'La Cantidad debe ser un número',
  draftBomError: 'Error al manipular el borrador de la Lista de Materiales',
  draftPage: 'Borrador de la Página',
  draftPageDelete: 'Eliminar Borrador',
  draftPageDeleteMessage: 'El Borrador de la página se ha eliminado correctamente.',
  draftPageDeleteWarning: 'Al Eliminar el borrador de la Página, se eliminarán las actualizaciones en curso.',
  draftPageLastUpdated: 'Borrador - Última actualización {date} por {username}',
  draftPageLoadError: 'Error al cargar la página de borrador.',
  draftPageProcessingPlz: 'Página que se carga desde el archivo PLZ',
  draftPagePublishInProgress: 'Tarea de Publicación de Página en curso',
  draftPageSaveSuccess: 'El Borrador de la página se ha guardado correctamente.',
  draftPageSaveUnsuccessful: 'El Borrador de la página no se ha guardado correctamente.',
  draftSheetImageHotpointsFailure: 'No se pueden generar {algorithm} puntos calientes para la imagen de la hoja de borrador.',
  draftValidationMessage: {
    item: 'ADVERTENCIA: El número de pieza, {partNumber}, de la lista de materiales no tiene especificado ningún número de artículo',
    empty: 'No Hay Errores/Advertencias de Validación',
    hotpointOrHotpointLink: 'El elemento de la lista de materiales {item} no tiene especificado(s) punto(s) caliente(s)/enlace(s) de puntos calientes',
    quantity: 'El elemento de la lista de materiales {item} no tiene especificada ninguna cantidad',
    tag: 'A la etiqueta {tagName} del elemento de la lista de materiales {item} le falta un valor',
    translationDescription: 'Al elemento de la lista de materiales {item} le falta la descripción de la traducción en {lang}',
    uom: 'El elemento de la lista de materiales {item} no tiene especificada ninguna unidad de medida',
    supersede: 'La pieza {partNumber} ha sustituido a {supersededPartNumber} por motivos de reemplazo.'
  },
  drawAutohotpointRegion: 'Dibujar tamaño de {shape}',
  drawMinimumArea: 'Dibujar el área mínima',
  dropFileMessage: 'Arrastrar archivo aquí o hacer clic para subirlo.',
  duplicate: 'Duplicado',
  duplicateSaveError: 'Entrada duplicada. No se puede guardar.',
  dutchDescription: 'Descripción en Holandés',
  dutchName: 'Nombre en Holandés',
  dynamic: 'Dinámico',
  dynamicNaming: 'Denominación Dinámica',
  ecommerce: 'Comercio Electrónico',
  ecommerceEmailFormatCreated: 'El Formato del Correo Electrónico de Comercio Electrónico se ha creado',
  ecommerceEmailFormatSaved: 'El Formato del Correo Electrónico de Comercio Electrónico se ha guardado',
  ecommerceLogo: 'Logotipo de Comercio electrónico',
  ecommerceUseExtOrder: 'Número de pedido externo para uso de comercio electrónico',
  edit: 'Editar',
  editItem: 'Editar {item}',
  email: 'Correo Electrónico',
  emailFormat: 'Formato del Correo electrónico',
  emailFormatTemplate: 'Plantilla de Formato del Correo Electrónico | Plantillas de Formato del Correo Electrónico',
  emailList: 'Lista de Correo Electrónico',
  emailMessage: 'Es necesario un correo electrónico',
  emailRecipient: 'Destinatario del Correo Electrónico | Destinatarios del Correo Electrónico',
  emailRecipientMessage: 'Estas direcciones de correo electrónico recibirán un correo cuando el estado del pedido cambie a',
  emptyMessage: {
    orderSuggestions: 'Parece que todavía no hay sugerencias de orden para esta pieza.'
  },
  emptyBomState: 'No se han definido Elementos de la Lista de Materiales para la página. Haga clic en +{add} para comenzar',
  enable: 'Habilitar',
  enable3d: 'Habilitar 3D',
  enableAll: 'Habilitar todo',
  enableAPIKeyAdmin: 'Habilitar administrador de claves de API',
  enabled: 'Habilitado',
  enableCommerce: 'Habilitar Comercio',
  enableEcommerceOrderManagement: 'Habilitar Gestión de Pedidos',
  enableExporter: 'Habilitar exportador',
  enableExportToLinkOne: 'Habilitar exportar a LinkOne',
  enableExportToSmartEquip: 'Habilitar exportar a SmartEquip',
  enablePDFDownloadWarning: 'Habilitar Advertencia de Descarga de PDF de gran tamaño',
  largePDFDownloadWarning: 'Debido al gran tamaño del archivo PDF, puede que tarde más de lo habitual en cargarse y mostrarse el documento dentro de la aplicación; tenga paciencia mientras se descarga el archivo para su visualización.',
  enableIframeSupport: 'Habilitar compatibilidad con iFrame',
  enableLibraryBetaFeatures: 'Habilitar las Funciones Beta de la Biblioteca',
  enablePerformanceLogging: 'Activar registro de rendimiento',
  enablePLZToDraft: 'Habilitar proceso PLZ para borrador',
  enableQuickAddToCart: 'Habilitar Adición Rápida al Carro',
  enableQuickAddToCartHelpContext: 'Al configurarlo en \‘Habilitar\’ se habilitará la función Adición Rápida al Carro encima del visor de contenido para este contenido.',
  enableRocketUi: 'Habilitar Experiencia del Usuario Cohete',
  enableSmartEquipApiIntegration: 'Habilitar Integración de API de SmartEquip',
  enableWidgetLogo: 'Habilitar el Logotipo del Widget de Documoto',
  enableWidgets: 'Habilitar Widgets',
  encryptedKey: 'Clave Encriptada',
  endDate: 'Fecha de Finalización',
  endDateBad: 'La fecha de inicio debe ser igual o anterior a la fecha de finalización.',
  enforceNumericSize: 'Ejecutar Tamaño Numérico',
  englishDescription: 'Descripción en Inglés',
  englishName: 'Nombre en Inglés',
  enterprise: 'Empresarial',
  enterReportParameters: 'Introducir Parámetros del Informe',
  enterUniqueNameForNewItem: 'Introducir un nombre exclusivo para el nuevo {item}',
  enterValidNumber: 'Introduzca una cifra válida',
  enterValidEmail: 'Introduzca un correo electrónico válido',
  entityType: 'Tipo de entidad | Tipos de Entidades',
  erp: 'ERP',
  erpEndpointConfig: 'Configuración de Terminal-ERP',
  erpPartInfo: 'Información sobre piezas ERP Minutos de duración de la memoria caché',
  erpSystem: 'Sistema ERP',
  erpSystems: 'Sistemas ERP',
  erpSystemSaved: 'Sistema ERP Guardado',
  error: 'Error',
  errorDeletePageMessage: 'Se ha producido un error mientras se eliminaba la página.',
  errorInputParsing: 'Problema al analizar archivo de entrada.',
  errorPageMessage: 'Se ha producido un error.',
  errorPageTitle: 'Algo está fallando...',
  errorType: {
    error: 'Error',
    warning: 'Advertencia:'
  },
  essential: 'Aspecto Fundamental | Aspectos fundamentales',
  eta: 'ETA',
  etlJob: 'ETL',
  exact: 'Exacto',
  excelFile: 'Archivo Excel',
  expandAll: 'Expandir Todo',
  export: 'Exportar',
  exportSearch: 'Exportar Búsqueda',
  exportAsCsv: 'Exportar como CSV (Formato Necesario para Importar)',
  exportAsCSVDropdown: 'Exportar como CVS',
  exportAsHtml: 'Exportar como HTML',
  exportAsPdf: 'Exportar como PDF',
  exportAsPlz: 'Exportar como PLZ',
  exportAsXlsx: 'Exportar como XLSX',
  exportBasicPartInfo: 'Exportar Información Básica de la Pieza',
  exportCartToXlsxDisabled: 'Deshabilitar Exportar Carro a XLSX',
  exportCartToXlsxEnabled: 'Habilitar Exportar Carro a XLSX',
  exportDisplayedCartColumns: 'Exportar las Columnas Mostradas del Carro',
  exportJob: 'Trabajo de Exportación',
  exportLane: 'Exportar pista',
  exportMessage: 'Trabajo de exportación enviado correctamente.',
  exporter: 'Exportador',
  exporterMessage: 'Te permite exportar contenido',
  exportParts: 'Exportar Partes',
  external: 'Externo | Externos',
  externalUrl: 'URL externa',
  faceted: 'Con facetas',
  facetedTag: 'Los valores de filtro deberían incluir facetas',
  facetedTagTooMany: 'La etiqueta sobrepasa el límite de 1.000 en el valor de etiqueta única',
  failedAction: '{content} no se ha podido {action}.',
  failedProcessContentSql: 'El Contenido del Proceso falló debido a texto no autorizado. Por favor, vuelva a cargar el contenido admitido.',
  failedToCopy: '{content} no se ha podido copiar.',
  failedToDelete: '{content} no se ha podido eliminar.',
  failedToLoad: '{content} no se ha podido cargar.',
  failedToSave: '{content} no se ha podido guardar.',
  failedToSubmitItem: 'Error al enviar {item}',
  failedToUpdate: '{content} no se ha podido actualizar.',
  false: 'Falso',
  faq: 'FAQ',
  favorites: 'Favoritos',
  favoritesLists: 'Listas Favoritas',
  february: 'Febrero',
  feedbackForm: 'Enviar Comentarios',
  feedbackFormMessage: 'Introduzca entradas sobre las características que te gustaría visualizar',
  fieldDefinition: 'Definición de Campo',
  field: 'Campo | Campos',
  fieldName: 'Nombre de Campo',
  file: 'Archivo | Archivos',
  filename: 'Nombre del Archivo',
  filesSubmitted: 'Archivo/s enviado/s para su procesamiento',
  fileUpload: 'Subir achivo',
  fill: 'Rellenar',
  filter: 'Filtro | Filtros',
  filterOrgNameOnly: 'Filtro: Solo el Nombre de la Org.',
  filterOrgParentNames: 'Filtro: Nombres Principales y de la Org.',
  filterable: 'Filtrable',
  filterableTag: 'Etiqueta Filtrable',
  filterBy: 'Filtrar Por',
  filterBoundsError: 'El filtro debe incluir 3 caracteres o más',
  finnishDescription: 'Descripción en Finlandés',
  finnishName: 'Nombre en Finlandés',
  firstName: 'Nombre',
  firstNameEmpty: 'Debe añadir el nombre',
  fixedHotpointSize: 'Tamaño Fijo del Punto Caliente',
  font: 'Fuente | Fuentes',
  footer: 'Pie de Página',
  forgotPassword: 'Contraseña Olvidada',
  forgotPasswordMessage: 'Se enviará un correo electrónico al nombre de usuario proporcionado con las instrucciones para restablecer la contraseña.',
  forgotPasswordSuccess: 'Si el nombre de usuario proporcionado es válido, se le enviará a la dirección registrada un correo electrónico con instrucciones para restablecer su contraseña.',
  form: 'Formulario | Formularios',
  format: 'Formato',
  formattingOptions: 'Opciones de Formato',
  formCount: 'Recuento de Formularios',
  formName: 'Nombre del Formulario',
  formType: 'Tipo de Formulario',
  freezeImage: 'Congelar Imagen',
  frenchDescription: 'Descripción en Francés',
  frenchName: 'Nombre en Francés',
  fri: 'Vier',
  from: 'De',
  fromDate: 'Desde la Fecha',
  frontCover: 'Portada Delantera',
  frontStitch: 'Puntada Delantera',
  fullScreen: 'Pantalla Completa',
  gdprFaqs: 'Preguntas Frecuentes de GDPR',
  gdprMessage: 'Al iniciar sesión en esta sección de nuestro sitio web, admite que utilizamos cookies esenciales. Estas cookies son fundamentales para que pueda navegar por nuestros Servicios. Sin estas cookies, no podemos prestar los servicios que ha solicitado. Para obtener más información, consulte nuestra {policy}.',
  general: 'General',
  generalSearch: 'Búsqueda General',
  generic: 'Genérico',
  germanDescription: 'Descripción en Alemán',
  germanName: 'Nombre en Alemán',
  gotIt: 'Ententido',
  goTo: 'Ir a',
  goToHome: 'Ir a la Pagina Inicial',
  goToJobManager: 'Ir al Administrador de Tareas',
  goToSearch: 'Ir a Buscar',
  hashKey: 'Almohadilla',
  headerAmpFooter: 'Encabezado y Pie',
  header: 'Encabezado',
  help: 'Ayuda',
  helpCenter: 'Centro de Ayuda',
  highlightDescription: 'Destacar las piezas de la lista de piezas con esta etiqueta',
  history: 'Historial',
  home: 'Inicio',
  horizontal: 'Horizontal',
  host: 'Servidor',
  hotpoint: 'Punto Caliente | Puntos Calientes',
  hotpointCalloutBehavior: 'Comportamiento de leyenda del punto caliente',
  hotpointLinksWillBeCreated: 'Se crearán enlaces de puntos calientes en {count} {content}',
  hotpointLink: 'Enlace a Punto Caliente | Enlaces a Puntos Calientes',
  hotpointsGenerated: 'Se han encontrado {count} puntos calientes en la imagen.',
  hotpointShift: 'Punto Caliente (Mayús. H)',
  hourlyDWSRequestLimit: 'Límite de la solicitud de DWS por horas',
  hourlyRESTRequestLimit: 'Límite de la solicitud de REST por horas',
  htmlUiUserSwitchEnabled: 'Se ha habilitado el cambio de usuario de la interfaz de usuario HTML',
  hungarianDescription: 'Descripción en Húngaro',
  hungarianName: 'Nombre en Húngaro',
  icon: 'Icono',
  id: 'ID',
  identifier: 'Identificador',
  ignore: 'IGNORAR',
  ignoreAccessControls: 'Ignorar Controles de Acceso',
  ignoreMatchingTranslations: 'Ignorar Traducciones que tengan coincidencia de configuración regional al Importar',
  illustration: 'Ilustración | Ilustraciones',
  image: 'Imagen | Imágenes',
  imageDeleteMessage: '¿Seguro que quiere eliminar esta Imagen de la Página?',
  imageUploadFailure: 'La Imagen no se ha Podido Cargar',
  imageSaveFail: 'La Imagen no se ha Podido Guardar',
  imageOptimizer: 'Optimizador de Imagen',
  imageSizeExceededTitle: 'Se ha Superado el Tamaño Máximo de la Imagen',
  imageSizeExceededMessage: 'El tamaño máximo de archivo de imagen admitido en Documoto es {size} megabytes. La imagen que está intentando cargar ha superado el máximo y no se puede cargar. Cambie el tamaño de la imagen para añadirla a esta página.',
  imageDimensionsExceededMessage: 'El tamaño máximo de la imagen en Documoto es 65,5 megapíxeles (8,191 X 8,191 píxeles).  La imagen que está intentando cargar ha superado el máximo y no se puede cargar. Haga clic en "Redimensionar" para permitir que Documoto redimensione e inserte la imagen, o bien en "Cancelar" para editar la imagen e insertarla manualmente',
  import: 'Importar',
  importBOM: 'Importar lista de materiales',
  importBOMError: 'Error al procesar los datos de muestra, vuelva a comprobar la fuente de entrada',
  importParts: 'Importar Partes',
  inCart: 'En el Carro',
  inCartDescription: 'Mostrar etiquetas en el carro',
  includePagePartDetails: 'Incluir detalles a nivel de Pieza de Página',
  includePartDetails: 'Incluir detalles a nivel de Pieza',
  indexQueueJob: 'Cola de índice',
  inPartsList: 'En la Lista de Piezas',
  inPartsListDescription: 'Mostrar etiquetas en la lista de piezas',
  inputSource: 'Importar Fuente',
  inch: 'Pulgada | Pulgadas',
  includeComments: 'Incluir Comentarios',
  includeCommentsAuthorAndDate: 'Incluir Fecha y Autor del Comentario',
  includeHeaderRow: 'Incluir Fila de Encabezado',
  includeImages: 'Incluir Imágenes',
  includePagesIndex: 'Incluir Índice de Páginas',
  includePartsIndex: 'Incluir Índice de Piezas',
  includePartsList: 'Incluir Lista de Piezas',
  includeSubChapters: 'Incluir Subcapítulos',
  indexLane: 'Pista de índice',
  useSequentialPageNumbering: 'Usar Numeración Secuencial de Páginas',
  hideBookInSearchAndRecent: 'Ocultar Libros en los Resultados de Búsqueda y Añadidos Recientemente',
  hideDuplicatePages: 'Ocultar Páginas Duplicadas',
  hidePageInSearchAndRecent: 'Ocultar Páginas en los Resultados de Búsqueda y Añadidos Recientemente',
  hidePartInSearchAndRecent: 'Ocultar Piezas en los Resultados de Búsqueda y Añadidos Recientemente',
  homePageSettings: 'Configuración de la Página Inicial',
  hotpointZoomBehavior: 'Comportamiento del Punto Caliente',
  includeNewParts: 'Incluir solo piezas "nuevas" (no en Documoto)',
  includeWatermark: '¿Incluir Marca de Agua?',
  increaseHotpoint: 'Aumentar Punto Caliente',
  indexed: 'Indexado',
  indexingConfiguration: 'Configuración de la indexación',
  indexIncludeTimestamp: 'Incluir marca temporal en índice',
  info: 'Inf.',
  information: 'Información',
  inherit: 'Heredar',
  inheritedParent: 'Heredado del Elemento Principal.',
  inputSpecification: 'Especificación de Entrada',
  inputType: 'Tipo de Entrada',
  inputTypeListboxWarning: 'Las configuraciones de clasificación se mostrarán después de guardar',
  integration: 'Integración | Integraciones',
  integrationsAddPartToErpCart: '{action} Añadir Pieza a Carro de ERP',
  integrationsAddToShoppingCartPricingFromErp: '{action} Añadir al Carro precios del ERP',
  integrationsDisableErp: 'Deshabilitar ERP',
  integrationsDoErpForQuotes: 'Hacer ERP para Presupuestos',
  integrationsEnableErp: 'Habilitar ERP',
  integrationsEnableFetchPartInfoFromErp: 'Habilitar Traer Información de la Pieza del ERP',
  integrationsEnableScopeErp: 'Habilitar {scop} ERP',
  integrationsEnableSendShoppingCartToErp: 'Habilitar Enviar Carro a ERP',
  integrationsEnableSyncPoAddressWithErp: 'Habilitar Sincronización de Dirección de Pedido con ERP',
  integrationsErpAvailabilityMappedFrom: 'Asignación de Disponibilidad del ERP A Partir De',
  integrationsErpCurrencyCodeMappedFrom: 'Asignación del Código de Divisa del ERP A Partir De',
  integrationsErpDiscountedPriceMappedFrom: 'Asignación del Precio de Descuento del ERP A Partir De',
  integrationsErpEtaMappedFrom: 'Asignación de ETA del ERP A Partir De',
  integrationsErpMappings: 'Asignaciones del ERP',
  integrationsErpRetailPriceMappedFrom: 'Asignación del Precio de Venta al Público del ERP A Partir De',
  integrationsErpWholesalePriceMappedFrom: 'Asignación del Precio Al Por Mayor del ERP A Partir De',
  integrationsInvokeGetPartInfoFromClient: 'Invocar GetPartInfo del Cliente',
  integrationsParseAddressForType: 'Analizar Dirección por Tipo',
  integrationsShoppingCartAndPartsTitle: 'Carro y Piezas',
  integrationsShoppingCartAvailability: '{action} Disponibilidad del Carro',
  integrationsShoppingCartEtaFromErp: '{action} ETA del Carro del ERP',
  internalApiKey: 'Clave de API Interna',
  internalDescription: 'Descripción Interna',
  internalName: 'Nombre Interno',
  internalNameOptional: 'Su identificador para la etiqueta',
  internalNameRequired: 'El nombre es obligatorio',
  invalidDecimals: 'Solo se admiten dos decimales',
  invalidField: '{field} no es válido',
  invalidFileType: 'Tipo de archivo no admitido. Véase el Centro de Asistencia de Documoto para obtener una lista completa de extensiones de archivos admitidas.',
  isSearchable: 'Permite búsquedas',
  insertDataField: 'Insertar Campo de Datos',
  isSystem: 'Es Sistema',
  italianDescription: 'Descripción en Italiano',
  italianName: 'Nombre en Italiano',
  item: '#',
  itemAlt: 'Elemento',
  itemCopied: '{item} copiado',
  itemDeleted: '{item} eliminado',
  itemInProgress: '{item} en curso',
  itemInformation: 'Información del {item}',
  itemMustBeLteItem: 'El {itemA} debe ser igual o anterior al {itemB}.',
  itemMustBeUnique: '{item} debe ser único',
  itemMustBeUniqueAndNotEmpty: '{item} debe ser único y es obligatorio',
  itemName: '{item} Nombre',
  itemNotShown: '',
  itemSaved: '{item} guardado',
  itemSpecification: 'Especificación de Elemento',
  showChapterTOCForHiddenPages: 'Mostrar Índice del Capítulo para Páginas Ocultas',
  january: 'Enero',
  japaneseDescription: 'Descripción en Japonés',
  japaneseName: 'Nombre en Japonés',
  job: 'Trabajo | Tareas',
  jobManager: 'Administrador de Tareas',
  jobManagerMessage: 'Exporta, publica e imprime/descarga trabajos',
  july: 'Julio',
  june: 'Junio',
  koreanDescription: 'Descripción en Coreano',
  koreanName: 'Nombre en Coreano',
  label: 'Etiqueta | Etiquetas',
  labelKey: 'Clave de Etiqueta',
  landscape: 'Horizontal',
  laneConfiguration: 'Configuración de pista',
  language: 'Idioma',
  lastModifiedDate: 'Última Fecha de Modificación',
  lastGenerated: 'Generada por Última vez',
  lastName: 'Apellido/s',
  lastNameEmpty: 'Debe añadir apellido/s',
  lastUpdatedOn: 'Última actualización el',
  layout: 'Plantilla',
  layoutOptions: 'Opciones de Diseño',
  ledger: 'Registro de Cuentas',
  left: 'Izquierda',
  legal: 'Legal',
  letter: 'Carta',
  library: 'Biblioteca',
  libraryAdmin: 'Administrador de la Biblioteca',
  libraryBannerLogo: 'Logotipo del Titular de la biblioteca',
  librarySettings: 'Configuración de la Biblioteca',
  librarySettingsDescription: 'Esta configuación establece varios elementos y varias opciones de la Biblioteca. Tenga cuidado cuando realice cambios. Póngase en contacto con los Servicios de Documoto para obtener más información.',
  licenseConfiguration: 'Configuración de licencia',
  line: 'Línea',
  lineTool: 'Herramienta de línea',
  link: 'Enlace | Enlaces',
  listBox: 'Casilla de Lista',
  listBoxValue: 'Valor de Cuadro de Lista | Valores de Cuadro de Lista',
  listValue: 'Valor de Lista | Valores de Lista',
  load: 'Cargar',
  loadMore: 'Cargar más',
  loading: 'Cargando',
  locale: 'Lugar',
  login: 'Iniciar sesión',
  loginBackground: 'Fondo de Inicio de Sesión',
  loginGDPRMessageBeginning: 'Este sitio web utiliza cookies. Si sigue utilizando este sitio web, da su consentimiento para que se utilicen cookies. Para obtener más información, consulte nuestra ',
  loginGDPRMessageEnd: 'y nuestras',
  loginSSO: 'Iniciar Sesión SSO',
  logiReportName: 'Nombre de Informe de Logi',
  logiReportToggle: '¿Informe de Logi?',
  logo: 'Logotipo | Logotipos',
  logout: 'Cerrar sesión',
  lookupLoadParts: 'Buscar/Cargar Partes de Documoto al Importar',
  lowerBound: 'Límite Inferior',
  makePagesDivisibleBy: 'Hacer Páginas Divisibles Por:',
  mainInfo: 'Información Principal',
  mainLogo: 'Logotipo Principal',
  manageItem: 'Gestionar {item}',
  management: 'Gestión',
  march: 'Marzo',
  margin: 'Margen | Márgenes',
  markdown: 'Rebaja',
  may: 'Mayo',
  media: 'Medios | Medios',
  mediaCategory: 'Categoría de Medios | Categorías de Medios',
  mediaCategoryEditor: 'Editor de la Categoría de Medios',
  mediaCategoryName: 'Nombre de la Categoría de Medios',
  mediaCategoryNameMissing: 'Todas las categorías de medios deben tener un nombre',
  mediaBookDeleteMessage: 'Al eliminar este medio, impedirá el acceso a este contenido tanto en la Biblioteca como en el Administrador de Contenido y ya no estará disponible para los usuarios o los Administradores de Contenido. Cualquier pieza o página de este libro que no esté en otros libros, tampoco estará disponible en la "Biblioteca"',
  mediaDeleteMessage: 'Al eliminar esta plantilla, se borrará del Administrador de Contenido y ya no estará disponible para copiarla en nuevos Medios.',
  mediaDeleteSuccessText: 'se eliminará de las listas cuando la indexación finalice',
  mediaDescription: 'Descripción de Medios',
  mediaIdentifier: 'Identificador de Medios',
  mediaName: 'Nombre de Medios',
  mediaPrintSettingsSaveSuccess: 'Configuraciones guardadas de Impresión de Medios',
  mediaWhereUsed: 'Medios Donde se Utilizan',
  menu: 'Menú',
  merge: 'Combinar',
  mergeOrOverrideMessage: '¿Desea sobrescribir o combinar estos artículos?',
  mergeWarning: 'Al Importar datos se actualizarán las partes existentes y se añadirán entradas nuevas',
  message: 'Mensaje',
  messageLevel: 'Nivel de Mensaje',
  microsite: 'Micrositio | Micrositios',
  micrositeAdmin: {
    cardSubtitle: 'El archivo de contenido comprimido y la información de entrada HTML',
    cardTitle: 'Contenido del Sitio',
    errorRequiredFile: 'Es necesario un archivo ZIP de Contenido del Sitio',
    fieldFile: 'Archivo ZIP de Contenido del Sitio',
    fieldPoint: 'Punto de entrada HTML',
  },
  minArea: 'Área Mínima',
  minAreaMessage: 'Es obligatorio un valor mínimo. El valor no puede superar el rango de 1 a 1500.  Debe ser un número entero.',
  moreInfo: 'Más información',
  multipleAlternatingPages: 'Varias Páginas Alternas',
  multipleAlternatingPagesMessage: 'Diagrama en las páginas 1 y 3; lista de piezas en las páginas 2 y 4',
  mToM: 'M2M',
  multiplePages: 'Varias Páginas',
  multiplePagesMessage: 'Diagrama en su propia página, seguido de las páginas de la lista de piezas',
  requiredField: 'Campo Obligatorio',
  messageMessage: 'Es obligatorio un mensaje',
  metadata: 'Metadatos',
  mine: 'Mío',
  miscellaneous: 'Varios',
  mon: 'Lun',
  myCart: 'Mi carro | Mis Carros',
  navigation: 'Navegación',
  nItemsCreated: '{n} {items} Creados',
  nItemsDeleted: '{n} {items} Eliminados',
  nOfMItems: '{n} de {m} {items}',
  name: 'Nombre',
  nameDescriptionHelpText: 'El nombre y la descripción opcional',
  new: 'Nuevo',
  newAPIKey: 'Nueva Clave de API',
  newERPSystem: 'Nuevo Sistema ERP',
  newItem: 'Nuevo {item}',
  newKeyGenerated: 'Se ha Generado una Nueva Clave de Integración, Copiar y Conservar para su uso futuro',
  newMediaCategory: 'Nueva Categoría de Medios',
  newPassword: 'Nueva Contraseña',
  newsItem: 'Elemento de Noticias | Elementos de Noticias',
  newTenant: 'Nuevo propietario',
  newWidgetKey: 'Nueva Clave de Widget',
  newWidgetKeyGenerated: 'Se ha Generado una Nueva Clave de Widget, Copiar y Conservar para su uso futuro',
  noTenantSuppliers: 'Ningún proveedor asignado al propietario.',
  noAPIKeys: 'No se han definido Claves de API.',
  node: 'Nodo',
  noData: 'No hay Datos',
  noFilesSelected: 'No se ha Seleccionado Ningún Archivo',
  noIllustrationFound: 'No se han Encontrado Ilustraciones.',
  noImageFound: 'No se han Encontrado Imágenes',
  noItemAvailable: 'Ningún {item} disponible',
  noItemFound: 'No se ha encontrado ningún {item}.',
  noItemSelected: 'Ningún {item} seleccionado',
  noItemAddedToTarget: 'No se ha agregado ningún {item} al {target}. ',
  noItemsFound: 'No se han Encontrado Elementos',
  noMoreResults: 'No hay más resultados',
  noFormsMessage: 'No se Han Definido Formularios.',
  noMediaDefined: 'No se ha definido ningún medio',
  nonSearchableFieldsSuccessfullyUpdated: 'Campos que No Permitan Búsquedas Actualizados Correctamente.',
  noPartsSelected: 'No hay piezas seleccionadas.',
  noPrice: 'Solicitar el Precio',
  noResults: 'No hay resultados',
  norwegianDescription: 'Descripción en Noruego',
  norwegianName: 'Nombre en Noruego',
  noSearchableTags: 'No hay etiquetas que permitan búsquedas',
  noSuggestions: 'No hay sugerencias para el término de búsqueda',
  notOrderable: 'No se Puede Pedir',
  notSelected: 'Sin seleccionar',
  noTagsFound: 'No se han Encontrado Etiquetas',
  noUnsavedChanges: 'Sin Cambios No Guardados',
  none: 'Ninguno',
  notImplemented: 'Sin implementar',
  notification: 'Notificación | Notificaciones',
  notificationsMessage: 'Anuncios y novedades importantes',
  notMapped: 'Sin Asignar',
  november: 'Noviembre',
  noWidgetKeys: 'No se han definido Claves de Widget.',
  numeric: 'Numérico',
  numberOf: 'N.° de {item}',
  october: 'Octubre',
  of: 'de',
  off: 'Desactivado',
  offlineContent: 'Contenido Sin Conexión',
  ok: 'Aceptar',
  oldPassword: 'Contraseña antigua',
  on: 'Activado',
  opacity: 'Opacidad',
  open: 'Abrir',
  openItem: 'Abrir {item}',
  optimizeIndexJob: 'Optimizar Índice',
  options: 'Opciones',
  openTab: 'Abrir enlace en una pestaña nueva',
  order: 'Pedido | Pedidos',
  orderable: 'Se puede pedir',
  orderableDescription: 'La pieza se puede añadir al carro y comprarla',
  orderableLocal: 'Se puede pedir en esta página',
  orderableLocalBlocked: 'La Pieza no Se puede Pedir Globalmente',
  orderDisable: 'Deshabilitar Historial de Pedidos',
  orderEmailRecipient: 'Destinatario del Correo Electrónico de Pedido | Destinatarios del Correo Electrónico de Pedido',
  orderEntity: 'Entidad de Pedido | Entidades de Pedido',
  orderFieldUneditableRequired: 'Si se Establece un Campo de Presupuesto/Pedido en Obligatorio y No Editable por el Usuario, es posible que el Campo de Presupuesto/Pedido no se pueda completar. Por favor, compruebe que sus Formularios de Presupuestos/Pedidos se pueden completar.',
  orderFieldsEmptyMessage: 'No se han definido Campos de Presupuesto/Pedido.',
  orderFieldsEmptyPrompt: 'Agregar un Campo de Presupuesto/Pedido',
  orderField: {
    nameMessage: 'Si cambia el nombre del Campo de Presupuesto/Pedido, compruebe que actualiza todos los demás Formularios de Presupuestos/Pedidos o Formatos de Correo Electrónico que este campo use.',
  },
  orderFieldCopyMessage: 'Introduzca un nombre exclusivo para el nuevo Campo de Presupuesto/Pedido',
  orderForm: {
    uniqueNameInputHint: 'Introduzca un nombre exclusivo para el nuevo Formulario.'
  },
  orderFormField: 'Campo de Presupuesto/Pedido | Campos de Presupuestos/Pedidos',
  orderFormLayout: 'Plantilla de Formulario de Presupuesto/Pedido | Plantillas de Formularios de Presupuestos/Pedidos',
  orderFormSetDefault: 'Conjunto de Formulario de Oferta/Orden Predeterminado',
  orderFormType: 'Formulario de Pedido',
  orderLayout: 'Plantilla de Pedido | Plantillas de Pedido',
  orderManagementApprover: 'Aprobador de la Gestión de Pedidos',
  orderManagementDisable: 'Deshabilitar la Administración de la Gestión de Pedidos',
  orderManagementViewer: 'Visor de Gestión de Pedidos',
  orderNumber: 'Número de Pedido',
  orderQuoteEmail: 'Correo Electrónico de Pedido/Presupuesto | Correos Electrónicos de Pedido/Presupuesto',
  orderQuoteEmailFormat: 'Formato del Correo Electrónico de Pedido/Presupuesto| Formatos del Correo Electrónico de Pedido/Presupuesto',
  orderQuoteEmailFormatCopyMessage: 'Seleccione un idioma para el nuevo Formato del Correo Electrónico de Pedido/Presupuesto.',
  orderQuoteFetchError: 'Error al obtener los datos del Formulario de Pedido/Presupuesto',
  orderQuoteForm: 'Pedido/Formulario de presupuesto | Pedido/Formularios de presupuestos',
  orderQuoteSaveConfirmation: '¿Guardar los cambios en los Formularios de Pedido/Presupuesto de la Organización?',
  orderQuoteSaveError: 'Error al guardar los datos del Formulario de Pedido/Presupuesto',
  orderQuoteFormSaved: 'El Formulario de Presupuesto/Pedido se ha guardado',
  orderStatus: 'Estado de Presupuesto/Pedido | Estados de Presupuestos/Pedidos',
  orderStatusDeleteMessage: 'Este estado se ha utilizado en pedidos y no puede eliminarse.',
  orderSubmittedMessage: 'Enviado Correctamente',
  orderSuggestion: 'Sugerencia de Pedido | Sugerencias de Pedido',
  orderSuggestionEnable: 'Habilitar Sugerencias de Pedido',
  orderSuggestionRemovalWarning: 'La pieza ya no se mostrará como sugerencia de pedido. | Las piezas ya no se mostrarán como sugerencias de pedido.',
  orderSuggestionThreshold: 'Umbral de Sugerencias de Pedido',
  ordersLogo: 'Logotipo de Pedidos',
  ordersMessage: 'Historial de envío de pedidos',
  orientation: 'Orientación',
  org: 'Org',
  orgEmailRecipients: 'Destinatarios del Correo Electrónico de la Organización',
  organization: 'Organización | Organizaciones',
  organizationAdmin: 'Administración de la Organización',
  organizationAdminBrowseFlow: 'Una Organización puede utilizar el flujo del navegador predeterminado o usted puede sobrescribir y utilizar cualquier otro flujo del navegador.',
  organizationAdminMessage: 'Controla la gestión de usuarios y la configuración de la org.',
  organizationEmpty: 'Debe seleccionarse la organización',
  organizationEmailRecipients: 'Destinatarios del Correo Electrónico de la Organización',
  organizationEnforceAccessControlsForQuickAdd: 'Aplicar controles de acceso para Agregar Rápidamente',
  organizationKey: 'Clave de la Organización',
  organizationLibrarySettingsDescription: 'Esta configuración establece varios elementos y varias opciones de la Biblioteca a nivel Organizativo. Tenga cuidado cuando realice cambios. Póngase en contacto con los Servicios de Documoto para obtener más información.',
  organizationMoveToRoot: 'Soltar aquí para mover a la raíz',
  organizationName: 'Nombre de la Organización',
  organizationRearrange: 'Reorganizar Organizaciones',
  organizationRearrangeDescription: 'La estructura Organizativa principal-secundaria define muchos atributos que se pueden heredar de una Organización, como Configuraciones de Impresión, Estilos, etc. Arrastre y suelte las Organizaciones para reflejar la jerarquía deseada.',
  organizationReportSettingsSuccessMessage: 'Configuración de creación de informes guardada',
  organizationSelect: 'Seleccionar Organización',
  organizationTags: 'Etiquetas de Organización',
  organizationsEmpty: 'Parece que todavía no ha creado ninguna organización',
  orgNameOnly: 'Solo el Nombre de la Org.',
  other: 'Otros',
  otherSettings: 'Otras Configuraciones',
  outboundCustomerIdParameter: 'Nombre del Parámetro Saliente de Identificación del Cliente',
  outboundSessionIdParameter: 'Nombre del Parámetro Saliente de Identificación de la Sesión',
  outlineMode: 'Modo de Esquema (Mayús. O)',
  output: 'Salida',
  outputCatalog: 'Catálogo de Salida',
  outputDefinition: 'Definición de Salida | Definiciones de Salidas',
  outputDefinitionDeleted: 'Definición de Salida eliminada',
  outputDefinitionSaved: 'Definición de Salida guardada',
  outputFileName: 'Nombre de Archivo de Salida',
  outputName: 'Nombre de Salida',
  outputDestination: 'Destino de Salida',
  outputSpecification: 'Especificación de Salida',
  outputSubType: 'Subtipo de Salida',
  outputType: 'Tipo de Salida',
  overridden: 'Sobrescrito',
  overriddenByOrg: 'Sobrescrito por Organización',
  override: 'Sobrescribir',
  overrideBrowseFlow: 'Sobrescribir Flujo del Navegador',
  overrideFacetLimitValue: 'Anular el valor límite de la faceta',
  overrideHotpointScale: 'Sobrescribir Escala del Punto Caliente',
  overridePdfNamingStandard: 'Anular el Estándar de Nombres de Archivos PDF',
  overridePrintSettings: 'Sobrescribir Configuración de Impresión',
  overrideFontSettings: 'Sobrescribir Configuración de Fuente',
  overrideOrderForm: 'Sobrescribir Formulario de Pedido',
  overrideQuoteForm: 'Sobrescribir Formulario de Presupuesto',
  overrideSvgOpacity: 'Sobrescribir Opacidad SVG',
  overrideWarning: 'Al importar datos se sustituirá la lista de materiales actual',
  owner: 'Propietario | Propietarios',
  page: 'Página | Páginas',
  pageBulkInsertConfirmTitle: '¿Desea finalizar la inserción en masa?',
  pageBulkInsertConfirmInsertAfter: 'De este modo se insertará {replacement} detrás de {original} en {whereUsedBookCount} {bookUnits} y {whereUsedTemplateCount} {templateUnits}.',
  pageBulkInsertConfirmInsertBefore: 'De este modo se insertará {replacement} delante de {original} en {whereUsedBookCount} {bookUnits} y {whereUsedTemplateCount} {templateUnits}.',
  pageBulkInsertConfirmInsertReplace: 'De este modo se insertará {replacement} y se eliminará {original} en {whereUsedBookCount} {bookUnits} y {whereUsedTemplateCount} {templateUnits}.',
  pageBulkInsertBooksConfirmInsertAfter: 'De este modo se insertará {replacement} detrás de {original} en {whereUsedBookCount} {bookUnits}.',
  pageBulkInsertBooksConfirmInsertBefore: 'De este modo se insertará {replacement} delante de {original} en {whereUsedBookCount} {bookUnits}.',
  pageBulkInsertBooksConfirmInsertReplace: 'De este modo se insertará {replacement} y se eliminará {original} en {whereUsedBookCount} {bookUnits}.',
  pageBulkInsertTemplatesConfirmInsertAfter: 'De este modo se insertará {replacement} detrás de {original} en {whereUsedTemplateCount} {templateUnits}.',
  pageBulkInsertTemplatesConfirmInsertBefore: 'De este modo se insertará {replacement} delante de {original} en {whereUsedTemplateCount} {templateUnits}.',
  pageBulkInsertTemplatesConfirmInsertReplace: 'De este modo se insertará {replacement} y se eliminará {original} en {whereUsedTemplateCount} {templateUnits}.',
  pageBulkInsertDiscard: 'Su inserción en masa no se procesará.',
  pageBulkInsertHelpText: 'Esta función le permite insertar esta página de origen {thisDisplayName} en los libros seleccionados en los que ya exista otra página {originalDisplayName}. Si lo desea, también puede eliminar {originalDisplayName} de estos libros.',
  pageBulkInsertInsertAfter: 'Insertar {replacement} DESPUÉS DE {original}',
  pageBulkInsertInsertBefore: 'Insertar {replacement} ANTES DE {original}',
  pageBulkInsertInsertReplace: 'Insertar {replacement} y ELIMINAR {original}',
  pageBulkInsertScopeAll: 'Cada {type} que tenga la página de destino {originalDisplayName} ({whereUsedCount} {units})',
  pageBulkInsertScopeNone: 'Ninguno de los {type} que tengan la página de destino {originalDisplayName}',
  pageBulkInsertScopeSome: 'Alguno de los {type} que tengan la página de destino {originalDisplayName}',
  pageBulkInsertStep1: 'Paso 1: Encontrar una página de destino que desee',
  pageBulkInsertStep2: 'Paso 2: Decidir cómo insertar o reemplazar las páginas',
  pageBulkInsertStep3: 'Paso 3: Elegir los libros en los que desee que se procese la inserción en masa.',
  pageBulkInsertStep4: 'Paso 4: Seleccione las plantillas en las que desea que se procese la inserción en masa.',
  pageBulkInsertSuccessTitle: 'Tarea de inserción en masa enviada',
  pageBulkInsertSuccessBody: 'Los cambios en el índice del libro se realizarán inmediatamente. Los resultados de búsqueda se verán reflejados cuando termine la indexación.',
  pageBulkErrorTooManyBooks: 'Limitar el número de libros seleccionados a no más de 1000',
  pageBuilder: 'Generador de Páginas',
  pageComposer: 'Page Composer',
  pageCreated: 'Página Creada',
  pageDeleteMessage: 'La eliminación de páginas se realizará inmediatamente tanto en la Biblioteca como en el Administrador de Contenido. La página eliminada se podrá seguir consultando hasta que se produzca la reindexación en unos minutos.',
  pageDeleteMessage2: 'Esta acción no se puede deshacer sin la ayuda del Centro de Asistencia de Documoto. Transcurridos 90 días, el registro eliminado y todas las asociaciones se borrarán de forma permanente.',
  pageDescription: 'Descripción de la Página',
  pageFile: 'Archivo de Página',
  pageFilename: 'Nombre de Archivo de la Página',
  pageFilenameDeleted: '{filename} se eliminará.',
  pageFileRequired: 'Es obligatorio un archivo de página.',
  pageBomPartsDeleted: '{count} las piezas de la página Lista de materiales ya no serán accesibles a través de {filename}.',
  pageDraftsDeleted: 'Los borradores asociados a esta página se eliminarán permanentemente.',
  pageFit: 'Adaptación Página',
  pageHeader: 'Encabezado de Página',
  pageLabel: 'Página | Páginas',
  pageLayout: 'Plantilla de Página',
  pageName: 'Nombre de la Página',
  pagePart: 'Sección de la página | Secciones de la Página',
  pagePartTag: 'Etiqueta de Partes de Página | Etiquetas de Partes de Páginas',
  pagePartData: 'Datos de Partes de Páginas',
  pageUsageDataCouldNotBeLoaded: 'No se Han Podido Cargar los Datos de Uso de la Página',
  pagePartLevelDetails: 'Pieza de Página Ordenable /Visible, Etiquetas de Pieza de Página',
  pagePartNumberVisible: 'Número Visible de Parte de Página',
  pagePartOrderable: 'Parte de Página Ordenable',
  pagePartOrderableDescription: 'Parte Ordenable de esta página',
  pagePartVisibleDescription: 'Número de Parte visible de esta página',
  pageUpdated: 'Página Actualizada',
  pageViewer: 'Visor de Páginas',
  pageWidth: 'Ancho de Página',
  pagesWhereUsed: 'Páginas Donde se Utilicen',
  parentName: 'Nombre Principal | Nombres Principales',
  part: 'Pieza | Piezas',
  partCode: 'Código de Pieza | Códigos de Piezas',
  partCodeAddError: 'No se pueden añadir los Códigos de Piezas',
  partCodeErrorEmpty: 'No se puede guardar: Debe añadirse un código de pieza',
  partCodeErrorInUse: 'Este código de pieza se está utilizando en algunas piezas en Documoto y no se puede eliminar. Póngase en contacto con el Centro de Asistencia en support{\'@\'}documoto.com para recibir ayuda.',
  partCodeErrorNotFound: 'No se puede eliminar este código de pieza porque no se ha encontrado. Asegúrese de que se hayan guardado todos los cambios.',
  partCodesManager: 'Gestor de Códigos de Piezas',
  partCodesMessage: 'Se pueden usar los códigos de las piezas en lugar de los números de las piezas y, a menudo, se usan para indicar que los elementos de la lista de materiales son obsoletos, no están disponibles o son solo elementos de referencia (por ejemplo, \'PNNA\', \'-\', \'REF\').',
  partCreated: 'Pieza Creada',
  partData: 'Datos de Partes',
  partUpdated: 'La Pieza se ha Actualizado',
  partDebouncerEmailQueueJob: 'Cola de Correo Electrónico',
  partDescription: 'Descripción de la Pieza',
  partLabel: 'Pieza | Piezas',
  partLevelDetails: 'Precio de Página Ordenable /Visible, Traducciones Adicionales, Etiquetas de Piezas',
  partName: 'Nombre de la Pieza',
  partnerApiKey: 'Clave de API de Socio',
  partnerRESTRequestLimit: 'Límite de la solicitud de REST del Socio',
  partNumber: 'Número de Pieza',
  partNumberDescription: 'Número de pieza interno para que los usuarios finales lo busquen',
  partNumberVisible: 'Número de Pieza Visible',
  partNumberVisibleDescription: 'El número de pieza es visible para los usuarios finales',
  partNumberVisibleLocal: 'Visible en esta página',
  partNumberVisibleLocalBlocked: 'Número de Pieza no Visible Globalmente',
  partOrderable: 'Parte Ordenable',
  partRequired: 'Es obligatoria al menos una pieza',
  partTagProperty: 'Propiedad de la Etiqueta de la Pieza | Propiedades de la Etiqueta de la Pieza',
  partTag: 'Etiqueta de Parte | Etiquetas de Partes',
  partThumbnailDescription: '',
  partialSupport: 'Soporte Parcial',
  partsListColumn: 'Columna de la Lista de Piezas | Columnas de la Lista de Piezas',
  partsBook: 'Libro de Piezas | Libros de Piezas',
  partsColumns: 'Columnas de Piezas',
  partsNotSelected: 'Imagen predeterminada utilizada para las miniaturas de piezas. Si no se aplica una miniatura predeterminada, Documoto mostrará una miniatura predeterminada estándar.',
  partsPage: 'Página de Partes | Páginas de Partes',
  partsPages: 'Páginas de Piezas',
  partsPageGroup: {
    excludePartCodesFromPartsLists: 'Excluir Códigos de Piezas de las Listas de Piezas',
    highlightAlternateRows: 'Destacar Filas Alternas',
    rotateImagesAutomatically: 'Rotar Imágenes Automáticamente',
    printBorderAroundImages: 'Imprimir Borde Alrededor de las Imágenes',
    keepImagesOnLeft: 'Mantener las Imágenes a la Izquierda',
    keepBOMOnRight: 'Mantener la Lista de Materiales a la Derecha',
    columnsUpdateErrorMsg: 'Ha ocurrido un error al intentar actualizar las Columnas de Piezas',
    columnsUpdateSuccessMsg: 'Columnas de Piezas actualizadas',
    columnsLoadErrorMsg: 'Fallo al cargar las columnas de piezas',
    printHotpointsOnImages: 'Imprimir Puntos Calientes en Imágenes',
  },
  partsList: 'Lista de Partes',
  partsListData: 'Datos de la Lista de Piezas',
  partsListGridHeader: 'Encabezamiento del Cuadro de la Lista de Piezas',
  password: 'Contraseña',
  passwordExpiredHeader: 'La contraseña ha caducado',
  passwordExpiredInfo: 'Su contraseña ha caducado. Su nueva contraseña debe cumplir los siguientes criterios:',
  passwordCriteriaInfo: 'Su nueva contraseña debe cumplir los siguientes criterios:',
  passwordBlankMessage: 'La contraseña no puede estar en blanco',
  passwordCannotBeSame: 'No puede ser la misma que su contraseña actual',
  passwordCasingMassage: 'Debe tener al menos un carácter en minúscula y otro en mayúscula',
  passwordLowerCaseMessage: 'La contraseña debe incluir al menos un carácter en minúsculas',
  passwordMinLengthMessage: 'Las contraseñas deben tener al menos 8 caracteres.',
  passwordNotMatchingMessage: 'La contraseña no es la misma',
  passwordSpecialCharactersMessage: 'La contraseña debe incluir al menos un carácter especial',
  passwordUpperCaseMessage: 'La contraseña debe incluir al menos un carácter en mayúsculas',
  passwordWillExpire1Day: 'La contraseña de inicio de sesión caducará en menos de 1 día',
  passwordWillExpire5Days: 'La contraseña de inicio de sesión caducará en menos de 5 días',
  passwordsMatch: 'Las Contraseñas Coinciden',
  permission: 'Permiso | Permisos',
  phoneNumber: 'Número de Teléfono',
  phoneNumberExt: 'Ext.',
  phonePrimary: 'Número de Teléfono Principal',
  phoneSecondary: 'Número de Teléfono Secundario',
  pinFilter: 'Anclar filtro para la página de resultados de búsqueda',
  pinned: 'Anclado',
  plusMoreOrgs: '+ {totalOrgCount} Organizaciones adicionales',
  polishDescription: 'Descripción en Polaco',
  polishName: 'Nombre en Polaco',
  populatePdfNameWith: 'Rellenar Nombre de Archivo PDF con',
  populatePdfNameSelection: 'Rellenar Selección de Nombre de Archivo PDF',
  port: 'Puerto',
  portrait: 'Vertical',
  portugueseDescription: 'Descripción en Portugués',
  portugueseName: 'Nombre en Portugués',
  postComment: 'Publicar Comentario',
  postalCode: 'Código Postal',
  poweredBy: 'Desarrollado Por',
  preferences: 'Preferencias',
  preferencesDisable: 'Deshabilitar Preferencias de Usuario',
  prefix: 'Prefijo | Prefijos',
  preview: 'Previsualizar',
  previewOfName: 'Vista previa de {name}',
  pricing: 'Precios',
  pricingDiscountEnabled: 'Mostrar Precio de Descuento',
  pricingDisplayInCart: 'Mostrar Precios en el Carro',
  pricingDisplayInPartsList: 'Mostrar Precios en la Lista de Piezas',
  pricingGlobal: 'Precios Globales',
  pricingGlobalDescription: 'El precio normal cuando no hay una Organización sobrescrita',
  pricingHidePricingButton: 'Habilitar el Botón de Ocultar Precios',
  pricingHidePricingButtonDisable: 'Deshabilitar el Botón de Ocultar Precios',
  pricingOrganization: 'Precios de la Organización',
  pricingOrganizationDeleteConfirm: '¿Seguro que desea eliminar los precios de esta Organización?',
  pricingOrganizationDescription: 'Los precios de la Organización sobrescriben los precios globales para todos los usuarios de una Organización y sus Organizaciones secundarias.',
  pricingOrganizationDuplicate: 'Esta organización ya se está reemplazando',
  pricingOrganizationEmptyState: 'No se han definido reemplazos. Haga clic en {add} para comenzar.',
  pricingRetailEnabled: 'Mostrar Precio de Venta al Público',
  pricingWholesaleEnabled: 'Mostrar Precio Al Por Mayor',
  primary: 'Principal',
  print: 'Imprimir',
  printerLane: 'Pista de la impresora',
  printJob: 'Trabajo de Impresión',
  printLogo: 'Imprimir Logotipo',
  printSetting: 'Configuración de Impresión',
  printSettingDeleteFail: 'Fallo al Eliminar la Configuración de Impresión',
  printSettingSaveFail: 'Fallo al Guardar la Configuración de Impresión',
  printSettingEmptyMessage: 'No se han definido Configuraciones de Impresión. Añadir una Configuración de Impresión para comenzar.',
  printSettingSetDefaultSuccess: 'Se ha establecido una Configuración de Impresión Predeterminada',
  printSettingUpdateFail: 'Fallo al Actualizar la Configuración de Impresión',
  printSettingCopyMessage: 'Introducir un nombre exclusivo para la nueva Configuración de Impresión.',
  printSettingCoverLoadErrorMsg: '',
  printSettingCreated: 'Se ha creado una Configuración de Impresión',
  printSettings: 'Configuraciones de Impresión',
  printSettingsLogo: 'Imprimir Logotipo de Configuración',
  printing: 'Impresión',
  printingDisabled: 'Deshabilitar Todas las Impresiones',
  privacyPolicy: 'Política de Privacidad',
  proceed: 'Proceder',
  process: 'Proceso',
  processContent: 'Contenido del Proceso',
  processing: 'Procesando',
  processToDraft: 'Proceso de elaboración del borrador',
  processedPlzToDraft: 'Archivos PLZ para borradores',
  production: 'Producción',
  professional: 'Profesional',
  profile: 'Perfil',
  prohibitExportDownloadOfContent: 'Prohibir exportar o descargar contenido',
  prohibitExportDownloadOfContentHelpText: 'Si se establece en \'Habilitar\’, se impedirá que cualquier usuario (con privilegios) exporte o descargue este contenido.',
  property: 'Propiedad | Propiedades',
  province: 'Provincia',
  public: 'Público',
  publish: 'Publicar',
  publisherLane: 'Pista del editor',
  publisherManualLane: 'Pista manual del editor',
  publishThumbnailJob: 'Publicar Miniatura',
  publishDocuments: 'Publicar Documentos',
  publishDraft: 'Publicar Borrador',
  publisher: 'Editor | Editores',
  publishingErrors: 'Errores de Publicación',
  publishingErrorsWithName: 'Errores de publicación {name}',
  publishingInProgress: 'Publicación en curso, no se puede enviar',
  publisherJob: 'Tarea del Editor',
  publisherMessage: 'Creación de contenido, gestión e impresión de los valores configurados',
  purchaseOrder: 'Orden de Compra',
  purchaseOrderTemplateEnabled: 'Habilitar Plantillas de Pedido',
  purchaseOrderTemplateDisabled: 'Deshabilitar Plantillas de Pedido',
  qty: 'Cant.',
  quantity: 'Cantidad',
  query: 'Consulta',
  quickAdd: 'Adición Rápida',
  quickAddHelpText: 'Empiece a escribir para buscar piezas para añadir al carro.',
  quickAddInCartEnabled: 'Habilitar Adición Rápida al Carro',
  quickLinks: 'Enlaces Rápidos',
  quote: 'Oferta | Oferta',
  quoteFormType: 'Formulario de Presupuesto',
  quoteTemplateDisabled: 'Deshabilitar Plantillas de Presupuesto',
  quoteTemplateEnabled: 'Habilitar Plantillas de Presupuesto',
  range: 'Rango',
  rearrange: 'Reorganizar',
  rebuildIndexJob: 'Volver a Generar Índice',
  recentSearches: 'Búsquedas recientes',
  recentlyAdded: 'Añadido Recientemente',
  recentlyEdited: 'Editado Recientemente',
  recentlyViewed: 'Visto Recientemente',
  rectangle: 'Rectángulo',
  redirect: 'Redireccionar',
  refresh: 'Actualizar',
  related: 'Relacionados',
  relatedDescription: 'Relacionar los soportes existentes en la biblioteca con este {type}. Nota, los medios relacionados deben tener controles de acceso aplicados para que los usuarios puedan verlos en la pestaña\'Relacionado\'.',
  relatedInfo: 'Información Relacionada',
  relatedView: 'Ver medios Relacionados',
  relationship: 'Relación | Relaciones',
  releaseNotes: 'Notas de la Versión',
  rememberMe: 'Recordarme',
  remainingWidth: 'Anchura Restante',
  remove: 'Eliminar',
  removeAll: 'Eliminar Todos',
  removeItemConfirmation: '¿Seguro que desea eliminar {item}?',
  removeItemCount: '¿Seguro que desea eliminarla {count} {type}?',
  replace: 'Reemplazar',
  replaceExisting: 'Reemplazar Existente',
  report: 'Informe | Informes',
  reportAnIssue: 'Referir un Problema',
  reportAnIssueMessage: 'Correo electrónico enviado correctamente',
  reportCatalogName: 'Nombre de Catálogo de Informes',
  reportCatalogNotSet: 'El Catálogo de Informes no se ha definido para el informe.',
  reportDefinition: 'Definición de Informe | Definiciones de Informes',
  reportDefinitionDeleteError: 'No se pudo eliminar la definición de Informe',
  reportsEmptyState: 'No hay Informes',
  reportExportTimeWarning: 'En función del número de registros y del formato seleccionado, es posible que lleve varios minutos exportar los datos.',
  reporting: 'Informes',
  reportingMessage: 'Acceso a diversos informes y métricas',
  reportSettings: 'Configuración de Informes',
  reportsLibrary: 'Biblioteca de informes',
  requestForQuote: 'Solicitar Presupuesto',
  requestFormType: 'Formulario de Presupuesto',
  required: 'Obligatorio',
  requiredItem: '{item} es obligatorio.',
  requiredMessage: 'Un {item} es obligatorio.',
  requiredMessageAndUnique: 'Es obligatorio un {item} y debe ser único.',
  requireAuthentication: 'La autenticación es Obligatoria',
  requiredReading: 'Lectura Obligatoria',
  requiredReadingMessage: 'Por favor, revise lo siguiente: ',
  requiredUrlMessage: 'Una URL correcta es obligatoria.',
  reset: 'Restablecer',
  resetPassword: 'Restablecer Contraseña',
  resetPasswordError: 'Hubo un problema al restablecer su contraseña, vuelva a intentarlo.',
  resetPasswordTokenError: 'El token de restablecimiento de la contraseña no es válido.',
  resetZoom: 'Restablecer Zoom',
  resource: 'Recurso | Recursos',
  resize: 'Redimensionar',
  resizeThumbnails: 'Cambiar automáticamente el tamaño de las miniaturas',
  result: 'Resultado | Resultados',
  resultFor: 'Resultado para {item} | Resultados para {item}',
  retail: 'Venta Minorista',
  retailPrice: 'Precio de Venta al Público',
  retailTotal: 'Total Venta Minorista',
  return: 'Volver',
  returnToItem: 'Devolver a {item}',
  returnToPartList: 'Devolver a la Lista de Piezas',
  returnToMedia: 'Devolver a Medios',
  returnToTemplate: 'Volver a la Plantilla',
  returnToViewer: 'Volver al Visor',
  revertChanges: 'Revertir Cambios',
  right: 'Derecha',
  romanianDescription: 'Descripción en Rumano',
  romanianName: 'Nombre en Rumano',
  rootLevelBrowseFlow: 'Nivel Raíz',
  row: 'Fila',
  russianDescription: 'Descripción en Ruso',
  russianName: 'Nombre en Ruso',
  sampleData: 'Datos de Muestra',
  samplePreview: 'Vista Previa de la Muestra',
  sandbox: 'Entorno de pruebas',
  sat: 'Sáb',
  save: 'Guardar',
  saveChanges: '¿Guardar sus cambios en {item}? {name}',
  saveChangesToItem: '¿Guardar sus cambios en este {item}?',
  saved: 'Guardado',
  saveNewAPIKey: 'La Nueva Clave de Integración no se ha Guardado, Vuelva atrás para completar la configuración',
  saveNewWidgetKey: 'La Nueva Clave de Integración no se ha Guardado. Vuelva atrás para completar la configuración.',
  search: 'Buscar',
  searchableFieldsSuccessfullyUpdated: 'Campos que Permiten Búsquedas Actualizados Correctamente.',
  searchAllContent: 'Buscar Todo el Contenido',
  searchConfiguration: 'Buscar Configuración',
  searchCriteria: 'Criterio de Búsqueda',
  searchDocumoto: 'Buscar Documoto',
  searchFeatures: 'Buscar Funciones',
  searchFieldConfigDescription: 'Los siguientes ajustes de búsqueda de campos del sistema solo se aplican a la búsqueda general de la Biblioteca, a la página de resultados de búsqueda y a la Adición Rápida en el Carro. Otras funciones de búsqueda (como la búsqueda en el Centro de Administración y la Biblioteca Dónde se Utilice) no se verán afectadas.',
  searchInContent: 'Buscar en {contentName}...',
  searchingInLanguage: 'Buscando solo en {lang}',
  searchingInTwoLanguages: 'Buscando solo en {lang1} y {lang2}',
  searchPlaceholder: 'Buscar todo el contenido...',
  searchPrompt: 'Buscar todo el contenido...',
  searchProperty: 'Buscar Propiedad | Buscar Propiedades',
  searchResultsConfiguration: 'Configuración de los Resultados de la Búsqueda.',
  searchSettings: 'Configuración de la Búsqueda',
  searchSettingsDescription: 'Esta configuración controla diferentes comportamientos, características y resultados de la búsqueda en la Biblioteca.',
  searchTerm: 'Término de Búsqueda',
  searchTips: 'Consejos para la búsqueda:',
  searchable: 'Permite búsquedas',
  searchableTag: 'Etiqueta que Permite Búsquedas',
  section: 'Sección',
  select: 'Seleccionar',
  selectAField: 'Seleccionar un {field}',
  selectAll: 'Seleccionar Todos',
  selectAndClose: 'Seleccionar y Cerrar',
  selected: 'Seleccionado',
  selectedTargetPage: 'Página de Destino Seleccionada',
  selectChapters: 'Seleccionar Capítulo(s)',
  selectChildren: 'Seleccionar Elementos Secundarios',
  selectFields: 'Seleccionar {fields}',
  selectItem: 'Seleccionar {item}',
  selectMedia: 'Seleccionar Medios',
  selectPage: 'Seleccionar Página',
  selectPages: 'Seleccionar Página(s)',
  selectTargetPage: 'Seleccionar Página de Destino',
  send: 'Enviar',
  september: 'Septiembre',
  serbianDescription: 'Descripción en Serbio',
  serbianName: 'Nombre en Serbio',
  serviceUri: 'URI de Servicio',
  sessionLog: 'Registro de sesión',
  set: 'Establecer',
  setAsDefault: 'Establecer como Predeterminado',
  settings: 'Configuración',
  settingsMessage: 'Actualice su contraseña y sus preferencias de usuario',
  sequence: 'Secuencia',
  shape: 'Forma',
  shapeTool: 'Herramienta para dar forma',
  shared: 'Compartido',
  sharedCart: 'Carro Compartido | Carros Compartidos',
  sharedCartDeleteContent: 'Esto eliminará este Carro Compartido de todas las Organizaciones en las que se haya compartido.',
  sharedCartOrganizationContent: 'Seleccione las Organizaciones que puedan utilizar este Carro Compartido.',
  sharedShoppingCartsEnabled: 'Habilitar Carros Compartidos',
  shoppingCartExportJob: 'Exportar Carrito de la Compra',
  shoppingCartLoadingLongTime: 'El carro se está cargando. Los iconos del carro estarán disponibles una vez que finalice el proceso. Si el problema persiste, comuníquese con su Administrador de Documoto para obtener ayuda.',
  showExportFromSearch: 'Permitir la Exportación Desde los Resultados de la Búsqueda',
  showExportFromSearchHelp: 'Permitir exportar los resultados de la búsqueda de la lista de libros',
  showHelp: 'Mostrar Ayuda',
  showIdentifierInSearch: 'Mostrar Identificador de Medios en la Búsqueda',
  showItem: 'Mostrar {item}',
  showLess: 'Mostrar Menos',
  showMediaDescriptionFilter: 'Mostrar Filtro de Descripción de Medios',
  showMediaIdentifierFilter: 'Mostrar Filtro de Identificador de Medios',
  showMediaNameFilter: 'Mostrar Filtro de Nombre de Medios',
  showMore: 'Mostrar Más',
  showOnlyExactMatches: 'Mostrar solo coincidencias exactas',
  showOrganizationFilter: 'Mostrar Filtro de Organización',
  showPartNameFilter: 'Mostrar Filtro de Nombre de Piezas',
  showPartNumberFilter: 'Mostrar Filtro de Número de Piezas',
  showRecentlyAdded: 'Mostrar Añadido Recientemente',
  showRecentlyViewed: 'Mostrar Visto Recientemente',
  showSearchButton: 'Mostrar Botón de Búsqueda de la Página de Inicio',
  showSearchButtonHelp: 'Mostrar el botón \'Ir a Buscar\' en el encabezamiento de la Biblioteca',
  signature: 'Firma',
  size: 'Tamaño',
  snapshot: 'Foto | Fotos',
  solrSlaveSvrOverride: 'Servidores esclavos SOLR Anulación de las URL base',
  sortAsc: '{value} A-Z',
  sortDesc: '{value} Z-A',
  sortRelevant: 'Más Relevante',
  sortType: 'Clasificar Tipo',
  spanishDescription: 'Descripción en Español',
  spanishName: 'Nombre en Español',
  square: 'Cuadrado',
  ssoConfiguration: 'Configuración de SSO',
  ssoIdpMetadataUrl: 'Proveedor de identidad de SSO URL de metadatos',
  ssoLoginMaxAuthenticationAgeSeconds: 'Inicio de sesión SSO Tiempo máximo de autenticación en segundos',
  ssoSamlDnAttributeName: 'ND SAML SSO Nombre de atributo',
  ssoSamlEmailAttributeName: 'SAML SSO Nombre del atributo de correo electrónico',
  ssoSamlMemberofAttributeName: 'SAML SSO Nombre de atributo de MiembroDe ',
  ssoSniForSsoMetadataRetrieval: 'Utilizar SNI para la recuperación de metadatos SSO',
  standard: 'Estándar',
  standardUserGroup: 'Grupo de Usuarios Estándar',
  startDate: 'Fecha de Inicio',
  startDateRequired: 'Hace falta una fecha de Inicio.',
  state: 'Estado',
  static: 'Estático',
  status: 'Estado',
  statusCode: 'Código de Estado | Códigos de Estado',
  stitchPages: 'Páginas de Puntada',
  storeFrontAdmin: 'Administración del Escaparate',
  storefront: 'Escaparate',
  storefrontIntegration: 'Integración del Escaparate | Integraciones del Escaparate',
  storefrontIntegrationSubtitle: 'Estas configuraciones controlan varios elementos y opciones de la integración del escaparate. Tenga cuidado cuando realice cambios. Póngase en contacto con el Centro de Asistencia de Documoto para obtener más información.',
  storefrontSettings: 'Configuraciones del Escaparate',
  storefrontSettingsDescription: 'Estas configuraciones controlan varios elementos y opciones del escaparate. Tenga cuidado cuando realice cambios. Póngase en contacto con Documoto para obtener más información.',
  storefrontSettingsOrgDescription: 'Esta configuración establece varios elementos y varias opciones del Escaparate a nivel Organizativo. Tenga cuidado cuando realice cambios. Póngase en contacto con los Servicios de Documoto para obtener más información.',
  storeFilterValues: 'Almacenar Valores de Filtro',
  storeFilterValuesDescription: 'Al habilitar esta opción, se almacenan los valores establecidos por los usuarios en los filtros de la página de búsqueda para esta etiqueta.',
  stroke: 'Trazo',
  style: 'Estilo | Estilos',
  styles: {
    colorHotpoint: 'Valor Predeterminado de Punto caliente',
    invalidHexcode: 'Código hexadecimal no válido',
    inheritedFromOrg: 'Heredado de la Organización {org}',
    inheritedFromSystem: 'Heredado del Valor Predeterminado del Sistema',
    inheritedFromTenant: 'Heredado del Propietario',
    organization: 'Estilo de organización | Estilos de organización',
    organizationSaved: 'Estilos de la organización guardados',
    sampleNavigation: 'Pestañas de desplazamiento',
    samplePrimary: 'Desplazamiento/Botones',
    sampleSelectedHotpoint: 'Puntos calientes Seleccionados',
    tenant: 'Estilo del Propietario | Estilos del Propietario',
    tenantSaved: 'Estilo del Propietario guardados'
  },
  subChapter: 'Subcapítulo | Subcapítulos',
  subject: 'Asunto',
  subjectMessage: 'Es necesario un Asunto',
  submit: 'Enviar',
  submitPurchaseOrder: 'Orden de Compra',
  submitRequestForQuote: 'Solicitar Presupuesto',
  submitted: 'Enviado',
  submitter: 'Remitente',
  submittedBy: 'Enviado por',
  submitterEmail: 'Correo Electrónico del Remitente',
  submitterFirstName: 'Nombre del Remitente',
  submittedFilesMessage: '{fileCount} archivo/s enviado/s.',
  submitterLastName: 'Apellido del Remitente',
  submitterOrgName: 'Nombre de la Organización del Remitente',
  submitterUserName: 'Nombre del Usuario del Remitente',
  success: 'Correcto',
  imageSaveSuccess: 'Imágenes Subidas Correctamente',
  successNotificationDuration: 'Agregar al Carro - Duración de la Notificación de Éxito del Sistema',
  suffix: 'Sufijo | Sufijos',
  suggestedKeywords: 'Palabras Clave sugeridas',
  suggestedResults: 'Resultados sugeridos',
  suggestion: 'Sugerencia | Sugerencias',
  sun: 'Dom',
  superadmin: 'Superadministrador',
  superAdminSettings: 'Configuración de superadministrador',
  supersede: 'Sustituir',
  supersedeComplete: '¿Desea finalizar la sustitución?',
  supersedeDiscardChanges: '¿Descartar su sustitución?',
  supersedeMainText: 'La sustitución reemplaza una pieza existente por una o varias piezas en cada página de piezas en la que se encontraba la pieza antigua. El número de la pieza antigua se elimina, pero se puede seguir buscando a través de las etiquetas de modo que el contenido puede encontrarse utilizando el número antiguo o el/los número/s nuevo/s.',
  supersedeMessage: 'Las actualizaciones de piezas surten efecto de inmediato. La búsqueda reflejará las actualizaciones en unos minutos.',
  supersedeNotProcessed: 'Su sustitución no se procesará.',
  supersedeReplacePart: 'Encuentre una o más piezas que vayan a sustituir la pieza',
  supersedeSuccessful: 'Sustitución Correcta',
  supersedeWith: 'Sustituir Pieza Por...',
  supplier: 'Proveedor | Proveedores',
  supplierKey: 'Clave del proveedor',
  supplierDescription: 'La empresa que suministra esta pieza',
  supportedImageFormats: 'Formatos de imagen admitidos: GIF, JPG, JPEG, PNG',
  supportedDraftArchives: 'Archivo no admitido; archivos admitidos como borradores: MDZ y PLZ',
  surroundWithQuotes: 'Entrecomille varios términos de búsqueda para obtener una coincidencia exacta.',
  svgFinder: 'Buscador SVG',
  swedishDescription: 'Descripción en Sueco',
  swedishName: 'Nombre en Sueco',
  system: 'Sistema',
  systemAdmin: 'Administrador del Sistema',
  systemFields: 'Campos del Sistema',
  systemFieldSearchAndFiltering: 'Búsqueda y Filtrado de Campos del Sistema',
  systemStatus: 'Estado del Sistema',
  systemName: 'Nombre del Sistema',
  systemVersion: 'Versión del Sistema',
  tabDelimited: 'Delimitado por tabuladores',
  tableOfContents: 'Índice',
  tabloid: 'Tabloide',
  tag: 'Etiqueta | Etiquetas',
  tagName: 'Nombre de Etiqueta',
  tagEditor: {
    rangeBoundLengthInvalid: 'El límite no puede tener más de {limit} dígitos.',
    tagEditorModal: 'Modal del Editor de Etiquetas',
    filterPrompt: 'Mostrando los primeros 100 valores. Use el filtro para ver más.'
  },
  tagSearchAndFiltering: 'Filtrado y Búsqueda de Etiquetas',
  tagSearchAndFilteringHelpText: 'Sugerencia: Algunas opciones de filtros y búsqueda de Sugerencias también se puede establecer en los editores de etiquetas del Gestor de Etiquetas, que se encuentran aquí: ',
  tagSubmitAffected: 'Cualquier contenido {type} suprimirá la etiqueta {tag} automáticamente además de los cambios.',
  tagDeleteAffectedCount: 'Esto eliminará la etiqueta y borrará la información de la etiqueta de los {count} elementos.',
  tagDeleteAffectedNone: 'No hay elementos que utilicen esta etiqueta actualmente.',
  tagManagerDeleteWarning: 'Todos los medios existentes con {deleteCategoryName} perderán esta categoría después de la eliminación.',
  tagsDisplayMore: 'Mostrando los primeros 100 valores. Use el filtro para ver más.',
  tagsManager: 'Gestor de Etiquetas',
  tagValue: 'Valor de la Etiqueta | Valores de la Etiqueta',
  tagValueMissing: 'Debe introducir un valor de etiqueta',
  tagValueMultiple: 'Punto y coma delimitado para entradas múltiples',
  tagValueTooLong: 'El valor de la etiqueta sobrepasa el límite de 25 caracteres | Los valores de la etiqueta sobrepasan el límite de 25 caracteres',
  taxon: 'Taxón',
  taxonomy: 'Taxonomía',
  template: 'Plantilla | Plantillas',
  templateEmailRecipients: 'Destinatarios del Correo Electrónico de Plantilla',
  templateLayout: {
    addRow: 'Añadir Fila',
    addItem: 'Añadir Artículo',
    deleteRow: 'Eliminar Fila',
    deleteColumn: 'Eliminar Columna',
    fullWidth: 'Ancho Completo',
    oneColumn: 'Una Columna',
    threeColumns: 'Tres Columnas',
    twoColumns: 'Dos Columnas'
  },
  tenant: 'Propietario | Propietarios',
  tenantAdmin: 'Administración de Propietario',
  tenantAdminMessage: 'Controla valores de configuración amplia del propietario',
  tenantCleanerJob: 'Mantenimiento del Inquilino',
  tenantConfiguration: 'Configuración de propietario',
  tenantEmailRecipients: 'Destinatarios del Correo Electrónico del Propietario',
  tenantEmailRecipientsOverrideMessage: 'Estos se sobrescribirán si se especifican los Destinatarios del Correo Electrónico de la Organización',
  tenantIndexQueueJob: 'Cola de Índice de Inquilino',
  tenantKey: 'Clave de propietario',
  tenantKeyError: 'La clave de propietario no es única',
  tenantKeyMessage: 'La clave de propietario debe ser única',
  tenantLabelDeleteMessage: 'La eliminación de esta etiqueta revertirá el nombre predeterminado de Documoto.',
  tenantReindexModulus: 'Módulo de reindexación de propietarios',
  tenantSettings: {
    collapseTOCLabel: 'Contraer Índice en la Página Correspondiente',
    defaultLocaleLabel: 'Configuración Regional Predeterminada',
    defaultLocaleMessage: 'Configuración regional predeterminada del propietario.',
    deleteMissingTranslationsOnPublishLabel: '¿Suprimir las traducciones que faltan cuando se publiquen?',
    deleteMissingTranslationsOnPublishMessage: 'Habilitar la eliminación de traducciones de artículos al publicarlos',
    defaultSupplierLabel: 'Proveedor Predeterminado',
    defaultSupplierMessage: 'Proveedor predeterminado del propietario.',
    enableChapterIndexingMessage: 'Indexar capítulos',
    enableChapterIndexingLabel: 'Activa la indexación de los capítulos multimedia',
    enableIndexingLabel: 'Índice de Publicación',
    enableIndexingMessage: 'Activa la indexación para publicación.',
    helpUrlLabel: 'URL de Ayuda Predeterminado',
    helpUrlMessage: 'La URL de un sistema de ayuda alternativa.',
    hotPointLinkPageInBookOnlyLabel: 'Enlaces a Punto Caliente de Página en Libro Únicamente',
    hotPointLinkPageInBookOnlyMessage: 'Enlaces a Punto Caliente desde Páginas en Libro Únicamente',
    mediaTimestampLabel: 'Actualizar Marca Temporal de los Medios en el Cambio del Control del Acceso',
    mediaTimestampMessage: 'Habilitar si un cambio en los controles de acceso debería actualizar la marca temporal de los medios.',
    publisherDefaultUom: 'UM Predeterminada del Editor',
    publishingContactLabel: 'Correo Electrónico de Contacto de Publicación',
    publishingContactMessage: 'La dirección de correo electrónico predeterminada que recibirá los correos cuando se notifique un problema de contenido.',
    replaceHotpointLabel: 'Reemplazar Siempre los Enlaces de Punto Caliente en Publicación',
    replaceHotpointMessage: 'Solamente para la Publicación de Docustudio.',
    ssoLoginSystemLabel: 'Sistema de inicio de sesión SSO',
    ssoLoginSystemMessage: 'Identificar sistema del Proveedor para SSO.',
    userPasswordExpirationLabel: 'Días para que caduque la contraseña de usuario',
    userPasswordExpirationMessage: 'Número de días tras los cuales deben restablecerse las contraseñas; dejar en blanco para que no caduquen nunca.',
    lockPartTrOnPublishLabel: 'Bloquear traducciones parciales al publicarlas',
    lockPartTrOnPublishMessage: 'Evita que las traducciones de fragmentos se modifiquen durante la publicación',
    exportPgAsHashLabel: 'Exportar nombre de archivo de página como clave Hash',
    exportPgAsHashMessage: 'Utilizar claves Hash de página como nombres de archivo de página en el contenido exportado'
  },
  tenantSettingsTitle: 'Configuración del Propietario',
  tenantSettingsSaved: 'La Configuración del Propietario se ha Guardado',
  tenantSettingsSubtitle: 'Esta configuración establece las propiedades y el comportamiento de los propietarios. Tenga cuidado al hacer los cambios. Contactar con los Servicios de Documoto para obtener más información.',
  tenantSuppliers: 'Proveedores del propietario',
  tenantLabelsPageText: 'Las etiquetas se utilizan para personalizar los nombres de muchos objetos encontrados en la Biblioteca de Documoto y las Pantallas de administración.',
  tenantPrintSettingsDescription: 'Elija la configuración de impresión predeterminada a nivel de propietario. Se utilizará para toda la impresión de libros, excepto si se sobrescribe en la Organización o los Medios.',
  tenantType: 'Tipo de propietario',
  tenantWebserviceUser: 'Usuario del servicio web del propietario',
  text: 'Texto',
  textArea: 'Área de Texto',
  textField: 'Campo de Texto',
  textFile: 'Archivo de Texto (CSV, TXT)',
  textTool: 'Herramienta de Texto',
  threeD: {
    addFile: 'Añadir Archivo de Pieza 3D',
    beforeSaveModal: {
      title: 'Ya existe un archivo CAD con este nombre en Documoto.',
      message: '¿Desea sustituir este archivo en todos los lugares donde exista o adjuntarlo solo a esta pieza? Si elige reemplazar el archivo, ocurrirá lo siguiente:',
      messageBulletOne: '{count} pieza/s se actualizará/n con este nuevo archivo CAD',
      messageBulletTwo: 'La versión anterior de este archivo se eliminará del sistema de archivos de Documoto',
      tip: 'Consejo: para evitar que se sobrescriban los archivos durante el proceso de adjuntar, puede modificar el nombre del archivo que intenta adjuntar antes de importarlo.'
    },
    deleteTitle: '¿Seguro que desea eliminar esta vista 3D?',
    drawMode: {
      label: 'Modo Dibujo',
      hiddenLines: 'Líneas ocultas',
      shaded: 'Con sombreado',
      wireframe: 'Wireframe',
      wireframeShaded: 'Wireframe sombreado',
    },
    explode: 'Desmontar',
    fileSizeModal: {
      message: 'El tamaño máximo de archivo permitido en Documoto es de 25 megabytes. El archivo que está intentando cargar ha superado el máximo y no se puede cargar. Haga clic en "Aceptar" para continuar.',
      title: 'Se ha Superado el Tamaño Máximo de Archivo',
    },
    orientationAndProjection: {
      back: 'Parte trasera',
      bottom: 'Parte Inferior',
      front: 'Parte frontal',
      isometric: 'Isométrico',
      left: 'Izquierda',
      orthographic: 'Ortográfico',
      projection: 'Proyección',
      perspective: 'Perspectiva',
      right: 'Derecha',
      top: 'Parte Superior'
    },
    resetCamera: 'Restablecer Cámara',
    slice: {
      label: 'Cortar',
      planeX: 'Plano de Corte X',
      planeY: 'Plano de Corte Y',
      planeZ: 'Plano de Corte Z',
      reset: 'Restablecer Plano de Corte',
      section: 'Conmutar la Sección del Plano de Corte',
      visibility: 'Conmutar la Visibilidad del Plano de Corte',
    },
    supportedFilesMessage: 'Documoto admite numerosos formatos de archivos CAD. Consulte el Centro de Asistencia si duda si su formato de archivo CAD es compatible.',
    toolsLabel: 'Herramientas 3D',
    topAssemblyFilename: 'Nombre del Archivo de Ensamblaje Superior',
    topAssemblyFilenameRequired: 'El Nombre del Archivo de Ensamblaje Superior es obligatorio',
    viewerTitle: 'Visor 3D',
  },
  thu: 'Jue',
  thumbnail: 'Miniatura',
  thumbnailDeleted: 'Miniatura Eliminada Correctamente',
  thumbnailDeletedError: 'Fallo al Eliminar la Miniatura',
  thumbnailUpdated: 'Miniatura Actualizada Correctamente',
  thumbnailUpdatedError: 'Fallo al Actualizar la Miniatura',
  thumbnailReapplyWarning: 'El sistema puede volver a aplicar una miniatura basándose en el contenido de los medios. Es posible que tarde unos minutos.',
  thumbnailResizeTooltip: 'Cambia el tamaño de las miniaturas a un máximo de 500 x 500 píxeles, si se ha seleccionado la opción',
  title: 'Título',
  to: 'Para',
  toastNotifications: 'Notificaciones del Sistema',
  toDate: 'Hasta la Fecha',
  toc: 'Índice',
  tocClipBoardSuccessTitle: 'Copiado en el Portapapeles',
  tocClipBoardWarningText: 'ADVERTENCIA: Los cambios sin guardar no se pueden copiar en el Portapapeles',
  tocClipBoardWarningTitle: 'Copiado en el Portapapeles',
  tocDisplayingFirstValues: 'Mostrando los primeros 100 valores. Use el filtro para ver más.',
  tocDuplicateWarningText: 'Se ha detectado una página duplicada en un capítulo. El duplicado se ha eliminado.',
  tocEmptyMessage: 'Parece que todavía no ha creado ningún Índice',
  toGetStarted: 'para comenzar',
  tocMissingChapterName: 'Falta el Nombre del Capítulo',
  tocModalTableCaption: 'Modal del Editor de Etiquetas',
  tocNothingSelected: 'No hay Nada Seleccionado',
  tocOpenEditor: 'Abrir Editor',
  tocSaveRequired: 'Es obligatorio guardar antes de editar la información del capítulo',
  tocState: 'Estado Inicial del Índice',
  tocThumbnailDeleteFailTitle: 'Fallo al Eliminar la Miniatura',
  tocThumbnailDeleteSuccessTitle: 'Miniatura Eliminada Correctamente',
  tocThumbnailUploadFailTitle: 'Fallo al Cargar la Miniatura',
  tocThumbnailUploadSuccessTitle: 'Miniatura Cargada Correctamente',
  tocTranslationMessage: 'El nombre y la descripción opcional de este capítulo',
  tocUntitledPage: 'Página sin Título',
  tooManySelections: 'La lista supera el límite de selección de {limit}',
  togglePage: 'Alternar Página',
  top: 'Parte Superior',
  total: 'Total',
  totalItems: 'Elementos Totales',
  translation: 'Traducción | Traducciones',
  translationHelpTextItem: 'El nombre y la descripción opcional de este {item}',
  true: 'Verdadero',
  tryAgain: 'Por favor, inténtelo de nuevo.',
  tue: 'Mar',
  turkishDescription: 'Descripción en Turco',
  turkishName: 'Nombre en Turco',
  twoSided: 'Dos caras',
  type: 'Tipo | Tipos',
  typeAtLeastTwoChars: 'Escriba al menos 2 caracteres para ver una lista de coincidencias.',
  typeToConfirm: 'Tipo {value} que se debe confirmar',
  typography: 'Tipografía',
  ukraineDescription: 'Descripción en Ucraniano',
  ukraineName: 'Nombre en Ucraniano',
  unableToRetrieveResults: 'No se han podido encontrar resultados para su búsqueda',
  unauthorized: 'No autorizado',
  unknownError: 'Error desconocido. Póngase en contacto con el Centro de Asistencia de Documoto en support{\'@\'}documoto.com para recibir ayuda.',
  unknownPart: 'Pieza Desconocida',
  unknownType: 'Tipo desconocido',
  unsavedAPIKeyWarning: 'Su Clave de API se perderá si no Vuelve atrás',
  unsavedChanges: 'Hay cambios que no ha guardado ¿Desea continuar?',
  unsavedChangesWarning: 'Perderá los cambios si no los guarda.',
  unsavedWidgetKeyWarning: 'Su Clave de API se perderá si no vuelve atrás.',
  unsupportedBrowserMessage: 'Esta aplicación se ha desarrollado con la tecnología más reciente, lo que la hace más rápida y fácil de usar. Lamentablemente, su navegador no admite esta tecnología.',
  uom: 'UM',
  update: 'Actualizar',
  updateFailed: 'Fallo al actualizar',
  updateIndex: 'Actualizar Índice',
  updatePassword: 'Actualizar Contraseña',
  updatePasswordMessage: 'Contraseña actualizada correctamente',
  updatePasswordPending: 'Pendiente de guardar el cambio de contraseña.',
  updated: 'Actualizado',
  upgradeBrowser: 'Por favor actualizar su navegador.',
  upload: 'Subir',
  uploadComplete: 'Carga Terminada',
  uploadInProgress: 'Carga en Curso',
  uponSavingDisabledTenant: 'Al guardar esta página con esta configuración habilitada, ocurrirá lo siguiente:',
  uponSavingThisLicense: 'Al guardar esta página con esta configuración, DEBE HACER LO SIGUIENTE:',
  upperBound: 'Límite Superior',
  untitledOrg: 'Organización sin Título',
  usCurrency: 'USD',
  useDefaultCartColumns: 'Usar las Columnas del Carro Predeterminadas',
  useDefaultPartsListColumns: 'Usar las Columnas de la Lista de Piezas Predeterminadas',
  user: 'Usuario',
  userAddress: 'Direcciones de Usuarios | Direcciones de Usuarios',
  userCount: 'Recuento de Usuarios',
  userEditable: 'Editable por el Usuario',
  userExpirationDate: 'Fecha de Vencimiento del Usuario',
  userExpirationDateInvalid: 'Se debe introducir una Fecha de Vencimiento del Usuario',
  userGroup: 'Grupo de Usuarios | Grupos de Usuarios',
  userGroupEmpty: 'Grupo de Usuarios no puede estar vacío',
  userGroupType: 'Tipo de Grupo de Usuarios',
  userGroupUniqueMessage: 'Este nombre debe ser único con respecto a los demás nombres de Grupos de Usuarios de este propietario',
  userGroupUsers: 'Usuarios del Grupo de Usuarios',
  username: 'Nombre de Usuario',
  usernameEmpty: 'Debe añadir un nombre de usuario',
  users: 'Usuarios',
  usersWillReceiveAnUnauthorizedError: 'Los usuarios recibirán un error de "No autorizado" cuando intenten iniciar sesión en el propietario.',
  useSniForSsoMetadataRetrieval: 'Utilizar SNI para la recuperación de metadatos SSO',
  value: 'Valor',
  validate: 'Validar',
  validateTenantDirectories: {
    buttonLabel: 'Validar directorios de contenido',
    error: 'Error al validar directorios',
    success: 'Directorios de propietarios validados'
  },
  variantSKU: 'SKU Variable',
  validationMessage: 'Mensaje de Validación | Mensajes de Validación',
  valueGteError: 'El valor debe ser inferior o igual a {max}',
  valueLteError: 'El valor debe ser superior o igual a {min}',
  vendorName: 'Nombre del Proveedor',
  verifyPassword: 'Verificar la Contraseña',
  vertical: 'Vertical',
  version: 'Versión',
  video: 'Vídeo | Vídeos',
  view: 'Ver',
  viewAll: 'Ver Todo',
  viewAllPagesAndBooks: 'Ver: Todos los Libros y todas las Páginas',
  viewAllResults: 'Ver Todos los Resultados',
  viewBooks: 'Ver: Libros',
  viewMore: 'Ver Más',
  viewPages: 'Ver: Páginas',
  viewModeList: 'Vista de la Lista',
  viewModeTable: 'Vista de Tabla',
  visibility: 'Visibilidad',
  visibilityDescription: 'Quién puede ver esta {entity}',
  visible: 'Visible',
  waitForUpload: 'Por favor, espere a que termine la carga.',
  watermark: 'Marca de Agua',
  watermarkFont: 'Fuente de Marca de Agua',
  watermarkOpacityPercentage: '% de Opacidad de Marca de Agua',
  watermarkOrientation: 'Orientación de Marca de Agua',
  watermarkSaved: 'Marca de Agua guardada',
  watermarkSaveFailed: 'Error al guardar marca de agua.',
  watermarkSelectionRequired: 'Es obligatorio seleccionar Nombre de usuario o Texto.',
  watermarkText: 'Texto de Marca de Agua',
  wed: 'Miér',
  whereUsed: 'Donde se Utilice',
  whoCanViewComment: '¿Quién puede ver este comentario?',
  wholesale: 'Venta Al Por Mayor',
  wholesalePrice: 'Precio Al Por Mayor',
  wholesaleTotal: 'Total Venta Al Por Mayor',
  widget: 'Widget',
  widgetAuthHeader: 'Valor de Autorización de Widget',
  widgetKey: 'Clave de Widget | Claves de Widget',
  widgetKeyAdmin: 'Administrador de Claves de Widget',
  widgetKeyCopied: 'Valor de Autorización de Widget copiado en el Portapapeles',
  widgetKeyDeleted: 'La Clave de Widget se ha Eliminado',
  widgetKeyDeletedError: 'Error al eliminar la Clave de Widget',
  widgetKeyDeleteMsg: 'Al eliminar la Clave de Widget, se deshabilitará su uso',
  widgetKeyManager: 'Administrador de la Clave de Widget',
  widgetKeyNameEmpty: 'El nombre debe ser único y es obligatorio. Este valor también se utilizará en los informes y registros como nombre de usuario para cualquier actividad del Widget.',
  widgetKeyRegenError: 'Error al regenerar la Clave de Widget',
  widgetKeyRegenMsg: 'Al Regenerar esta Clave de Widget, es posible que se deshabiliten las integraciones existentes',
  widgetKeyRegenTitle: '¿Seguro que desea volver a generar esta Clave de Widget?',
  widgetKeySaved: 'La Clave de Widget se ha Guardado',
  widgetPermissions: 'Los usuarios de Widgets son de solo lectura, y el acceso a las funciones se gestiona mediante la configuración a nivel de Widget. Los permisos estándar de grupo de usuarios no se aplican a los usuarios de Widget. Los privilegios de acceso a la Categoría de Medios se admiten. Proceda a asignar controles de acceso de categoría de medios.',
  widgetRefreshErrorMsg: "Por favor, actualice su página para ver el contenido solicitado",
  widgetUserGroup: 'Grupo de Usuarios del Widget',
  widgetUserGroupDeleteMsg: 'No se puede eliminar el grupo Widget con usuarios',
  willBeAffected: 'se verá afectado.',
  willBeDeleted: '{content} se eliminará.',
  willBeInsertedIntoItem: '{name} se insertará en {item}',
  willBeUpdated: 'se actualizará.',
  zipCode: 'Código Postal',
  zoomHotpointToCanvas: 'Agrandar el Punto Caliente al Tamaño de Canvas',
  zoomIn: 'Acercar',
  zoomOut: 'Alejar'
}
/* eslint-enable max-len */
