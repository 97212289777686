import './checks'
import { createApp } from 'vue'
import Notifications from '@kyvg/vue3-notification'
import localforage from 'localforage'
import VueDraggableResizable from 'vue-draggable-resizable'
import { checkForCookieAndLocalStorage } from '@/checks'

import { store } from './store'
import router from './router'
import i18n from './locales'

import localStoragePlugin from './plugins/localStoragePlugin'
import wait from './plugins/wait'
import rest from './plugins/rest'
import globalFilters from './plugins/globalFilters'
import indeterminate from './plugins/indeterminate'

import './plugins/buefy'
import BButton from "./buefy/src/components/button/Button.vue"
import BInput from "./buefy/src/components/input/Input.vue"
import BNumberInput from "./buefy/src/components/numberinput/Numberinput.vue"
import BIcon from "./buefy/src/components/icon/Icon.vue"
import BSwitch from "./buefy/src/components/switch/Switch.vue"
import BSelect from "./buefy/src/components/select/Select.vue"
import BRadio from "./buefy/src/components/radio/Radio.vue"
import BCheckbox from "./buefy/src/components/checkbox/Checkbox.vue"
import BTag from "./buefy/src/components/tag/Tag.vue"
import BTagList from "./buefy/src/components/tag/Taglist.vue"
import BTagInput from "./buefy/src/components/taginput/Taginput.vue"
import BTable from "./buefy/src/components/table/Table.vue"
import BTableColumn from "./buefy/src/components/table/TableColumn.vue"
import BTooltip from "./buefy/src/components/tooltip/Tooltip.vue"
import BDropdown from "./buefy/src/components/dropdown/Dropdown.vue"
import BDropdownItem from "./buefy/src/components/dropdown/DropdownItem.vue"
import BField from "./buefy/src/components/field/Field.vue"
// import Skeleton from "./buefy/src/components/skeleton/Skeleton.vue"
import BNotification from "./buefy/src/components/notification/Notification.vue"
import BAutocomplete from "./buefy/src/components/autocomplete/Autocomplete.vue"
import BSidebar from "./buefy/src/components/sidebar/Sidebar.vue"
import BProgress from "./buefy/src/components/progress/Progress.vue"
import BRadioButton from "./buefy/src/components/radio/RadioButton.vue"
import BModal from "./buefy/src/components/modal/Modal.vue"
import BDatepicker from "./buefy/src/components/datepicker/Datepicker.vue"
import BDatepickerMonth from "./buefy/src/components/datepicker/DatepickerMonth.vue"
import BDatepickerTable from "./buefy/src/components/datepicker/DatepickerTable.vue"
import BDatepickerTableRow from "./buefy/src/components/datepicker/DatepickerTableRow.vue"
import BNavbar from "./buefy/src/components/navbar/Navbar.vue"
import BNavbarItem from "./buefy/src/components/navbar/NavbarItem.vue"
import BNavbarDropdown from "./buefy/src/components/navbar/NavbarDropdown.vue"
import BTabs from "./buefy/src/components/tabs/Tabs.vue"
import BTabItem from "./buefy/src/components/tabs/TabItem.vue"

import App from './App.vue'
import './main.scss'
import WidgetApp from '@/components/widgets/app/WidgetApp.vue'
import { vDClickOutside, vDMouseout } from '@/helpers'

const isWidget = window.location.search.includes('widget')
if (!isWidget) {
  checkForCookieAndLocalStorage()
}
//@ts-ignore
const app = createApp(isWidget ? WidgetApp : App)

const { hostname } = window.location
const isLocalhost = hostname === 'localhost'
const isQA = (hostname.includes('.digabit.com') && !hostname.includes('documoto.digabit.com'))
const isInt = (hostname.includes('.documoto.com') && !hostname.includes('.app.documoto.com'))

if (isLocalhost || isQA || isInt) {
  // @ts-ignore
  window.__VUE_DEVTOOLS_GLOBAL_HOOK__?.enabled = true
}

app.component('VueDraggableResizable', VueDraggableResizable)

app.component('b-button', BButton)
app.component('b-input', BInput)
app.component('b-numberinput', BNumberInput)
app.component('b-icon', BIcon)
app.component('b-switch', BSwitch)
app.component('b-select', BSelect)
app.component('b-radio', BRadio)
app.component('b-checkbox', BCheckbox)
app.component('b-tag', BTag)
app.component('b-taglist', BTagList)
app.component('b-taginput', BTagInput)
app.component('b-table', BTable)
//@ts-ignore
app.component('b-table-column', BTableColumn)
app.component('b-tooltip', BTooltip)
app.directive('b-dropdown', BDropdown)
app.component('b-dropdown-item', BDropdownItem)
// app.component('b-field', BField)
// app.component('b-skeleton', Skeleton)
app.component('b-notification', BNotification)
app.component('b-autocomplete', BAutocomplete)
app.component('b-sidebar', BSidebar)
app.component('b-progress', BProgress)
app.component('b-radio-button', BRadioButton)
app.directive('b-modal', BModal)
app.component('b-datepicker', BDatepicker)
app.component('b-datepicker-table', BDatepickerTable)
app.component('b-datepicker-table-row', BDatepickerTableRow)
app.component('b-datepicker-month', BDatepickerMonth)
//@ts-ignore
app.component('b-navbar', BNavbar)
app.component('b-navbar-item', BNavbarItem)
//@ts-ignore
app.component('b-navbar-dropdown', BNavbarDropdown)
app.component('b-tabs', BTabs)
app.component('b-tab-item', BTabItem)
app.directive('dClickOutside', vDClickOutside)
app.directive('dMouseOut', vDMouseout)
import './routeGuards'

localforage.config({
  name: 'documoto'
})

/* eslint-disable no-new */
app.use(rest)
app.use(wait)
app.use(localStoragePlugin)
app.use(globalFilters)
app.use(indeterminate)
app.use(Notifications)
//@ts-ignore
app.use(store)
//@ts-ignore
app.use(router)
//@ts-ignore
app.use(i18n)
app.config.globalProperties.$store = store
app.config.globalProperties.$t = i18n.global.t
app.config.globalProperties.$tc = i18n.global.tc

router.isReady().then(() => {
  app.mount('#app')
})

window.addEventListener("DOMContentLoaded", e => {
  // Asterisk is acceptable here because no
  // confidential data are being passed
  window.parent.postMessage({ action: "init" }, "*")
  window.removeEventListener("DOMContentLoaded", () => null)
})

window.addEventListener("message", async e => {
  const isAuthenticating = store.state.auth.authenticating
    || store.getters['auth/isAuthenticated']
  const isWidgetRequest = e.data?.mode === "WIDGET_MODE"
  if (isAuthenticating || !isWidgetRequest) return

  // Initiate Widget login & set-up calls
  await store.dispatch("auth/loginWidget", e)
})
