<template>
  <label  class="radio">
    <input  v-model="radioValue"
            type="radio"
            :value="nativeValue"/>
    <span class="check" />
    <span class="ml-2">
      <slot />
    </span>
  </label>
</template>

<script setup lang="ts">
import { computed } from "vue"
const props = defineProps({
  value: {
    type: String,
    required: true
  },
  nativeValue: {
    type: String,
    required: true
  }
})

const emit = defineEmits(['update:value'])

const radioValue = computed({
  get: () => props.value,
  set: updatedValue => { emit('update:value', updatedValue) }
})
</script>

<style scoped lang="scss">
.radio {
  outline: none;
  display: inline-flex;
  align-items: center;
  &:not(.button) {
      margin-right: 0.5em;
      & + .radio:last-child {
          margin-right: 0;
      }
  }
  input[type=radio] {
      position: absolute;
      left: 0;
      opacity: 0;
      outline: none;
      z-index: -1;
      + .check {
          display: flex;
          flex-shrink: 0;
          position: relative;
          cursor: pointer;
          width: 1.25em;
          height: 1.25em;
          transition: background 150ms ease-out;
          border-radius: 50%;
          border: 2px solid var(--grey);
          &:before {
              content: "";
              display: flex;
              position: absolute;
              left: 50%;
              margin-left: calc(-1.25em / 2);
              bottom: 50%;
              margin-bottom: calc(-1.25em / 2);
              width: 1.25em;
              height: 1.25em;
              transition: transform 150ms ease-out;
              border-radius: 50%;
              transform: scale(0);
              background-color: var(--primary);
          }
      }
      &:checked + .check {
          border-color: var(--primary);
          &:before {
              transform: scale(.5);
          }
      }
      &:focus {
          + .check {
              box-shadow: 0 0 0.5em rgba(var(--grey), 0.8);
          }
          &:checked + .check {
              box-shadow: 0 0 0.5em rgba(var(--primary), 0.8);
          }
      }
    } 
    &:hover {
      input[type=radio]:not(:disabled) + .check {
          border-color: var(--primary);
      }
    }
  }

.file-input {
  display: none;
}
</style>