<template>
  <component v-if="!isLoading && !isMounting"
             :is="componentInView"
             :key="media.id"
             class="widget-media-viewer"/>
</template>

<script setup>
import { computed, ref, watch } from 'vue'
import { useStore } from "vuex"
import { useFetch } from '@/components/widgets/composables/useFetchContent'
import { useStaticContent } from "@/components/widgets/composables/useStaticContent.js"
import { useWidgetNavigator } from "@/components/widgets/composables/useWidgetNavigator"
import { useSearch } from '@/components/widgets/composables/useSearch'
import WidgetBook from "@/components/widgets/components/WidgetBook"
import { WidgetMediaTypes } from '@/interfaces/global/widgets'

const { media } = useWidgetNavigator()
const { componentInViewer } = useStaticContent()
const componentInView = computed(() => {
  return (media.value.contentType === WidgetMediaTypes.book)
    ? WidgetBook
    : componentInViewer.value
})

const store = useStore()
const isMounting = ref(true)
const { fetchDecorator, isLoading } = useFetch()
const { logSearchClickThrough } = useSearch()

async function getMediaForVuex() {
  const params = {
    id: media.value.id.toString(),
    type: media.value.contentType
  }
  await store.dispatch("content/getContent", params)
}

/**
 * Widgets use a custom navigation. Each content with their own viewer types.
 * If the content type remains the same after a navigation, the component may
 * not rebuild. Hence, watchers are in-place.
 */
watch(
  () => media.value.id,
  async () => {
    // Vuex race condition necessitates isMounting local state
    await fetchDecorator(getMediaForVuex)()
    await logSearchClickThrough()
    isMounting.value = false
  },
  { immediate: true }
)
</script>
