<template>
  <section class="section">
    <a v-if="isWidget"
       @click="customDownload(content?.contentUrl)">
      <thumbnail class="is-128x128"
                 data-int="download-thumbnail"
                 :thumbnail-type="entityType"
                 :thumbnail-url="content.thumbnailUrl" />
      <label class="is-size-5 has-text-centered">{{ $t('clickToDownload') }}</label>
    </a>
    <a v-else
       download
       :href="content?.contentUrl">
      <thumbnail class="is-128x128"
                 data-int="download-thumbnail"
                 :thumbnail-type="entityType"
                 :thumbnail-url="content.thumbnailUrl" />
      <label class="is-size-5 has-text-centered">{{ $t('clickToDownload') }}</label>
    </a>
  </section>
</template>

<script setup lang="ts">
import Thumbnail from '@/components/common/Thumbnail.vue'
import { computed } from 'vue'
import { customDownload } from '@/helpers/customDownload'
import { useStore } from 'vuex'

const store = useStore()
const content = computed(() => store.state.content)

const entityType = computed(() => content.value?.mediaType || content.value?.entityType)
const isWidget = store.getters['widgets/isWidget']
</script>

<style scoped>
.section {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
}
.is-128x128 {
  width: initial;
}
</style>
