<template>
  <div class="detail-column">
    <template v-for="(value, key) in columnInfo"
              :key="key">
      <div v-if="componentAvailable(value)"
           :id="value"
           class="card detail-card column-item"
           :class="{'where-used-card': value === 'WhereUsed'}"
      >
        <div v-if="titleMap[value] !== ''"
             class="card-header-title">
          {{ $t(titleMap[value]) }}
        </div>
        <component :is="componentMap[value]"
                   :id="castIdToNumber(content.id)"
                   :content="content"
                   :type="content.type"
                   :showTags="false"
                   :height="componentHeight(value)"></component>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue"
import { useStore } from "vuex"
import {EntityType} from "@/interfaces/global";
import InfoWrapper from "@/components/library/content/info/InfoWrapper.vue";
import InfoTags from "@/components/library/content/info/tags/InfoTags.vue";
import WhereUsed from "@/components/library/content/whereused/WhereUsed.vue";
import Related from "@/components/library/content/related/Related.vue";
import Comments from "@/components/library/content/comments/Comments.vue";
import Suggestions from "@/components/library/content/suggestions/Suggestions.vue";
import { useBreakpoints } from "@/helpers"
import { DetailPageComponentTitles } from "@/interfaces/library/detailLayouts";
import i18n from "@/locales";

const props = defineProps({
  content: {
    type: Object,
    required: true
  },
  columnInfo: {
    type: Object,
    required: true
  }
})
const { width } = useBreakpoints()
const isPart = computed(() => props.content.type === EntityType.part)
const isPage = computed(() => props.content.type === EntityType.page)
const store = useStore()
const canViewComments =  computed(() => store.getters['user/canViewComments'])
const canViewRelated = computed(() => store.getters['user/canViewRelated'])
const canViewWhereUsed = computed(() => isPart.value || isPage.value)
const canViewSuggestions = computed(() => {
  return store.getters['user/hasPartOrderSuggestions'] && isPart.value
})
const isMobileView = computed(() => width.value <= 768)
function castIdToNumber (id: string) {
  return parseInt(id)
}

function componentAvailable (componentName: String): boolean {
  switch (componentName) {
    case 'InfoWrapper':
      return true
      break;
    case 'InfoTags':
      return true
      break;
    case 'WhereUsed':
      return canViewWhereUsed.value
      break;
    case 'Related':
      return canViewRelated.value
      break;
    case 'Comments':
      return canViewComments.value
      break;
    case 'Suggestions':
      return canViewSuggestions.value
    default:
      return false
  }
}

function componentHeight (componentName: String): string {
  if (componentName === 'WhereUsed' && !isMobileView.value) { return '90vh' }
  else { return '60vh'}
}

const componentMap: Record<string, any> = {
  'InfoWrapper': InfoWrapper,
  'InfoTags': InfoTags,
  'WhereUsed': WhereUsed,
  'Related': Related,
  'Comments': Comments,
  'Suggestions': Suggestions
}

const titleMap: Object = {
  'InfoWrapper': '',
  'InfoTags': i18n.global.tc('detail', 2),
  'WhereUsed': i18n.global.t('whereUsed'),
  'Related': i18n.global.t('related'),
  'Comments': i18n.global.tc('comment', 2),
  'Suggestions': i18n.global.tc('suggestion', 2)
}
</script>

<style scoped lang="scss">
.card {
  padding: .5em;
  margin: 1em;
  max-height: 60vh;
  min-height: 20vh;
  overflow-y: scroll;

  &.no-scroll {
    overflow-y: hidden;
  }
}

.where-used-card {
  @media only screen and (min-width: 768px) {
    max-height: 90vh;
  }
}

.card:first-of-type {
  .column-item:first-of-type {
    margin-top: 0;
  }
}
</style>
