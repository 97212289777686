<template>
  <Details  v-if="!isLoading && !isMounting"
            style="overflow-y: scroll;"/>
</template>

<script setup>
import { onMounted, onUnmounted, ref, watch } from 'vue'
import { useInfoLandingPage } from '@/components/widgets/composables/useInfoLandingPage'
import { useWidgetNavigator } from '@/components/widgets/composables/useWidgetNavigator'
import { useFetch } from '@/components/widgets/composables/useFetchContent'
import { useSearch } from '@/components/widgets/composables/useSearch'
import Details from '@/components/library/content/details/Details.vue'

const {
  hasInfoPageOpen,
  setHasInfoPageOpen,
  syncPartInfoWithVuex,
  closeInfoPage } = useInfoLandingPage()
const { part } = useWidgetNavigator()
const { isLoading } = useFetch()
const isMounting = ref(true)
const { logSearchClickThrough } = useSearch()

onMounted(async() => {
  if (!hasInfoPageOpen.value) {
    setHasInfoPageOpen(true)
  }
  if (!!part.value) {
    await syncPartInfoWithVuex()
    await logSearchClickThrough()
  }
})
onUnmounted(() => {
  if (!!hasInfoPageOpen.value) {
    closeInfoPage()
  }
})
watch(
  () => part.value,
  async () => {
    isMounting.value = true
    await syncPartInfoWithVuex()
    //state is cleared after a logging action so there should be no re-log of a click through
    //keeping here to ensure all paths covered on entering info page
    await logSearchClickThrough()
    isMounting.value = false
  },
  { immediate: true }
)
</script>
