<template>
  <div class="pdf-container is-full-height"
       id="pspdf-engine-mount"
       @fullscreenchange="toggleToolbar" />
</template>

<script setup lang="ts">
import PSPDFKit, { Instance, ToolbarItem } from 'pspdfkit'
import { computed, onBeforeUnmount, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { notify } from '@kyvg/vue3-notification'
import i18n from '@/locales'

const route = useRoute()
const store = useStore()
const pspdfDocumentData = computed(() => store.getters['content/pspdfDocumentData'])

let pspdfInstance: Instance|null = null

const hasDisabledPrinting =  store.getters['user/hasDisablePrinting'] ||
  store.state.content?.disableExport || store.state.book?.disableExport

function determineDesktopToolbarItems () {
  let toolbarItems: ToolbarItem[] = [
    { type: 'sidebar-thumbnails' },
    { type: 'sidebar-document-outline' },
    { type: 'pager' },
    { type: 'pan' },
    { type: 'zoom-out' },
    { type: 'zoom-in' },
    { type: 'zoom-mode' },
    {
      type: 'custom',
      icon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M448 344v112a23.9 23.9 0 0 1 -24 24H312c-21.4 0-32.1-25.9-17-41l36.2-36.2L224 295.6 116.8 402.9 153 439c15.1 15.1 4.4 41-17 41H24a23.9 23.9 0 0 1 -24-24V344c0-21.4 25.9-32.1 41-17l36.2 36.2L184.5 256 77.2 148.7 41 185c-15.1 15.1-41 4.4-41-17V56a23.9 23.9 0 0 1 24-24h112c21.4 0 32.1 25.9 17 41l-36.2 36.2L224 216.4l107.2-107.3L295 73c-15.1-15.1-4.4-41 17-41h112a23.9 23.9 0 0 1 24 24v112c0 21.4-25.9 32.1-41 17l-36.2-36.2L263.5 256l107.3 107.3L407 327.1c15.1-15.2 41-4.5 41 16.9z"/></svg>',
      onPress: () => {
        const element = document.getElementById('pspdf-engine-mount')

        if (element?.requestFullscreen) {
          element.requestFullscreen()
        }
      }
    },
    { type: 'spacer' },
    { type: 'search' }
  ]

  if (hasDisabledPrinting) {
    toolbarItems.push({type: 'debug'})
  } else {
    toolbarItems.push({type: 'print'})
    toolbarItems.push({type: 'debug'})
  }

  return toolbarItems
}

const desktopToolbarItems: ToolbarItem[] = determineDesktopToolbarItems()
const fullScreenToolbarItems: ToolbarItem[] = [
  { type: 'spacer' },
  { type: 'zoom-out' },
  { type: 'zoom-in' },
  {
    type: 'custom',
    icon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M200 288H88c-21.4 0-32.1 25.8-17 41l32.9 31-99.2 99.3c-6.2 6.2-6.2 16.4 0 22.6l25.4 25.4c6.2 6.2 16.4 6.2 22.6 0L152 408l31.1 33c15.1 15.1 40.9 4.4 40.9-17V312c0-13.3-10.7-24-24-24zm112-64h112c21.4 0 32.1-25.9 17-41l-33-31 99.3-99.3c6.2-6.2 6.2-16.4 0-22.6L481.9 4.7c-6.2-6.2-16.4-6.2-22.6 0L360 104l-31.1-33C313.8 55.9 288 66.6 288 88v112c0 13.3 10.7 24 24 24zm96 136l33-31.1c15.1-15.1 4.4-40.9-17-40.9H312c-13.3 0-24 10.7-24 24v112c0 21.4 25.9 32.1 41 17l31-32.9 99.3 99.3c6.2 6.2 16.4 6.2 22.6 0l25.4-25.4c6.2-6.2 6.2-16.4 0-22.6L408 360zM183 71.1L152 104 52.7 4.7c-6.2-6.2-16.4-6.2-22.6 0L4.7 30.1c-6.2 6.2-6.2 16.4 0 22.6L104 152l-33 31.1C55.9 198.2 66.6 224 88 224h112c13.3 0 24-10.7 24-24V88c0-21.3-25.9-32-41-16.9z"/></svg>',
    onPress: () => {
      // Common path
      if (document.fullscreenElement && document.exitFullscreen) {
        document.exitFullscreen()
      }
    }
  },
  { type: 'spacer' }
]

function toggleToolbar() {
  if (document.fullscreenElement) {
    pspdfInstance?.setToolbarItems(fullScreenToolbarItems)
  } else {
    pspdfInstance?.setToolbarItems(desktopToolbarItems)
  }
}

onMounted(async () => {
  try {
    await PSPDFKit.load({
      container: '#pspdf-engine-mount',
      documentId: pspdfDocumentData.value.documentId,
      serverUrl: `${window.location.origin}/document-engine/`,
      styleSheets: ['/api/styles/user.css'],
      authPayload: { jwt: pspdfDocumentData.value.jwt },
      instant: false,
      toolbarItems: desktopToolbarItems
    }).then(async (instance) => {
      let query: null|string
      query = store.state.content.toc.query || route.query.q || store.state.search.filters.query

      if (!!query) {
        const trimmedQuery = query.trim().replace(
          /^"(.*)"$/g,
          '$1'
        )
        instance.startUISearch(trimmedQuery)
      }

      pspdfInstance = instance
    })
  } catch (e) {
    console.error(e)
    notify({
      text: i18n.global.t('failedToLoad', { content: i18n.global.tc('document', 1) }),
      type: 'error'
    })
  }
})
onBeforeUnmount(async () => {
  PSPDFKit.unload('#pspdf-engine-mount')
})

</script>

<style scoped lang="scss">
.spinner {
  height: 100%;

  h3 {
    padding-top: 20%;
    width: 50%;
    margin: 0 auto;
    text-align: center;
  }
}
</style>
