<template>
  <div
    v-on-clickaway="closeMenu"
    class="dropdown is-right"
    :class="{ 'is-active': isMenuOpened }"
  >
    <button class="dropdown-trigger button"
            data-unit="actions-dropdown"
            @click="isMenuOpened = true">
      <span>{{ $tc('action', 2) }}</span>
      <span class="icon is-small">
        <i  class="fas fa-angle-down"
            aria-hidden="true" />
      </span>
    </button>
    <div  :class="{ 'is-active': isMenuOpened }"
          class="modal">
      <div  class="modal-background" 
            @click="isMenuOpened = false"/>
      <div  class="modal-card">
        <section  class="modal-card-body m-4 is-flex-direction-column"
                  style="border-radius: 4px;">
          <div  role="listitem"
                class="my-3">
            <router-link  :to="{ name: 'LibrarySharedCarts' }"
                          class="has-text-dark">
              <span class="actions-icon fas fa-shopping-cart"/>
              {{ $t('manageItem', {item: $tc('sharedCart', 2)}) }}
            </router-link>
          </div>
          <cart-mobile-action v-if="isSavedCartEnabled"
                              :text="$t('open')"
                              icon="fa-folder-open"
                              @click="viewCarts"/>
          <cart-mobile-action v-if="isSavedCartEnabled"
                              :text="$t('save')"
                              icon="fa-save"
                              @click="$refs.saveCartModal.open()"/>
          <cart-mobile-action :disabled="!hasItems"
                              :text="$t('email')"
                              icon="fa-envelope"
                              @click="openEmailOptionsModal"/>
          <cart-mobile-action v-if="isSavedCartEnabled">
            <label class="file-label">
              <input  type="file"
                      accept="text/csv"
                      class="file-input"
                      id="cart-import-file-input"
                      @input="importCart">
              <span class="actions-icon fas fa-upload" />
              {{ $t('import') }}
            </label>
          </cart-mobile-action>
          <cart-mobile-action   :disabled="!hasItems"
                                :text="$t('export')"
                                icon="fa-download"
                                @click="openExportOptionsModal"/>
          <cart-mobile-action   :disabled="!hasItems"
                                :text="$t('clear')"
                                icon="fa-eraser"
                                @click="clearCart"/>
        </section>
      </div>
    </div>
    <div class="actions-buttons buttons mr-1">
      <router-link  v-if="showSharedCartBtn"
                    :to="{ name: 'LibrarySharedCarts' }"
                    target="_blank"
                    class="mr-2">
        <bulma-button-icon  :text="$t('manageItem', 
                              { item: $tc('sharedCart', 2) })"
                            icon-class="fas fa-shopping-cart"/>
      </router-link>
      <bulma-button-icon  v-if="isSavedCartEnabled"
                          :text="$t('open')"
                          icon-class="fas fa-folder-open"
                          @click="viewCarts"/>
      <bulma-button-icon  v-if="isSavedCartEnabled"
                          :disabled="!hasItems"
                          :text="$t('save')"
                          icon-class="fas fa-save"
                          @click="$refs.saveCartModal.open()"/>
      <bulma-button-icon  :disabled="!hasItems"
                          :text="$t('email')"
                          icon-class="fas fa-envelope"
                          @click="openEmailOptionsModal"/>
      <button v-if="isSavedCartEnabled"
              class="button">
        <bulma-icon iconClass="fas fa-upload"/>
        <span>
          <label class="file-label">
          <input  type="file"
                  accept="text/csv"
                  class="file-input"
                  id="cart-import-file-input"
                  @input="importCart">
            {{ $t('import') }}
        </label>
        </span>
      </button>
      <bulma-button-icon  :disabled="!hasItems"
                          :text="$t('export')"
                          icon-class="fas fa-download"
                          @click="openExportOptionsModal"/>
      <bulma-button-icon  v-if="isSavedCartEnabled || isSharedCartEnabled"
                          :disabled="!hasItems"
                          :text="$t('clear')"
                          icon-class="fas fa-eraser"
                          @click="clearCart"/>
    </div>

    <save-cart-modal ref="saveCartModal" />
    <open-cart-modal ref="openCartModal" />

    <cart-merge-override-modal  ref="mergeOverrideModal"
                                @openClear="importConfirm"/>
    <delete-modal v-if="clearCartModalOpened"
                  :item="items"
                  @deleteConfirm="clearConfirm"
                  @cancel="clearCartModalOpened = false"/>
    <bulma-submit-modal :is-active="isExportModalActive"
                        :is-loading="isLoading"
                        :can-submit="canSubmitExport"
                        @close="isExportModalActive = false"
                        @cancel="isExportModalActive = false"
                        @submit="exportSelected">
      <template #title>
        {{ $t('export') }}
      </template>
      <template #content>
        <label class="label is-size-6 mt-4">
          {{ $t('format') }}
        </label>
        <div class="field">
          <bulma-radio  :value="exportFormat"
                        native-value="csv"
                        @update:value="exportFormat = $event">
                        {{ $t('exportAsCsv') }}
          </bulma-radio>
        </div>
        <cart-columns-buttons v-show="exportFormat === 'csv'"
                              :isBasicExport="isBasicExport"
                              :disabled="exportFormat !== 'csv'"
                              @update:isBasicExport="isBasicExport = $event">
          <template #leftButtonTextSpan>
            <span>{{ $t('exportDisplayedCartColumns') }}</span>
          </template>
          <template #rightButtonTextSpan>
            <span>{{ $t('exportBasicPartInfo') }}</span>
          </template>
        </cart-columns-buttons>
        <div class="field">
          <bulma-radio  :value="exportFormat"
                        native-value="xlsx"
                        @update:value="exportFormat = $event">
            {{ $t('exportAsXlsx') }}
          </bulma-radio>
        </div>
      </template>
    </bulma-submit-modal>
    <bulma-submit-modal :is-active="isEmailModalActive"
                        :is-loading="isLoading"
                        :can-submit="canSubmitEmail"
                        @close="isEmailModalActive = false"
                        @cancel="isEmailModalActive = false"
                        @submit="submitEmail">
      <template #title>
        {{ $t('email') }}
      </template>
      <template #content>
        <label class="label is-size-6 mt-4">
          {{ $t('recipientOrRecipients') }}
        </label>
        <emails-list  v-model="emails"
                      :newAddress="newAddress"
                      :canSave="hasValidRecipientEmail"
                      :isLoading="isLoading"
                      class="mb-5"
                      @update:newAddress="newAddress = $event"
                      @update:canSave="hasValidRecipientEmail = $event"/>
        <label class="label is-size-6 mt-4">
          {{ $t('format') }}
        </label>
        <div class="field">
          <bulma-radio  :value="emailFormat"
                        native-value="csv"
                        @update:value="emailFormat = $event">
                        {{ $t('emailAsCsv') }}
          </bulma-radio>
        </div>
        <cart-columns-buttons v-show="emailFormat === 'csv'"
                              :isBasicExport="isBasicEmail"
                              :disabled="emailFormat !== 'csv'"
                              @update:isBasicExport="isBasicEmail = $event">
          <template #leftButtonTextSpan>
            <span>{{ $t('emailDisplayedCartColumns') }}</span>
          </template>
          <template #rightButtonTextSpan>
            <span>{{ $t('emailBasicPartInfo') }}</span>
          </template>
        </cart-columns-buttons> 
        <div class="field mb-5">
          <bulma-radio  :value="emailFormat"
                        native-value="xlsx"
                        @update:value="emailFormat = $event">
            {{ $t('emailAsXlsx') }}
          </bulma-radio>
        </div>
        <label class="label is-size-6 mt-4">
          {{ $t('emailDetails') }}
        </label>
        <bulma-field  :message="senderEmailDangerText"
                      :message-class="yourEmailStyleType">
          <label>{{ $t('yourEmailAddress') }}</label>
          <bulma-input  :style-type="yourEmailStyleType"
                        :value="yourEmail"
                        @input="yourEmail = $event"/>
        </bulma-field>
        <div class="field">
          <label>{{ $t('emailSubject') }}</label>

          <bulma-input  :value="emailSubject"
                        @input="emailSubject = $event"/>
        </div>
        <div class="field mb-4">
          <label>{{ $t('emailComments') }}</label>
          <div  class="is-clearfix control"
                style="height: 100px;">
            <textarea v-model="emailComments" 
                      class="textarea-styling is-full-width full-height"
                      maxlength="4096"/>
          </div>
        </div>
      </template>
    </bulma-submit-modal>
  </div>
</template>

<script>
import DeleteModal from '@/components/common/DeleteModal'
import { directive as onClickaway } from 'vue3-click-away'
import CartMergeOverrideModal from './CartMergeOverrideModal'
import fileServices from '@/components/common/fileServices'
import OpenCartModal from './open/OpenCartModal'
import SaveCartModal from './save/SaveCartModal'
import SubmitModal from '@/components/common/modal/SubmitModal'
import { mapGetters } from 'vuex'
import EmailsList from './EmailsList'
import CartColumnsButtons from './CartColumnsButtons'
import { postError } from '@/helpers/notification'
import CartMobileAction from './CartMobileAction.vue'
import BulmaButtonIcon from '@/components/common/BulmaButtonIcon.vue'
import BulmaIcon from '@/components/common/BulmaIcon.vue'
import BulmaSubmitModal from '@/components/common/modal/BulmaSubmitModal.vue'
import BulmaRadio from '@/components/common/modal/BulmaRadio.vue'
import BulmaInput from '@/components/common/BulmaInput.vue'
import BulmaField from '@/components/common/BulmaField.vue'
import { getLocalStorage, setLocalStorage } from '@/helpers'
import { isEmailValid } from "@/helpers"

const EMAIL_FORMAT = 'cartEmailFormat'
const EMAIL_RECIPIENTS = 'cartEmailRecipients'
const IS_BASIC_EMAIL = 'isCartEmailBasic'
const CART_EMAIL_SENDER = "cartEmailSender"

export default {
  name: 'CartMenu',
  mixins: [fileServices],
  directives: {
    onClickaway
  },
  components: {
    CartMergeOverrideModal,
    DeleteModal,
    OpenCartModal,
    SaveCartModal,
    SubmitModal,
    EmailsList,
    CartColumnsButtons,
    CartMobileAction,
    BulmaButtonIcon,
    BulmaIcon,
    BulmaSubmitModal,
    BulmaRadio,
    BulmaInput,
    BulmaField
  },
  data () {
    return {
      exportFormat: null,
      clearCartModalOpened: false,
      itemToImport: false,
      isBasicExport: false,
      isLoading: false,
      isMenuOpened: false,
      emails: [],
      canSubmitExport: true,
      newAddress: "",
      isExportModalActive: false,
      isEmailModalActive: false,
      emailFormat: null,
      isBasicEmail: false,
      emailSubject: "",
      emailComments: "",
      hasValidRecipientEmail: true,
      yourEmail: ""
    }
  },
  watch: {
    exportFormat () { // Reset value when export format changes
      this.isBasicExport = false
    }
  },
  computed: {
    ...mapGetters({
      isSavedCartEnabled: 'user/isSavedCartEnabled',
      isSharedCartEnabled: 'user/isSharedCartEnabled',
      isSharedCartMaintainer: 'user/isSharedCartMaintainer',
      isEssentialsTenant: 'user/isEssentialsTenant'
    }),
    canSubmitEmail() {
      return this.hasValidRecipientEmail
        && this.hasValidSenderEmail
    },
    hasValidSenderEmail() {
      return isEmailValid(this.yourEmail)
    },
    hasItems () {
      return this.items.length > 0
    },
    items () {
      return this.$store.state.cart.items
    },
    mailToList() {
      return [this.newAddress]
        .concat(this.emails)
        .filter(it => isEmailValid(it))
    },
    senderEmailDangerText() {
      if (this.hasValidSenderEmail) {
        return ""
      } else if (this.yourEmail.length > 0) {
        return this.$t('invalidField', { field: this.$t('email') })
      }
      return this.$t('emailMessage')
    },
    showSharedCartBtn() {
      return this.isSavedCartEnabled 
        && this.isSharedCartEnabled
        && this.isSharedCartMaintainer
        && !this.isEssentialsTenant
    },
    yourEmailStyleType() {
      return this.hasValidSenderEmail ? "" : "is-danger"
    }
  },
  methods: {
    clearCart () {
      if (this.hasItems) {
        this.clearCartModalOpened = true
        this.closeMenu()
      }
    },
    clearConfirm () {
      this.clearCartModalOpened = false
      this.$store.dispatch('cart/clearCart')
    },
    async emailCart() {
      try {
        await this.$rest.post(`cart/email`, 
          { recipients: this.mailToList,
            format: this.emailFormat,
            subject: this.emailSubject,
            comments: this.emailComments,
            sender: this.yourEmail
          }, 
          { params: {
              trim: (this.emailFormat === "csv") 
                ? this.isBasicEmail
                : false
            } 
          }
        )
        localStorage.setItem(CART_EMAIL_SENDER, this.yourEmail)
        this.$notify({
          title: this.$t('success'),
          type: 'success',
          text: this.$t('cartEmailSent'),
          duration: 5000
        })
      } catch {
        postError({
          title: this.$t('error'),
          text: this.$t('cartEmailFailed'),
        })
      }
    },
    async exportSelected () {
      if (!this.canSubmitExport) return
      try {
        this.isLoading = true
        if (this.exportFormat === 'csv') {
          await this.$rest.get(`cart/${this.exportFormat}`, {
            params: {
              trim: this.isBasicExport
            }
          }).then((response) => {
            this.downloadCsvFrom(response.data)
          })
        } else if (this.exportFormat === "xlsx") {
          await this.$rest.get(`cart/${this.exportFormat}`)
            .then((response) => {
              this.downloadXlsxFrom(response.data.body)
            })
        } 
      } catch (e) {
        postError({
          title: this.$t('error'),
          text: this.$t('failedAction', {
            content: this.$tc('cart', 2),
            action: this.$t('download').toLocaleLowerCase()
          }),
        })
      } finally {
        this.isLoading = false
        this.emails = []
        this.newAddress = ""
        this.isExportModalActive = false
        if (this.isMenuOpened) {
          this.closeMenu()
        }
      }
    },
    importCart (event) {
      if (event.target.files?.length > 0) {
        const [file] = event.target.files

        if (file) {
          if (this.items.length) {
            this.itemToImport = file
            this.$refs.mergeOverrideModal.open()
          } else {
            this.$store.dispatch(
              'cart/importCart',
              { attachment: file, clear: false }
            )
            this.closeMenu()
            this.resetFileUploadInput()
          }
        }
      }
    },
    openExportOptionsModal () {
      this.exportFormat = 'csv'
      this.isBasicExport = false
      this.isExportModalActive = true
    },
    resetFileUploadInput () {
      document.getElementById('cart-import-file-input').value = ''
    },
    importConfirm (clear) {
      this.$store.dispatch(
        'cart/importCart',
        { attachment: this.itemToImport, clear }
      )
      this.itemToImport = undefined
      this.closeMenu()
    },
    toggleMenu () {
      this.isMenuOpened = !this.isMenuOpened
    },
    closeMenu () {
      this.isMenuOpened = false
    },
    viewCarts () {
      this.$refs.openCartModal.open()
      this.closeMenu()
    },
    openEmailOptionsModal() {
      this.emailFormat = getLocalStorage(EMAIL_FORMAT) ?? "csv"
      this.emails = getLocalStorage(EMAIL_RECIPIENTS)?.split(",") ?? []
      this.isBasicEmail = (this.emailFormat === "csv")
        ? (getLocalStorage(IS_BASIC_EMAIL) === "true")
        : false
      this.emailSubject = ""
      this.emailComments = ""
      this.isEmailModalActive = true
    },
    async submitEmail() {
      if (!this.canSubmitEmail) return
      
      this.isLoading = true
      await this.emailCart()

      setLocalStorage(EMAIL_RECIPIENTS, 
        this.mailToList
      )
      setLocalStorage(EMAIL_FORMAT, this.emailFormat)
      if (this.emailFormat === "csv") {
        setLocalStorage(IS_BASIC_EMAIL, this.isBasicEmail)
      }

      this.isLoading = false
      this.emails = []
      this.newAddress = ""
      this.isEmailModalActive = false

      if (this.isMenuOpened) {
        this.closeMenu()
      }
    }
  },
  mounted() {
    const lastSenderEmailUsed = localStorage.getItem(CART_EMAIL_SENDER)
    if (!!lastSenderEmailUsed) {
      this.yourEmail = lastSenderEmailUsed
    }
  }
}
</script>

<style scoped>
.actions-icon {
  padding-right: 1.0rem;
  padding-left: 0;
  margin-left: 0;
}

@media only screen and (min-width: 1024px) {
  .dropdown-trigger {
    display: none;
  }
}

@media only screen and not (min-width: 1024px) {
  .actions-buttons {
    display: none;
  }
}

.file-input {
  display: none;
}
</style>
