<template>
  <section v-if="contentLoaded"
           class="assetContainer is-paddingless">
    <component :is="viewer" />
  </section>
  <section v-else
           class="loading">
    <loading-icon />
  </section>
</template>

<script>
import DocumentEngineContainer
  from '@/components/library/content/asset/viewer/pspdf/DocumentEngineContainer.vue'
import DownloadViewer from './viewer/DownloadViewer'
import ExternalViewer from './viewer/ExternalViewer'
import IllustrationViewer from './viewer/illustrationViewer/IllustrationViewer'
import ImageViewer from './viewer/ImageViewer'
import LoadingIcon from '@/components/common/LoadingIcon'
import MicrositeViewer from '@/components/library/content/asset/viewer/MicrositeViewer.vue'
import NavigatorViewer from './viewer/navigatorViewer/NavigatorViewer'
import NoAssetViewer from './viewer/NoAssetViewer'
import VideoViewer from './viewer/VideoViewer'
import { mapState } from 'vuex'

export default {
  name: 'Asset',
  components: {
    DocumentEngineContainer,
    DownloadViewer,
    ExternalViewer,
    IllustrationViewer,
    ImageViewer,
    MicrositeViewer,
    NavigatorViewer,
    NoAssetViewer,
    LoadingIcon,
    VideoViewer
  },
  computed: {
    ...mapState({
      content: (state) => state.content,
      contentLoaded: (state) => state.content.isLoaded
    }),
    viewer () {
      switch (this.content.contentType) {
        case 'book':
        case 'chapter':
          return 'navigator-viewer'
        case 'document':
          const hasDocumentEngineSupport = !this.content.pspdfDocumentUnsupported
          // Support is dependent on filesize -- 250MB max
          if (hasDocumentEngineSupport) {
            return 'document-engine-container'
          } else {
            return 'download-viewer'
          }
        case 'external':
          return 'external-viewer'
        case 'illustration':
          return 'illustration-viewer'
        case 'image':
          return 'image-viewer'
        case 'microsite':
          return 'microsite-viewer'
        case 'video':
          return 'video-viewer'
        default:
          return 'no-asset-viewer'
      }
    }
  }
}
</script>

<style scoped>
.assetContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.loading {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
}
</style>
