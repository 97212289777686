<template>
  <div  class="control"
        :class="{ 'is-expanded': expanded }">
    <label  class="radio button is-flex"
            :class="[value === nativeValue ? type : '', size, { 
              'is-disabled': disabled }]"
            :disabled="disabled"
            @click="handleClick">
      <slot />
    </label>
  </div>
</template>
<script setup lang="ts">
/* Although meant as a replacement for Buefy RadioButton.vue,
 * this common has a simpler architecture. It does not use an input
 * element with a ref attribute for maintaining state because
 * that seemed unnecessary for encountered implementations in Rocket.
 * See SettingInput.vue for an implementation
 */
const props = defineProps({
  disabled: Boolean,
  expanded: Boolean,
  name: String,
  nativeValue: [String, Number, Boolean, Function, Object, Array],
  required: Boolean,
  size: String,
  type: {
      type: String,
      default: 'is-primary'
  },
  value: [String, Number, Boolean, Function, Object, Array]
})
const emit = defineEmits(["input"])

function handleClick() {
  emit("input", props.nativeValue)
}
</script>