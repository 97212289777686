import { ref, computed, Ref, readonly, toRaw } from 'vue'
import { useStore } from 'vuex'
import { useWidgetNavigator } from './useWidgetNavigator'
import { useFetch } from './useFetchContent'
import { MediaDto, ContentDto, PageDto, PartDto } from '@/interfaces/global'
import { LibraryWidgetPages, WidgetTypes } from '@/interfaces/global/widgets'
import { cloneDeep } from 'lodash'

const hasInfoPageOpen = ref(false)
const infoBackDestination: Ref<string|null> = ref(null)
const suggestionsDoubleBackDestination: Ref<string|null> = ref(null)
const previousMedia: Ref<MediaDto|null> = ref(null)
const previousParentChapters = ref([] as ContentDto[])
const previousChapter: Ref<ContentDto|null> = ref(null)
const previousPage: Ref<PageDto|null> = ref(null)
const previousPart: Ref<PartDto|null> = ref(null)

const hasNavigatedToSuggestedPart = computed(() => {
  return !!previousMedia.value || !!previousChapter.value
    || !!previousPage.value || !!previousPart.value
})

export function useInfoLandingPage() {
  const store = useStore()
  const {
    media,
    parentChapters,
    chapter,
    page,
    part,
    currentWidgetView,
    previousWidgetView,
    setCurrentWidgetView,
    navigateToContent,
    setPart,
    clearContentState,
    setMedia,
    updateChapters,
    setPage } = useWidgetNavigator()
  const { isLoading } = useFetch()

  const hasInfoLandingPage = computed(() => {
    return store.getters['widgets/hasInfoLandingPage']
      && !isLoading.value
  })
  const isPageWidget = computed(() => {
    return store.getters['widgets/widgetType'] === WidgetTypes.page
  })

  async function openInfoPage() {
    if (!hasInfoLandingPage.value) return

    infoBackDestination.value = currentWidgetView.value
    setCurrentWidgetView('info')
    hasInfoPageOpen.value = true
    if (store.state.content.id) {
      await store.dispatch('content/getTags', {
        filter: '',
        id: store.state.content.id,
        type: store.state.content.type
      })
    }
  }

  async function openPartInfoPage(statefulDto: any) {
    if (!hasInfoLandingPage.value) return

    try {
      const bomItem = toRaw(statefulDto) as PartDto
      if (bomItem?.type === 'part') {
        await store.dispatch("content/getContent", {
          id: String(bomItem.id),
          type: "part"
        })
        setPart(bomItem)
        openInfoPage()
      }
    } catch(e) {
      console.error(e)
    }
  }

  function restorePreviousBreadcrumbs() {
    if (!!previousMedia.value) {
      setMedia(toRaw(previousMedia.value))
    }
    if (!!previousChapter.value) {
      updateChapters(
        toRaw(previousChapter.value),
        toRaw(previousParentChapters.value))
    }
    if (!!previousPage.value) {
      setPage(toRaw(previousPage.value))
    }
    if (!!previousPart.value) {
      setPart(toRaw(previousPart.value))
    }
    clearCachedPreviousContent()
  }

  async function backToContentFromInfoPage() {
    if (isPageWidget.value) {
      setHasInfoPageOpen(false)
      !!part.value && setPart(null)
      return
    }
    if (!infoBackDestination.value) return

    setCurrentWidgetView(infoBackDestination.value)

    if (hasNavigatedToSuggestedPart.value) {
      restorePreviousBreadcrumbs()
      await syncPartInfoWithVuex()
      infoBackDestination.value = suggestionsDoubleBackDestination.value
      suggestionsDoubleBackDestination.value = null
    }
  }

  function closeInfoPage() {
    hasInfoPageOpen.value = false
    infoBackDestination.value = null
    suggestionsDoubleBackDestination.value = null
    clearCachedPreviousContent()
    if (!!part.value) {
      setPart(null)
    }
  }

  function cacheContentState() {
    previousMedia.value = cloneDeep(toRaw(media.value))
    previousParentChapters.value = cloneDeep(toRaw(parentChapters.value)) as ContentDto[]
    previousChapter.value = cloneDeep(toRaw(chapter.value)) as ContentDto
    previousPage.value = cloneDeep(toRaw(page.value))
    previousPart.value = cloneDeep(toRaw(part.value))
  }

  function clearCachedPreviousContent() {
    previousMedia.value = null
    previousParentChapters.value = []
    previousChapter.value = null
    previousPage.value = null
    previousPart.value = null
  }

  function navigateToRelatedContent(dto: any) {
    closeInfoPage()
    if (!!part.value) {
      setPart(null)
    }
    if (dto.type === "media") {
      !!page.value && setPage(null)
      !!chapter.value && updateChapters(null)
    }
    navigateToContent(dto)
  }

  async function navigateToSuggestedPart(dto: any) {
    cacheContentState()
    clearContentState()
    suggestionsDoubleBackDestination.value = infoBackDestination.value
    navigateToContent(dto)
    await syncPartInfoWithVuex()
  }

  async function syncPartInfoWithVuex() {
    if ((currentWidgetView.value === LibraryWidgetPages.info) && !!part.value) {
      await store?.dispatch("content/getContent", {
        id: String(part.value?.id),
        type: "part"
      })
      await store?.dispatch('content/getTags', {
        filter: '',
        id: store.state.content.id,
        type: store.state.content.type
      })
      hasInfoPageOpen.value = true
      infoBackDestination.value = previousWidgetView.value
    }
  }

  function setHasInfoPageOpen(val: boolean) {
    hasInfoPageOpen.value = val
  }

  return {
    hasInfoPageOpen: readonly(hasInfoPageOpen),
    hasInfoLandingPage,
    openInfoPage,
    backToContentFromInfoPage,
    navigateToRelatedContent,
    openPartInfoPage,
    navigateToSuggestedPart,
    closeInfoPage,
    syncPartInfoWithVuex,
    setHasInfoPageOpen
  }
}
