import router from "@/router";
import { NavigationGuardNext, RouteLocationNormalized } from "vue-router";
import { store } from "@/store";
import { ssoLogin } from "@/helpers/login";
import i18n from "@/locales";
import { Guid } from "guid-typescript";
import localforage from "localforage";
import http from "@/http";

/**
 * Theme Loaded
 *
 * Ensure that the app theme has been loaded
 * before resolving navigation.
 *
 */
router.beforeEach((to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
  const isWidget = window.location.href.includes('widget')
    || (store.state.auth.isWidget === true)
  if (!store.state.theme.data && !isWidget) {
    store.dispatch('theme/getTheme')

    store.watch(store.getters['theme/themeLoaded'], function () {
      if (store.getters['theme/themeLoaded']) {
        next()
      }
    })
  } else {
    next()
  }
})

// Authenticated
router.beforeEach(async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
  if (to.query.sso) {
    // if sso parameters are passed in, regardless of the destination or authentication state,
    // we want to capture the query string parameters for later use
    localStorage.ssoQueryParams = to.fullPath.split('?').slice(1)[0]

    await store.dispatch('user/getUser', true)

    // this path forces a re-login in order to update to correct tenant
    if (to.query.tk && (store.getters['auth/isAuthenticated']) && (store.state.user.tenantKey !== to.query.tk)) {
      // force a reauthentication via sso
      next(false)
      await ssoLogin(to.query.tk)
    }
  }
  // this is where we check for authentication state when opening a tab or returning from portal login
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    const query = Object.assign({
      redirect: to.path
    }, to.query)

    if (!store.getters['auth/isAuthenticated']) {
      // Do not execute when authenticating a widget request
      const isWidget = window.location.href.includes('widget')
      if (!isWidget) {
        await store.dispatch('user/getUser')
      }
      if (!store.getters['auth/isAuthenticated']) {
        next({
          name: 'Login',
          query
        })
        // Do not store redirect when we are attempting to hit Home
        // because this will cause us to bounce back and forth from
        // the login page and home
        if (!localStorage.getItem('redirect') && to.name !== 'Home') {
          localStorage.redirect = decodeURIComponent(to.fullPath)
        }
        return
      }
    }
  }
  let localeParamExists = false
  if (to.query.locale) {
    localeParamExists = true
    let locale = to.query.locale
    if (typeof locale === 'string') {
      locale = locale.replace('_', '-')
      if (!store.state.locales.items.length) {
        await store.dispatch('locales/getLocales')
      }
      const localeList = store.state.locales.items
      if (localeList?.length) {
        const validLocale = localeList.find((l: { code4: string | (string | null)[] }) => l.code4 === locale)
        if (validLocale) {
          localStorage.setItem('userLocale', locale)
        }
      }
    }
  }
  const userLocale = localStorage.getItem('userLocale')
  const isWidget = store.getters['widgets/isWidget']
  if (userLocale && !isWidget) {
    if (i18n.global.locale !== userLocale) {
      // @ts-ignore
      i18n.global.locale = userLocale
    }
  } else if (!isWidget) {
    if (localeParamExists && store.state.user.tenantLocale) {
      const locale = store.state.user.tenantLocale.replace('_', '-')
      i18n.global.locale = locale
      localStorage.setItem('userLocale', locale)
    } else { i18n.global.locale = 'en-US' }
  }
  next()
})
// router hook cookie refresh
router.beforeEach(async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
  if (store.getters['auth/isAuthenticated']) {
    try {
      if (to.query.cookieRefresh === 'true') {
        const uuid = Guid.create()
        await localforage.setItem('csrfToken', uuid.toString())
        let queryString = localStorage.getItem('ssoQueryParams')
        if (queryString) {
          // sso redirect saved parameters
          await http.get(`/rewriteToken?${queryString}`)
        } else {
          // manually entered
          if (to.fullPath.indexOf('?') > -1) {
            queryString = to.fullPath.split('?').slice(1)[0]
            await http.get(`/rewriteToken?${queryString}`)
          }
        }
      }
    } catch (error) {
      // proceed
    } finally {
      if (localStorage.getItem('ssoQueryParams')) {
        localStorage.removeItem('ssoQueryParams')
      }
    }
  }
  next()
})

router.beforeEach(async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
  if (store.getters['auth/isAuthenticated'] && localStorage.getItem('redirect')) {
    const redirect = localStorage.getItem('redirect')
    localStorage.removeItem('redirect')
    if (redirect) {
      next(redirect)
    }
  } else {
    next()
  }
})

// Check for Super Admin
router.beforeEach((to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
  if (to.matched.some(record => record.meta.requiresSuperAdmin)) {
    const isSuperAdmin = store.state.user.superAdmin
    isSuperAdmin ? next() : next({ name: from.name ?? "AdminDashboard"})
  }
  next()
})

router.beforeEach(async (to, from, next) => {
  const isAdminNavigation = to.path.startsWith('/admin')

  if (isAdminNavigation) {
    await store.dispatch('admin/banners/checkForAdminBanners')
  }

  next()
})

