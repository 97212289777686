<template>
  <section data-int="search"
           class="section">
    <div>
      <h1 class="title is-size-4 is-marginless">
        <label v-if="query.length"
               data-testid="total">
          {{ total || '' }} {{ $tc('resultFor', total, { item: formattedQuery }) }}
        </label>
        <label v-else
               data-testid="total">
          {{ total || '' }} {{ $tc('result', total) }}
        </label>
        <span v-show="query.length"
              class="icon is-size-6"
              @click.stop.prevent="clearSearchTerm">
          <i class="fa fa-times" />
        </span>
        <slot name="printExport">
          <span v-if="allowPrintExport"
                class="icon is-size-6"
                @click.stop.prevent="exportSearchContent">
          <i class="fas fa-arrow-circle-down has-text-info"
             :class="{ disabled: ((total > 100) || (total === 0))}" />
        </span>
        </slot>
        <span class="icon is-size-6">
          <b-tooltip multilined type="is-light is-primary" position="is-bottom tooltip" :label="localeToolTipText">
            <i class="fas fa-globe" />
          </b-tooltip>
        </span>
      </h1>
      <div class="exact-match-section">
        <div @click="toggleExactMatch">
          <input type="checkbox"
                 :checked="exactMatch">
          <label class="title-six exact-match-label">{{ $t('showOnlyExactMatches') }}</label>
        </div>
      </div>
    </div>
    <search-filter-bar
      :exact-match="exactMatch"
    />
    <slot name="content"
          :info="info"
          :items="items"
          :itemsLoaded="itemsLoaded"
          :open="open"
          :viewModeComponent="viewModeComponent">
      <section v-if="itemsLoaded"
               :class="['search-results', searchOverflow]">
      <component :is="viewModeComponent"
                 :items="items"
                 @open="open"
                 @info="info"
                 @widgetNavToContent="$emit('widgetNavToContent', $event)"/>
    </section>
    <section v-else
             class="search-results-loading"
             :class="[searchOverflow]">
      <loading-icon />
    </section>
    </slot>
    <slot name="searchPagination">
      <search-pagination-wrapper v-if="items.length" />
    </slot>
    <search-export-modal v-if="exportSearchModalOpen"
                         @cancel="exportSearchModalOpen = false" />
    <content-info-modal v-if="infoModalOpen"
                        :search-result="infoModalContent"
                        @close="closeInfoModal" />
  </section>
</template>

<script>
import { mapState } from 'vuex'
import ContentInfoModal from '@/components/common/ContentInfoModal'
import LoadingIcon from '@/components/common/LoadingIcon'
import SearchPaginationWrapper from './SearchPaginationWrapper'
import SearchFilterBar from './SearchFilterBar'
import SearchResultsList from './SearchResultsList'
import SearchResultsTable from './SearchResultsTable'
import SearchExportModal from '@/components/common/search/SearchExportModal'
import { query } from '@/router/compositionObjects'
import { SearchCallTypes } from '@/helpers/SearchCallType'
import { computed } from "vue";
import i18n from "@/locales";

export default {
  name: 'Search',
  components: {
    ContentInfoModal,
    LoadingIcon,
    SearchExportModal,
    SearchFilterBar,
    SearchPaginationWrapper,
    SearchResultsTable
  },
  props: {
    exactMatch: {
      type: Boolean
    }
  },
  data () {
    return {
      infoModalContent: undefined,
      infoModalOpen: false,
      exportSearchModalOpen: false,
      windowWidth: -1
    }
  },
  watch: {
    $route () {
      if (this.filtersLoaded) {
        this.updateSelectedFilters()
      }
      this.scrollToTop()
    },
    filters (newFilter, oldFilter) {
      if (newFilter !== oldFilter) {
        this.updateSelectedFilters()
      }
    },
    offset () {
      if (!this.isWidget) {
        this.scrollToTop()
      }
    },
    exactMatch (newSetting, oldSetting) {
      if (newSetting !== oldSetting) {
        this.updateSelectedFilters()
      }
    }
  },
  computed: {
    allowPrintExport () {
      return this.$store.state.user.searchExport &&
        !(this.$store.getters['user/hasDisablePrinting'] || this.$store.getters['user/hasDisableBookPrinting'])
    },
    isWidget() {
      return this.$store.getters['widgets/isWidget']
    },
    searchOverflow() {
      return (this.isWidget && this.viewModeKey === 'list')
        ? 'infinite-scrolling-overflow'
        : 'pagination-overflow'
    },
    viewModeComponent () {
      if (this.windowWidth <= 768) {
        return SearchResultsList
      }

      switch (this.viewModeKey) {
        case 'table':
          return SearchResultsTable

        case 'list':
        default:
          return SearchResultsList
      }
    },
    localeToolTipText () {
      const userLocale = i18n.global.locale ?? 'en-US'
      const tenantLocale = this.$store.state.user.tenantLocale?.replace('_','-') ?? userLocale
      const userLang = this.$store.state.locales.items.find((locale) => locale.code4 === userLocale)?.name ?? userLocale
      const properUserLang = userLang.charAt(0).toUpperCase() + userLang.slice(1)
      if (userLocale === tenantLocale) {
        return this.$t('searchingInLanguage', { lang: properUserLang })
      } else {
        const tenantLang = this.$store.state.locales.items.find((locale) => locale.code4 === tenantLocale)?.name ?? tenantLocale
        const properTenantLang = tenantLang.charAt(0).toUpperCase() + tenantLang.slice(1)
        return this.$t('searchingInTwoLanguages', { lang1: properUserLang, lang2: properTenantLang })
      }
    },
    formattedQuery() {
      return ((this.exactMatch) && (this.query?.length > 0)) && (!this.query?.startsWith('"')) && (!this.query?.endsWith('"')) ? '"' + this.query + '"' : this.query
    },
    ...mapState({
      filters: (state) => state.search.filters.items,
      filtersLoaded: (state) => state.search.filters.isLoaded,
      items: (state) => state.search.items,
      itemsLoaded: (state) => state.search.isLoaded,
      offset: (state) => state.search.offset,
      total: (state) => state.search.total,
      viewModeKey: (state) => state.search.viewModeKey
    }),
    ...query
  },
  methods: {
    clearSearchTerm () {
      this.$emit('clearSearchInput');
      this.$router.push({
        query: { ...this.$route.query, q: '' },
        name: this.$route.name
      })
    },
    closeInfoModal () {
      this.infoModalOpen = false
    },
    exportSearchContent () {
      if (this.allowPrintExport && this.total <= 100 && this.total > 0) {
        this.exportSearchModalOpen = true
      }
    },
    info (content) {
      this.infoModalContent = content
      this.infoModalOpen = true
    },
    infoModalEventListener (event) {
      if (!this.infoModalOpen) return

      if (event.key === 'Escape' || event.key === 'Esc') {
        this.closeInfoModal()
      }
    },
    open (content) {
      const { query: { q } } = this.$route
      this.$store.dispatch('content/navigateToContent', {
        content,
        query: q ? { q } : null
      })
    },
    scrollToTop () {
      const results = document.getElementsByClassName('search-results')
      if (results.length) {
        results[0].scrollTo(0, 0)
      }
    },
    async updateSelectedFilters () {
      const payload = { ...this.$route.query }
      payload.q ??= ''
      this.$store.dispatch('search/setSelectedFiltersAndOptions', payload)

      // Careful with the typing; query params are string-types
      const { q = '' } = this.$route.query
      await this.$store.dispatch('search/search', { q, exactMatch: this.exactMatch, callType: SearchCallTypes.GENERALSEARCH })
    },
    async toggleExactMatch () {
      const { q = '' } = this.$route.query
      await this.$store.dispatch('search/search', { q, exactMatch: !this.exactMatch, callType: SearchCallTypes.GENERALSEARCH })
      this.$emit('toggleExactMatch')
    }
  },
  async created () {
    document.title = this.$t('search')
    this.windowWidth = window.innerWidth
    this.$root.$on('windowResize', () => {
      this.windowWidth = window.innerWidth
    })
    if (!this.filtersLoaded) {
      await this.$store.dispatch('search/getFilters')
    } else {
      await this.updateSelectedFilters()
    }
  },
  async mounted () {
    window.addEventListener('keyup', this.infoModalEventListener)
    await this.$store.dispatch("locales/getLocales")
  },
  beforeUnmount () {
    window.removeEventListener('keyup', this.infoModalEventListener)
  },
  beforeRouteLeave(to) {
    // When navigating to Asset,
    // Content.vue will dispatch callSearchTracker & clear the id
    if (to.name !== "Asset") {
      this.$store.dispatch("search/clearSearchTrackingId")
    }
  }
}
</script>

<style scoped>
.section {
  flex: 1;
  display: flex;
  flex-flow: column;
  padding: .5rem 0 0 0;
}
.title {
  padding-left: 1rem;
}
.search-results {
  flex: 1;
  border-top: lightgray 1px solid;
  border-bottom: lightgray 1px solid;
}
.pagination-overflow {
  overflow: auto;
}
.infinite-scrolling-overflow {
  overflow: hidden;
}
.search-results-loading {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  border-top: lightgray 1px solid;
  border-bottom: lightgray 1px solid;
}
.disabled {
  color: lightgrey !important;
}
.tooltip {
  /* needs to show above other content when triggered */
  z-index: 4;
}
.exact-match-section {
  padding: .5rem 0 .5rem 1rem;
}
.exact-match-label {
  margin-left: .5em;
}
</style>
