import http from '@/http';

const LOCALES_RECEIVED = 'LOCALES_RECEIVED';

const state = {
  items: [],
  isLoaded: false
};

const getters = {
  validLocaleCodesSet(state) {
    return new Set([ ...state.items.map(it => it.code4) ])
  }
}

const actions = {
  async getLocales({ commit }) {
    try {
      if (!state.isLoaded) {
        const {data} = await http.get('locales');
        commit(LOCALES_RECEIVED, {locales: data});
      }
    } catch (err) {
      // na
    }
  }
};

const mutations = {
  [LOCALES_RECEIVED](state, { locales }) {
    state.items = locales;
    state.isLoaded = true;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
