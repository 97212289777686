<template>
  <div  class="field my-2">
    <bulma-radio-button :value="isBasicExport"
                        :native-value="false"
                        :disabled="disabled"
                        @input="$emit('update:isBasicExport', $event)">
      <slot name="leftButtonTextSpan" />
    </bulma-radio-button>
    <bulma-radio-button :value="isBasicExport"
                        :native-value="true"
                        :disabled="disabled"
                        @input="$emit('update:isBasicExport', $event)">
      <slot name="rightButtonTextSpan" />
    </bulma-radio-button>
  </div>
</template>

<script>
import BulmaField from "@/components/common/BulmaField.vue"
import BulmaRadioButton from "@/components/common/BulmaRadioButton.vue"

export default {
  name: "CartColumnsButtons",
  components: { BulmaField, BulmaRadioButton },
  props: {
    isBasicExport: Boolean,
    disabled: Boolean
  }
}
</script>

<style scoped>
@media only screen and (min-width: 640px) {
  .field {
    display: flex;
    justify-content: center;
  }
}
</style>