/* eslint-disable max-len */
module.exports =
{
  aOfB: '{a} von {b}',
  about: 'Über Uns',
  aboutThisItem: 'Über dieses Element',
  accessApiKeyManager: 'Greifen Sie auf den API-Schlüssel-Manager für diesen Mandanten zu.',
  accessControls: 'Zugriffskontrollen',
  accessControlsCopiedText: 'Zugriffskontrollen werden sofort aktualisiert. Die Sicherheitsberechtigungen für Medien werden in den nächsten Minuten aktualisiert.',
  accessControlsCopiedTitle: 'Zugangskontrollen wurden erfolgreich kopiert',
  accessControlsCopyConfirmation: 'Damit werden alle bestehenden Zugangskontrollen von {to} entfernt und durch Zugangskontrollen von {from} ersetzt. Der Vorgang kann nicht rückgängig gemacht werden.',
  accessControlsDescription: 'Zugangskontrollen definieren, wer die Medien entsprechend der gewählten Organisationen und Medienkategorien ansehen kann.',
  accessControlsIndeterminance: 'Hinweis: Mutterorganisationen haben immer Zugriff auf Inhalte, die einem ihrer Tochterunternehmen zugewiesen wurden.',
  account: 'Konto',
  accountErrorMessage: 'Kontofehlermeldung',
  accountErrorMessageWarning: 'Das Feld accountErrorMessage (Kontofehlermeldung) in der Datenbank wird für den Mandanten auf „Nicht autorisiert“ gesetzt.',
  action: 'Aktion | Aktionen',
  actionDelete: 'löschen',
  actionLoad: 'laden',
  actionPaste: 'Einfügen',
  actionSave: 'Speichern',
  actionRun: 'ausführen',
  actionUndoWarning: 'Der Vorgang kann nicht rückgängig gemacht werden.',
  actionUpload: 'hochladen',
  actualSize: 'Tatsächliche Größe',
  add: 'Hinzufügen',
  addAPIKey: 'Einen API-Schlüssel hinzufügen',
  addItem: '{item} hinzufügen',
  addABookText: 'Ein Buch hinzufügen',
  addASharedCart: 'Gemeinsamen Warenkorb hinzufügen',
  addATemplate: 'Vorlage hinzufügen',
  addATag: 'Tag hinzufügen',
  addABookToStart: '{addABookText}, um zu beginnen.',
  addAForm: 'Fügen Sie ein Formular hinzu, um zu beginnen.',
  addAFormOnly: 'Formular hinzufügen',
  addAMedia: 'Medium hinzufügen',
  addAnOrderQuoteEmailFormat: 'E-Mail-Format für Bestellungen/Angebote hinzufügen',
  addAPrintSettingText: 'Druckeinstellung hinzufügen',
  addAPrintSettingToStart: '{addAPrintSettingText}, um zu beginnen.',
  addBooks: 'Bücher hinzufügen',
  addChildBrowseFlow: 'Untergeordneten Durchsuchungsverlauf hinzufügen',
  addChapter: 'Kapitel hinzufügen',
  addComment: 'Kommentar hinzufügen',
  addExistingPart: 'Bestehendes Teil hinzufügen',
  addGroupsToUsers: '{groups}-Gruppen zu {users}-Benutzern hinzufügen',
  additionalInformation: 'Weitere Informationen',
  additionalNotification: 'Zusätzliche Benachrichtigung',
  additionalPartNumberDescription: 'Als zusätzliches Teilenummernfeld verwenden',
  addBrowseFlow: 'Durchsuchungsverlauf hinzufügen',
  addMedia: 'Medien hinzufügen',
  addPages: 'Seiten hinzufügen',
  addPart: 'Ein Teil hinzufügen',
  addReports: 'Berichte hinzufügen',
  addRootBrowseFlow: 'Durchsuchungsverlauf für Stammverzeichnis hinzufügen',
  addRow: 'Zeile hinzufügen',
  addTemplates: 'Vorlagen hinzufügen',
  addToCartMessage: '{partNumber} erfolgreich hinzugefügt',
  addToUserGroup: 'Zu Gruppen hinzufügen',
  address: 'Adresse | Adressen',
  addressInUse: 'Aktuell ist eine Adresse als Standardadresse eingestellt und kann nicht gelöscht werden',
  addressInUseSubtitle: 'Um diese Adresse zu löschen, müssen Sie zuerst die Einstellung als Standardadresse löschen.',
  addressLine: 'Adresszeile {number}',
  addWidgetKey: 'Widget-Schlüssel hinzufügen',
  admin: 'Admin',
  adminCenter: 'Admin-Zentrum',
adminCenterBanner: {
    bodyLabel: 'Banner-Text',
    enableLabel: 'Admin-Banner aktivieren',
    headerGreenOption: 'Grün',
    headerLabel: 'Banner-Kopfzeile',
    headerRedOption: 'Rot',
    headerYellowOption: 'Gelb',
    title: 'Admin-Center-Banner',
    typeLabel: 'Banner-Typ'
  },
  adminCenterMessage: 'Adminstrationszentrum für Inhalt, Benutzer, Bibliothekseinstellungen, Berichterstattung und Exportfunktion',
  adminMessage: 'Funktionen werden hinzugefügt, sobald sie verfügbar sind',
  administration: 'Administration',
  affectedBookMedia: '{count} Buchmedien werden aktualisiert.',
  affectedTemplateMedia: '{count} Buchvorlagen werden aktualisiert.',
  after: 'Nach',
  aggregatedFromChildren: 'Von den Tochterunternehmen zusammengefasst',
  aiAlgorithm: 'KI-Scanner',
  all: 'Alle',
  alphanumeric: 'Alphanumerisch',
  algorithm: 'Algorithmus',
  alignHorizontal: 'Horizontal Ausrichten',
  alignVertical: 'Vertikal Ausrichten',
  allUsersDisabled: 'Alle Benutzer, die zum Mandanten gehören, werden deaktiviert..',
  analyticsJSON: 'Analyse JSON',
  andCountMore: 'und {count} weitere ...',
  any: 'Beliebig',
  apiKey: 'API-Schlüssel | API-Schlüssel',
  apiKeyAdmin: 'API Key-Admin',
  apiAuthHeader: 'API-Autorisierungs-Kopfzeile',
  apiKeyCopied: 'Wert von API-Autorisierungs-Kopfzeile in Zwischenablage kopiert',
  apiKeyDeleted: 'API-Schlüssel  Gelöscht',
  apiKeyDeletedError: 'Fehler beim Löschen des API-Schlüssels',
  apiKeyDeleteMsg: 'Wenn dieser API-Schlüssel gelöscht wird, wird er für die Verwendung durch Integrationen deaktiviert',
  apiKeyDeleteTitle: 'Sind Sie sicher, dass Sie diesen API-Schlüssel löschen möchten?',
  apiKeyInstructions: 'Der Schlüssel wird generiert, wenn Gespeichert wird',
  apiKeyManager: 'API-Schlüssel-Manager',
  apiKeyNameEmpty: 'Nachname-Feld darf nicht leer sein',
  apiKeyRegenError: 'Fehler beim erneuten Generieren des API-Schlüssels',
  apiKeyRegenMsg: 'Durch das erneute Generieren dieses API-Schlüssels werden ggf. bestehende Integrationen deaktiviert',
  apiKeyRegenTitle: 'Sind Sie sicher, dass Sie diesen API-Schlüssel erneut generieren möchten?',
  apiKeySaved: 'API-Schlüssel Gespeichert',
  apiKeyType: 'API-Schlüssel-Typ',
  appliesTo: 'Gilt für',
  appliesToEmpty: 'Es muss zumindest ein Objekttyp gewählt werden',
  appliesToPopulated: 'Geben Sie das Objekt ein, für das das Tag verwendet werden kann',
  applyItem: '{item} anwenden',
  applyToChildItems: 'Für Tochterunternehmen anwenden {item}',
  applyToPartOnly: 'Nur auf dieses Teil anwenden',
  april: 'April',
  area: 'Gebiet',
  areYouSure: 'Sind Sie sicher, dass Sie {item} wirklich löschen möchten?',
  areYouSureCopy: 'Sind Sie sicher, dass Sie {item} kopieren möchten?',
  areYouSureYouWantToDisableTheTenant: 'Wollen Sie den Mandanten wirklich deaktivieren?',
  assembly: 'Baugruppe | Baugruppen',
  assemblyCreatorSuccessTitle: 'Auftrag „Baugruppe erstellen“ übermittelt',
  assemblyCreatorSuccessSubtitle: 'Änderungen der Hotpoint-Links und des Inhaltsverzeichnisses des Buches erfolgen sofort. Die Suchresultate ändern sich, nachdem die Indizierung abgeschlossen ist.',
  assemblyConfirmationTitle: 'Möchten Sie die Erstellung der Baugruppe beenden?',
  assemblyCreatorHelpText1: 'Mit dieser Funktion können Sie eine gewählte Seite überall dort in Bücher einfügen, wo Sie dieses Teil {partName} finden, und das Teil wird automatisch über einen Hotpoint-Link mit der gewählten Seite verbunden. Sie können wahlweise die Einfügungen auf ausgewählte Bücher beschränken.',
  assemblyCreatorHelpText2: 'Die gewählte Seite wird einmal pro Buch eingefügt, nachdem das Teil {partName} das erste Mal aufgetreten ist.',
  assemblyStepOne: 'Schritt 1: Wählen Sie die Seite aus, die eingefügt werden soll',
  assemblyStepTwo: 'Schritt 2: Wählen Sie die Bücher, in die die Seite eingefügt werden soll',
  asset: 'Asset | Assets',
  assetsNotFound: 'Kein {type} Gefunden',
  assignBrowseFlow: 'Durchsuchungsverlauf zuweisen',
  assignedOrganizations: 'Zugewiesene Organisationen',
  atLeastFiveError: 'Wählen Sie bitte einen Wert aus, der nicht kleiner als 5 ist.',
  attachment: 'Anhang | Anhänge',
  attachmentEditorDescription: 'Hängen Sie direkt an dieses Objekt Bilder, Videos und Dokumente an. Beachten Sie, dass Sie sie in der Registerkarte „Zugehöriges“ finden.',
  attachmentView: 'Anhänge anzeigen',
  attachRelatedPDFs: 'PDFs von zugehörigen Teile-Seiten anhängen',
  attributions: 'Zuordnungen',
  august: 'August',
  automaticZoom: 'Automatischer Zoom',
  autoHotpoint: 'Auto-Hotpoint',
  autoPopulate: 'Automatisch ausfüllen',
  availability: 'Verfügbarkeit',
  back: 'Zurück',
  backCover: 'Rückseite',
  backStitch: 'Rückwärts Anheften',
  backToLibrary: 'Zurück zur Bibliothek',
  backToSearch: 'Zurück zur Suche',
  badCredentials: 'Falsche Anmeldeinformationen',
  bahasaDescription: 'Indonesische Beschreibung (Bahasa)',
  bahasaName: 'Indonesischer Name (Bahasa)',
  banner: 'Banner',
  before: 'Vorher',
  beginTypingToFindAnything: 'Beginnen Sie mit der Eingabe, um etwas zu finden',
  betaAccessKeys: 'Beta-Zugangscodes',
  betaResources: 'Beta-Ressourcen',
  betaReleaseVideo: 'Betaversion-Video',
  blobFinder: 'BLOB-Sucher',
  body: 'Hauptteil',
  bomDefaultSupplier: 'Stückliste Standardlieferant',
  bomItem: 'BOM-Artikel',
  bom: 'BOM',
  bomExportSuccess: 'Export der Stückliste erfolgreich',
  bomLayout: 'Layout der Materialliste',
  bomListOptions: 'Optionen Stückliste (BOM)',
  bomListSettings: 'Einstellungen Stückliste (BOM)',
  both: 'Beide',
  book: 'Buch | Bücher',
  bookInserts: 'Buch-Einfügungen',
  bookLabel: 'Buch | Bücher',
  bookPrintingDisable: 'Buchdrucken deaktivieren',
  booksAndPagesDisplay: 'Bücher- und Seitenanzeige',
  boost: 'Verbesserte Suche',
  boostDescription: 'Geben Sie einen Wert über 1,5 ein, um die Suchrelevanz des Tags für Teilenummern, Medienbezeichnungen und Namen zu verbessern',
  boostRangeInvalid: 'Boost muss zwischen 0,5 und 100,0 liegen',
  border: 'Rahmen',
  bottom: 'Unten',
  branding: 'Branding',
  browse: 'Durchsuchen',
  browseComputer: 'Computer durchsuchen',
  browseFlow: 'Durchsuchungsverlauf | Durchsuchungsverläufe',
  browseFlowAssigned: 'Durchsuchungsverlauf Zugeordnet',
  browseFlowCopyMessage: 'Geben Sie einen Namen für den neuen untergeordneten Durchsuchungsverlauf ein.',
  browseFlowDeleted: 'Browse-Flow gelöscht',
  browseFlowDeleteMessage: 'Wenn Sie einen Durchsuchungsverlauf löschen, wird nicht nur der Durchsuchungsverlauf, sondern werden alle untergeordneten Durchsuchungsverläufe gelöscht.',
  browseFlowEmptyState: 'Keine Durchsuchungsverläufe definiert. {new}, um zu beginnen.',
  browseFlowHeirarchy: 'Durchsuchungsverlauf-Hierarchie',
  browseFlowNoExistingCriteria: 'Es wurden keine Suchkriterien für den Durchsuchungsverlauf ausgewählt. Verwenden Sie die Steuerung oben, um Suchkriterien auszuwählen.',
  browseFlowMoveToRoot: 'Knoten hier ablegen, um erste untergeordnete Ebene zu erstellen',
  browseFlowNoAssignedOrgs: 'Keine Organisationen zugewiesen',
  browseFlowSaveFailed: 'Durchsuchungsverlauf konnte nicht aktualisiert werden',
  browseFlowSetDefaultSuccess: 'Standard-Durchsuchungsverlauf wurde gewählt',
  browseFlowThumbnailDeleteFailTitle: 'Miniaturansicht konnte Nicht Gelöscht werden',
  browseFlowThumbnailDeleteSuccessTitle: 'Miniaturansicht Erfolgreich Gelöscht',
  browseFlowThumbnailUploadFailTitle: 'Miniaturansicht konnte Nicht Hochgeladen werden',
  browseFlowThumbnailUploadSuccessTitle: 'Miniaturansicht Erfolgreich Hochgeladen',
  browseFlowTooManyOrgs: 'Sie haben zu viele Organisationen, um den aktuellen Editor für den Durchsuchungsverlauf zu verwenden. Bitte kontaktieren Sie den Documoto-Support unter  {email}, um Hilfe bei der Bearbeitung Ihrer Documoto Durchsuchungsverläufe zu erhalten.',
  browserPartiallySupport: 'Möglicherweise gibt es ästhetische Unterschiede und sonstige Änderungen in diesem Browser.',
  browseFlowSaved: 'Durchsuchungsverlauf gespeichert',
  browserDeprecation: {
    title: 'Mitteilung zur Browser-Nutzungsdauer',
    text: 'Entsprechend der auslaufenden Unterstützung von Microsoft für den Internet Explorer (alle Versionen) und die verbundenen Sicherheitsbelange, wird der Benutzerzugang auf diese Site via Internet Explorer (alle Versionen) nach dem 30. September 2021 nicht mehr unterstützt.'
  },
  buildHotpointLinks: 'Hotpoint-Links erstellen',
  buildHotpointLinksInfo: 'Dieser Vorgang entfernt zunächst alle bestehenden Hotpoint-Links aus den Seiten des Buches und erstellt danach neue Hotpoint-Links zwischen den Teilen und allen übereinstimmenden Baugruppen-Seiten.',
  buildHotpointLinksTitle: 'Möchten Sie Hotpoint-Links in diesem Buch erstellen?',
  bulkIndexingLane: 'Massenindexierungsspur',
  bulkInsert: 'Masseneinfügung durchführen',
  bulkExportConfirm: 'Möchten Sie {n} {unit} exportieren?',
  bulkExportLimit: 'Die Begrenzung beträgt {limit} Bücher pro Exportauftrag',
  bulkPageInsertJob: 'Masseneinfügung von Seiten',
  business: 'Geschäft',
  cancel: 'Abbrechen',
  canceled: 'Abgebrochen',
  cancelAssemblyCreatorTitle: 'Ihr „Baugruppe erstellen“ verwerfen?',
  cancelAssemblyCreatorSubtitle: 'Ihr „Baugruppe erstellen“ wird nicht durchgeführt.',
  cannotExceed100: 'Kann nicht größer als 100 % sein',
  cart: 'Warenkorb | Warenkörbe',
  cartBehaviorEnabled: 'Eingabeaufforderung Menge in Warenkorb Hinzufügen aktivieren',
  cartBehaviorDisabled: 'Eingabeaufforderung Menge in Warenkorb Hinzufügen deaktivieren',
  cartBehaviorLabel: 'Mengenverhalten in Warenkorb hinzufügen',
  cartColumn: 'Warenkorb-Spalte | Warenkorb-Spalten',
  cartColumn1: 'Spalte für Preis des 1. Warenkorbs',
  cartColumn2: 'Spalte für Preis des 2. Warenkorbs',
  cartColumn3: 'Spalte für Preis des 3. Warenkorbs',
  cartColumnPartLocationContent1: 'Die Aktivierung dieser Funktion hat folgende Auswirkungen:',
  cartColumnPartLocationContent2: 'Die Eindeutigkeit der Teile-Reihe im Warenkorb basiert auf ihrer Stelle im Inhalt, falls anwendbar.',
  cartColumnPartLocationContent3: 'Diese Einstellung kann auf Organisationsebene nicht deaktiviert werden und gilt für alle Benutzer.',
  cartColumnPartLocationContent4: 'Diese Einstellung sollte nicht aktiviert werden, wenn eine Warenkorb-senden-Integration erfolgte, ohne vorher Ihren Documoto-Kundensupport zu kontaktieren.',
  cartColumnPartLocationOption: 'Teil-Lagerortdaten für Warenkorb aktivieren',
  cartColumnPartLocationTitle: 'Sind Sie sicher, dass Sie Teil-Lagerortdaten für den Warenkorb aktivieren möchten?',
  cartDisable: 'Warenkorb deaktivieren',
  cartEmailFailed: 'Warenkorb-E-Mail fehlgeschlagen',
  cartEmailSent: 'Warenkorb-E-Mail gesendet',
  cartEnable: 'Warenkorb aktivieren',
  cartSaveEnable: 'Warenkorb Speichern aktivieren',
  cartErpMessage: 'Erfolgreich an ERP gesendet',
  cartImportError: 'Fehler beim Importieren des Warenkorbs',
  cartLoadedWithItemsRemoved: '{count} Artikel entfernt, da Sie derzeit keine Berechtigung haben, diese zu bestellen',
  cartSharedAddEdit: 'Gemeinsame Warenkörbe hinzufügen / bearbeiten',
  cartUpdated: 'Warenkorb Aktualisiert',
  cartUpdatedAndRemovedCount: 'Warenkorb wurde Aktualisiert; {count} nicht bestellbare Teile wurden entfernt',
  category: 'Kategorie | Kategorien',
  categoryCreated: 'Kategorie Erstellt',
  categoryUpdated: 'Kategorie Aktualisiert',
  centimeter: 'Zentimeter | Zentimeter',
  changeNotification: 'Änderungsmitteilung',
  changeScope: '{n} {type} wird betroffen sein',
  changeUserOrganization: 'Organisation wechseln',
  chapter: 'Kapitel | Kapitel',
  chapterHeader: 'Kapitelüberschrift',
  chaptersOnly: 'Nur Kapitel',
  chineseDescription: 'Chinesische Beschreibung',
  chineseName: 'Chinesischer Name',
  circle: 'Kreis',
  city: 'Stadt',
  classicAdminMessage: 'Hierdurch werden Sie an Flex zurückgeleitet, um die Admin-Module zu nutzen.',
  classicAdmins: 'Klassische Admins',
  classicExporter: 'Klassische Exportfunktion',
  classicExporterMessage: 'Altes Flash-basiertes Inhaltsexportmodul',
  classicOrganizationAdmin: 'Klassische Organisationsverwaltung',
  classicOrganizationAdminMessage: 'Altes Flash-basiertes Benutzerverwaltungsmodul zur Verwaltung von Organisationen und Benutzern',
  classicPublisher: 'Klassische Veröffentlichungsfunktion',
  classicPublisherMessage: 'Altes Flash-basiertes Veröffentlichtungsmodul für Inhaltserstellung und Druckeinstellung',
  classicReporting: 'Klassische Berichterstattung',
  classicReportingMessage: 'Altes Flash-basiertes Berichterstattungsmodul',
  classicTenantAdmin: 'Klassische Mandantenverwaltung',
  classicTenantAdminMessage: 'Altes Flash-basiertes Verwaltungsmodul für mandantenübergreifende Einstellungen',
  className: 'Klassenname',
  clear: 'Löschen',
  clearItemNumber: 'Artikelnummer löschen',
  clearItemNumberTitle: 'Artikelnummer löschen?',
  clearItemNumberMessage: 'Durch Löschen der Artikelnummer werden folgende Daten gelöscht:',
  clearFilters: 'Filter löschen',
  clearHotpoints: 'Hotpoints löschen',
  clearSearch: 'Suche löschen',
  clickHereToUpload: 'Datei ablegen oder klicken, um Überschreibungsdatei hochzuladen.',
  clickMagnifyingGlass: 'Klicken Sie auf die Lupe, um direkt zur erweiterten Suche zu gelangen.',
  clickOkToContinue: 'Klicken Sie auf „OK“, um fortzufahren.',
  clickToDownload: 'Klicken für Download',
  clickToEnlarge: 'Klicken um zu vergrößern',
  clickToOpen: 'Zum Öffnen klicken',
  clipboard: 'Zwischenablage',
  clipboardAccessError: 'Passen Sie bitte Ihre Browsereinstellungen an, um den Zugriff auf die Zwischenablage zuzulassen.',
  close: 'Schließen',
  closed: 'Geschlossen',
  collapseAll: 'Alle zuklappen',
  color: 'Farbe | Farben',
  column: 'Spalte | Spalten',
  columnName: 'Spaltenname',
  columnItemType: {
    customText: 'Benutzerdefinierter Text',
    documentTitle: 'Dokumenttitel',
    horizontalLine: 'Horizontale Linie',
    pageName: 'Seitenname',
    pageNumber: 'Seitennummer',
    printDate: 'Druckdatum',
    tenantName: 'Mandantenname',
    userName: 'Benutzername'
  },
  combinedPage: 'Kombinierte Seite',
  combinedPageMessage: 'Diagramm oben, Teileliste unten',
  comingSoon: 'In Kürze verfügbar',
  commaDelimited: 'durch Kommas getrennt',
  comment: 'Kommentar | Kommentare',
  commentAdded: 'Kommentar hinzugefügt',
  commentDeleted: 'Kommentar gelöscht',
  commentEdit: 'Kommentare hinzufügen / bearbeiten',
  commentEditAll: 'Alle Kommentare bearbeiten',
  commentUpdated: 'Kommentar aktualisiert',
  commentView: 'Kommentare anzeigen',
  commerceSystem: 'Handelssystem',
  completed: 'Beendet',
  confirm: 'Bestätigen',
  confirmBrowseFlowApplyToChildren: 'Möchten Sie den Durchsuchungsverlauf für Tochterunternehmen verwenden?',
  confirmBrowseFlowOverrideToChildren: 'Damit werden alle Überschreibungsdateien für die Durchsuchungsverläufe in den Tochterunternehmen gelöscht.',
  confirmPrintSettingsApplyToChildren: 'Möchten Sie diese Druckeinstellung für das Tochterunternehmen verwenden?',
  confirmPrintSettingsOverrideToChildren: 'Damit werden alle Überschreibungsdateien für die Druckeinstellung in den Tochterunternehmen gelöscht.',
  contains: 'Enthält',
  containsHeaderRow: 'Enthält eine Kopfzeile',
  content: 'Inhalt | Inhalte',
  contentAccessControls: '{content} Zugriffskontrollen',
  contentEntity: 'Content-Einheit | Content-Einheiten',
  contentNotFound: 'Inhalt nicht gefunden',
  contentUpdatedQueueJob: 'Warteschlange für Aktualisierte Inhalte',
  contourShape: 'Umrissform',
  convertEntity: '{entity} konvertieren',
  convertToEntity: 'Konvertieren zu {entity}',
  copyAccessControls: 'Zugriffskontrollen kopieren',
  copyAccessControlsWarning: 'Damit werden alle zugewiesenen Zugangskontrollen einer gewählten Organisation in die aktuelle Organisation kopiert',
  copy: 'Kopieren',
  copyAndRetain: 'Für Integrationszwecke Kopieren und Aufbewahren',
  copyAndRetainWidget: 'Kopieren & aufbewahren; dies ist das einzige Mal, dass der Widget-Autorisierungswert für Sie in der App sichtbar ist',
  copyAtoB: 'Von {a} nach {b} kopieren',
  copyContent: '{type} kopieren? {name}',
  copyingItem: '{item} wird kopiert',
  copyItem: '{item} kopieren',
  copyItemQuestion: '{item} kopieren?',
  copyTo: 'Nach {target} kopieren',
  copyMeMessage: 'Eine Kopie der Nachricht an mich senden',
  copyrightMessage: '© 2010-{year} Documoto Inc. Alle Rechte vorbehalten.',
  count: 'Anzahl',
  country: 'Land | Länder',
  coverPages: 'Umschlagseiten',
  coverAmpStitchPages: 'Umschlag und Angeheftete Seiten',
  createAssembly: 'Baugruppe erstellen',
  created: 'Erstellt',
  createdBy: 'Erstellt von {name}',
  createdDate: 'Erstelltes Datum',
  createdOn: 'Erstellt am',
  currentPassword: 'Aktuelles Passwort',
  custom: 'Benutzerdefiniert',
  czechDescription: 'Tschechische Beschreibung',
  czechName: 'Tschechischer Name',
  dashboard: 'Dashboard | Dashboards',
  dashboardLibrary: 'Dashboard-Bibliothek',
  dataInsights: 'Dateneinblicke',
  dataInsightsInfoMessage: 'Berichterstattung und Dashboard Center für Inhalte, Nutzer- und Bibliotheksdateneinblicke.',
  dataMapping: 'Datenzuordnung/Beispieldaten (bis zu 10 Zeilen)',
  dataOptions: 'Datenoptionen',
  date: 'Datum',
  dateModified: 'Geändert am',
  debugPageBuilderPLZ: 'Debug Seiten-Builder PLZ',
  december: 'Dezember',
  decreaseHotpoint: 'Hotpoint Verringern',
  default: 'Standardeinstellung',
  defaultAddress: 'Standardadresse',
  defaultBrowseFlow: 'Standard-Durchsuchungsverlauf',
  defaultCurrencyCode: 'Standard-Währungscode',
  defaultCurrencySymbolOn: 'Standardwährungssymbol aktiviert',
  defaultEmailRecipient: 'Standard-E-Mail-Empfänger |Standard-E-Mail-Empfänger',
  defaultImage: 'Standardbild | Standardbilder',
  defaultImagesManager: 'Standardbild-Manager',
  defaultItemImage: '{item} Standardbild',
  defaultPartsListLayout: 'Standardlayout für Teileliste',
  defaultPartsListLayoutHelp: 'Standardlayout für Teileliste links oder unter der Abbildung',
  defaultSearchMode: 'Standard-Suchmodus',
  defaultSearchModeHelp: 'Globale Suchmethode automatisch einstellen auf \'enthält\' oder \'genau\'',
  defaultSortMode: 'Standard-Sortiermodus',
  defaultSupplier: 'Standard-Lieferant',
  defaultTocStateOpen: 'Inhaltsverzeichnis Automatisch im Status Offen',
  defaultTocStateOpenHelp: 'Legt fest, ob das Inhaltsverzeichnis automatisch geöffnet werden soll, wenn ein Buch angesehen wird',
  defaultUom: 'Standard-Maßeinheit',
  defaultValue: 'Standardwert',
  defaults: 'Standardeinstellungen',
  defaultsAndCapabilities: 'Standardeinstellungen und Möglichkeiten',
  delete: 'Löschen',
  deleteAll: 'Von Mutterunternehmen übernehmen',
  deleteAnyPartnerApiKeys: 'Löschen Sie alle Partner-API-Schlüssel, die SmartEquip bereitgestellt wurden.',
  deleteContent: 'Sind Sie sicher, dass Sie {item} wirklich löschen möchten? {name}',
deletePart: {
    infoOne: 'Das Löschen eines Teil erfolgt umgehend in der Bibliothek und im Inhalt-Admin. Das gelöschte Teil kann weiterhin gesucht werden, bis die Neuindizierung innerhalb weniger Minuten erfolgte.',
    deletingPartNumber: '{partNumber} wird gelöscht.',
    affectedCarts: 'Das Teil wird aus allen Einkaufswagen, die dieses Teil enthalten, entfernt.',
    infoTwo: 'Diese Aktion kann ohne Hilfe des Documoto-Supports nicht rückgängig gemacht werden. Nach 90 Tagen werden der gelöschte Datensatz und alle Verknüpfungen dauerhaft gelöscht.'
  },
deletePartInUse: {
    title: 'Dieser Teil gehört zu (einer) Seite(n) und kann nicht gelöscht werden.',
    body: 'Documoto unterstützt derzeit nur das Löschen von Teilen, die nicht zu Seiten gehören. Bitte entfernen Sie diesen Teil von allen veröffentlichten Seiten oder Entwürfen, um mit dem Löschen fortzufahren.',
    submitLabel: 'Zur Seite gehen, auf der es verwendet wird'
  },
  deleteRelated: 'Wollen Sie {count} {type} wirklich löschen?',
  deletedItem: '{item} gelöscht',
  deleteFilesMessage: '{count} Datei(en) wirklich löschen?',
  deleteHotpointLinksTitle: 'Hotpoint-Link(s) wirklich löschen?',
  deleteMessage: 'Wirklich löschen?',
  deleteSelectedCriteria: 'Gewählte Kriterien löschen',
  deleteTagsTitle: 'Tag(s) wirklich löschen?',
  deleteTaxonMessage: 'Damit wird dieses Taxon mit allen Unterkategorien endgültig gelöscht. Wirklich fortsetzen?',
  deleteTaxonomyMessage: 'Damit werden alle Taxa für diese Organisation oder den Mandanten endgültig gelöscht. Wirklich fortsetzen?',
  deleteTenantSupplier: 'Wollen Sie diesen Lieferanten für den Mandanten wirklich löschen?',
  demo: 'Demo',
  description: 'Beschreibung',
  deselectAll: 'Alle deaktivieren',
  deselectCriteria: 'Kriterien abwählen',
  detail: 'Detail | Details',
  diagonal: 'Diagonal',
  diameter: 'Durchmesser',
  disable: 'Deaktivieren',
  disableAll: 'Alle deaktivieren',
  disableDocumotoTenant: 'Documoto Mandant deaktivieren',
  disableFwdReindex: 'Weiterleitung der Neuindexierung von Zielmedien für DSW deaktivieren',
  disableLibraryBannerImage: 'Bibliotheksbanner-Bild deaktivieren',
  disableSmartEquipModalTitle: 'Wollen Sie diese Lizenz wirklich deaktivieren?',
  disableTEK: 'Direkte URL-Anmeldung deaktivieren',
  disabled: 'Deaktiviert',
  discard: 'Verwerfen',
  discardChanges: 'Änderungen verwerfen',
  discardQ: 'Änderungen verwerfen?',
  discounted: 'Reduziert',
  discountedPrice: 'Reduzierter Preis',
  discountedTotal: 'Reduzierung Gesamt',
  discountPrice: 'Reduzierter Preis',
  displayInOrdersList: 'In der Bestellliste anzeigen',
  displayOrder: 'Reihenfolge',
  displayOrderDescription: 'Stellt die Reihenfolge des Dropdown-Menüs „Filter“ für die Inhaltstyp-Tags ein',
  document: 'Dokument | Dokumente',
  documentLabel: 'Dokument | Dokumente',
  documentSettings: 'Dokumenteinstellungen',
  documotoAcademy: 'Documoto Akademie',
  documotoLicensePackage: 'Documoto-Lizenzpaket',
  domain: 'Domain',
  domainEmptyMessage: 'Erlaubte Domain darf nicht leer sein',
  domainInvalidMessage: 'Ein gültiger Domain-Name ist erforderlich',
  download: 'Herunterladen',
  downloadBrowser: 'Laden Sie einen dieser großartigen Browser herunter.',
  downloadFailed: 'Fehler beim Herunterladen von {name}',
  downloadStarted: 'Download von {name} läuft',
  draft: 'Entwurf | Entwürfe',
  draftBomDeleteTitle: 'Diesen Teil wirklich von der Seite löschen?',
  draftBomMultipleDeleteTitle: 'Diese Teile wirklich von der Seite löschen?',
  draftBomDropError: 'Teile können nicht tiefer als 5 Ebenen integriert werden',
  draftBomEmptyField: 'Alle Felder in der Teileliste müssen ausgefüllt sein',
  draftBomQuantityError: 'Anzahl muss eine Zahl sein',
  draftBomError: 'Fehler bei der Handhabung von Stücklisten-Entwurf',
  draftPage: 'Entwurfsseite',
  draftPageDelete: 'Entwurf löschen',
  draftPageDeleteMessage: 'Entwurfsseite erfolgreich gelöscht.',
  draftPageDeleteWarning: 'Durch Löschen des Seitenentwurfs werden alle Fortschritte gelöscht.',
  draftPageLastUpdated: 'Entwurf - zuletzt aktualisiert am {date} durch {username}',
  draftPageLoadError: 'Entwurfsseite konnte nicht geladen werden.',
  draftPageProcessingPlz: 'Seite wird von der PLZ-Datei geladen',
  draftPagePublishInProgress: 'Seiten-Veröffentlichung in Bearbeitung',
  draftPageSaveSuccess: 'Entwurfsseite erfolgreich gespeichert.',
  draftPageSaveUnsuccessful: 'Entwurfsseite nicht erfolgreich gespeichert.',
  draftSheetImageHotpointsFailure: '{algorithm} Hotpoints für das Entwurfsbild können nicht generiert werden.',
  draftValidationMessage: {
    item: 'ACHTUNG: Für BOM Teilenummer {partNumber} wurde keine Artikelnummer angegeben',
    empty: 'Fehler/Warnhinweise Keine Validierung',
    hotpointOrHotpointLink: 'Für die Stücklistenposition {item} wurde(n) kein(e) Hotpoint(s)/Hotpoint-Link(s) angegeben',
    quantity: 'Für die Stücklistenposition {item} wurde keine Menge angegeben',
    tag: 'Der Tag {tagName} der Stücklistenposition {item} hat keinen Tag-Wert',
    translationDescription: 'Die Stücklistenposition {item} hat keine {lang} Übersetzung der Beschreibung',
    uom: 'Für die Stücklistenposition {item} wurde keine Maßeinheit angegeben',
    supersede: 'Teil {partNumber} hat {supersededPartNumber} aufgrund von Ersetzung ersetzt.'
  },
  drawAutohotpointRegion: '{shape}-Größe zeichnen',
  drawMinimumArea: 'Mindestbereich zeichnen',
  dropFileMessage: 'Datei hier ablegen oder zum Hochladen klicken.',
  duplicate: 'Duplikat',
  duplicateSaveError: 'Doppelter Eintrag. Kann nicht gespeichert werden.',
  dutchDescription: 'Niederländische Beschreibung',
  dutchName: 'Niederländischer Name',
  dynamic: 'Dynamisch',
  dynamicNaming: 'Dynamische Namensvergabe',
  ecommerce: 'E-Commerce',
  ecommerceEmailFormatCreated: 'E-Mail-Format für E-Commerce erstellt',
  ecommerceEmailFormatSaved: 'E-Mail-Format für E-Commerce gespeichert',
  ecommerceLogo: 'E-Commerce-Logo',
  ecommerceUseExtOrder: 'Externe Bestellnummer für E-Commerce-Verwendung',
  edit: 'Bearbeiten',
  editItem: '{item} bearbeiten',
  email: 'E-Mail',
  emailFormat: 'E-Mail-Format',
  emailFormatTemplate: 'E-Mail-Format-Vorlage | E-Mail-Format-Vorlagen',
  emailList: 'E-Mail-Liste',
  emailMessage: 'Es wird eine E-Mail benötigt',
  emailRecipient: 'E-Mail-Empfänger | E-Mail-Empfänger',
  emailRecipientMessage: 'Eine E-Mail wird an diese E-Mail-Adressen gesendet, wenn der Bestellstatus geändert wird zu ',
  emptyMessage: {
    orderSuggestions: 'Für dieses Teil scheint es noch keine Bestellvorschläge zu geben.'
  },
  emptyBomState: 'Keine BOM-Artikel für Seite definiert. Klicken Sie auf +{add}, um damit zu beginnen',
  enable: 'Aktivieren',
  enable3d: '3D aktivieren',
  enableAll: 'Alle aktivieren',
  enableAPIKeyAdmin: 'API Key-Admin aktivieren',
  enabled: 'Aktiviert',
  enableCommerce: 'Handel aktivieren',
  enableEcommerceOrderManagement: 'Bestellmanagement aktivieren',
  enableExporter: 'Exportfunktion aktivieren',
  enableExportToLinkOne: 'Export nach LinkOne aktivieren',
  enableExportToSmartEquip: 'Export nach SmartEquip aktivieren',
  enablePDFDownloadWarning: 'Warnung beim Herunterladen großer PDF-Dateien aktivieren',
  largePDFDownloadWarning: 'Aufgrund der Größe der PDF-Datei kann das Laden und Anzeigen des Dokuments in der Anwendung länger als gewöhnlich dauern. Bitte haben Sie Geduld, während die Datei zum Anzeigen heruntergeladen wird.',
  enableIframeSupport: 'iFrame-Unterstützung aktivieren',
  enableLibraryBetaFeatures: 'Beta-Funktionen der Bibliothek aktivieren',
  enablePerformanceLogging: 'Leistungsprotokollierung aktivieren',
  enablePLZToDraft: 'PLZ-Verarbeitung zu Entwurf aktivieren',
  enableQuickAddToCart: 'Schnelles Hinzufügen zum Warenkorb aktivieren',
  enableQuickAddToCartHelpContext: 'Durch die Einstellung \, Aktivieren\‘ wird die Funktion „Schnell zum Warenkorb hinzufügen“ über dem Inhaltsbetrachter für diesen Inhalt aktiviert.',
  enableRocketUi: 'Rocket-Benutzererfahrung aktivieren',
  enableSmartEquipApiIntegration: 'SmartEquip API-Integration aktivieren',
  enableWidgetLogo: 'Documoto Widget-Logo aktivieren',
  enableWidgets: 'Widgets aktivieren',
  encryptedKey: 'Verschlüsselungsschlüssel',
  endDate: 'Enddatum',
  endDateBad: 'Das Startdatum muss gleich oder vor dem Enddatum liegen.',
  enforceNumericSize: 'Numerische Größe erzwingen',
  englishDescription: 'Englische Beschreibung',
  englishName: 'Englischer Name',
  enterprise: 'Unternehmen',
  enterReportParameters: 'Berichtparameter eingeben',
  enterUniqueNameForNewItem: 'Geben Sie einen Namen für den neuen {item} ein',
  enterValidNumber: 'Bitte geben Sie eine gültige Nummer ein',
  enterValidEmail: 'Bitte geben Sie eine gültige E-Mail ein',
  entityType: 'Objekttyp | Objekttypen',
  erp: 'ERP',
  erpEndpointConfig: 'ERP-Endpunktkonfiguration',
  erpPartInfo: 'Dauer des ERP-Teile-Info-Caches in Minuten',
  erpSystem: 'ERP-System',
  erpSystems: 'ERP-Systeme',
  erpSystemSaved: 'ERP-System gespeichert',
  error: 'Fehler',
  errorDeletePageMessage: 'Beim Löschen der Seite ist ein Fehler aufgetreten.',
  errorInputParsing: 'Problem beim Parsen von Eingabedatei.',
  errorPageMessage: 'Es ist ein Fehler aufgetreten.',
  errorPageTitle: 'Irgendwas ist hier schiefgelaufen.',
  errorType: {
    error: 'Fehler',
    warning: 'Warnhinweis'
  },
  essential: 'Merkmal | Merkmale',
  eta: 'ETA',
  etlJob: 'ETL',
  exact: 'Genau',
  excelFile: 'Excel-Datei',
  expandAll: 'Alle aufklappen',
  export: 'Exportieren',
  exportSearch: 'Suche exportieren',
  exportAsCsv: 'Als CSV exportieren (für den Import erforderliches Format)',
  exportAsCSVDropdown: 'Als CSV exportieren',
  exportAsHtml: 'Als HTML exportieren',
  exportAsPdf: 'Als PDF exportieren',
  exportAsPlz: 'Als PLZ exportieren',
  exportAsXlsx: 'Als XLSX exportieren',
  exportBasicPartInfo: 'Grundlegende Teile-Informationen exportieren',
  exportCartToXlsxDisabled: 'Export von Warenkorb nach XLSX deaktivieren',
  exportCartToXlsxEnabled: 'Export von Warenkorb nach XLSX aktivieren',
  exportDisplayedCartColumns: 'Angezeigte Warenkorb-Spalten exportieren',
  exportJob: 'Exportauftrag',
  exportLane: 'Exportspur',
  exportMessage: 'Exportauftrag erfolgreich übermittelt.',
  exporter: 'Exportfunktion',
  exporterMessage: 'Ermöglicht den Export von Inhalten',
  exportParts: 'Teile Exportieren',
  external: 'Extern| Externe',
  externalUrl: 'Externe URL',
  faceted: 'Facettiert',
  facetedTag: 'Filterwerte sollten mehr Kriterien enthalten',
  facetedTagTooMany: 'Tag überschreitet das 1000-Tagwertlimit',
  failedAction: '{content} konnte nicht {action}',
  failedProcessContentSql: 'Inhalt konnte aufgrund von unzulässigem Text nicht bearbeitet werden. Bitte laden Sie den unterstützten Inhalt erneut hoch.',
  failedToCopy: '{content} konnte nicht kopiert werden.',
  failedToDelete: '{content} konnte nicht gelöscht werden.',
  failedToLoad: '{content} konnte nicht geladen werden.',
  failedToSave: '{content} konnte nicht gespeichert werden.',
  failedToSubmitItem: '{item} konnte nicht gesendet werden',
  failedToUpdate: '{content} konnte nicht aktualisiert werden.',
  false: 'Falsch',
  faq: 'FAQ',
  favorites: 'Favoriten',
  favoritesLists: 'Favoriten-Liste',
  february: 'Februar',
  feedbackForm: 'Feedback senden',
  feedbackFormMessage: 'Nennen Sie uns die Funktionen, die Sie gerne hätten',
  fieldDefinition: 'Feldbestimmung',
  field: 'Feld | Felder',
  fieldName: 'Feldname',
  file: 'Datei | Dateien',
  filename: 'Dateiname',
  filesSubmitted: 'Datei(en) zur Bearbeitung übermittelt',
  fileUpload: 'Hochladen von Dateien',
  fill: 'Füllen',
  filter: 'Filter | Filter',
  filterOrgNameOnly: 'Filter: Org Name Only',
  filterOrgParentNames: 'Filter: Name der Org. und des Mutterunternehmens',
  filterable: 'Filterbar',
  filterableTag: 'Filterbares Tag',
  filterBy: 'Filtern Nach',
  filterBoundsError: 'Filter muss 3 oder mehr Zeichen enthalten',
  finnishDescription: 'Finnische Beschreibung',
  finnishName: 'Finnischer Name',
  firstName: 'Vorname',
  firstNameEmpty: 'Vorname-Feld darf nicht leer sein',
  fixedHotpointSize: 'Feste Hotpoint-Größe',
  font: 'Schriftart | Schriftarten',
  footer: 'Fußzeile',
  forgotPassword: 'Passwort vergessen',
  forgotPasswordMessage: 'Es wird eine E-Mail mit Anweisungen zum Zurücksetzen Ihres Passworts an den angegebenen Benutzernamen gesendet.',
  forgotPasswordSuccess: 'Wenn der angegebene Benutzername gültig ist, wird eine E-Mail mit Anweisungen zum Zurücksetzen Ihres Passworts an die hinterlegte Adresse gesendet.',
  form: 'Formular | Formulare',
  format: 'Format',
  formattingOptions: 'Formatierungsoptionen',
  formCount: 'Anzahl der Formulare',
  formName: 'Name des Formulars',
  formType: 'Art des Formulars',
  freezeImage: 'Bild einfrieren',
  frenchDescription: 'Französische Beschreibung',
  frenchName: 'Französischer Name',
  fri: 'Fr.',
  from: 'Von',
  fromDate: 'Absendedatum',
  frontCover: 'Titelseite',
  frontStitch: 'Vorne Anheften',
  fullScreen: 'Vollbild',
  gdprFaqs: 'DSGVO FAQ',
  gdprMessage: 'Wenn Sie sich in diesem Abschnitt unserer Website anmelden, akzeptieren Sie, dass wir grundlegende Cookies verwenden. Diese Cookies sind grundlegend, um unsere Dienste durchsuchen zu können. Ohne diese Cookies können wir die von Ihnen angeforderten Dienste nicht bereitstellen. Weitere Informationen finden Sie in unserer {policy}.',
  general: 'Allgemeines',
  generalSearch: 'Allgemeine Suche',
  generic: 'Allgemein',
  germanDescription: 'Deutsche Beschreibung',
  germanName: 'Deutscher Name',
  gotIt: 'Verstanden',
  goTo: 'Gehe zu',
  goToHome: 'Zur Startseite',
  goToJobManager: 'Zum Auftragsmanager',
  goToSearch: 'Zur Suche',
  hashKey: 'Rautetaste',
  headerAmpFooter: 'Kopf- und Fußzeile',
  header: 'Kopfzeile',
  help: 'Hilfe',
  helpCenter: 'Hilfe-Center',
  highlightDescription: 'Markieren Sie Teile in der Teileliste mit diesem Tag',
  history: 'Verlauf',
  home: 'Startseite',
  horizontal: 'Horizontal',
  host: 'Host',
  hotpoint: 'Hotpoint | Hotpoints',
  hotpointCalloutBehavior: 'Hotpoint Callout-Verhalten',
  hotpointLinksWillBeCreated: 'Hotpoint-Links werden in {count} {content} erstellt',
  hotpointLink: 'Hotpoint-Link | Hotpoint-Links',
  hotpointsGenerated: '{count} Hotpoints auf dem Bild gefunden.',
  hotpointShift: 'Hotpoint (Umschalttaste H)',
  hourlyDWSRequestLimit: 'Stündliches DWS-Anfragelimit',
  hourlyRESTRequestLimit: 'Stündliches REST-Anfragelimit',
  htmlUiUserSwitchEnabled: 'Benutzerwechsel für HTML-Benutzeroberfläche aktiviert',
  hungarianDescription: 'Ungarische Beschreibung',
  hungarianName: 'Ungarischer Name',
  icon: 'Symbol',
  id: 'ID',
  identifier: 'Bezeichner',
  ignore: 'IGNORIEREN',
  ignoreAccessControls: 'Zugriffskontrollen ignorieren',
  ignoreMatchingTranslations: 'Übersetzungen mit passenden Gebietsschemata beim Import ignorieren',
  illustration: 'Illustration | Illustrationen',
  image: 'Bild | Bilder',
  imageDeleteMessage: 'Dieses Seitenbild wirklich löschen?',
  imageUploadFailure: 'Bild konnte Nicht Hochgeladen werden',
  imageSaveFail: 'Bild konnte Nicht Gespeichert werden',
  imageOptimizer: 'Bildoptimierer',
  imageSizeExceededTitle: 'Maximale Bildgröße überschritten',
  imageSizeExceededMessage: 'Die bei Documoto maximal zulässige Bilddateigröße beträgt {size} Megabyte. Das Bild, das Sie hochladen möchten, hat das Maximum überschritten und kann nicht geladen werden. Ändern Sie bitte die Größe des Bildes, um es zu dieser Seite hinzuzufügen.',
  imageDimensionsExceededMessage: 'Die maximale Bildgröße in Documoto beträgt 65,5 Megapixel (8191 X 8191 Pixel).  Das hochzuladende Bild überschreitet die maximale Größe und kann nicht geladen werden.Klicken Sie auf „Größe anpassen“, um die Größe über Documoto anzupassen, und fügen Sie das Bild ein; oder klicken Sie auf „Abbrechen“, um das Bild zu bearbeiten und manuell einzufügen',
  import: 'Importieren',
  importBOM: 'Stückliste Importieren',
  importBOMError: 'Beispieldaten konnten nicht verarbeitet werden, Eingangsquelle erneut prüfen',
  importParts: 'Teile Importieren',
  inCart: 'In den Warenkorb',
  inCartDescription: 'Tags im Warenkorb anzeigen',
  includePagePartDetails: 'Details auf Seitenteil-Ebene einschließen',
  includePartDetails: 'Details auf Teilebene einschließen',
  indexQueueJob: 'Index-Warteschlange',
  inPartsList: 'In Teileliste',
  inPartsListDescription: 'Tags in Teileliste anzeigen',
  inputSource: 'Eingabequelle',
  inch: 'Zoll | Zoll',
  includeComments: 'Kommentare einbeziehen',
  includeCommentsAuthorAndDate: 'Autor und Datum des Kommentars einbeziehen',
  includeHeaderRow: 'Kopfzeile einschließen',
  includeImages: 'Bilder einbinden',
  includePagesIndex: 'Seitenverzeichnis einbeziehen',
  includePartsIndex: 'Teileverzeichnis einbinden',
  includePartsList: 'Teileliste einbinden',
  includeSubChapters: 'Unterkapitel einbeziehen',
  indexLane: 'Index-Spur',
  useSequentialPageNumbering: 'Fortlaufende Seitennummerierung verwenden',
  hideBookInSearchAndRecent: 'Bücher in Suchresultaten und Vor Kurzem Hinzugefügt verbergen',
  hideDuplicatePages: 'Doppelte Seiten verbergen',
  hidePageInSearchAndRecent: 'Seiten in Suchresultaten und Vor Kurzem Hinzugefügt verbergen',
  hidePartInSearchAndRecent: 'Teile in Suchresultaten und Vor Kurzem Hinzugefügt verbergen',
  homePageSettings: 'Einstellungen der Startseite',
  hotpointZoomBehavior: 'Hotpoint Zoom-Verhalten',
  includeNewParts: 'Nur „neue“ Teile einschließen (nicht in Documoto)',
  includeWatermark: 'Wasserzeichen einfügen?',
  increaseHotpoint: 'Hotpoint Erhöhen',
  indexed: 'Indexiert',
  indexingConfiguration: 'Indexierungskonfiguration',
  indexIncludeTimestamp: 'Index Zeitstempel einschließen',
  info: 'Info',
  information: 'Informationen',
  inherit: 'Übernehmen',
  inheritedParent: 'Von Mutterunternehmen übernommen.',
  inputSpecification: 'Eingabespezifizierung',
  inputType: 'Eingangstyp',
  inputTypeListboxWarning: 'Sortierkonfigurationen werden nach dem Speichern angezeigt',
  integration: 'Integration | Integrationen',
  integrationsAddPartToErpCart: '{action} Teil zu ERP-Warenkorb hinzufügen',
  integrationsAddToShoppingCartPricingFromErp: '{action} Zum Warenkorb-Preis von ERP hinzufügen',
  integrationsDisableErp: 'ERP deaktivieren',
  integrationsDoErpForQuotes: 'ERP für Angebote verwenden',
  integrationsEnableErp: 'ERP aktivieren',
  integrationsEnableFetchPartInfoFromErp: 'Teileinfo von ERP einholen aktivieren',
  integrationsEnableScopeErp: '{scope} ERP aktivieren',
  integrationsEnableSendShoppingCartToErp: 'Senden von Warenkorb nach ERP aktivieren',
  integrationsEnableSyncPoAddressWithErp: 'PO-Adressen mit ERP Synchronisieren aktivieren',
  integrationsErpAvailabilityMappedFrom: 'ERP-Verfügbarkeit Erstellt Aus',
  integrationsErpCurrencyCodeMappedFrom: 'ERP-Währungscode Erstellt Aus',
  integrationsErpDiscountedPriceMappedFrom: 'ERP-Reduzierter Preis Erstellt Aus',
  integrationsErpEtaMappedFrom: 'ERP-ETA Erstellt Aus',
  integrationsErpMappings: 'ERP-Mappings',
  integrationsErpRetailPriceMappedFrom: 'ERP-Verkaufspreis Erstellt Aus',
  integrationsErpWholesalePriceMappedFrom: 'ERP-Großhandelspreis Erstellt Aus',
  integrationsInvokeGetPartInfoFromClient: 'GetPartInfo vom Client aufrufen',
  integrationsParseAddressForType: 'Adresse nach Typ aufgliedern',
  integrationsShoppingCartAndPartsTitle: 'Warenkorb und Teile',
  integrationsShoppingCartAvailability: '{action} Warenkorb-Verfügbarkeit',
  integrationsShoppingCartEtaFromErp: '{action} Warenkorb-ETA von ERP',
  internalApiKey: 'Interner API-Schlüssel',
  internalDescription: 'Interne Beschreibung',
  internalName: 'Interner Name',
  internalNameOptional: 'Ihre Bezeichnung für das Tag',
  internalNameRequired: 'Ein Name ist erforderlich',
  invalidDecimals: 'Es sind nur zwei Dezimalstellen zulässig',
  invalidField: '{field} ist ungültig',
  invalidFileType: 'Dateityp wird nicht unterstützt. Bitte gehen Sie zum Documoto Support Center, um eine vollständige Liste der unterstützten Dateierweiterungen anzuzeigen.',
  isSearchable: 'Ist Durchsuchbar',
  insertDataField: 'Datenfeld einfügen',
  isSystem: 'Ist das System',
  italianDescription: 'Italienische Beschreibung',
  italianName: 'Italienischer Name',
  item: 'Nr.',
  itemAlt: 'Element',
  itemCopied: '{item} kopiert',
  itemDeleted: '{item} gelöscht',
  itemInProgress: '{item} in Arbeit',
  itemInformation: '{item}-Informationen',
  itemMustBeLteItem: '{itemA} muss gleich oder vor {itemB} sein.',
  itemMustBeUnique: '{item} muss einmalig sein',
  itemMustBeUniqueAndNotEmpty: '{item} muss einmalig sein und ist erforderlich',
  itemName: '{item}-Name',
  itemNotShown: '',
  itemSaved: '{item} gespeichert',
  itemSpecification: 'Spezifizierung der Position',
  showChapterTOCForHiddenPages: 'Kapitel-Inhaltsverzeichnis für Verborgene Seiten anzeigen',
  january: 'Januar',
  japaneseDescription: 'Japanische Beschreibung',
  japaneseName: 'Japanischer Name',
  job: 'Auftrag| Aufträge',
  jobManager: 'Auftragsmanager',
  jobManagerMessage: 'Aufträge exportieren, veröffentlichen und drucken/herunterladen',
  july: 'Juli',
  june: 'Juni',
  koreanDescription: 'Koreanische Beschreibung',
  koreanName: 'Koreanischer Name',
  label: 'Bezeichnung | Bezeichnungen',
  labelKey: 'Beschreibung der Bezeichnungen',
  landscape: 'Querformat',
  laneConfiguration: 'Spurkonfiguration',
  language: 'Sprache',
  lastModifiedDate: 'Datum der letzten Änderung',
  lastGenerated: 'Zuletzt Generiert',
  lastName: 'Nachname',
  lastNameEmpty: 'Nachname-Feld darf nicht leer sein',
  lastUpdatedOn: 'Zuletzt aktualisiert am',
  layout: 'Layout',
  layoutOptions: 'Layout-Optionen',
  ledger: 'Hauptbuch',
  left: 'Links',
  legal: 'Impressum',
  letter: 'Brief',
  library: 'Bibliothek',
  libraryAdmin: 'Bibliotheksverwaltung',
  libraryBannerLogo: 'Logo Bibliotheksbanner',
  librarySettings: 'Bibliothekseinstellungen',
  librarySettingsDescription: 'Diese Einstellungen steuern verschiedene Elemente und Optionen der Bibliothek. Gehen Sie sorgfältig vor, wenn Sie Änderungen durchführen. Kontaktieren Sie Documoto Services für weitere Informationen.',
  licenseConfiguration: 'Lizenzkonfiguration',
  line: 'Linie',
  lineTool: 'Linienwerkzeug',
  link: 'Link | Links',
  listBox: 'Listenfeld',
  listBoxValue: 'Listenfeld-Wert | Listenfeld-Werte',
  listValue: 'Listenwert | Listenwerte',
  load: 'Laden',
  loadMore: 'Mehr laden',
  loading: 'Wird geladen',
  locale: 'Standort',
  login: 'Anmelden',
  loginBackground: 'Anmeldung-Hintergrund',
  loginGDPRMessageBeginning: 'Diese Website verwendet Cookies. Wenn Sie diese Website weiterhin nutzen, stimmen Sie der Verwendung von Cookies zu. Weitere Informationen finden Sie in unserer ',
  loginGDPRMessageEnd: 'und unseren',
  loginSSO: 'SSO-Anmeldung',
  logiReportName: 'Logi-Report-Name',
  logiReportToggle: 'Logi-Report?',
  logo: 'Logo | Logos',
  logout: 'Abmelden',
  lookupLoadParts: 'Beim Importieren Teile aus Documoto Suchen/Laden',
  lowerBound: 'Untere Grenze',
  makePagesDivisibleBy: 'Seiten Teilbar machen Durch:  ',
  mainInfo: 'Hauptinfo',
  mainLogo: 'Hauptlogo',
  manageItem: '{item} verwalten',
  management: 'Management',
  march: 'März',
  margin: 'Marge | Margen',
  markdown: 'Abschlag',
  may: 'Mai',
  media: 'Medium | Medien',
  mediaCategory: 'Medienkategorie | Medienkategorien',
  mediaCategoryEditor: 'Medienkategorie-Editor',
  mediaCategoryName: 'Bezeichnung der Medienkategorie',
  mediaCategoryNameMissing: 'Für alle Medienkategorien ist ein Name erforderlich',
  mediaBookDeleteMessage: 'Wenn Sie diese Mediendatei löschen, wird der Zugang zu diesem Inhalt in der Bibliothek und Inhaltsverwaltung entfernt und er wird nicht mehr für Benutzer oder Inhaltsadministratoren verfügbar sein. Alle in diesem Buch enthaltenen Teile oder Seiten, die in anderen Büchern nicht zu finden sind, werden auch in der Bibliothek nicht mehr verfügbar sein.',
  mediaDeleteMessage: 'Wenn Sie die Vorlage löschen, wird sie gelöscht von der Inhaltsverwaltung und sie wird nicht mehr zum Kopieren in neue Medien verfügbar sein.',
  mediaDeleteSuccessText: 'Sie wird von den Listen gelöscht, nachdem die Indizierung abgeschlossen ist.',
  mediaDescription: 'Medienbeschreibung',
  mediaIdentifier: 'Medienkennung',
  mediaName: 'Medienname',
  mediaPrintSettingsSaveSuccess: 'Medien-Druckeinstellungen gespeichert',
  mediaWhereUsed: 'Medien-Verwendungsnachweis',
  menu: 'Menü',
  merge: 'Zusammenführen',
  mergeOrOverrideMessage: 'Möchten Sie diese Elemente überschreiben oder zusammenführen?',
  mergeWarning: 'Durch das Importieren von Daten werden vorhandene Teile aktualisiert und die neuen Einträge hinzugefügt',
  message: 'Nachricht',
  messageLevel: 'Nachrichtenebene',
  microsite: 'Microsite | Microsites',
  micrositeAdmin: {
    cardSubtitle: 'Die komprimierte Inhaltsdatei und HTML-Eingabeinformationen',
    cardTitle: 'Site Content',
    errorRequiredFile: 'Es wird eine Site Content ZIP-Datei benötigt',
    fieldFile: 'Site Content ZIP-Datei',
    fieldPoint: 'HMTL-Eingabe-Punkt',
  },
  minArea: 'Mindestbereich',
  minAreaMessage: 'Es muss ein Mindestwert angegeben werden. Der Wert darf den Bereich von 1 bis 1500 nicht überschreiten.  Der Wert muss eine ganze Zahl sein.',
  moreInfo: 'Mehr Informationen',
  multipleAlternatingPages: 'Mehrere Alternierende Seiten',
  multipleAlternatingPagesMessage: 'Diagramm auf Seite 1 und 3, Teileliste auf Seite 2 und 4.',
  mToM: 'M2M',
  multiplePages: 'Mehrere Seiten',
  multiplePagesMessage: 'Diagramm auf eigener Seite und Teilelisten-Seiten',
  requiredField: 'Pflichtfeld',
  messageMessage: 'Eine Nachricht ist erforderlich',
  metadata: 'Metadaten',
  mine: 'Mein',
  miscellaneous: 'Sonstiges',
  mon: 'Mo.',
  myCart: 'Mein Warenkorb | Meine Warenkörbe',
  navigation: 'Navigation',
  nItemsCreated: '{n} {items} Erstellt',
  nItemsDeleted: '{n }{items} Gelöscht',
  nOfMItems: '{n} von {m} {items}',
  name: 'Name',
  nameDescriptionHelpText: 'Name und wahlweise Beschreibung',
  new: 'Neu',
  newAPIKey: 'Neuer API-Schlüssel',
  newERPSystem: 'Neues ERP-System',
  newItem: 'Neues {item}',
  newKeyGenerated: 'Neuer Integrationsschlüssel Generiert, Für eine zukünftige Verwendung Kopieren und Aufbewahren',
  newMediaCategory: 'Neue Medienkategorie',
  newPassword: 'Neues Passwort',
  newsItem: 'Nachricht | Nachrichten',
  newTenant: 'Neuer Mandant',
  newWidgetKey: 'Neuer Widget-Schlüssel',
  newWidgetKeyGenerated: 'Neuer Widget-Schlüssel generiert; kopieren und für zukünftige Verwendung aufbewahren',
  noTenantSuppliers: 'Dem Mandanten wurden keine Lieferanten zugewiesen.',
  noAPIKeys: 'Keine API-Schlüssel festgelegt.',
  node: 'Knoten',
  noData: 'Keine Daten',
  noFilesSelected: 'Keine Dateien Ausgewählt',
  noIllustrationFound: 'Keine Illustration Gefunden',
  noImageFound: 'Kein Bild Gefunden',
  noItemAvailable: 'Kein {item} verfügbar',
  noItemFound: 'Kein {item} gefunden.',
  noItemSelected: 'Kein {item} ausgewählt',
  noItemAddedToTarget: 'Kein {item} zum {target} hinzugefügt. ',
  noItemsFound: 'Keine Elemente Gefunden',
  noMoreResults: 'Keine weiteren Ergebnisse',
  noFormsMessage: 'Keine Formulare Festgelegt.',
  noMediaDefined: 'Keine Medien festgelegt',
  nonSearchableFieldsSuccessfullyUpdated: 'Nicht durchsuchbare Felder erfolgreich aktualisiert.',
  noPartsSelected: 'Keine Teile gewählt.',
  noPrice: 'Preisanfrage',
  noResults: 'Keine Ergebnisse',
  norwegianDescription: 'Norwegische Beschreibung',
  norwegianName: 'Norwegischer Name',
  noSearchableTags: 'Keine durchsuchbaren Tags',
  noSuggestions: 'Keine Vorschläge zum Suchbegriff',
  notOrderable: 'Nicht Bestellbar',
  notSelected: 'Nicht ausgewählt',
  noTagsFound: 'Keine Tags gefunden',
  noUnsavedChanges: 'Keine Nicht Gespeicherten Änderungen',
  none: 'Keine',
  notImplemented: 'Nicht Implementiert',
  notification: 'Benachrichtigung | Benachrichtigungen',
  notificationsMessage: 'Wichtige Ankündigungen und Nachrichten',
  notMapped: 'Nicht Abgebildet',
  november: 'November',
  noWidgetKeys: 'Keine Widget-Schlüssel definiert.',
  numeric: 'Numerisch',
  numberOf: 'Nr. von {item}',
  october: 'Oktober',
  of: 'von',
  off: 'Aus',
  offlineContent: 'Offline-Inhalt',
  ok: 'Okay',
  oldPassword: 'Altes Passwort',
  on: 'Ein',
  opacity: 'Opazität',
  open: 'Öffnen',
  openItem: '{item} öffnen',
  optimizeIndexJob: 'Index Optimieren',
  options: 'Optionen',
  openTab: 'Link in einer neuen Registerkarte öffnen',
  order: 'Bestellung | Bestellungen',
  orderable: 'Bestellbar',
  orderableDescription: 'Teil kann in den Warenkorb hinzugefügt und gekauft werden',
  orderableLocal: 'Kann auf dieser Seite bestellt werden',
  orderableLocalBlocked: 'Teil ist Global nicht Bestellbar',
  orderDisable: 'Bestellverlauf deaktivieren',
  orderEmailRecipient: 'Standard-E-Mail-Empfänger |Standard-E-Mail-Empfänger',
  orderEntity: 'Bestelleinheit | Bestelleinheiten',
  orderFieldUneditableRequired: 'Wenn Sie ein Bestell-/Angebotsfeld auf „Erforderlich“ und nicht auf „Vom Benutzer bearbeitbar“ setzen, kann Ihr Bestell-/Angebotsformular möglicherweise nicht mehr ausgefüllt werden. Überprüfen Sie bitte, ob Ihre Bestell-/Angebotsformulare ausgefüllt werden können.',
  orderFieldsEmptyMessage: 'Keine Bestell-/Angebotsfelder festgelegt.',
  orderFieldsEmptyPrompt: 'Ein Bestell-/Angebotsfeld hinzufügen',
  orderField: {
    nameMessage: 'Wenn Sie den Namen des Bestell-/Angebotsfelds ändern, müssen Sie alle Bestell-/Angebotsformulare bzw. E-Mail-Formate aktualisieren, die dieses Feld verwenden.',
  },
  orderFieldCopyMessage: 'Geben Sie einen Namen für das neue Bestell-/Angebotsfeld ein.',
  orderForm: {
    uniqueNameInputHint: 'Geben Sie einen Namen für das neue Formular ein.'
  },
  orderFormField: 'Bestell-/Angebotsformularfeld | Bestell-/Angebotsformularfelder',
  orderFormLayout: 'Bestell-/Angebotsformular-Layout | Bestell-/Angebotsformulare-Layouts',
  orderFormSetDefault: 'Standardauftrags-/Angebotsformular-Satz',
  orderFormType: 'Bestellformular',
  orderLayout: 'Bestellung-Layout | Bestellungen-Layout',
  orderManagementApprover: 'Bestellmanagement-Genehmigungen',
  orderManagementDisable: 'Bestellmanagementverwaltung deaktivieren',
  orderManagementViewer: 'Bestellmanagement-Anzeige',
  orderNumber: 'Bestellnummer',
  orderQuoteEmail: 'E-Mail-Adresse für Bestellungen/Angebote | E-Mail-Adressen für Bestellungen/Angebote',
  orderQuoteEmailFormat: 'E-Mail-Format für Bestellungen/Angebote| E-Mail-Formate für Bestellungen/Angebote',
  orderQuoteEmailFormatCopyMessage: 'Sprache für das neue E-Mail-Format für Bestellungen/Angebote auswählen.',
  orderQuoteFetchError: 'Fehler beim Abrufen der Bestell-/Angebotsformulardaten',
  orderQuoteForm: 'Bestell-/Auftragsformular | Bestell-/Auftragsformulare',
  orderQuoteSaveConfirmation: 'Ihre Änderungen an den Bestell-/Angebotsformularen des Unternehmens speichern?',
  orderQuoteSaveError: 'Fehler beim Speichern der Bestell-/Angebotsformulardaten',
  orderQuoteFormSaved: 'Bestell-/Angebotsformular gespeichert',
  orderStatus: 'Bestell-/Angebotsformular-Status | Bestell-/Angebotsformular-Status',
  orderStatusDeleteMessage: 'Dieser Status wurde für Bestellungen verwendet und kann nicht gelöscht werden.',
  orderSubmittedMessage: 'Erfolgreich Gesendet',
  orderSuggestion: 'Bestellempfehlung | Bestellempfehlungen',
  orderSuggestionEnable: 'Bestellempfehlungen aktivieren',
  orderSuggestionRemovalWarning: 'Das Teil wird nicht mehr als Bestellempfehlung angezeigt. | Die Teile werden nicht mehr als Bestellempfehlungen angezeigt.',
  orderSuggestionThreshold: 'Bestellempfehlung-Grenzwert',
  ordersLogo: 'Bestellungen-Logo',
  ordersMessage: 'Verlauf der Bestellungen',
  orientation: 'Orientierung',
  org: 'Org.',
  orgEmailRecipients: 'E-Mail-Empfänger der Organisation',
  organization: 'Organisation | Organisationen',
  organizationAdmin: 'Organisationsverwaltung',
  organizationAdminBrowseFlow: 'Eine Organisation kann den Standard-Durchsuchungsverlauf verwenden oder Sie überschreiben ihn und verwenden einen anderen Durchsuchungsverlauf.',
  organizationAdminMessage: 'Steuert Benutzermanagement und Organisationseinstellungen',
  organizationEmpty: 'Organisation muss gewählt werden',
  organizationEmailRecipients: 'E-Mail-Emfpänger der Organisation',
  organizationEnforceAccessControlsForQuickAdd: 'Zugriffssteuerung für Schnelles Hinzufügen aktivieren',
  organizationKey: 'Erklärung der Organisationen',
  organizationLibrarySettingsDescription: 'Diese Einstellungen steuern verschiedene Elemente und Optionen der Bibliothek auf Organisationsebene. Gehen Sie sorgfältig vor, wenn Sie Änderungen durchführen. Kontaktieren Sie Documoto Services für weitere Informationen.',
  organizationMoveToRoot: 'Hier ablegen, um ins Stammverzeichnis zu verschieben',
  organizationName: 'Organisationsname',
  organizationRearrange: 'Organisationen neu ordnen',
  organizationRearrangeDescription: 'Die Mutter-Tochter-Organisationsstruktur definiert viele Attribute einer Organisation, die übernommen werden, wie z. B. Druckeinstellungen, Stile usw. Verschieben Sie die Organisationen, um die gewünschte Hierarchie darzustellen.',
  organizationReportSettingsSuccessMessage: 'Berichterstattungseinstelllungen wurden gespeichert',
  organizationSelect: 'Organisation wählen',
  organizationTags: 'Organisations-Tags',
  organizationsEmpty: 'Sie scheinen noch keine Organisationen erstellt zu haben',
  orgNameOnly: 'Nur Name der Org.',
  other: 'Sonstige',
  otherSettings: 'Sonstige Einstellungen',
  outboundCustomerIdParameter: 'Parameterbezeichnung für Outbound-Kunden-ID',
  outboundSessionIdParameter: 'Parameterbezeichnung für Outbound Session ID',
  outlineMode: 'Gliederungsmodus (Umschalttaste O)',
  output: 'Ausgabe',
  outputCatalog: 'Ausgabekatalog',
  outputDefinition: 'Ausgabe-Definition | Ausgabe-Definitionen',
  outputDefinitionDeleted: 'Ausgabe-Definition gelöscht',
  outputDefinitionSaved: 'Ausgabe-Definition gespeichert',
  outputFileName: 'Ausgabe-Dateiname',
  outputName: 'Ausgabe-Name',
  outputDestination: 'Ausgabeziel',
  outputSpecification: 'Ausgangsspezifikationen',
  outputSubType: 'Ausgabe-Subtyp',
  outputType: 'Ausgabetyp',
  overridden: 'Überschrieben',
  overriddenByOrg: 'Überschrieben von Organisation',
  override: 'Überschreiben',
  overrideBrowseFlow: 'Durchsuchungsverlauf überschreiben',
  overrideFacetLimitValue: 'Facettengrenzwert überschreiben',
  overrideHotpointScale: 'Hotpoint-Skala überschreiben',
  overridePdfNamingStandard: 'PDF-Filenaming-Standard umgehen',
  overridePrintSettings: 'Druckeinstellungen überschreiben',
  overrideFontSettings: 'Schriftart-Einstellungen überschreiben',
  overrideOrderForm: 'Bestellformular überschreiben',
  overrideQuoteForm: 'Angebotsformular überschreiben',
  overrideSvgOpacity: 'SVG-Transparenz überschreiben',
  overrideWarning: 'Durch das Importieren von Daten wird Ihre aktuelle Stückliste ersetzt',
  owner: 'Inhaber | Inhaber',
  page: 'Seite | Seiten',
  pageBulkInsertConfirmTitle: 'Möchten Sie die Masseneinfügung durchführen?',
  pageBulkInsertConfirmInsertAfter: 'Hierdurch wird {replacement} nach {original} in {whereUsedBookCount} {bookUnits} und {whereUsedTemplateCount} {templateUnits} eingefügt.',
  pageBulkInsertConfirmInsertBefore: 'Hierdurch wird {replacement} vor {original} in {whereUsedBookCount} {bookUnits} und {whereUsedTemplateCount} {templateUnits} eingefügt.',
  pageBulkInsertConfirmInsertReplace: 'Hierdurch wird {replacement} in {whereUsedBookCount} {bookUnits} und {whereUsedTemplateCount} {templateUnits} eingefügt und {original} entfernt.',
  pageBulkInsertBooksConfirmInsertAfter: 'Hierdurch wird {replacement} nach {original} in {whereUsedBookCount} {bookUnits} eingefügt.',
  pageBulkInsertBooksConfirmInsertBefore: 'Hierdurch wird {replacement} vor {original} in {whereUsedBookCount} {bookUnits} eingefügt.',
  pageBulkInsertBooksConfirmInsertReplace: 'Hierdurch wird {replacement} in {whereUsedBookCount} {bookUnits} eingefügt und {original} entfernt.',
  pageBulkInsertTemplatesConfirmInsertAfter: 'Hierdurch wird {replacement} nach {original} in {whereUsedTemplateCount} {templateUnits} eingefügt.',
  pageBulkInsertTemplatesConfirmInsertBefore: 'Hierdurch wird {replacement} vor {original} in {whereUsedTemplateCount} {templateUnits} eingefügt.',
  pageBulkInsertTemplatesConfirmInsertReplace: 'Hierdurch wird {replacement} in {whereUsedTemplateCount} {templateUnits} eingefügt und {original} entfernt.',
  pageBulkInsertDiscard: 'Ihre Masseneinfügung wird nicht durchgeführt.',
  pageBulkInsertHelpText: 'Mit dieser Funktion können Sie die Quellseite {thisDisplayName} in ausgewählte Teile-Bücher einfügen, die bereits eine andere Seite {originalDisplayName} enthalten. Wahlweise können Sie {originalDisplayName} auch von diesen Büchern entfernen.',
  pageBulkInsertInsertAfter: '{replacement} NACH {original} einfügen',
  pageBulkInsertInsertBefore: '{replacement} VOR {original} einfügen',
  pageBulkInsertInsertReplace: '{replacement} einfügen und {original} ENTFERNEN',
  pageBulkInsertScopeAll: 'Alle {type}, die die Zielseite haben {originalDisplayName} ({whereUsedCount} {units})',
  pageBulkInsertScopeNone: 'Keine der {type}, die die Zielseite haben{originalDisplayName}',
  pageBulkInsertScopeSome: 'Einige der {type}, die die Zielseite haben{originalDisplayName}',
  pageBulkInsertStep1: 'Schritt 1: Finden Sie die gewünschte Zielseite',
  pageBulkInsertStep2: 'Schritt 2: Bestimmen Sie, wie die Seiten eingefügt oder ersetzt werden',
  pageBulkInsertStep3: 'Schritt 3: Wählen Sie die Bücher, in die die Masseneinfügung durchgeführt werden soll.',
  pageBulkInsertStep4: 'Schritt 4: Wählen Sie die Vorlagen, in die die Masseneinfügung durchgeführt werden soll.',
  pageBulkInsertSuccessTitle: 'Masseneinfügungsauftrag wurde übermittelt',
  pageBulkInsertSuccessBody: 'Änderungen des Inhaltsverzeichnisses des Buches erfolgen sofort. Die Suchresultate ändern sich, nachdem die Indizierung abgeschlossen ist.',
  pageBulkErrorTooManyBooks: 'Begrenzen Sie die ausgewählte Anzahl von Büchern auf nicht mehr als 1000',
  pageBuilder: 'Seiten-Builder',
  pageComposer: 'Page Composer',
  pageCreated: 'Seite Erstellt',
  pageDeleteMessage: 'Das Löschen einer Seite erfolgt umgehend in der Bibliothek und im Inhalt-Admin. Das gelöschte Teil kann weiterhin gesucht werden, bis die Neuindizierung innerhalb weniger Minuten erfolgte.',
  pageDeleteMessage2: 'Diese Aktion kann ohne Hilfe des Documoto-Supports nicht rückgängig gemacht werden. Nach 90 Tagen werden der gelöschte Datensatz und alle Verknüpfungen dauerhaft gelöscht.',
  pageDescription: 'Seitenbeschreibung',
  pageFile: 'Auslagerungsdatei',
  pageFilename: 'Seiten-Dateiname',
  pageFilenameDeleted: '{filename} wird gelöscht.',
  pageFileRequired: 'Eine Auslagerungsdatei ist erforderlich.',
  pageBomPartsDeleted: '{count} Teile auf der BOM-Seite werden nicht mehr über {filename} zugänglich sein.',
  pageDraftsDeleted: 'Mit dieser Seite verknüpfte Seitenentwürfe werden dauerhaft gelöscht.',
  pageFit: 'Seitenanpassung',
  pageHeader: 'Seitenkopf',
  pageLabel: 'Seite | Seiten',
  pageLayout: 'Seitenlayout',
  pageName: 'Seitenname',
  pagePart: 'Seitenteil | Seitenteile',
  pagePartTag: 'Seiten-Teiletag | Seiten-Teiletags',
  pagePartData: 'Seitenteildaten',
  pageUsageDataCouldNotBeLoaded: 'Seitennutzungsdaten konnten nicht geladen werden',
  pagePartLevelDetails: 'Seite Teil bestellbar/sichtbar, Seite Teile-Tags',
  pagePartNumberVisible: 'Seiten-Teilenummer Sichtbar',
  pagePartOrderable: 'Seiten-Teil Bestellbar',
  pagePartOrderableDescription: 'Teil von dieser Seite Bestellbar',
  pagePartVisibleDescription: 'Teilenummer auf dieser Seite sichtbar',
  pageUpdated: 'Seite Aktualisiert',
  pageViewer: 'Seitenübersicht',
  pageWidth: 'Seitenbreite',
  pagesWhereUsed: 'Seiten-Verwendungsnachweis',
  parentName: 'Name des Mutterunternehmens | Namen der Mutterunternehmen',
  part: 'Teil | Teile',
  partCode: 'Teilecode | Teilecodes',
  partCodeAddError: 'Teilecode(s) können nicht hinzugefügt werden',
  partCodeErrorEmpty: 'Konnte nicht gespeichert werden: Das Teilecode-Feld darf nicht leer sein',
  partCodeErrorInUse: 'Dieser Teilecode wird von Teilen in Documoto verwendet und darf nicht gelöscht werden. Kontaktieren Sie Documoto Support unter support{\'@\'}documoto.com.',
  partCodeErrorNotFound: 'Diese Teilecodes konnten nicht gelöscht werden, weil sie nicht gefunden wurden. Bitte stellen Sie sicher, dass Sie alle Änderungen gespeichert haben.',
  partCodesManager: 'Teilecodes-Manager',
  partCodesMessage: 'Teilecodes können anstelle von Teilenummern verwendet werden und werden häufig verwendet, um anzuzeigen, wenn Elemente der Stückliste veraltet, nicht verfügbar oder nur Referenzelemente sind (z. B. \'PNNA\', \'-\', \'REF\').',
  partCreated: 'Teil Erstellt',
  partData: 'Teiledaten',
  partUpdated: 'Teil aktualisiert',
  partDebouncerEmailQueueJob: 'E-Mail-Warteschlange',
  partDescription: 'Teile-Beschreibung',
  partLabel: 'Teil | Teile',
  partLevelDetails: 'Teilepreise, bestellbar/sichtbar, zusätzliche Übersetzungen, Teile-Tags',
  partName: 'Teilename',
  partnerApiKey: 'Partner-API-Schlüssel',
  partnerRESTRequestLimit: 'Partner-REST-Anfragelimit',
  partNumber: 'Teilenummer',
  partNumberDescription: 'Die interne Teilenummer, nach der Endbenutzer suchen können',
  partNumberVisible: 'Teilenummer Sichtbar',
  partNumberVisibleDescription: 'Teilenummer ist für Endbenutzer sichtbar',
  partNumberVisibleLocal: 'Ist auf dieser Seite sichtbar',
  partNumberVisibleLocalBlocked: 'Teilenummer ist nicht Global Sichtbar',
  partOrderable: 'Teil Bestellbar',
  partRequired: 'Es wird mindestens ein Teil benötigt',
  partTagProperty: 'Eigenschaft des Teil-Tags | Eigenschaften des Teil-Tags',
  partTag: 'Teiletag | Teiletags',
  partThumbnailDescription: '',
  partialSupport: 'Teile-Support',
  partsListColumn: 'Teilelisten-Spalte | Teilelisten-Spalten',
  partsBook: 'Teilekatalog | Teilekataloge',
  partsColumns: 'Teile-Spalten',
  partsNotSelected: 'Standardbild wird für Teile-Miniaturansicht verwendet. Wenn keine benutzerdefinierte Miniaturansicht verwendet wird, zeigt Documoto eine Standard-Miniaturansicht an.',
  partsPage: 'Teileseite | Teileseiten',
  partsPages: 'Teile-Seiten',
  partsPageGroup: {
    excludePartCodesFromPartsLists: 'Keine Teilecodes von den Teilelisten einbeziehen',
    highlightAlternateRows: 'Jede Zweite Reihe markieren',
    rotateImagesAutomatically: 'Bilder Automatisch drehen',
    printBorderAroundImages: 'Rand um Bilder Herum drucken',
    keepImagesOnLeft: 'Bilder weiter auf der Linken Seite anzeigen',
    keepBOMOnRight: 'Stückliste weiter auf der Rechten Seite anzeigen',
    columnsUpdateErrorMsg: 'Beim Aktualisieren der Teile-Spalten ist ein Fehler aufgetreten',
    columnsUpdateSuccessMsg: 'Teile-Spalten wurden aktualisiert',
    columnsLoadErrorMsg: 'Teile-Spalten konnten nicht geladen werden',
    printHotpointsOnImages: 'Hotpoints auf den Bildern Drucken',
  },
  partsList: 'Teileliste',
  partsListData: 'Teilelisten-Daten',
  partsListGridHeader: 'Überschrift der Teilelisten-Tabelle',
  password: 'Passwort',
  passwordExpiredHeader: 'Passwort abgelaufen',
  passwordExpiredInfo: 'Ihr Passwort ist abgelaufen. Ihr neues Passwort muss die folgenden Kriterien erfüllen:',
  passwordCriteriaInfo: 'Ihr neues Passwort muss die folgenden Kriterien erfüllen:',
  passwordBlankMessage: 'Passwort darf nicht leer sein',
  passwordCannotBeSame: 'darf nicht dasselbe wie Ihr derzeitiges Passwort sein',
  passwordCasingMassage: 'muss mindestens einen Klein- und einen Großbuchstaben enthalten',
  passwordLowerCaseMessage: 'Passwort muss mindestens einen Kleinbuchstaben enthalten',
  passwordMinLengthMessage: 'Passwörter müssen mindestens 8 Zeichen enthalten',
  passwordNotMatchingMessage: 'Passwörter stimmen nicht überein',
  passwordSpecialCharactersMessage: 'Passwort muss mindestens ein Sonderzeichen enthalten',
  passwordUpperCaseMessage: 'Passwort muss mindestens einen Großbuchstaben enthalten',
  passwordWillExpire1Day: 'Das Anmelde-Passwort läuft nach weniger als 1 Tag ab',
  passwordWillExpire5Days: 'Das Anmelde-Passwort läuft nach weniger als 5 Tagen ab',
  passwordsMatch: 'Kennwortabgleich',
  permission: 'Berechtigung | Berechtigungen',
  phoneNumber: 'Telefonnummer',
  phoneNumberExt: 'Dw.',
  phonePrimary: 'Haupttelefonnummer',
  phoneSecondary: 'Zweite Telefonnummer',
  pinFilter: 'Filter anheften, um die Ergebnisseite zu durchsuchen',
  pinned: 'Angepinnt',
  plusMoreOrgs: '+ {totalOrgCount} zusätzliche Organisationen',
  polishDescription: 'Polnische Beschreibung',
  polishName: 'Polnischer Name',
  populatePdfNameWith: 'PDF-Dateinamen ausfüllen mit',
  populatePdfNameSelection: 'PDF-Dateinamen-Auswahl ausfüllen',
  port: 'Port',
  portrait: 'Porträt',
  portugueseDescription: 'Portugiesische Beschreibung',
  portugueseName: 'Portugiesischer Name',
  postComment: 'Kommentar veröffentlichen',
  postalCode: 'Postleitzahl',
  poweredBy: 'Unterstützt durch',
  preferences: 'Präferenzen',
  preferencesDisable: 'Benutzerpräferenzen deaktivieren',
  prefix: 'Präfix | Präfixe',
  preview: 'Vorschau',
  previewOfName: 'Vorschau von {name}',
  pricing: 'Preisgestaltung',
  pricingDiscountEnabled: 'Reduzierten Preis anzeigen',
  pricingDisplayInCart: 'Preise im Warenkorb anzeigen',
  pricingDisplayInPartsList: 'Preise in Teileliste anzeigen',
  pricingGlobal: 'Globale Preisgestaltung',
  pricingGlobalDescription: 'Der normale Preis, wenn er von der Organisation nicht überschrieben wird',
  pricingHidePricingButton: 'Schaltfläche Preis Verbergen aktivieren',
  pricingHidePricingButtonDisable: 'Schaltfläche Preis Verbergen deaktivieren',
  pricingOrganization: 'Preisgestaltung der Organisation',
  pricingOrganizationDeleteConfirm: 'Sind Sie sicher, dass Sie die Preisgestaltung der Organisation löschen möchten?',
  pricingOrganizationDescription: 'Die Preisgestaltung der Organisation überschreibt die globale Preisgestaltung für alle Benutzer innerhalb einer Organisation und ihrer Tochterunternehmen.',
  pricingOrganizationDuplicate: 'Diese Organisation wird bereits überschrieben',
  pricingOrganizationEmptyState: 'Es wurden keine Überschreibungen für Organisationen definiert. Klicken Sie auf {add}, um zu beginnen.',
  pricingRetailEnabled: 'Verkaufspreis anzeigen',
  pricingWholesaleEnabled: 'Großhandelspreis anzeigen',
  primary: 'Primär',
  print: 'Drucken',
  printerLane: 'Druckerspur',
  printJob: 'Druckauftrag',
  printLogo: 'Logo Drucken',
  printSetting: 'Druckeinstellungen',
  printSettingDeleteFail: 'Druckeinstellungen konnten nicht Gelöscht werden',
  printSettingSaveFail: 'Druckeinstellungen konnten nicht Gespeichert werden',
  printSettingEmptyMessage: 'Es wurden keine Druckeinstellungen definiert. Fügen Sie eine Druckeinstellung hinzu, um zu beginnen.',
  printSettingSetDefaultSuccess: 'Standarddruckeinstellung eingestellt',
  printSettingUpdateFail: 'Druckeinstellung konnte nicht Aktualisiert werden',
  printSettingCopyMessage: 'Geben Sie einen Namen für die neue Druckeinstellung ein.',
  printSettingCoverLoadErrorMsg: '',
  printSettingCreated: 'Druckeinstellung erstellt',
  printSettings: 'Druckeinstellungen',
  printSettingsLogo: 'Druckeinstellungen-Logo',
  printing: 'Wird gedruckt',
  printingDisabled: 'Drucken deaktivieren',
  privacyPolicy: 'Datenschutzrichtlinie',
  proceed: 'Fortfahren',
  process: 'Verarbeiten',
  processContent: 'Content bearbeiten',
  processing: 'Wird bearbeitet',
  processToDraft: 'Zu Entwurf verarbeiten',
  processedPlzToDraft: 'Zu Entwurf/Entwürfen verarbeitete PLZ(s)',
  production: 'Produktion',
  professional: 'Professionell',
  profile: 'Profil',
  prohibitExportDownloadOfContent: 'Export/Herunterladen von Inhalten untersagen',
  prohibitExportDownloadOfContentHelpText: 'Durch die Einstellung \'Enable\' (Aktivieren) wird verhindert, dass Benutzer (mit Berechtigungen) diesen Inhalt exportieren oder herunterladen.',
  property: 'Eigenschaft| Eigenschaften',
  province: 'Region',
  public: 'Öffentlich',
  publish: 'Veröffentlichen',
  publisherLane: 'Herausgeberspur',
  publisherManualLane: 'Herausgeberhandbuch-Spur',
  publishThumbnailJob: 'Miniaturansicht Veröffentlichen',
  publishDocuments: 'Dokumente Veröffentlichen',
  publishDraft: 'Entwurf Veröffentlichen',
  publisher: 'Herausgeber | Herausgeber',
  publishingErrors: 'Veröffentlichungsfehler',
  publishingErrorsWithName: 'Veröffentlichungsfehler {name}',
  publishingInProgress: 'Publishing läuft. Sendung nicht möglich',
  publisherJob: 'Herausgeber-Auftrag',
  publisherMessage: 'Inhaltserstellung, Verwaltung und Druckeinstellungen',
  purchaseOrder: 'Bestellung',
  purchaseOrderTemplateEnabled: 'Bestellvorlagen aktivieren',
  purchaseOrderTemplateDisabled: 'Bestellvorlagen deaktivieren',
  qty: 'Menge',
  quantity: 'Menge',
  query: 'Abfrage',
  quickAdd: 'Schnelles Hinzufügen',
  quickAddHelpText: 'Beginnen Sie mit der Eingabe, um Teile zu finden, die Sie in den Warenkorb legen können.',
  quickAddInCartEnabled: 'Schnelles Hinzufügen in den Warenkorb aktivieren',
  quickLinks: 'Quick-Links',
  quote: 'Angebot | Angebote',
  quoteFormType: 'Angebotsformular',
  quoteTemplateDisabled: 'Angebotsvorlagen deaktivieren',
  quoteTemplateEnabled: 'Angebotsvorlagen aktivieren',
  range: 'Bereich',
  rearrange: 'Neu ordnen',
  rebuildIndexJob: 'Index Neu Erstellen',
  recentSearches: 'Letzte Suchanfragen',
  recentlyAdded: 'Vor Kurzem Hinzugefügt',
  recentlyEdited: 'Vor Kurzem Bearbeitet',
  recentlyViewed: 'Vor Kurzem Angezeigt',
  rectangle: 'Rechteck',
  redirect: 'Umleiten',
  refresh: 'Aktualisieren',
  related: 'Zugehöriges',
  relatedDescription: 'Zugehörige Medien, die in der Bibliothek für diesen {type} vorhanden sind. Beachten Sie, dass die zugehörigen Medien Zugriffskontrollen haben müssen, damit die Benutzer sie in der Registerkarte \'Zugehöriges\' sehen können.',
  relatedInfo: 'Zugehörige Info',
  relatedView: 'Zugehörige Medien anzeigen',
  relationship: 'Beziehung | Beziehungen',
  releaseNotes: 'Freigabehinweise',
  rememberMe: 'Anmeldeinformationen speichern',
  remainingWidth: 'Übrige Breite',
  remove: 'Entfernen',
  removeAll: 'Alle entfernen',
  removeItemConfirmation: 'Sind Sie sicher, dass Sie {item} entfernen möchten?',
  removeItemCount: 'Wollen Sie {count} {type} wirklich löschen?',
  replace: 'Ersetzen',
  replaceExisting: 'Bestehendes ersetzen',
  report: 'Bericht | Berichte',
  reportAnIssue: 'Problem melden',
  reportAnIssueMessage: 'E-Mail erfolgreich gesendet.',
  reportCatalogName: 'Reportkatalog-Name',
  reportCatalogNotSet: 'Reportkatalog wurde für den Report nicht definiert.',
  reportDefinition: 'Report-Definition | Report-Definitionen',
  reportDefinitionDeleteError: 'Report-Definition konnte nicht gelöscht werden',
  reportsEmptyState: 'Keine Reports',
  reportExportTimeWarning: 'Je nach Anzahl der Einträge und ausgewähltem Format kann der Export mehrere Minuten erfordern.',
  reporting: 'Wird gemeldet',
  reportingMessage: 'Zugriff auf verschiedene Berichte und Metriken',
  reportSettings: 'Berichtseinstellungen',
  reportsLibrary: 'Berichtsbibliothek',
  requestForQuote: 'Angebotsanforderung',
  requestFormType: 'Angebotsformular',
  required: 'Erforderlich',
  requiredItem: '{item} erforderlich.',
  requiredMessage: 'Ein {item} ist erforderlich',
  requiredMessageAndUnique: 'Ein {item} ist erforderlich und muss eindeutig sein.',
  requireAuthentication: 'Authentifizierung erforderlich',
  requiredReading: 'Pflichtlektüre',
  requiredReadingMessage: 'Bitte prüfen Sie Folgendes:  ',
  requiredUrlMessage: 'Eine gut formatierte URL ist erforderlich.',
  reset: 'Zurücksetzen',
  resetPassword: 'Passwort zurücksetzen',
  resetPasswordError: 'Beim Zurücksetzen Ihres Passworts ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.',
  resetPasswordTokenError: 'Passwort-Wiederherstellungstoken ist ungültig.',
  resetZoom: 'Zoom Zurücksetzen',
  resource: 'Ressource | Ressourcen',
  resize: 'Größe anpassen',
  resizeThumbnails: 'Größe von Thumbnails automatisch anpassen',
  result: 'Ergebnis | Ergebnisse',
  resultFor: 'Ergebnis für {item} | Ergebnisse für {item}',
  retail: 'Einzelhandel',
  retailPrice: 'Verkaufspreis',
  retailTotal: 'Verkaufspreis Gesamt',
  return: 'Zurückgehen',
  returnToItem: 'Zurück zu {item}',
  returnToPartList: 'Zurück zur Teileliste',
  returnToMedia: 'Zurück zu Medien',
  returnToTemplate: 'Zurück zur Vorlage',
  returnToViewer: 'Zurück zur Anzeige',
  revertChanges: 'Änderungen rückgängig machen',
  right: 'Rechts',
  romanianDescription: 'Rumänische Beschreibung',
  romanianName: 'Rumänischer Name',
  rootLevelBrowseFlow: 'Stammverzeichnis-Ebene',
  row: 'Reihe',
  russianDescription: 'Russische Beschreibung',
  russianName: 'Russischer Name',
  sampleData: 'Beispieldaten',
  samplePreview: 'Muster-Vorschau',
  sandbox: 'Sandbox',
  sat: 'Sa.',
  save: 'Speichern',
  saveChanges: 'Ihre Änderungen bei {item}? {name}',
  saveChangesToItem: 'Ihre Änderungen des {item} speichern?',
  saved: 'Gespeichert',
  saveNewAPIKey: 'Neuer Integrationsschlüssel wurde nicht Gespeichert, Zurückgehen, um die Einrichtung abzuschließen',
  saveNewWidgetKey: 'Neuer Widget-Schlüssel nicht gespeichert. Zurückkehren, um Einrichtung abzuschließen.',
  search: 'Suchen',
  searchableFieldsSuccessfullyUpdated: 'Durchsuchbare Felder erfolgreich aktualisiert.',
  searchAllContent: 'Alle Inhalte durchsuchen',
  searchConfiguration: 'Suchkonfiguration',
  searchCriteria: 'Suchkriterien',
  searchDocumoto: 'Documoto durchsuchen',
  searchFeatures: 'Suchfunktionen',
  searchFieldConfigDescription: 'Folgende Einstellungen für die Systemfeldsuche gelten nur für die allgemeine Suche in der Bibliothek, die Suchergebnisseite und das schnelle Hinzufügen im Warenkorb. Andere Suchfunktionen (z. B. Admin-Center-Suche und Bibliothek, wo verwendet) sind davon nicht betroffen.',
  searchInContent: 'Suchen in {contentName}...',
  searchingInLanguage: 'Suche erfolgt {lang} nur auf',
  searchingInTwoLanguages: 'Suche erfolgt nur auf {lang1} und {lang2}',
  searchPlaceholder: 'Alle Inhalte durchsuchen...',
  searchPrompt: 'Alle Inhalte durchsuchen...',
  searchProperty: 'Eigenschaft durchsuchen | Eigenschaften durchsuchen',
  searchResultsConfiguration: 'Konfiguration Suchergebnisse',
  searchSettings: 'Sucheinstellungen',
  searchSettingsDescription: 'Diese Einstellungen steuern das Verhalten, die Funktionen und die Ergebnisse der Bibliothekssuche.',
  searchTerm: 'Suchbegriff',
  searchTips: 'Suchtipps:',
  searchable: 'Durchsuchbar',
  searchableTag: 'Durchsuchbares Tag',
  section: 'Abschnitt',
  select: 'Auswählen',
  selectAField: '{field} auswählen',
  selectAll: 'Alle auswählen',
  selectAndClose: 'Auswählen und Schließen',
  selected: 'Ausgewählt',
  selectedTargetPage: 'Gewählte Zielseite',
  selectChapters: 'Kapitel Auswählen',
  selectChildren: 'Tochterunternehmen auswählen',
  selectFields: '{field} auswählen',
  selectItem: '{item} wählen',
  selectMedia: 'Medien Auswählen',
  selectPage: 'Seite wählen',
  selectPages: 'Seite(n) Auswählen',
  selectTargetPage: 'Zielseite wählen',
  send: 'Senden',
  september: 'September',
  serbianDescription: 'Serbische Beschreibung',
  serbianName: 'Serbischer Name',
  serviceUri: 'Service URI',
  sessionLog: 'Sitzungsprotokoll',
  set: 'Einstellen',
  setAsDefault: 'Als Standard festlegen',
  settings: 'Einstellungen',
  settingsMessage: 'Aktualisieren Sie Ihre Benutzereinstellungen und Ihr Passwort',
  sequence: 'Sequenz',
  shape: 'Form',
  shapeTool: 'Formwerkzeug',
  shared: 'Gemeinsam genutzt',
  sharedCart: 'Gemeinsamer Warenkorb | Gemeinsame Warenkörbe',
  sharedCartDeleteContent: 'Damit wird der Gemeinsame Warenkorb für alle Organisationen entfernt, die ihn gemeinsam verwendeten.',
  sharedCartOrganizationContent: 'Wählen Sie die Organisationen, die den Gemeinsamen Warenkorb verwenden können.',
  sharedShoppingCartsEnabled: 'Gemeinsame Warenkörbe aktivieren',
  shoppingCartExportJob: 'Export des Einkaufswagens',
  shoppingCartLoadingLongTime: 'Der Einkaufswagen wird geladen. Die Einkaufswagensymbole werden verfügbar, sobald der Vorgang abgeschlossen ist. Wenn das Problem weiterhin besteht, wenden Sie sich bitte an Ihren Documoto-Administrator, um Unterstützung zu erhalten.',
  showExportFromSearch: 'Exportieren der Suchergebnisse zulassen',
  showExportFromSearchHelp: 'Exportieren des bei der Suche gefundenen Bücherverzeichnisses zulassen',
  showHelp: 'Hilfe anzeigen',
  showIdentifierInSearch: 'Medienkennung bei der Suche anzeigen',
  showItem: '{item} anzeigen',
  showLess: 'Weniger anzeigen',
  showMediaDescriptionFilter: 'Medienbeschreibungsfilter anzeigen',
  showMediaIdentifierFilter: 'Medienbezeichnungsfilter anzeigen',
  showMediaNameFilter: 'Mediennamensfilter anzeigen',
  showMore: 'Mehr anzeigen',
  showOnlyExactMatches: 'Nur exakte Übereinstimmungen anzeigen',
  showOrganizationFilter: 'Organisationsfilter anzeigen',
  showPartNameFilter: 'Teilenamenfilter anzeigen',
  showPartNumberFilter: 'Teilenummernfilter anzeigen',
  showRecentlyAdded: 'Vor Kurzem Hinzugefügtes anzeigen',
  showRecentlyViewed: 'Vor Kurzem Angezeigtes anzeigen',
  showSearchButton: 'Such-Schaltfläche für Startseite anzeigen',
  showSearchButtonHelp: 'Die Schaltfläche \'Zur Suche\' in der Kopfzeile der Bibliothek anzeigen',
  signature: 'Unterschrift',
  size: 'Größe',
  snapshot: 'Snapshot | Snapshots',
  solrSlaveSvrOverride: 'Basis-URLs der SOLR-Slave-Server überschreiben',
  sortAsc: '{value} A-Z',
  sortDesc: '{value} Z-A',
  sortRelevant: 'Am Relevantesten',
  sortType: 'Nach Typ sortieren',
  spanishDescription: 'Spanische Beschreibung',
  spanishName: 'Spanischer Name',
  square: 'Quadrat',
  ssoConfiguration: 'SSO-Konfiguration',
  ssoIdpMetadataUrl: 'SSO-IDP-Metadaten-URL',
  ssoLoginMaxAuthenticationAgeSeconds: 'Max. Authentifizierungsalter für SSO-Anmeldung in Sekunden',
  ssoSamlDnAttributeName: 'SSO-SAML-DN Attributname',
  ssoSamlEmailAttributeName: 'SSO-SAML-E-Mail Attributname',
  ssoSamlMemberofAttributeName: 'SSO-SAML-MemberOf Attributname',
  ssoSniForSsoMetadataRetrieval: 'SNI für SSO-Metadatenabruf verwenden ',
  standard: 'Standard',
  standardUserGroup: 'Standard-Benutzergruppe',
  startDate: 'Beginndatum',
  startDateRequired: 'Startdatum erforderlich.',
  state: 'Land',
  static: 'Statisch',
  status: 'Status',
  statusCode: 'Status-Code | Status-Codes',
  stitchPages: 'Seiten anheften',
  storeFrontAdmin: 'Storefront Admin',
  storefront: 'Storefront',
  storefrontIntegration: 'Storefront-Integration | Storefront-Integrationen',
  storefrontIntegrationSubtitle: 'Diese Einstellungen steuern verschiedene Storefront-Integrationselemente und Optionen. Gehen Sie sorgfältig vor, wenn Sie Änderungen durchführen. Kontaktieren Sie Documoto Support für weitere Informationen.',
  storefrontSettings: 'Storefront-Einstellungen',
  storefrontSettingsDescription: 'Diese Einstellungen steuern verschiedene Storefront-Elemente und Optionen. Gehen Sie sorgfältig vor, wenn Sie Änderungen durchführen. Kontaktieren Sie Documoto Services für weitere Informationen.',
  storefrontSettingsOrgDescription: 'Diese Einstellungen steuern verschiedene Elemente und Optionen von Storefront auf Organisationsebene. Gehen Sie sorgfältig vor, wenn Sie Änderungen durchführen. Kontaktieren Sie Documoto Services für weitere Informationen.',
  storeFilterValues: 'Filterwerte speichern',
  storeFilterValuesDescription: 'Wenn diese Einstellung aktiviert wird, werden die Werte gespeichert, die Benutzer in den Filtern ihrer Suchseite für diesen Tag festgelegt haben.',
  stroke: 'Strich',
  style: 'Stil | Stile',
  styles: {
    colorHotpoint: 'Hotpoint-Standardeinstellung',
    invalidHexcode: 'Ungültiger Hexadezimalcode',
    inheritedFromOrg: 'Von Unternehmen {org} übernommen',
    inheritedFromSystem: 'Von der Systemvorgabe übernommen',
    inheritedFromTenant: 'Vom Mandanten übernommen',
    organization: 'Organisationsstil | Organisationsstile',
    organizationSaved: 'Organisationsstile gespeichert',
    sampleNavigation: 'Navigationsregisterkarten',
    samplePrimary: 'Navigation/Schaltflächen',
    sampleSelectedHotpoint: 'Ausgewählte Hotpoints',
    tenant: 'Mandantenstil | Mandantenstile',
    tenantSaved: 'Mandantenstile gespeichert'
  },
  subChapter: 'Unterkapitel | Unterkapitel',
  subject: 'Betreff',
  subjectMessage: 'Betreff erforderlich',
  submit: 'Senden',
  submitPurchaseOrder: 'Bestellung',
  submitRequestForQuote: 'Angebotsanforderung',
  submitted: 'Gesendet',
  submitter: 'Gesendet von',
  submittedBy: 'Gesendet von',
  submitterEmail: 'E-Mail-Adresse des Einreichers',
  submitterFirstName: 'Vorname des Einreichers',
  submittedFilesMessage: '{fileCount} Datei(en) gesendet.',
  submitterLastName: 'Nachname des Einreichers',
  submitterOrgName: 'Organisation des Einreichers',
  submitterUserName: 'Benutzername des Einreichers',
  success: 'Erfolg',
  imageSaveSuccess: 'Bilder erfolgreich aktualisiert',
  successNotificationDuration: 'In den Warenkorb hinzufügen - Dauer der Erfolgs-Popup-Benachrichtigung',
  suffix: 'Suffix | Suffixe',
  suggestedKeywords: 'Vorgeschlagene Schlüsselwörter',
  suggestedResults: 'Vorgeschlagene Ergebnisse',
  suggestion: 'Vorschlag | Vorschläge',
  sun: 'So.',
  superadmin: 'Superadmin',
  superAdminSettings: 'Superadmin-Einstellungen',
  supersede: 'Ersetzen',
  supersedeComplete: 'Möchten Sie die Ersetzung durchführen?',
  supersedeDiscardChanges: 'Möchten Sie Ihre Ersetzung verwerfen?',
  supersedeMainText: 'Bei der Ersetzung wird ein bestehendes Teil durch ein oder mehrere andere Teile auf jeder Teileseite ersetzt, wo das alte Teil gefunden wird. Die alte Teilenummer wird gelöscht, kann aber über Tags noch gefunden werden, sodass Inhalte über die alte Nummer oder die neue(n) Nummer(n) gefunden werden können.',
  supersedeMessage: 'Aktualisierungen von Teilen sind sofort wirksam. Bei der Suche werden die Aktualisierungen innerhalb weniger Minuten reflektiert.',
  supersedeNotProcessed: 'Ihre Ersetzung wird nicht durchgeführt.',
  supersedeReplacePart: 'Finden Sie ein oder mehrere Teile, die das Teil ersetzen',
  supersedeSuccessful: 'Ersetzung war Erfolgreich',
  supersedeWith: 'Teil Durch... ersetzen',
  supplier: 'Lieferant | Lieferanten',
  supplierKey: 'Lieferantenschlüssel',
  supplierDescription: 'Das Unternehmen, das dieses Teil liefert',
  supportedImageFormats: 'Unterstützte Bildformate: GIF, JPG, JPEG, PNG',
  supportedDraftArchives: 'Nicht unterstützte Datei; Entwurfsdateien werden unterstützt: MDZ, PLZ',
  surroundWithQuotes: 'Setzen Sie mehrere Suchbegriffe in Anführungszeichen, um eine genaue Übereinstimmung zu erzielen.',
  svgFinder: 'SVG Finder',
  swedishDescription: 'Schwedische Beschreibung',
  swedishName: 'Schwedischer Name',
  system: 'System',
  systemAdmin: 'System-Admin',
  systemFields: 'Systemfelder',
  systemFieldSearchAndFiltering: 'Systemfeldsuche & Filterung',
  systemStatus: 'Systemstatus',
  systemName: 'Systemname',
  systemVersion: 'System-Version',
  tabDelimited: 'durch Tabstopps getrennt',
  tableOfContents: 'Inhaltsverzeichnis',
  tabloid: 'Kleinformatige Zeitung',
  tag: 'Tag | Tags',
  tagName: 'Tag-Name',
  tagEditor: {
    rangeBoundLengthInvalid: 'Grenze darf nicht mehr als (limit) Ziffern enthalten.',
    tagEditorModal: 'Tag Editor Modal',
    filterPrompt: 'Die ersten 100 Werte werden angezeigt. Verwenden Sie den Filter, um mehr anzuzeigen.'
  },
  tagSearchAndFiltering: 'Tag-Suche & Filterung',
  tagSearchAndFilteringHelpText: 'Hinweis: Einige Such- und Filtereinstellungen sind auch in den „Tag Manager“-Tag-Editoren konfigurierbar, die hier zu finden sind: ',
  tagSubmitAffected: 'Bei jedem {type}-Inhalt wird zusätzlich zu den Änderungen automatisch das Tag {tag} entfernt.',
  tagDeleteAffectedCount: 'Damit wird das Tag gelöscht und die Tag-Informationen von {count} Elementen entfernt.',
  tagDeleteAffectedNone: 'Dieses Tag wird derzeit von keinem Element verwendet.',
  tagManagerDeleteWarning: 'Alle bestehenden Medien mit {deleteCategoryName} verlieren diese Kategorie nach dem Löschen.',
  tagsDisplayMore: 'Die ersten 100 Werte werden angezeigt. Verwenden Sie den Filter, um mehr anzuzeigen.',
  tagsManager: 'Tags-Manager',
  tagValue: 'Tag-Wert | Tag-Werte',
  tagValueMissing: 'Sie müssen einen Tag-Wert eingeben',
  tagValueMultiple: 'Mehrere Einträge werden durch Semikolon getrennt',
  tagValueTooLong: 'Tag-Wert überschreitet die Beschränkung von 25 Zeichen | Tag-Werte überschreiten die Beschränkung von 25 Zeichen',
  taxon: 'Taxon',
  taxonomy: 'Taxonomie',
  template: 'Vorlage | Vorlagen',
  templateEmailRecipients: 'Vorlage E-Mail-Emfpänger',
  templateLayout: {
    addRow: 'Zeile hinzufügen',
    addItem: 'Element hinzufügen',
    deleteRow: 'Zeile löschen',
    deleteColumn: 'Spalte löschen',
    fullWidth: 'komplette Breite',
    oneColumn: 'eine Spalte',
    threeColumns: 'drei Spalten',
    twoColumns: 'zwei Spalten'
  },
  tenant: 'Mandant | Mandanten',
  tenantAdmin: 'Mandantenverwaltung',
  tenantAdminMessage: 'Steuert mandantenübergreifende Einstellungen',
  tenantCleanerJob: 'Mandantenerhalt',
  tenantConfiguration: 'Mandantenkonfiguration',
  tenantEmailRecipients: 'Empfänger der Mandanten-E-Mail',
  tenantEmailRecipientsOverrideMessage: 'Sie werden überschrieben, wenn E-Mail-Empfänger der Organisation angegeben werden',
  tenantIndexQueueJob: 'Mandantenindex-Warteschlange',
  tenantKey: 'Mandantenschlüssel',
  tenantKeyError: 'Mandantenschlüssel ist nicht eindeutig',
  tenantKeyMessage: 'Mandantenschlüssel muss eindeutig sein',
  tenantLabelDeleteMessage: 'Wenn diese Bezeichnung gelöscht wird, wird der Standard-Documoto-Name wieder verwendet.',
  tenantReindexModulus: 'Mandanten-Neuindexzierungsmodul',
  tenantSettings: {
    collapseTOCLabel: 'Inhaltsverzeichnis auf Matching Page ausblenden',
    defaultLocaleLabel: 'Standard-Standort',
    defaultLocaleMessage: 'Standard-Standort des Mandanten',
    deleteMissingTranslationsOnPublishLabel: 'Fehlende Übersetzungen beim Veröffentlichen löschen?',
    deleteMissingTranslationsOnPublishMessage: 'Teilübersetzungen beim Veröffentlichen löschen aktivieren',
    defaultSupplierLabel: 'Standard-Lieferant',
    defaultSupplierMessage: 'Standard-Lieferant des Mandanten',
    enableChapterIndexingMessage: 'Kapitel indizieren',
    enableChapterIndexingLabel: 'Aktiviert Indizierung von Medien-Kapiteln',
    enableIndexingLabel: 'Index bei Veröffentlichung',
    enableIndexingMessage: 'Indizierung bei Veröffentlichung einschalten.',
    helpUrlLabel: 'Standard-Hilfe-URL',
    helpUrlMessage: 'URL eines alternativen Hilfe-Systems',
    hotPointLinkPageInBookOnlyLabel: 'Seiten-Hotpoint-Links nur in Buchung',
    hotPointLinkPageInBookOnlyMessage: 'Hotpoint-Links von Seiten nur in Buchung',
    mediaTimestampLabel: 'Medien-Zeitstempel auf der Zugangskontrolländerung aktualisieren',
    mediaTimestampMessage: 'Aktivieren, wenn durch eine Änderung der Zugangskontrollen der Medien-Zeitstempel aktualisiert werden soll.',
    publisherDefaultUom: 'Standard-Maßeinheit des Herausgebers',
    publishingContactLabel: 'Kontakt-E-Mail wird veröffentlicht',
    publishingContactMessage: 'Die Standard-E-Mail-Adresse, an die E-Mails gesendet werden, wenn ein Problem mit dem Inhalt gemeldet wird.',
    replaceHotpointLabel: 'Hotpoint-Links beim Veröffentlichen immer ersetzen',
    replaceHotpointMessage: 'Nur für Docustudio-Veröffentlichungen.',
    ssoLoginSystemLabel: 'SSO Login-System',
    ssoLoginSystemMessage: 'Identitätssystem für SSO.',
    userPasswordExpirationLabel: 'Ablaufdaten des Benutzerpassworts',
    userPasswordExpirationMessage: 'Anzahl der Tage, nach denen Passwörter zurückgesetzt werden müssen; Feld leer lassen, damit das Passwort nie abläuft',
    lockPartTrOnPublishLabel: 'Teilübersetzungen beim Veröffentlichen sperren',
    lockPartTrOnPublishMessage: 'Verhindert, dass Teilübersetzungen während der Veröffentlichung geändert werden',
    exportPgAsHashLabel: 'Dateinamen der Seite als Hash-Schlüssel exportieren',
    exportPgAsHashMessage: 'Seiten-Hash-Schlüssel in exportierten Inhalten als Seitendateinamen verwenden',
  },
  tenantSettingsTitle: 'Einstellungen des Mandanten',
  tenantSettingsSaved: 'Einstellungen des Mandanten Gespeichert',
  tenantSettingsSubtitle: 'Diese Einstellungen steuern mandantenweite Eigenschaften und Verhaltensweisen Seien Sie achtsam, wenn Sie Änderungen vornehmen. Für weitere Information setzen Sie sich mit dem Service von Documoto in Verbindung.',
  tenantSuppliers: 'Mandantenlieferanten',
  tenantLabelsPageText: 'Bezeichnungen werden verwendet, um die Namen vieler Objekte, die Sie in der Bibliothek von Documoto und auf Verwaltungsbildschirmen sehen, individuell anzupassen.',
  tenantPrintSettingsDescription: 'Wählen Sie die mandantenübergreifende Druckeinstellung. Sie wird beim Drucken aller Bücher verwendet, sofern sie nicht auf Organisations- oder Medienebene überschrieben wird.',
  tenantType: 'Mandantentyp',
  tenantWebserviceUser: 'Mandanten-Webservice-Benutzer',
  text: 'Text',
  textArea: 'Textbereich',
  textField: 'Textfeld',
  textFile: 'Textdatei (CSV, TXT)',
  textTool: 'Textwerkzeug',
  threeD: {
    addFile: '3D-Teiledatei hinzufügen',
    beforeSaveModal: {
      title: 'In Documoto ist bereits eine CAD-Datei mit diesem Dateinamen vorhanden.',
      message: 'Möchten Sie diese Datei überall ersetzen, wo sie vorhanden ist, oder sie nur an diesen Teil anhängen? Wenn Sie sich für das Ersetzen der Datei entscheiden, geschieht Folgendes:',
      messageBulletOne: '{count} teil(e) wird/werden mit dieser neuen CAD-Datei aktualisiert',
      messageBulletTwo: 'Die vorherige Version dieser Datei wird aus dem Documoto-Dateisystem entfernt.',
      tip: 'Tipp: Um zu verhindern, dass Dateien während des Anhängens überschrieben werden, können Sie den Dateinamen der Datei, die Sie anhängen möchten, vor dem Importieren ändern.'
    },
    deleteTitle: 'Wollen Sie diese 3D-Ansicht wirklich löschen?',
    drawMode: {
      label: 'Zeichenmodus',
      hiddenLines: 'Verborgene Linien',
      shaded: 'Schattiert',
      wireframe: 'Drahtgitter',
      wireframeShaded: 'Drahtgitter Shaded',
    },
    explode: 'Explosion',
    fileSizeModal: {
      message: 'Die maximal zulässige Dateigröße in Documoto beträgt 25 Megabyte. Die Datei, die Sie hochladen möchten, hat das Maximum überschritten und kann nicht geladen werden. Klicken Sie auf „OK“, um fortzufahren.',
      title: 'Maximal zulässige Dateigröße überschritten',
    },
    orientationAndProjection: {
      back: 'Zurück',
      bottom: 'Unten',
      front: 'Vorn',
      isometric: 'Isometrisch',
      left: 'Links',
      orthographic: 'Orthographisch',
      projection: 'Projektion',
      perspective: 'Perspektive',
      right: 'Rechts',
      top: 'Oben'
    },
    resetCamera: 'Kamera zurücksetzen',
    slice: {
      label: 'Schnitt',
      planeX: 'Schnittebene X',
      planeY: 'Schnittebene Y',
      planeZ: 'Schnittebene Z',
      reset: 'Schnittebene zurücksetzen',
      section: 'Schnittebenen-Abschnitt umschalten',
      visibility: 'Schnittebenen-Sichtbarkeit umschalten',
    },
    supportedFilesMessage: 'Documoto unterstützt viele CAD-Dateiformate. Prüfen Sie das Support Center, wenn Sie nicht sicher sind, ob Ihr CAD-Dateiformat unterstützt wird.',
    toolsLabel: '3D Tools',
    topAssemblyFilename: 'Dateiname der obersten Baugruppe',
    topAssemblyFilenameRequired: 'Dateiname der obersten Baugruppe erforderlich',
    viewerTitle: '3D Viewer',
  },
  thu: 'Do.',
  thumbnail: 'Miniaturansicht',
  thumbnailDeleted: 'Miniaturansicht Erfolgreich Gelöscht',
  thumbnailDeletedError: 'Miniaturansicht konnte Nicht Gelöscht werden',
  thumbnailUpdated: 'Miniaturansicht Erfolgreich Aktualisiert',
  thumbnailUpdatedError: 'Miniaturansicht konnte Nicht Aktualisiert werden',
  thumbnailReapplyWarning: 'Das System kann eine Miniaturansicht aufgrund des Medieninhalts wiederverwenden. Dies kann einige Minuten dauern.',
  thumbnailResizeTooltip: 'Passt die Größe von Thumbnails auf max. 500x500 Pixel an, falls markiert',
  title: 'Titel',
  to: 'An',
  toastNotifications: 'Popup-Benachrichtigungen',
  toDate: 'Bis Heute',
  toc: 'Inhaltsverzeichnis',
  tocClipBoardSuccessTitle: 'In die Zwischenablage kopiert',
  tocClipBoardWarningText: 'WARNHINWEIS: Nicht gespeicherte Änderungen können nicht in Ihre Zwischenablage kopiert werden',
  tocClipBoardWarningTitle: 'In die Zwischenablage kopiert',
  tocDisplayingFirstValues: 'Die ersten 100 Werte werden angezeigt. Verwenden Sie den Filter, um mehr anzuzeigen.',
  tocDuplicateWarningText: 'Duplikat einer Seite wurde in einem Kapitel entdeckt. Das Duplikat wurde entfernt.',
  tocEmptyMessage: 'Sie scheinen noch kein Inhaltsverzeichnis erstellt zu haben.',
  toGetStarted: 'um zu beginnen.',
  tocMissingChapterName: 'Kapitelname fehlt',
  tocModalTableCaption: 'Tag Editor Modal',
  tocNothingSelected: 'Es wurde nichts Ausgewählt',
  tocOpenEditor: 'Editor öffnen',
  tocSaveRequired: 'Sie müssen vor dem Bearbeiten von Kapiteldetails speichern',
  tocState: 'Anfangszustand des Inhaltsverzeichnisses',
  tocThumbnailDeleteFailTitle: 'Miniaturansicht konnte Nicht Gelöscht werden',
  tocThumbnailDeleteSuccessTitle: 'Miniaturansicht Erfolgreich Gelöscht',
  tocThumbnailUploadFailTitle: 'Miniaturansicht konnte Nicht Hochgeladen werden',
  tocThumbnailUploadSuccessTitle: 'Miniaturansicht Erfolgreich Hochgeladen',
  tocTranslationMessage: 'Der Name und die optionale Beschreibung dieses Kapitels',
  tocUntitledPage: 'Unbenannte Seite',
  tooManySelections: 'Liste überschreitet Auswahllimit von {limit}',
  togglePage: 'Seite Wechseln',
  top: 'Oben',
  total: 'Gesamt',
  totalItems: 'Elemente insgesamt',
  translation: 'Übersetzung | Übersetzungen',
  translationHelpTextItem: 'Der Name und die optionale Beschreibung von {item}',
  true: 'Richtig',
  tryAgain: 'Bitte versuchen Sie es noch einmal.',
  tue: 'Di.',
  turkishDescription: 'Türkische Beschreibung',
  turkishName: 'Türkischer Name',
  twoSided: 'Zweiseitig',
  type: 'Typ | Typen',
  typeAtLeastTwoChars: 'Geben Sie mindestens 2 Zeichen ein, um eine Liste mit Übereinstimmungen anzuzeigen.',
  typeToConfirm: '{value} zum Bestätigen eingeben',
  typography: 'Typografie',
  ukraineDescription: 'Ukrainische Beschreibung',
  ukraineName: 'Ukrainischer Name',
  unableToRetrieveResults: 'Für Ihre Anfrage/Suche konnten keine Ergebnisse gefunden werden',
  unauthorized: 'Nicht autorisiert',
  unknownError: 'Unbekannter Fehler, bitte kontaktieren Sie Documoto Support unter support{\'@\'}documoto.com',
  unknownPart: 'Unbekanntes Teil',
  unknownType: 'Unbekannter Typ',
  unsavedAPIKeyWarning: 'Ihr API-Schlüssel geht verloren, wenn Sie nicht Zurückgehen',
  unsavedChanges: 'Sie haben nicht gespeicherte Änderungen. Möchten Sie fortsetzen?',
  unsavedChangesWarning: 'Ihre Änderungen gehen verloren, wenn Sie sie nicht speichern.',
  unsavedWidgetKeyWarning: 'Ihr Widget-Schlüssel geht verloren, wenn Sie nicht zurückkehren.',
  unsupportedBrowserMessage: 'Wir haben diese Anwendung mithilfe der neuesten Technologie entwickelt. Dadurch wird die Anwendung schneller und benutzerfreundlicher. Leider unterstützt Ihr Browser diese Technologien nicht.',
  uom: 'Maßeinheit',
  update: 'Aktualisieren',
  updateFailed: 'Aktualisierung fehlgeschlagen',
  updateIndex: 'Verzeichnis aktualisieren',
  updatePassword: 'Passwort aktualisieren',
  updatePasswordMessage: 'Passwort erfolgreich aktualisiert',
  updatePasswordPending: 'Speichern der Passwortänderung ist ausständig.',
  updated: 'Aktualisiert',
  upgradeBrowser: 'Bitte führen Sie eine Aktualisierung Ihres Browsers durch.',
  upload: 'Hochladen',
  uploadComplete: 'Hochladen Abgeschlossen',
  uploadInProgress: 'Hochladen wird Durchgeführt',
  uponSavingDisabledTenant: 'Wenn diese Seite mit aktivierter Einstellung gespeichert wird, geschieht Folgendes:',
  uponSavingThisLicense: 'Wenn Sie diese Seite mit dieser Einstellung speichern, MÜSSEN Sie FOLGENDES TUN:',
  upperBound: 'Obere Grenze',
  untitledOrg: 'Unbenannte Organisation',
  usCurrency: 'USD',
  useDefaultCartColumns: 'Standard-Warenkorb-Spalten verwenden',
  useDefaultPartsListColumns: 'Standardspalten für Teileliste verwenden',
  user: 'Benutzer',
  userAddress: 'Benutzeradresse | Benutzeradressen',
  userCount: 'Benutzeranzahl',
  userEditable: 'Vom Benutzer Bearbeitbar',
  userExpirationDate: 'Benutzer Ablaufdatum',
  userExpirationDateInvalid: 'Benutzer Ablaufdatum ist ein Pflichtfeld',
  userGroup: 'Benutzergruppe | Benutzergruppen',
  userGroupEmpty: 'Benutzergruppen darf nicht leer sein',
  userGroupType: 'Benutzergruppen-Typ',
  userGroupUniqueMessage: 'Der Name muss sich von allen anderen Benutzergruppennamen für diesen Mandanten unterscheiden',
  userGroupUsers: 'Benutzergruppe-Benutzer',
  username: 'Benutzername',
  usernameEmpty: 'Benutzername darf nicht leer sein',
  users: 'Benutzer',
  usersWillReceiveAnUnauthorizedError: 'Beim Versuch, sich beim Mandanten anzumelden, wird den Benutzern die Fehlermeldung „Nicht autorisiert“ angezeigt.',
  useSniForSsoMetadataRetrieval: 'SNI für SSO-Metadatenabruf verwenden',
  value: 'Wert',
  validate: 'Validieren',
  validateTenantDirectories: {
    buttonLabel: 'Inhaltsverzeichnisse validieren',
    error: 'Inhaltsverzeichnisse konnten nicht validiert werden',
    success: 'Validierte Mandantenverzeichnisse',
  },
  variantSKU: 'Bestandseinheit-Variante',
  validationMessage: 'Validierungsnachricht | Validierungsnachrichten',
  valueGteError: 'Wert muss weniger oder gleich {max} betragen',
  valueLteError: 'Wert muss größer oder gleich {min} betragen',
  vendorName: 'Lieferantenname',
  verifyPassword: 'Passwort prüfen',
  vertical: 'Vertikal',
  version: 'Version',
  video: 'Video | Videos',
  view: 'Anzeigen',
  viewAll: 'Alle anzeigen',
  viewAllPagesAndBooks: 'Anzeigen: Alle Seiten und Bücher',
  viewAllResults: 'Alle Ergebnisse anzeigen',
  viewBooks: 'Anzeigen: Bücher',
  viewMore: 'Mehr anzeigen',
  viewPages: 'Anzeigen: Seiten',
  viewModeList: 'Listenansicht',
  viewModeTable: 'Tabellenansicht',
  visibility: 'Sichtbarkeit',
  visibilityDescription: 'Wer kann diese {entity} sehen',
  visible: 'Sichtbar',
  waitForUpload: 'Bitte warten Sie, bis das Hochladen abgeschlossen ist.',
  watermark: 'Wasserzeichen',
  watermarkFont: 'Schriftart Wasserzeichen',
  watermarkOpacityPercentage: '% Opazität Wasserzeichen',
  watermarkOrientation: 'Ausrichtung Wasserzeichen',
  watermarkSaved: 'Wasserzeichen gespeichert',
  watermarkSaveFailed: 'Wasserzeichen konnte nicht gespeichert werden.',
  watermarkSelectionRequired: 'Nutzername oder Textauswahl erforderlich.',
  watermarkText: 'Wasserzeichen-Text',
  wed: 'Mi.',
  whereUsed: 'Verwendungsnachweis',
  whoCanViewComment: 'Wer kann diesen Kommentar sehen?',
  wholesale: 'Großhandel',
  wholesalePrice: 'Großhandelspreis',
  wholesaleTotal: 'Großhandel Gesamt',
  widget: 'Widget',
  widgetAuthHeader: 'Widget-Autorisierungswert',
  widgetKey: 'Widget-Schlüssel | Widget-Schlüssel',
  widgetKeyAdmin: 'Widget-Schlüssel-Admin',
  widgetKeyCopied: 'Widget-Autorisierungswert in Zwischenablage kopiert',
  widgetKeyDeleted: 'Widget-Schlüssel gelöscht',
  widgetKeyDeletedError: 'Fehler beim Löschen des Widget-Schlüssels',
  widgetKeyDeleteMsg: 'Wenn der Widget-Schlüssel gelöscht wird, kann er nicht mehr verwendet werden.',
  widgetKeyManager: 'Widget-Schlüssel-Manager',
  widgetKeyNameEmpty: 'Der Name muss einzigartig sein und ist ein Pflichtfeld. Dieser Wert wird auch zu Berichts- und Protokollierungszwecken als Benutzername für jede Widget-Aktivität verwendet',
  widgetKeyRegenError: 'Fehler beim Generieren eines Widget-Schlüssels',
  widgetKeyRegenMsg: 'Die Neugenerierung dieses Widget-Schlüssels kann bestehende Integrationen deaktivieren.',
  widgetKeyRegenTitle: 'Diesen Widget-Schlüssel wirklich neu generieren?',
  widgetKeySaved: 'Widget-Schlüssel gespeichert',
  widgetPermissions: 'Widget-Benutzer können nur lesen und der Zugriff auf Funktionen wird über die Konfiguration auf Widget-Ebene verwaltet. Standardberechtigungen für Benutzergruppen gelten nicht für Widget-Benutzer, im Gegensatz zu den Zugriffsberechtigungen für Medienkategorien. Bitte fahren Sie mit der Zuweisung von Zugriffskontrollen für Medienkategorien fort.',
  widgetRefreshErrorMsg: "Bitte aktualisieren Sie Ihre Seite, um den gewünschten Inhalt anzuzeigen",
  widgetUserGroup: 'Widget-Benutzergruppe',
  widgetUserGroupDeleteMsg: 'Widget-Gruppe mit Benutzern kann nicht gelöscht werden',
  willBeAffected: 'wird beeinflusst.',
  willBeDeleted: '{content} wird gelöscht.',
  willBeInsertedIntoItem: '{name} wird in {item} eingefügt',
  willBeUpdated: 'wird aktualisiert.',
  zipCode: 'Postleitzahl',
  zoomHotpointToCanvas: 'Hotpoint auf Leinwandgröße zoomen',
  zoomIn: 'Heranzoomen',
  zoomOut: 'Herauszoomen'
}
/* eslint-enable max-len */
