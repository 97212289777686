<template>
  <div class="content-filter-bar">
    <div class="field is-horizontal">
      <div class="field-body">
        <div class="field has-addons"
             :class="{'justify-end' : !isMobile}"
        >
          <div class="control has-icons-left">
            <input type="text"
                   class="input"
                   data-int="grid-filter"
                   data-unit="grid-filter"
                   v-model="searchString"
                   :placeholder="$t('search')">
            <span class="icon is-left fas fa-search"/>
          </div>
        </div>
        <button class="button"
                :disabled="isFiltersEmpty"
                @click="clearFilters"
                data-int="grid-clear-filter"
                data-unit="grid-clear-filter">
          <span>
              {{ $t('clear') }}
          </span>
        </button>
      </div>
    </div>
    <div class="filter-options mb-4 justify-end">
      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label">{{ $t('advancedFilters') }}</label>
        </div>
        <div class="field-body">
          <div class="field justify-end"
          >
            <div class="control">
              <div class="select">
                <select class="input"
                        :class="{disabled: tagName === null}"
                        v-model="tagName"
                        :placeholder="$tc('tag', 1)"
                        @change="onTagNameChange"
                        :disabled="searchableTags.length === 0">
                  <option :value="null"
                          disabled>
                    <template v-if="searchableTags.length > 0">
                      {{ $tc('tag', 1) }}
                    </template>
                    <template v-else>
                      {{ $t('noSearchableTags') }}
                    </template>
                  </option>
                  <option v-for="tag in searchableTags"
                          :key="`tag-${tag.id}`"
                          :value="tag.name">
                    {{ tag.translatedName || tag.name || "Tag #" + tag.id }}
                  </option>
                </select>
              </div>
            </div>
            <div class="control">
              <span class="button is-static">
                &equals;
              </span>
            </div>
            <div class="control"
                 v-if="inputType === 'TEXTFIELD'">
              <input type="text"
                     class="input"
                     v-model="tagValue"
                     :placeholder="$t('value')"
                     :disabled="searchableTags.length < 1">
            </div>
            <div class="control"
                 v-else-if="inputType === 'LISTBOX'">
              <div class="select is-fullwidth">
                <select v-model="tagValue"
                        class="input"
                        :class="{disabled: tagValue === null}">
                  <option :disabled="true"
                          :value="null">
                    {{ $t('value') }}
                  </option>
                  <option v-for="(option, index) in tagNamePayload.selectOptions"
                          :key="`section-option-${index}`"
                          :value="option">
                    {{ option }}
                  </option>
                </select>
              </div>
            </div>
            <template v-else-if="inputType === 'RANGE'">
              <div class="control">
                <div class="select"
                     v-if="tagNamePayload.prefixes && tagNamePayload.prefixes.length">
                  <select v-model="prefix"
                          class="input"
                          :class="{disabled: prefix === null}">
                    <option :disabled="true"
                            :value="null">
                      {{ $tc('prefix', 2) }}
                    </option>
                    <option v-for="(prefix, index) in tagNamePayload.prefixes"
                            :key="index"
                            :value="prefix">
                      {{ prefix }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="control">
                <input type="text"
                       class="input"
                       v-model="tagValue"
                       :placeholder="$t('value')">
              </div>
              <div class="control">
                <div class="select"
                     v-if="tagNamePayload.suffixes && tagNamePayload.suffixes.length">
                  <select v-model="suffix"
                          class="input"
                          :class="{disabled: suffix === null}">
                    <option :disabled="true"
                            :value="null">
                      {{ $tc('suffix', 2) }}
                    </option>
                    <option v-for="(suffix, index) in tagNamePayload.suffixes"
                            :key="index"
                            :value="suffix">
                      {{ suffix }}
                    </option>
                  </select>
                </div>
              </div>
            </template>
            <div class="control"
                 v-else>
              <span class="button is-static">
                {{ $tc('value', 1) }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showPageBookSelect && !isMobile"
      class="filter-options mb-4 flex-end">
      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label">{{ $t('view') }}</label>
        </div>
        <div class="field-body">
          <div class="field has-addons">
            <div class="control">
              <div class="select">
                <select
                  v-model="partFilterValue">
                  <option value="ALL">
                    {{ $t('allPagesAndBooks') }}
                  </option>
                  <option value="BOOK">
                    {{ $tc('book', 2) }}
                  </option>
                  <option value="PAGE">
                    {{ $tc('page', 2) }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useStore } from 'vuex'
import { ref, computed, watch, onMounted } from "vue"
import debounce from 'lodash.debounce'
import rest from '@/http'

const emit = defineEmits(['updatePartFilter'])
const store = useStore()

const props = defineProps({
  gridName: {
    type: String,
    required: true
  },
  filters: {
    type: Object,
    required: true
  },
  hasSelections: {
    type: Boolean
  },
  isMobile: {
    type: Boolean,
    default: false
  },
  showPageBookSelect: {
    type: Boolean
  },
  partFilter: {
    type: String,
    required: true
  }
})

const searchableTags = ref([])
const searchString = ref('')
const tagName = ref('')
const tagValue = ref('')
const prefix = ref('')
const suffix = ref('')

const namedQueries = [
  'part_number_text_exact',
  'search',
  'tag_name',
  'tag_value',
  'tag_range_prefix',
  'tag_range_suffix'
]

const partFilterValue = computed({
  get: () => props.partFilter,
  set (val) {
    emit('updatePartFilter', val)
  }
})
const hasFilters = computed(() => {
  return props.filters?.tagsMatching
})
const queryParams = computed(() => {
  return store.getters['grids/searchFilters'](props.gridName)
})
const searchTerm = computed(() => {
  return store.getters['grids/filterTerm'](props.gridName)
})
const tagNamePayload = computed(() => {
  return searchableTags.value?.find((tn) => tn.name === tagName.value)
})
const searchTermEmpty = computed(() => {
  return (!searchTerm.value || !searchTerm.value?.length)
})
const inputType = computed(() => {
  return tagNamePayload.value && tagNamePayload.value.inputType
})
const effectiveFilter = computed(() => {
  const filter = {}
  if (tagName.value?.length > 0 && tagValue.value?.length > 0) {
    filter[`tag:${tagName.value}`] = tagValue.value
  }
  if (prefix.value?.length > 0) {
    filter.tag_range_prefix = prefix.value
  }
  if (suffix.value?.length > 0) {
    filter.tag_range_suffix = suffix.value
  }
  return filter
})
const filterCountApplied = computed(() => {
  return Object.keys(effectiveFilter.value).length
})
const isFiltersEmpty = computed(() => {
  return filterCountApplied.value === 0 && !searchTerm.value
})

const debounceSearchString = debounce(async function (val) {
  await store.dispatch('grids/setGridFilter', val)
}, 500)
const debounceSetFilterProp = debounce(async function (val) {
  await store.dispatch('grids/setFilterProp', val)
}, 500)

const clearFilters = async () => {
  searchString.value = ''
  tagName.value = ''
  tagValue.value = ''
  await store.dispatch('grids/setGridFilter', { grid: props.gridName, value: '' })
  await store.dispatch('grids/clearFilterProps', props.gridName)
}

const loadTagNames = async () => {
  try {
    const { data } = await rest.get('/tag-names')
    const matchValues = (match, value) => {
      if (!value) return false
      if (match instanceof Array) {
        return match.some((mv) => value.includes(mv))
      } else if (match instanceof Function) {
        return match(value)
      } else {
        return value.includes(match)
      }
    }
    const matchByKey = (match, value, key) => {
      return matchValues(match[key], value[key])
    }
    searchableTags.value = data.filter((tag) =>
      Object.keys(props.filters?.tagsMatching)
        .every((tagKey) => matchByKey(props.filters?.tagsMatching, tag, tagKey))
    )
  } catch (error) {
    console.log('error :>> ', error)
  }
}

const onTagNameChange = () => {
  prefix.value = ''
  suffix.value = ''
  tagValue.value = ''
}

const resetTagFields = () => {
  tagName.value = ''
  onTagNameChange()
}

const checkForPersistedState = () => {
  if (Object.keys(queryParams.value).length > 0) {
    const query = { ...queryParams.value }
    // Filters defined within this component
    for (const property of namedQueries) {
      // Null is typically the value for placeholder select option items
      const value = query[property] ? query[property] : null
      const formattedValue = value?.replaceAll(/[[\]]/g, '').split(' TO ')
      switch (property) {
        case 'search':
          searchString.value = searchTerm.value
          break
        case 'tag_name':
          tagName.value = value
          break
        case 'tag_value':
          tagValue.value = value
          break
        case 'tag_range_prefix':
          prefix.value = value
          break
        case 'tag_range_suffix':
          suffix.value = value
          break
        default:
      }
    }
  }
}

watch(effectiveFilter, (filter, oldFilter) => {
  const q = { ...queryParams.value }
  for (const o in oldFilter) {
    if (Object.prototype.hasOwnProperty.call(oldFilter, o)) {
      // Tags name and value need their hand held... gently
      if (o.split(':')[0] === 'tag') {
        delete q[o]
        delete q.tag_name
        delete q.tag_value
      } else {
        delete q[o]
      }
    }
  }
  for (const f in filter) {
    if (Object.prototype.hasOwnProperty.call(filter, f) && filter[f]) {
      // Tags name and value need their hand held... gently
      if (f.split(':')[0] === 'tag') {
        q.tag_name = f.split(':')[1]
        q.tag_value = filter[f]
      }
      q[f] = filter[f]
    }
  }
  debounceSetFilterProp({ grid: props.gridName, values: q })
})

watch(searchString, (value) => {
  debounceSearchString({ grid: props.gridName, value: value })
})

watch(tagName, (name) => {
  const q = { ...props.filters.queryParams }
  if (name && !tagValue.value) {
    q.tag_name = name
  } else if (!name && !tagValue.value) {
    delete q.tag_name
  }
})

onMounted(async () => {
  await loadTagNames()
  checkForPersistedState()
})

</script>
<style scoped lang="scss">
.content-filter-bar {
  position: relative;
  margin-right: 0.5rem;
}

.justify-end {
  display: flex;
  justify-content: flex-end;
  @media only screen and (max-width: 770px) {
    justify-content: flex-start !important;
  }
}

.field-label {
  flex-basis: fit-content;
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: 1.5rem;
  @media only screen and (min-width: 1023px) {
    text-align: right;
  }
}

.field-body {
  display: flex;
  flex-basis: 0;
  flex-grow: 5;
  flex-shrink: 1;
}

.is-field-body-text {
padding-top: 0.5rem;
margin-right: 0.75rem;
}

select.disabled {
color: grey;
font-style: italic;
}

.has-spacer {
margin-left: 0.75rem;
}
</style>
